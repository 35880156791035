import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CtcConversionCreateForm from "./CtcConversionCreateForm";
import CardBody from "../../Card/CardBody";
import GridItem from "../../Grid/GridItem";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import GridContainer from "../../Grid/GridContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const CtcConversionCreate = ({ location, history }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName } = userInfo;
  const posName = storeName && storeName.length > 0 ? storeName : warehouseName && warehouseName.length > 0 ? warehouseName : ""
  const parentUser = name && name.length > 0 ? name : ""
  return (
      <GridContainer spacing={2} >
        <GridItem xs={12} style={{ textAlign:"center", marginLeft:"auto", marginRight:"auto"}}>
          <Card style={{width: '100%', marginLeft:"auto", marginRight:"auto"}}>
            <CardHeader>
              <b><h2 align="center">CTC Raw material - CTC Powder Conversion </h2></b>
            </CardHeader>
            <CardBody>
              <CtcConversionCreateForm />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
  );
};

export default CtcConversionCreate;
