import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Message from "../Message";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import { Typography, Grid, Button, TextField, Tooltip, Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  listBulkByProductId,
  updateBulkByProductId,
  deleteBulkByProductId,
} from "../../actions/bulkAction";
import { listAllProducts } from "../../actions/productAction";
import { useNavigate, useParams } from "react-router-dom";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const BulkListScreen = (props) => {
  const navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const gridRef = useRef();
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      filter: false,
      floatingFilter: false,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "UnitOfMeassure",
      headerName: "UOM",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300
    },
    {
      field: "InKgs",
      headerName: "In Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "UnitPrice",
      headerName: "Unit Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "Mrp",
      headerName: "MRP",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "DiscountStartsFrom",
      headerName: "Discount for [Kgs]",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      field: "DiscountPercent",
      headerName: "Discount [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      field: "CGST",
      headerName: "CGST [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "SGST",
      headerName: "SGST [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "IGST",
      headerName: "IGST [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "TCS",
      headerName: "TCS [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },

    {
      field: "Id",
      headerName: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Edit Bulk Details" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "Id",
      headerName: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Delete Bulk Details" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("Deleting ", params.value)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const popupParent = useMemo(() => {
    return document.body;
  }, []); 

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const productId = id;
  console.log("productId : ", productId);
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  let productName = "";

  const allProducts = useSelector((state) => state.allProducts);
  const { all_products } = allProducts;

  const [open, setOpen] = useState(() => {
    return false;
  });
  const [editableBulkRecord, setEditableBulkRecord] = useState(() => {
    return {};
  });

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  useEffect(() => {
    console.log("useEffect Exec /...");
    dispatch(listBulkByProductId(productId));
  }, [dispatch, productId, editableBulkRecord]);

  useEffect(() => {
    dispatch(listAllProducts());
  }, [dispatch]);

  const unitOfMessureChangeHandler = (unitOfMessure) => {
    setEditableBulkRecord({
      ...editableBulkRecord,
      unitOfMessure: unitOfMessure,
    });
    console.log(editableBulkRecord);
  };
  const inKgsChangeHandler = (inKgs) => {
    setEditableBulkRecord({
      ...editableBulkRecord,
      inKgs: inKgs,
    });
  };

  const unitPriceChangeHandler = (unitPrice) => {
    setEditableBulkRecord({
      ...editableBulkRecord,
      unitPrice: unitPrice,
    });
    console.log(editableBulkRecord);
  };
  const mrpChangeHandler = (mrp) => {
    setEditableBulkRecord({
      ...editableBulkRecord,
      mrp: mrp,
    });
    console.log(editableBulkRecord);
  };
  const discountChangeHandler = (discount) => {
    setEditableBulkRecord({
      ...editableBulkRecord,
      discountStartsFrom: discount,
    });
    console.log(editableBulkRecord);
  };
  const discountPercentChangeHandler = (discountPercent) => {
    setEditableBulkRecord({
      ...editableBulkRecord,
      discountPercent: discountPercent,
    });
    console.log(editableBulkRecord);
  };

  const cgstChangeHandler = (cgst) => {
    setEditableBulkRecord({ ...editableBulkRecord, cgst: cgst });
    console.log(editableBulkRecord);
  };
  const sgstChangeHandler = (sgst) => {
    setEditableBulkRecord({ ...editableBulkRecord, sgst: sgst });
    console.log(editableBulkRecord);
  };
  const igstChangeHandler = (igst) => {
    setEditableBulkRecord({ ...editableBulkRecord, igst: igst });
    console.log(editableBulkRecord);
  };
  const tcsChangeHandler = (tcs) => {
    setEditableBulkRecord({ ...editableBulkRecord, tcs: tcs });
    console.log(editableBulkRecord);
  };

  const getProductNameByProductId = (prdId) => {
    const result =
      all_products &&
      all_products.length > 0 &&
      all_products.filter((product) => product._id === prdId);
    console.log(
      "Filter RESULT ::==>> ",
      result ? result.length : " Filter couldnt foind any..! ",
      result
    );
    return result;
  };

  if (productId) {
    const resArray = getProductNameByProductId(productId);
    productName = resArray && resArray.length > 0 ? resArray[0].name : "";
  }

  const handleEdit = (params) => {
    console.log("Bulk ID SELECTED : ", params);
    console.log("Bulk params", params.data.Id, params.data);
    setOpen(true);
    setEditableBulkRecord({
      _id: params.data.Id,
      unitOfMessure: params.data.UnitOfMeassure,
      unitPrice: params.data.UnitPrice,
      mrp: params.data.Mrp,
      discountStartsFrom: params.data.DiscountStartsFrom,
      discountPercent: params.data.DiscountPercent,
      cgst: params.data.CGST,
      sgst: params.data.SGST,
      igst: params.data.IGST,
      tcs: params.data.TCS,
    });
  };

  const handleDelete = (bulkRec) => {
    console.log("handleDelete Exec..." + bulkRec._id);
    console.log("ID SELECTED : " + bulkRec._id);
    dispatch(deleteBulkByProductId(bulkRec._id));
  };

  const createBulkItemHandler = () => {
    console.log("Before Push  product :" + productId);
    navigate(`/admin/bulk/new/${productId}`);
  };

  const submitHandler = () => {
    console.log("EXEC submitHandler");
    console.log(editableBulkRecord);
    dispatch(
      updateBulkByProductId(
        editableBulkRecord._id,
        editableBulkRecord.unitOfMessure,
        editableBulkRecord.inKgs,
        editableBulkRecord.unitPrice,
        editableBulkRecord.mrp,
        editableBulkRecord.discountStartsFrom,
        editableBulkRecord.discountPercent,
        editableBulkRecord.cgst,
        editableBulkRecord.sgst,
        editableBulkRecord.igst,
        editableBulkRecord.tcs
      )
    );
    setOpen(false);
    setEditableBulkRecord({});
  };

  const bulkList = useSelector((state) => state.bulkListByProductId);
  const { loading, bulk, error } = bulkList;

  let renderBulk = "";

  if (bulk && bulk.length > 0) {
    rowData = bulk.map((eachBulkEntry, index) => {
      console.log("eachBulkEntry : ", eachBulkEntry);
      return {
        "#": index + 1,
        UnitOfMeassure: eachBulkEntry.unitOfMessure,
        InKgs: eachBulkEntry.inKgs,
        unitPrice: eachBulkEntry.unitPrice,
        Mrp: eachBulkEntry.mrp,
        DiscountStartsFrom: eachBulkEntry.discountStartsFrom,
        DiscountPercent: eachBulkEntry.discountPercent,
        CGST: eachBulkEntry.cgst,
        SGST: eachBulkEntry.sgst,
        IGST: eachBulkEntry.igst,
        TCS: eachBulkEntry.tcs,
        Id: eachBulkEntry._id,
        Id: eachBulkEntry._id,
      };
    });

    renderContentUsingAGGrid = (
      <>
        <div
        className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
            // domLayout='autoHeight'
          ></AgGridReact>
        </div>
      </>
    );
  }

  return (
    <React.Fragment>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} style= {{marginTop:"1rem"}}>
          <Tooltip title="Back" arrow>
            <ArrowBackIcon
              fontSize="medium"
              onClick={() => window.history.back()}
              style={{ float: "left" }}
            />
          </Tooltip>
          <Tooltip title="Add Bulk" arrow>
            <AddCircleIcon
              fontSize="medium"
              style={{ float: "left" }}
              onClick={createBulkItemHandler}
            />
          </Tooltip>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Bulk Entries for Product : {productId ? productName : productId}
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon fontSize="medium" style={{ float: "right", margin: "1rem" }} onClick={onExportClick} />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader >
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    >

                    </Typography>
                    <h2 align="center"> Edit Bulk Entry </h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={submitHandler}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: "1.5rem",
                          marginBottom: "1.5rem",
                        }}
                      >
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Units Of Messure"
                            variant="outlined"
                            name="uom"
                            onChange={(e) =>
                              unitOfMessureChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              editableBulkRecord &&
                                editableBulkRecord.unitOfMessure
                                ? editableBulkRecord.unitOfMessure
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="In Kgs"
                            variant="outlined"
                            name="inKgs"
                            onChange={(e) => inKgsChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              editableBulkRecord && editableBulkRecord.inKgs
                                ? editableBulkRecord.inKgs
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Unit Price"
                            variant="outlined"
                            name="unitPrice"
                            id="unitPrice"
                            onChange={(e) =>
                              unitPriceChangeHandler(e.target.value)
                            }
                            type="number"
                            size="small"
                            value={
                              editableBulkRecord &&
                                editableBulkRecord.unitPrice
                                ? editableBulkRecord.unitPrice
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="MRP"
                            variant="outlined"
                            name="mrp"
                            id="mrp"
                            onChange={(e) =>
                              mrpChangeHandler(e.target.value)
                            }
                            type="number"
                            size="small"
                            value={
                              editableBulkRecord &&
                                editableBulkRecord.mrp
                                ? editableBulkRecord.mrp
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Discount Starts From"
                            variant="outlined"
                            name="discountStartsFrom"
                            id="discountStartsFrom"
                            onChange={(e) =>
                              discountChangeHandler(e.target.value)
                            }
                            type="number"
                            size="small"
                            value={
                              editableBulkRecord &&
                                editableBulkRecord.discountStartsFrom
                                ? editableBulkRecord.discountStartsFrom
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Discount [%]"
                            variant="outlined"
                            name="discountPercent"
                            id="discountPercent"
                            onChange={(e) =>
                              discountPercentChangeHandler(e.target.value)
                            }
                            type="number"
                            size="small"
                            value={
                              editableBulkRecord &&
                                editableBulkRecord.discountPercent
                                ? editableBulkRecord.discountPercent
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="CGST"
                            variant="outlined"
                            name="taxPercent"
                            id="cgst"
                            onChange={(e) => cgstChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              editableBulkRecord && editableBulkRecord.cgst
                                ? editableBulkRecord.cgst
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="SGST"
                            variant="outlined"
                            name="sgst"
                            id="sgst"
                            onChange={(e) => sgstChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              editableBulkRecord && editableBulkRecord.sgst
                                ? editableBulkRecord.sgst
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="IGST"
                            variant="outlined"
                            name="igst"
                            id="igst"
                            onChange={(e) => igstChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              editableBulkRecord && editableBulkRecord.igst
                                ? editableBulkRecord.igst
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="TCS"
                            variant="outlined"
                            name="tcs"
                            id="tcs"
                            onChange={(e) => tcsChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              editableBulkRecord && editableBulkRecord.tcs
                                ? editableBulkRecord.tcs
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          style={{
                            textTransform: "capitalize",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "auto",
                            marginTop: "1.5rem",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default BulkListScreen;
