export const VEHICLE_STOCK_LIST_BY_VEHICLE_NO_REQUEST = "VEHICLE_STOCK_LIST_BY_VEHICLE_NO_REQUEST";
export const VEHICLE_STOCK_LIST_BY_VEHICLE_NO_SUCCESS = "VEHICLE_STOCK_LIST_BY_VEHICLE_NO_SUCCESS";
export const VEHICLE_STOCK_LIST_BY_VEHICLE_NO_FAIL = "VEHICLE_STOCK_LIST_BY_VEHICLE_NO_FAIL";
export const VEHICLE_STOCK_LIST_BY_VEHICLE_NO_RESET = "VEHICLE_STOCK_LIST_BY_VEHICLE_NO_RESET";

export const VEHICLE_STOCK_BY_VEHICLE_NO_REQUEST =
  "VEHICLE_STOCK_BY_VEHICLE_NO_REQUEST";
export const VEHICLE_STOCK_BY_VEHICLE_NO_SUCCESS =
  "VEHICLE_STOCK_BY_VEHICLE_NO_SUCCESS";
export const VEHICLE_STOCK_BY_VEHICLE_NO_FAIL =
  "VEHICLE_STOCK_BY_VEHICLE_NO_FAIL";
  export const VEHICLE_STOCK_BY_VEHICLE_NO_RESET =
  "VEHICLE_STOCK_BY_VEHICLE_NO_RESET";

export const VEHICLE_STOCK_DELETE_BY_ID_REQUEST = "VEHICLE_STOCK_DELETE_BY_ID_REQUEST";
export const VEHICLE_STOCK_DELETE_BY_ID_SUCCESS = "VEHICLE_STOCK_DELETE_BY_ID_SUCCESS";
export const VEHICLE_STOCK_DELETE_BY_ID_FAIL = "VEHICLE_STOCK_DELETE_BY_ID_FAIL";

export const VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST = "VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST";
export const VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS = "VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS";
export const VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL = "VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL";
export const VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET = "VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET";

export const VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST = "VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST";
export const VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS = "VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS";
export const VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL = "VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL";
export const VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET = "VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET";

export const DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_REQUEST = "DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_REQUEST";
export const DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_SUCCESS = "DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_SUCCESS";
export const DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_FAIL = "DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_FAIL";
export const DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_RESET = "DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_RESET";

export const VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST = "VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST";
export const VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS = "VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS";
export const VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL = "VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL";
export const VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET = "VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET";

