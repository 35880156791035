import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../actions/customerAction.js";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_REGISTER_RESET } from "../../constants/customerConstants.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  contactNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 10,
      maximum: 10,
    },
  },
  billingAddress: {
    presence: { allowEmpty: false, message: "is required" },
  },
  email: {
    presence: { allowEmpty: true, message: "is required" },
    email: {
      message: "doesn't look like a valid email",
    },
    length: {
      maximum: 200,
    },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
  },
  state: {
    presence: { allowEmpty: false, message: "is required" },
  },
  pincode: {
    presence: { allowEmpty: true, message: "is required" },
  },

};

const CustomerSignupForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customerRegistered = useSelector((state) => state.customerRegistered);
  const { customer_register_loading, customer_register_error, customer_register_success, customer_register } = customerRegistered;
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name , storeName, warehouseName } = userInfo;
  const pos = storeName && storeName.length >0 ? storeName:warehouseName && warehouseName.length >0 ?warehouseName :""
  const parentUser = name && name.length >0 ? name : ""

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

useEffect(()=>{
  if(customer_register_success){
    console.log('Registered Customer so Routing to List Page: ')
    dispatch({
      type: CUSTOMER_REGISTER_RESET,
    });
    navigate('/admin/customers');
  }
},[customer_register_success,navigate, dispatch])

useEffect(()=>{
  console.log('userInfo : ', userInfo)
  console.log('POS : ', pos, 'parentUser : ', parentUser)
},[])

  React.useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "select"
            ? event.target.selected
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    console.log("Values Submitted are : ", formState.values);
    event.preventDefault();
    console.log("formState.isValid : ", formState.isValid);
    if (formState.isValid)
      dispatch(
        register(
          formState.values.name,
          formState.values.contactNo,
          formState.values.email,
          formState.values.billingAddress,
          parentUser,
          formState.values.gst,
          formState.values.city,
          formState.values.district,
          formState.values.state,
          formState.values.pincode,
          pos
        )
      );

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      {customer_register_error && <Message variant="danger">{customer_register_error}</Message>}
      {customer_register_loading && <CustomBackdropSpinner />}
      <form method="post" onSubmit={handleSubmit}>

        <Grid container spacing={2} justifyContent="center" marginTop="auto">
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Name"
              label="Name*"
              variant="outlined"
              style={{ width: "32vh" }}
              size="small"
              name="name"
              helperText={
                hasError("name") ? formState.errors.name[0] : null
              }
              error={hasError("name")}
              onChange={handleChange}
              type="text"
              value={formState.values.name || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Contact No"
              label="Contact No*"
              variant="outlined"
              size="small"
              name="contactNo"
              style={{ width: "32vh" }}
              helperText={
                hasError("contactNo") ? formState.errors.contactNo[0] : null
              }
              error={hasError("contactNo")}
              onChange={handleChange}
              type="number"
              value={formState.values.contactNo || ""}
            />
          </Grid>

          <Grid item xs={12} align="center">
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="small"
              name="email"
              style={{ width: "32vh" }}
              helperText={
                hasError("email") ? formState.errors.email[0] : null
              }
              error={hasError("email")}
              onChange={handleChange}
              type="email"
              value={formState.values.email || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Billing Address"
              label="Billing Address *"
              variant="outlined"
              size="small"
              name="billingAddress"
              style={{ width: "32vh" }}
              helperText={
                hasError("billingAddress") ? formState.errors.billingAddress[0] : null
              }
              error={hasError("billingAddress")}
              onChange={handleChange}
              type="text"
              value={formState.values.billingAddress || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="GST"
              label="GST *"
              variant="outlined"
              size="small"
              name="gst"
              style={{ width: "32vh" }}
              onChange={handleChange}
              type="text"
              value={formState.values.gst || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="City"
              label="City *"
              variant="outlined"
              size="small"
              name="city"
              style={{ width: "32vh" }}
              helperText={
                hasError("city") ? formState.errors.city[0] : null
              }
              error={hasError("city")}
              onChange={handleChange}
              type="text"
              value={formState.values.city || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="District"
              label="District *"
              variant="outlined"
              size="small"
              name="district"
              style={{ width: "32vh" }}
              onChange={handleChange}
              type="text"
              value={formState.values.district || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="State"
              label="State *"
              variant="outlined"
              size="small"
              name="state"
              style={{ width: "32vh" }}
              helperText={
                hasError("state") ? formState.errors.state[0] : null
              }
              error={hasError("state")}
              onChange={handleChange}
              type="text"
              value={formState.values.state || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Pincode"
              label="Pincode *"
              variant="outlined"
              size="small"
              name="pincode"
              style={{ width: "32vh" }}
              helperText={
                hasError("pincode") ? formState.errors.pincode[0] : null
              }
              error={hasError("pincode")}
              onChange={handleChange}
              type="text"
              value={formState.values.pincode || ""}
            />
          </Grid>
         
          <Grid item xs={12} align="center">
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              // disabled = {!formState.isValid}
              style={{
                textTransform: "capitalize",
                width: "25vh",
                borderRadius: "5px",
              }}
            >
              Register Customer
            </Button>
          </Grid>
        </Grid>

      </form>
    </div>
  );
};

export default CustomerSignupForm;
