import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardBody from "../Card/CardBody.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {
  updateCategory,
  deleteCategory,
  listCategories,
} from "../../actions/categoryAction";
import Paper from "@mui/material/Paper";
import { Typography, Grid, Button, TextField, Tooltip, Divider } from "@mui/material";
import {
  CATEGORY_CREATE_RESET,
  CATEGORY_UPDATE_RESET,
} from "../../constants/categoryConstants";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import Message from "../Message.js";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const styles = {
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      fontWeight: "700",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
  trStyle: {
    borderBottom: "3px",
    borderColor: "gray",
  },
};

const CategoryListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const gridRef = useRef();
  const navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    // { field: "Id", resizable: true, suppressSizeToFit: true },
    { field: "Name", resizable: true, suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250 },
    { field: "Description", resizable: true, suppressSizeToFit: true },
    {
      field: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Edit Category" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer : (params) => (
        <span>
          <Tooltip title="Delete Category" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("Deleting ", params.value)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const dispatch = useDispatch();

  const [open, setOpen] = useState(() => false);
  const [filteredCat, setFilteredCat] = useState(() => { });
  const [idSelected, setIdSelected] = useState(() => {
    return "";
  });

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;
  const catgs = categories.categories;

  const categoryDeleted = useSelector((state) => state.categoryDeleted);
  const { success_delete } = categoryDeleted;
  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const { success_update } = categoryUpdate;


  useEffect(() => {
    console.log("useEffect Getting Called CategoryListScreen");
    dispatch(listCategories());
  }, [dispatch, navigate, success_update, success_delete, open]);

  const createCategoryHandler = (category) => {
    navigate("/admin/category/new");
  };

  const nameChangeHandler = (nm) => {
    setFilteredCat({ ...filteredCat, name: nm });
    console.log(filteredCat);
  };

  const descChangeHandler = (dsc) => {
    setFilteredCat({ ...filteredCat, description: dsc });
    console.log(filteredCat);
  };

  const handleEdit = (params) => {
    console.log(
      "catg params",
      params.data.Edit,
      params.data.Name,
      params.data.Description,
      params
    );
    setOpen(true);
    console.log("ID SELECTED : " + params.data.Edit);
    setFilteredCat({
      id: params.data.Edit,
      name: params.data.Name,
      description: params.data.Description,
    });
  };

  const handleDelete = (catg) => {
    console.log("handleDelete Exec..." + catg._id);
    setIdSelected(catg._id);
    dispatch(deleteCategory(catg._id));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log("EXEC submitHandler");
    console.log(filteredCat);
    dispatch(
      updateCategory(filteredCat.id, filteredCat.name, filteredCat.description)
    );
    setFilteredCat({});
    setOpen(false);
  };

  if (catgs && catgs.length > 0) {
    rowData = catgs.map((catg, index) => {
      console.log("catg : ", catg);
      return {
        "#": index + 1,
        Name: catg.name,
        Description: catg.description,
        Edit: catg._id,
        Delete: catg._id,
      };
    });
  }
  renderContentUsingAGGrid = (
    <>
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
          // domLayout='autoHeight'
        ></AgGridReact>
      </div>
    </>
  );

  return (
    <React.Fragment>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{ fontWeight: 500, textAlign: "center" }}>
                  <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em" }}
                    />
                  </Tooltip>
                  Categories
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={createCategoryHandler}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "right", marginRight: "10px" }}
                    />
                    Create Category
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        {rowData && rowData.length > 0 && (
          <GridItem xs={12} sm={12} md={12}>
            <Tooltip title="Export > Excel" arrow>
              <IosShareRoundedIcon
                fontSize="medium"
                style={{ float: "right", margin: "0.5rem" }}
                onClick={onExportClick}
              />
            </Tooltip>
          </GridItem>
        )
        }
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader >
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    >

                    </Typography>
                    <h2 align="center"> Edit Category </h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={submitHandler}>
                      <Grid container spacing={2} justifyContent="center" margin="auto">
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Name"
                            variant="outlined"
                            name="name"
                            onChange={(e) => nameChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredCat && filteredCat.name
                                ? filteredCat.name
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} align="center">
                          <TextField
                            className={classes.inputText}
                            placeholder="Description"
                            variant="outlined"
                            name="description"
                            id="description"
                            onChange={(e) => descChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              filteredCat && filteredCat.description
                                ? filteredCat.description
                                : ""
                            }
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>

                        <Grid item xs={12} align="center">
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                          >
                            UPDATE
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default CategoryListScreen;
