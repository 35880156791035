import {
  STORE_PURCHASE_ORDER_LIST_REQUEST,
  STORE_PURCHASE_ORDER_LIST_SUCCESS,
  STORE_PURCHASE_ORDER_LIST_FAIL,
  
  STORE_PURCHASE_ORDER_BY_ID_REQUEST,
  STORE_PURCHASE_ORDER_BY_ID_SUCCESS,
  STORE_PURCHASE_ORDER_BY_ID_FAIL,

  STORE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST,
  STORE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS,
  STORE_PURCHASE_ORDER_DELETE_BY_ID_FAIL,

  STORE_PURCHASE_ORDER_CREATE_RESET,
  STORE_PURCHASE_ORDER_CREATE_FAIL,
  STORE_PURCHASE_ORDER_CREATE_SUCCESS,
  STORE_PURCHASE_ORDER_CREATE_REQUEST,

  STORE_PURCHASE_ORDER_UPDATE_BY_ID_REQUEST,
  STORE_PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS,
  STORE_PURCHASE_ORDER_UPDATE_BY_ID_FAIL,
  STORE_PURCHASE_ORDER_UPDATE_BY_ID_RESET,
  STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST,
  STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL,
  STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS,
  STORE_PURCHASE_ORDER_DELETE_BY_ID_RESET,
  STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_RESET,
  STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_FAIL,
  STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_SUCCESS,
  STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_REQUEST,
  STORE_PURCHASE_ORDER_QUANTITY_UPDATE_REQUEST,
  STORE_PURCHASE_ORDER_QUANTITY_UPDATE_SUCCESS,
  STORE_PURCHASE_ORDER_QUANTITY_UPDATE_FAIL,
  STORE_PURCHASE_ORDER_QUANTITY_UPDATE_RESET,
  STORE_PURCHASE_ORDER_BY_ID_RESET,
} from "../constants/storePurchaseOrderConstants";

export const storePurchaseOrdersListReducer = (state = { storePurchaseOrders: [] }, action) => {
  switch (action.type) {
    case STORE_PURCHASE_ORDER_LIST_REQUEST:
      return { store_po_list_loading: true, storePurchaseOrders: [] };
    case STORE_PURCHASE_ORDER_LIST_SUCCESS:
      return {
        store_po_list_loading: false,
        storePurchaseOrders: action.payload,
      };
    case STORE_PURCHASE_ORDER_LIST_FAIL:
      return { store_po_list_loading: false, error: action.payload };
    default:
      return state;
  }
};

export const storePurchaseOrderByIdReducer = (
  state = { },
  action
) => {
  switch (action.type) {
    case STORE_PURCHASE_ORDER_BY_ID_REQUEST:
      return { store_po_by_id_loading: true, store_po_by_id_store_po_data:{}  };
    case STORE_PURCHASE_ORDER_BY_ID_SUCCESS:
      return {
        store_po_by_id_loading: false,
        store_po_by_id_data: action.payload,
      };
    case STORE_PURCHASE_ORDER_BY_ID_FAIL:
      return { store_po_by_id_loading: false, store_po_by_id_error: action.payload };
      case STORE_PURCHASE_ORDER_BY_ID_RESET:
      return {  };
    default:
      return state;
  }
};

export const storePurchaseOrderByPosReducer = (
  state = { store_po_pos_data: {} },
  action
) => {
  switch (action.type) {
    case STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST:
      return { store_po_pos_loading: true, store_po_pos_data:{}  };
    case STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS:
      return {
        store_po_pos_loading: false,
        store_po_pos_data: action.payload,
      };
    case STORE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL:
      return { store_po_pos_loading: false, store_po_pos_error: action.payload };
    default:
      return state;
  }
};

export const storePurchaseOrderDeleteByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST:
      return { store_po_delete_loading: true };
    case STORE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS:
      return { store_po_delete_loading: false, store_po_delete_success: true };
    case STORE_PURCHASE_ORDER_DELETE_BY_ID_FAIL:
      return { store_po_delete_loading: false, store_po_delete_error: action.payload };
      case STORE_PURCHASE_ORDER_DELETE_BY_ID_RESET:
        return {  };
    default:
      return state;
  }
};


export const storePurchaseOrderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_PURCHASE_ORDER_CREATE_REQUEST:
      return { store_po_create_loading: true };
    case STORE_PURCHASE_ORDER_CREATE_SUCCESS:
      return { store_po_create_loading: false, store_po_create_success: true, store_po_create_data: action.payload };
    case STORE_PURCHASE_ORDER_CREATE_FAIL:
      return { store_po_create_loading: false, store_po_create_error: action.payload };
    case STORE_PURCHASE_ORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const storePurchaseOrderUpdateByIdReducer = (state = { storePurchaseOrder: {} }, action) => {
  switch (action.type) {
    case STORE_PURCHASE_ORDER_UPDATE_BY_ID_REQUEST:
      return { store_po_update_loading: true };
    case STORE_PURCHASE_ORDER_UPDATE_BY_ID_SUCCESS:
      return { store_po_update_loading: false, store_po_update_success: true, storePurchaseOrder: action.payload };
    case STORE_PURCHASE_ORDER_UPDATE_BY_ID_FAIL:
      return { store_po_update_loading: false, store_po_update_error: action.payload };
    case STORE_PURCHASE_ORDER_UPDATE_BY_ID_RESET:
      return { storePurchaseOrder: {} };
    default:
      return state;
  }
};

export const storePurchaseOrderPaymentUpdateByIdReducer = (state = { storePurchaseOrder: {} }, action) => {
  switch (action.type) {
    case STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_REQUEST:
      return { store_po_payment_update_loading: true };
    case STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_SUCCESS:
      return { store_po_payment_update_loading: false, store_po_payment_update_success: true, store_po_payment_update: action.payload };
    case STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_FAIL:
      return { store_po_payment_update_loading: false, store_po_payment_update_error: action.payload };
    case STORE_PURCHASE_ORDER_PAYMENT_UPDATE_BY_ID_RESET:
      return { storePurchaseOrder: {} };
    default:
      return state;
  }
};


export const storePurchaseOrderQtyUpdateByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_PURCHASE_ORDER_QUANTITY_UPDATE_REQUEST:
      return { store_po_qty_update_loading : true };
    case STORE_PURCHASE_ORDER_QUANTITY_UPDATE_SUCCESS:
      return { store_po_qty_update_loading : false, store_po_qty_update: true, store_po_qty_update_data: action.payload };
    case STORE_PURCHASE_ORDER_QUANTITY_UPDATE_FAIL:
      return { store_po_qty_update_loading : false, store_po_qty_update_error: action.payload };
    case STORE_PURCHASE_ORDER_QUANTITY_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
