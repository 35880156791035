import React, { useEffect, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { listUsers, deleteUser } from "../../actions/userAction";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button } from "@mui/material";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../Message";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const AdminUsersListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75
    },
    {
      field: "Name",
      headerName: "Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      field: "User Name",
      headerName: "User Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: "Email",
      headerName: "Email",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "Role",
      headerName: "Role",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
    },
    {
      field: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRendererFramework: (params) => (

        <span>
          {console.log("params ", params)}
          <Tooltip title="Edit User" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => navigate(`/admin/edit-user/${params.value}`)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip></span>
      ), sortable: false,
      filter: false,
      floatingFilter: false
    },
    {
      field: "Delete", resizable: true, suppressSizeToFit: true, width: 125, minWidth: 125, maxWidth: 125,
      cellRendererFramework: (params) => (
        <span><Tooltip title="Delete" arrow>
          <DeleteRoundedIcon
            size="small"
            variant="contained"
            type="submit"
            color="Gray"
            justify="center"
            onClick={() => console.log("Deleting ", params.value)}
            style={{ marginRight: "1rem", width: "2rem" }}
          ></DeleteRoundedIcon>
        </Tooltip></span>
      ), sortable: false,
      filter: false,
      floatingFilter: false
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const export_label = 'Export > CSV'
  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userDeleted = useSelector((state) => state.userDeleted);
  const { success_delete } = userDeleted;

  useEffect(() => {
    console.log(" Loading Users List  ");
    dispatch(listUsers());
  }, [dispatch]);

  useEffect(() => {
    if (success_delete) {
      console.log(" Loading Users List  ");
      dispatch(listUsers());
    }
  }, [success_delete, dispatch, navigate]);

  const deleteHandler = (id) => {
    dispatch(deleteUser(id));
  };

  const handleAddUser = (e) => {
    e.preventDefault();
    navigate("/admin/users/create");
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  /**
   * Start AG Grid
   */
  if (users && users.length > 0) {
    rowData = users.map((user, index) => {
      console.log(user);
      return {
        "#": index + 1,
        Name: user.name,
        "User Name": user.userName,
        Email: user.email,
        Role: user.role,
        Edit: user._id,
        Delete: user._id,
      };
    })
    renderContentUsingAGGrid = (
      <>
        <div className="ag-theme-quartz" style={{ width: "100%", height: '65vh' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          // domLayout='autoHeight'
          ></AgGridReact>
        </div>
      </>
    );
  }
  /**
   * End AG Grid
   */
  return (
    <React.Fragment>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{
                  fontWeight: 500,
                  textAlign: "Center"
                }}>
                  <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em", textAlign: "center" }}
                    />
                  </Tooltip>
                  Users
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={handleAddUser}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "left", marginRight: "10px" }}
                    />
                    Create User
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon fontSize="medium" style={{ float: "right", margin: "1rem" }} onClick={onExportClick} />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default AdminUsersListPage;
