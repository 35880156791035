import {
  CTC_PRODUCTION_DETAILS_BY_ID_FAIL,
  CTC_PRODUCTION_DETAILS_BY_ID_REQUEST,
  CTC_PRODUCTION_DETAILS_BY_ID_SUCCESS,
  CTC_PRODUCTION_DETAILS_BY_ID_RESET,

  CTC_PRODUCTION_REGISTER_FAIL,
  CTC_PRODUCTION_REGISTER_REQUEST,
  CTC_PRODUCTION_REGISTER_SUCCESS,
  CTC_PRODUCTION_REGISTER_RESET,
 
  CTC_PRODUCTION_LIST_FAIL,
  CTC_PRODUCTION_LIST_SUCCESS,
  CTC_PRODUCTION_LIST_REQUEST,
  CTC_PRODUCTION_LIST_RESET,

  CTC_PRODUCTION_DELETE_REQUEST,
  CTC_PRODUCTION_DELETE_SUCCESS,
  CTC_PRODUCTION_DELETE_FAIL,
  CTC_PRODUCTION_DELETE_RESET,

  CTC_PRODUCTION_UPDATE_FAIL,
  CTC_PRODUCTION_UPDATE_SUCCESS,
  CTC_PRODUCTION_UPDATE_REQUEST,
  CTC_PRODUCTION_UPDATE_RESET,

} from "../constants/ctcProductionConstants.js";


export const ctcProductionRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_PRODUCTION_REGISTER_REQUEST:
      return { ctc_production_register_loading: true };
    case CTC_PRODUCTION_REGISTER_SUCCESS:
      return { ctc_production_register_loading: false,ctc_production_register_success: true, ctc_production_register_data: action.payload };
    case CTC_PRODUCTION_REGISTER_FAIL:
      return { ctc_production_register_loading: false, ctc_production_register_error: action.payload };
    case CTC_PRODUCTION_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const ctcProductionDetailsByIdReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_PRODUCTION_DETAILS_BY_ID_REQUEST:
      return { ...state, ctc_production_details_by_id_loading: true };
    case CTC_PRODUCTION_DETAILS_BY_ID_SUCCESS :
      return { ctc_production_details_by_id_loading: false, ctc_production_details_data : action.payload };
    case CTC_PRODUCTION_DETAILS_BY_ID_FAIL:
      return { ctc_production_details_by_id_loading: false, ctc_production_details_by_id_error: action.payload };
    case CTC_PRODUCTION_DETAILS_BY_ID_RESET:
      return {  };
    default:
      return state;
  }
};

export const ctcProductionListReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_PRODUCTION_LIST_REQUEST:
      return { ctc_production_list_loading: true };
    case CTC_PRODUCTION_LIST_SUCCESS:
      return { ctc_production_list_loading: false, ctc_production_list_success: false, ctc_production_list_data : action.payload };
    case CTC_PRODUCTION_LIST_FAIL:
      return { ctc_production_list_loading: false, ctc_production_list_error : action.payload };
    case CTC_PRODUCTION_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const ctcProductionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_PRODUCTION_DELETE_REQUEST:
      return { ctc_production_delete_loading: true };
    case CTC_PRODUCTION_DELETE_SUCCESS:
      return { ctc_production_delete_loading: false, ctc_production_delete_success: true };
    case CTC_PRODUCTION_DELETE_FAIL:
      return { ctc_production_delete_loading: false, ctc_production_delete_error: action.payload };
    default:
      return state;
  }
};

export const ctcProductionUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_PRODUCTION_UPDATE_REQUEST:
      return { ctc_production_update_loading: true };
    case CTC_PRODUCTION_UPDATE_SUCCESS:
      return { ctc_production_update_loading: false, ctc_production_update_success : true };
    case CTC_PRODUCTION_UPDATE_FAIL:
      return { ctc_production_update_loading: false, ctc_production_update_error: action.payload };
    case CTC_PRODUCTION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
