import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Icon,
  Divider
} from "@mui/material";
import rupeeSvgIcon from "../../../assets/images/currency-inr.svg";

import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom";
import { ctcTypes, destinations, product_dimensons } from "../../../data/index.js";
import { each } from "jquery";
import { listCtcPurchaseOrders } from "../../../actions/ctcPurchaseOrderAction.js";
import { calculateCtcProductsAveragePrice, formatToLocaleCurrency } from "../../../util/Utility.js";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../assets/images/green_tick.png";
import { styled } from '@mui/material/styles';
import { CTC_PURCHASE_ORDER_REGISTER_REQUEST, CTC_PRODUCTION_REGISTER_RESET } from "../../../constants/ctcProductionConstants.js";
import GridContainer from "../../Grid/GridContainer.js";
import { listCtcProductions } from "../../../actions/ctcProductionAction.js";
import Card from "../../Card/Card.js";
import CardBody from "../../Card/CardBody.js";
import GridItem from "../../Grid/GridItem.js";
import CardHeader from "../../Card/CardHeader.js";
import { listCtcConversions } from "../../../actions/ctcConversionAction.js";
import { fetchCtcBatchNo } from "../../../actions/ctcBatchNoAction.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const CtcConversionCreateForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [productDimensions, setProductDimensions] = useState(() => []);
  const [purchaseOrderIds, setPurchaseOrderIds] = useState(null);
  const [ctcProductNames, setCtcProductNames] = useState(() => []);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(() => null);
  const ctcPurchaseOrderList = useSelector((state) => state.ctcPurchaseOrderList);
  const ctcBatchNoFetched = useSelector((state)=>state.ctcBatchNoFetched)
  const {ctc_batch_no_loading, ctc_batch_no_data,ctc_batch_no_error } = ctcBatchNoFetched
  const { ctc_purchase_order_list_success, ctc_purchase_order_list_data } = ctcPurchaseOrderList
  const ctcConversionList = useSelector((state) => state.ctcConversionList);
  const { ctc_conversion_list_loading, ctc_conversion_list_success, ctc_conversion_list_data } = ctcConversionList
  const ctcConversionRegistered = useSelector((state) => state.ctcConversionRegistered);
  const { ctc_conversion_register_loading, ctc_conversion_register_success, ctc_conversion_register_data } = ctcConversionRegistered

  const populateNetWeights = () => {
    if (ctcConversionFormInputs.productName && ctcConversionFormInputs.productName.length > 0) {
      console.log('####### EXE populateNetWeights productName : ', ctcConversionFormInputs.productName)
      console.log('####### EXE populateNetWeights productName : ')
      setProductDimensions(prev => product_dimensons.filter(pd => ctcConversionFormInputs.productName.includes(pd.productName))[0].dimensions)
    }
  }

  const [ctcConversionFormInputs, setCtcConversionFormInputs] = useState({
    productName: "",
    powderNetWeight: "",
    rawMaterialNetWeight: "",
    purchaseOrderDate : "",
    mrp: "",
    productAvgPrice: "",
    batchNo: "",
    ctcPurchaseOrderId: "",
    purchasePricePerKg:"",
    totalPurchasePrice:"",
    recentRawMaterialNetWeight: "",
    recentRawMaterialValidNetWeight: "",
    recentRawMaterialPricePerKg: "",
    recentRawMaterialTotalPrice: "",
    recentProductAvgPrice : "",
  });

  /**
   * Resets the form inputs to their initial state.
   */
  const clearCtcProductionFormInputs = () => {
    console.log('EXEC clearCtcProductionFormInputs')
    setCtcConversionFormInputs({
      productName: "",
      powderNetWeight: "",
      rawMaterialNetWeight: "",
      purchaseOrderDate : "",
      mrp: "",
      productAvgPrice: "",
      batchNo: "",
      ctcPurchaseOrderId: "",
      purchasePricePerKg:"",
      totalPurchasePrice:""
    });
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);
  const [productAlreadyAdded, setProductAlreadyAdded] = React.useState(false);
  const [finishedProductEntries, setFinishedProductEntries] = useState(() => [])
  const noPoFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
            <Typography variant="h6" style={{ margin: "0.5rem", textTransform: "none" }} color="red">
              <i>No Purchase Orders found for  {ctcConversionFormInputs.productName}</i>
            </Typography>
      </GridItem>
    </GridContainer>
  );

  /**
   * Populates the `ctcProductNames` state with a list of options containing all the product names in `product_dimensons`.
   * This is used to populate the product name dropdown in the form.
   */
  const populateCtcProductNames = () => {
    setCtcProductNames((prev) => product_dimensons.map((eachPd, idx) => (
      <MenuItem key={eachPd.idx} value={eachPd.productName}>
        {eachPd.productName}
      </MenuItem>
    )
    ));
  }

  const displayRecentCtcProductsAvgPrice = ()=>{
    console.log("@@@@   displayRecentCtcProductsAvgPrice    @@@@ ")
    if(ctc_conversion_list_data && ctc_conversion_list_data.length>0 ){
      
      ctc_conversion_list_data.sort((a,b)=> new Date(b.conversionDate) - new Date(a.conversionDate))
      const filteredRecentConversionsByProductName = ctc_conversion_list_data.filter((each)=> each.productName === ctcConversionFormInputs.productName)
      if(filteredRecentConversionsByProductName && filteredRecentConversionsByProductName.length>0){
        setCtcConversionFormInputs((prev)=> ({
          ...prev, 
      recentRawMaterialPricePerKg : ctc_conversion_list_data[0].pricePerKg ? ctc_conversion_list_data[0].pricePerKg : 0 ,
      recentRawMaterialNetWeight : ctc_conversion_list_data[0].totalWeightInKgs ? ctc_conversion_list_data[0].totalWeightInKgs : 0,
      recentRawMaterialValidNetWeight : ctc_conversion_list_data[0].totalValidWeightInKgs ? ctc_conversion_list_data[0].totalValidWeightInKgs : 0,
     }))
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
    if (ctc_production_register_success) {
      dispatch({ type: CTC_PRODUCTION_REGISTER_RESET });
      navigate("/ctc/production");
    }
  };

  useEffect(() => {
    dispatch(listCtcPurchaseOrders())
    dispatch(listCtcConversions())
    populateCtcProductNames()
    dispatch(fetchCtcBatchNo())
  }, [])

  // useEffect(() => {
  //   if (ctcConversionFormInputs.productName && ctcConversionFormInputs.productName.length > 0) {
  //   populateNetWeights()
  //   }
  // }, [ctcConversionFormInputs.productName])


  useEffect(() => {
    if (ctcConversionFormInputs.productName && ctcConversionFormInputs.productName.length > 0 && ctc_purchase_order_list_data && ctc_purchase_order_list_data.length > 0) {
      populatePurchaseOrderIds()
      populateNetWeights()
      populateBatchNo()
      displayRecentCtcProductsAvgPrice()
    }
  }, [ctcConversionFormInputs.productName])


  const handleSubmit = (event) => {
    dispatch(
      createCtcPurchaseOrder(ctcConversionFormInputs)
    );
  };

  const populatePurchaseOrderIds = () => {
    console.log('ctc_production_list_data : ', ctc_purchase_order_list_data ? ctc_purchase_order_list_data.length :0 );
    console.log('Exec populatePurchaseOrderIds for Product Name  ...!', ctcConversionFormInputs.productName)
    const filteredData = ctc_purchase_order_list_data.filter((each) => ctcConversionFormInputs.productName.toLowerCase().includes(each.productName.toLowerCase()))
    setPurchaseOrderIds((prev) => filteredData.map((eachPo, idx) => (
      <MenuItem key={eachPo.idx} value={eachPo._id}>
        {eachPo._id}
      </MenuItem>
    )
    ));
  }

  const populateBatchNo = () => {
    console.log('ctc_batch_no_data : ', ctc_batch_no_data ? ctc_batch_no_data :0 );
    setCtcConversionFormInputs((prev)=>({
      ...prev , 
      batchNo : ctc_batch_no_data
    }));
  }

  const saveConversion = () => {
    console.log('Exec saveConversion .!', 'productName : ', ctcConversionFormInputs, 'selectedPurchaseOrder productName ::==>> ', selectedPurchaseOrder && selectedPurchaseOrder.productName ? selectedPurchaseOrder.productName : "")
    setFinishedProductEntries((prev) => ([...prev, {
      productName: selectedPurchaseOrder && selectedPurchaseOrder.productName ? selectedPurchaseOrder.productName : "",
      powderNetWeight: ctcConversionFormInputs.powderNetWeight ? ctcConversionFormInputs.powderNetWeight : "",
      rawMaterialNetWeight: ctcConversionFormInputs.rawMaterialNetWeight ? ctcConversionFormInputs.rawMaterialNetWeight : "",
      mrp: ctcConversionFormInputs.mrp ? ctcConversionFormInputs.mrp : 0.0,
      productAvgPrice: ctcConversionFormInputs.productAvgPrice ? ctcConversionFormInputs.productAvgPrice : 0.0,
      batchNo: ctcConversionFormInputs.batchNo ? ctcConversionFormInputs.batchNo : "",
      ctcPurchaseOrderId: ctcConversionFormInputs.ctcPurchaseOrderId ? ctcConversionFormInputs.ctcPurchaseOrderId : 0.0,
    }]))
    clearCtcProductionFormInputs()
    console.log(" $$$$$$$$$$$ Remove the Added Net Weight Entry from Drop Down $$$$$$$$$$$ ")
  }

  const purchaseOrderContent = (
    <Grid container spacing={2} marginTop="auto" >
      <Grid item xs={12} sm={6} md={6}>
        <FormControl style={{ justifyContent: "center", textAlign: "center", width: "70%" }}>
          <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
            Product Name :
          </InputLabel>
          <Select
            label="Product Name"
            placeholder="Product Name *"
            variant="outlined"
            required
            size="small"
            fullWidth
            name="productName"
            onChange={(e) => {
              console.log('productName : ', e.target.value)
              setCtcConversionFormInputs((prevState) => ({
                ...prevState,
                productName: e.target.value,
                ctcPurchaseOrderId: ""
              }));
            }}
            value={ctcConversionFormInputs.productName || ""}
          >
            {ctcProductNames}
          </Select>
        </FormControl>
      </Grid>
      {purchaseOrderIds && purchaseOrderIds.length > 0 ? (<Grid item xs={12} sm={6} md={6}>
        <FormControl style={{ justifyContent: "center", textAlign: "center", width: "70%" }}>
          <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
            CTC Purchase Order ID :
          </InputLabel>
          <Select
            label="CTC Purchase Order Id"
            placeholder="CTC Purchase Order Id *"
            variant="outlined"
            required
            size="small"
            fullWidth
            name="ctcPurchaseOrderId"
            onChange={(e) => {
              console.log('Purchase Order Id : ', e)
              const selectedPo = ctc_purchase_order_list_data.filter(rec => rec._id === e.target.value);
              console.log('Selected filtered PO Details : ', ctc_purchase_order_list_data.filter(rec => rec._id === e.target.value))

              setCtcConversionFormInputs((prevState) => ({
                ...prevState,
                ctcPurchaseOrderId: e.target.value,
                rawMaterialNetWeight: selectedPo && selectedPo.length>0 && selectedPo[0].initialTotalWeightInKgs ? selectedPo[0].initialTotalWeightInKgs : 0.0,
                purchaseOrderDate: selectedPo && selectedPo.length>0 ? selectedPo[0].purchaseOrderDate : 0.0,
                purchasePricePerKg : selectedPo && selectedPo.length>0 ? selectedPo[0].pricePerKg : 0.0,
                totalPurchasePrice : selectedPo && selectedPo.length>0 && selectedPo[0].totalPrice ?  selectedPo[0].totalPrice : 0.0
              }));
              const filteredRecs = ctc_purchase_order_list_data.filter(rec => rec._id === e.target.value && ctcConversionFormInputs.productName.includes(rec.productName))
              
              setSelectedPurchaseOrder((prev) => (filteredRecs && filteredRecs.length > 0 ? filteredRecs[0] : null))
            }}
            value={ctcConversionFormInputs.ctcPurchaseOrderId || ""}
          >
            {purchaseOrderIds && purchaseOrderIds.length > 0 ? purchaseOrderIds : null}
          </Select>
        </FormControl>
      </Grid>) : null}
      { ctcConversionFormInputs.productName && !(purchaseOrderIds && purchaseOrderIds.length >0) ? noPoFoundContent : null} 
      { ctcConversionFormInputs.ctcPurchaseOrderId && ctcConversionFormInputs.ctcPurchaseOrderId.length>0 ? ( <Grid item xs={4} sm={3}>
        <TextField
          placeholder="Purchase Order Date"
          label="Purchase Order Date *"
          variant="outlined"
          size="small"
          name="purchaseOrderDate"
          disabled
          type="text"
          style={{ justifyContent: "center", textAlign: "center" }}
          value={ctcConversionFormInputs && ctcConversionFormInputs.purchaseOrderDate ? ctcConversionFormInputs.purchaseOrderDate : null}
        />
      </Grid>):null}
      { ctcConversionFormInputs.ctcPurchaseOrderId && ctcConversionFormInputs.ctcPurchaseOrderId.length>0 ? (<Grid item xs={4} sm={3}>
        <TextField
          label="Raw material Net Weight [Kgs] *"
          variant="outlined"
          size="small"
          name="rawMaterialNetWeight"
          style={{ justifyContent: "center", textAlign: "center" }}
          onChange={(e) => setCtcConversionFormInputs((prev) => ({
            ...prev,
            rawMaterialNetWeight: e.target.value,
          }))}
          type="number"
          disabled
          value={ctcConversionFormInputs && ctcConversionFormInputs.rawMaterialNetWeight ? ctcConversionFormInputs.rawMaterialNetWeight : null}
        />
      </Grid>):null}
      { ctcConversionFormInputs.ctcPurchaseOrderId && ctcConversionFormInputs.ctcPurchaseOrderId.length>0 ? (<Grid item xs={4} sm={3}>
        <TextField
          placeholder="Purchase Price Per Kg"
          label="Purchase Price Per Kg *"
          variant="outlined"
          size="small"
          name="purchasePricePerKg"
          style={{ justifyContent: "center", textAlign: "center" }}
          onChange={(e) => setCtcConversionFormInputs((prev) => ({
            ...prev,
            purchasePricePerKg: e.target.value,
          }))}
          type="number"
          disabled
          value={ctcConversionFormInputs && ctcConversionFormInputs.purchasePricePerKg ? formatToLocaleCurrency(ctcConversionFormInputs.purchasePricePerKg) : null}
        />
      </Grid>):null}
      { ctcConversionFormInputs.ctcPurchaseOrderId && ctcConversionFormInputs.ctcPurchaseOrderId.length>0 ? (<Grid item xs={4} sm={3}>
        <TextField
          placeholder="Total Purchase Price"
          label="Total Purchase Price *"
          variant="outlined"
          size="small"
          name="totalPurchasePrice"
          style={{ justifyContent: "center", textAlign: "center" }}
          type="number"
          disabled
          value={ctcConversionFormInputs && ctcConversionFormInputs.totalPurchasePrice ? Number(ctcConversionFormInputs.totalPurchasePrice) : null}
        />
      </Grid>):null}
      { ctcConversionFormInputs.ctcPurchaseOrderId && ctcConversionFormInputs.ctcPurchaseOrderId.length>0 ? (<Grid item xs={4} sm={3} align="center">
          <TextField
            label="Recent Avg Price *"
            variant="outlined"
            size="small"
            name="productAvgPrice"
            type="number"
            disabled
            value={ctcConversionFormInputs.productAvgPrice || ""}
          />
        </Grid>):null}
        { ctcConversionFormInputs.ctcPurchaseOrderId && ctcConversionFormInputs.ctcPurchaseOrderId.length>0 ? (<Grid item xs={4} sm={3} align="center">
          <TextField
            placeholder="Batch No"
            label="Batch No *"
            variant="outlined"
            size="small"
            name="batchNo"
            disabled
            type="text"
            value={ctcConversionFormInputs.batchNo || ""}
          />
        </Grid>):null}
    </Grid>
  )

  const conversionDetails = (
    <>
      <Grid container spacing={2} marginTop="auto" >
        <Grid item xs={4} sm={3} align="center">
          <TextField
            placeholder="Powder Net Weight [Kgs]"
            label="Powder Net Weight [Kgs] *"
            variant="outlined"
            size="small"
            name="powderNetWeight"
            onChange={(e) => setCtcConversionFormInputs((prev) => ({
              ...prev,
              powderNetWeight: e.target.value,
            }))}
            type="number"
            value={ctcConversionFormInputs.powderNetWeight || ""}
          />
        </Grid>
        <Grid item xs={4} sm={3} align="center">
          <TextField
            placeholder="MRP"
            label="MRP *"
            variant="outlined"
            size="small"
            name="mrp"
            onChange={(e) => setCtcConversionFormInputs((prev) => ({
              ...prev,
              mrp: e.target.value,
            }))}
            onBlur={()=>calculateCtcProductsAveragePrice({ 
              productName: ctcConversionFormInputs.productName, 
              // oldEntry: { 
              //   oldCtcProductsTotalQuantity :0  , oldCtcProductsPricePerKg :0, oldCtcTotalValidPowderWeight :0
              // }, 
              oldEntry : null,
              newEntry: { 
                newCtcProductsTotalQuantity : ctcConversionFormInputs.rawMaterialNetWeight , newCtcProductsPricePerKg:ctcConversionFormInputs.purchasePricePerKg , newCtcTotalValidPowderWeight:ctcConversionFormInputs.powderNetWeight
              },  
            })}
            type="number"
            value={ctcConversionFormInputs.mrp || ""}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop="auto" >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            size="small"
            variant="contained"
            type="submit"
            color="secondary"
            justify="center"
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              textAlign: "center",
              width: "15rem",
            }}
            onClick={saveConversion}
          >
            Save Conversion
          </Button>
        </Grid>
      </Grid>
    </>
  )

  useEffect(() => {
    if (ctc_conversion_register_success) {
      console.log('Routing to List Screen as New Bond is Created. !');
      setOpen(() => true);
    }
  }, [ctc_conversion_register_success])

  return (
    <div >
      {ctc_conversion_register_success && (<BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <Grid item xs={4} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  CTC Purchase Order is Created
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>)}
      <Divider style={{margin:"0.5rem"}}/>
      {purchaseOrderContent}
      <Divider style={{margin:"0.5rem"}}/>
      {ctcConversionFormInputs.ctcPurchaseOrderId && ctcConversionFormInputs.ctcPurchaseOrderId.length > 0 && conversionDetails}
    </div>
  );
};

export default CtcConversionCreateForm;
