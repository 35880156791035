export const CTC_PRODUCTION_REGISTER_REQUEST = "CTC_PRODUCTION_REGISTER_REQUEST";
export const CTC_PRODUCTION_REGISTER_SUCCESS = "CTC_PRODUCTION_REGISTER_SUCCESS";
export const CTC_PRODUCTION_REGISTER_FAIL = "CTC_PRODUCTION_REGISTER_FAIL";
export const CTC_PRODUCTION_REGISTER_RESET = "CTC_PRODUCTION_REGISTER_RESET";

export const CTC_PRODUCTION_DETAILS_BY_ID_REQUEST = "CTC_PRODUCTION_DETAILS_BY_ID_REQUEST";
export const CTC_PRODUCTION_DETAILS_BY_ID_SUCCESS = "CTC_PRODUCTION_DETAILS_BY_ID_SUCCESS";
export const CTC_PRODUCTION_DETAILS_BY_ID_FAIL = "CTC_PRODUCTION_DETAILS_BY_ID_FAIL";
export const CTC_PRODUCTION_DETAILS_BY_ID_RESET = "CTC_PRODUCTION_DETAILS_BY_ID_RESET";

export const CTC_PRODUCTION_LIST_REQUEST = "CTC_PRODUCTION_LIST_REQUEST";
export const CTC_PRODUCTION_LIST_SUCCESS = "CTC_PRODUCTION_LIST_SUCCESS";
export const CTC_PRODUCTION_LIST_FAIL = "CTC_PRODUCTION_LIST_FAIL";
export const CTC_PRODUCTION_LIST_RESET = "CTC_PRODUCTION_LIST_RESET";

export const CTC_PRODUCTION_DELETE_REQUEST = "CTC_PRODUCTION_DELETE_REQUEST";
export const CTC_PRODUCTION_DELETE_SUCCESS = "CTC_PRODUCTION_DELETE_SUCCESS";
export const CTC_PRODUCTION_DELETE_FAIL = "CTC_PRODUCTION_DELETE_FAIL";

export const CTC_PRODUCTION_UPDATE_REQUEST = "CTC_PRODUCTION_UPDATE_REQUEST";
export const CTC_PRODUCTION_UPDATE_SUCCESS = "CTC_PRODUCTION_UPDATE_SUCCESS";
export const CTC_PRODUCTION_UPDATE_FAIL = "CTC_PRODUCTION_UPDATE_FAIL";
export const CTC_PRODUCTION_UPDATE_RESET = "CTC_PRODUCTION_UPDATE_RESET";