import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom";
import { getBondDetails, listBonds } from "../../../actions/bondAction.js";
import { convertInvoiceIdToDigits } from '../../../util/Utility.js'
import { listColdStorages } from "../../../actions/coldStorageAction.js";
import { listAvailableVehicles } from "../../../actions/vehicleAction.js";

import { ctcTypes } from "../../../data";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  productName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  markOnPacking: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  lotNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 10,
    },
  },
  vehicleNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  location: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  remainingStockInStorage: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  bondNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  receiptDate: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
};

const ReceiptCreateForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [bondIdList, setBondIdList] = useState(null);
  const bondList = useSelector((state) => state.bondList)
  const [selectedBondDetails, setSelectedBondDetails] = useState(() => null);
  const [isReceiptValid, setIsReceiptValid] = useState(() => false);
  const [receiptFormInputs, setReceiptFormInputs] = useState({
    coldStorageName: "",
    productName: "",
    bondId: "",
    bondDate: "",
    inventryTransferQuantity: "",
    vehicleNo:""
  });
  const [availableVehicles, setAvailableVehicles] = useState(() => null);
  const [coldStorageNamesList, setColdStorageNamesList] = useState(null);
  const [bondsListByStorageProduct, setBondsListByStorageProduct] = useState(null);
  const coldStorageList = useSelector((state) => state.coldStorageList)
  const [selectedColdStorage, setSelectedColdStorage] = useState(() => null);

  const { ctc_bond_list_loading, ctc_bond_list_data, ctc_bond_list_success, ctc_bond_list_error } = bondList
  const { cold_storage_list_loading, cold_storage_list, cold_storage_list_success, cold_storage_list_error } = coldStorageList


  const populateBondIdList = () => {
    setBondIdList((prev) => ctc_bond_list_data.map((eachBond, idx) => {
      return (
        <MenuItem key={eachBond.Grid_id} value={eachBond._id}>
          {convertInvoiceIdToDigits(eachBond._id)}
        </MenuItem>
      );
    }));
  }

  const populateBondsListByStorageProduct = (prdName) => {
    console.log(' receiptFormInputs : ', receiptFormInputs)
    const filtered = ctc_bond_list_data.filter((bond, id) => bond.coldStorage === receiptFormInputs.coldStorageName &&
      bond.productName === prdName)
    console.log('Filtered : ', filtered)
    setBondsListByStorageProduct((prev) => ctc_bond_list_data.filter((bond, id) => bond.coldStorage === receiptFormInputs.coldStorageName &&
      bond.productName === prdName)
      .map((eachBond, idx) => {
        return (
          <MenuItem key={eachBond._id} value={eachBond._id}>
            {convertInvoiceIdToDigits(eachBond._id)}
          </MenuItem>
        );
      }));
  }

  const populateAvailableVehicles = () => {
    setAvailableVehicles((prev) => vehicles_available.map((eachVehicle, idx) => {
      return (
        <MenuItem key={eachVehicle._id} value={eachVehicle.vehicleNo}>
          {eachVehicle.vehicleNo}
        </MenuItem>
      );
    }));
  }

  const populateColdStorageNames = () => {
    setColdStorageNamesList((prev) => cold_storage_list.map((eachStorage, idx) => {
      return (
        <MenuItem key={eachStorage._id} value={eachStorage.name}>
          {eachStorage.name}
        </MenuItem>
      );
    }));
  }

  const checkIfReceiptIsValid = (bondId) => {
    console.log('checkIfReceiptIsValid :  ', isReceiptValid)
    if (receiptFormInputs && receiptFormInputs.coldStorageName && receiptFormInputs.coldStorageName.length > 0 &&
      receiptFormInputs.productName && receiptFormInputs.productName.length > 0 &&
      bondId && bondId.length > 0) {
      setIsReceiptValid((prev) => true)
      console.log('RECEIPT IS VALID')
    } else {
      console.log('RECEIPT IS IN VALID', receiptFormInputs.coldStorageName, receiptFormInputs.productName, bondId)
      setIsReceiptValid((prev) => false)
    }
  }

  useEffect(() => {
    dispatch(listBonds())
    dispatch(listColdStorages())
    dispatch(listAvailableVehicles())
  }, [])

  useEffect(() => {
    if (ctc_bond_list_data && ctc_bond_list_data.length > 0) {
      populateBondIdList()
    }
  }, [ctc_bond_list_data])

  useEffect(() => {
    if (cold_storage_list && cold_storage_list.length > 0) {
      populateColdStorageNames()
    }
  }, [cold_storage_list])

  useEffect(() => {
    if (vehicles_available && vehicles_available.length > 0) {
      console.log('vehicles_available: ', vehicles_available ? vehicles_available.length : 0);
      populateAvailableVehicles()
    } else {
      console.log('NO VEHICLES AVAILABLE YET ')
    }
  }, [vehicles_available])

  const handleSubmit = (event) => {
    console.log("Values Submitted are : ", formState.values);
    event.preventDefault();
    console.log("formState.isValid : ", formState.isValid);
    if (formState.isValid)
      dispatch(
        register(
          formState.values.productName,
          formState.values.markOnPacking,
          formState.values.lotNo,
          formState.values.vehicleNo,
          formState.values.location,
          formState.values.remainingStockInStorage,
          formState.values.bondNo,
          formState.values.reciptDate
        )
      );

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "ctc_types":
        return ctcTypes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const bondDetailsSection = (<>
    <Grid item xs={4} align="center">
      <TextField
        placeholder="Bond Date"
        label="Bond Date"
        variant="outlined"
        fullWidth
        size="small"
        name="bondDate"
        type="text"
        disabled
        value={selectedBondDetails && selectedBondDetails.length > 0 && selectedBondDetails[0].bondDate || ""}
      />
    </Grid>
    <Grid item xs={4} align="center">
      <TextField
        placeholder="Bond Initial Quantity"
        label="Bond Initial Quantity"
        variant="outlined"
        fullWidth
        size="small"
        name="bondInitialQuantity"
        type="text"
        disabled
        value={selectedBondDetails && selectedBondDetails.length > 0 && selectedBondDetails[0].totalWeightInKgs || ""}
      />
    </Grid>
    </>
  )

  const receiptDetailsSection = (<>
    <Grid item xs={4} align="center">
      <TextField
        placeholder="Inventry Transfer Quantity"
        label="Inventry Transfer Quantity *"
        variant="outlined"
        size="small"
        name="inventryTransferQuantity"
        fullWidth
        onChange={handleReceiptsChange}
        type="text"
        value={receiptFormInputs.inventryTransferQuantity || ""}
      />
    </Grid>
    <Grid item xs={4}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Vehicle No
              </InputLabel>
              <Select
                label="Vehicle No"
                placeholder="Vehicle No *"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="vehicleNo"
                onChange={(e)=>console.log('Vehicle No Selected : ', e.target.value)}
                value={receiptFormInputs.vehicleNo || ""}
              >
                {availableVehicles}
              </Select>
            </FormControl>
          </Grid>
    {/* <Grid item xs={4} align="center">
      <TextField
        placeholder="Remaining Stock in Kgs"
        label="Remaining Stock in Kgs *"
        variant="outlined"
        size="small"
        name="remainingStockInStorage"
        fullWidth
        onChange={handleChange}
        type="number"
        value={formState.values.remainingStockInStorage || ""}
      />
    </Grid> */}
  </>
  )

  return (
    <>
      <form method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="center" marginTop="auto">
          <Grid item xs={4} align="center" style={{ width: '25rem' }}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: '85%' }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Cold Storage
              </InputLabel>
              <Select
                label="Cold Storage"
                placeholder="Cold Storage"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="coldStorage"
                onChange={(e) => setReceiptFormInputs((prev) => ({
                  ...prev, coldStorageName: e.target.value
                }))}
                value={receiptFormInputs.coldStorageName}
              >
                {coldStorageNamesList}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4} align="center">
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: '85%' }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                CTC Product Name
              </InputLabel>
              <Select
                style={{ justifyContent: "center" }}
                label="CTC Type"
                placeholder="CTC Type"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="productName"
                onChange={(e) => {
                  console.log('selected Cold Storage, CTC Product  ', receiptFormInputs.coldStorageName, e.target.value)
                  setReceiptFormInputs((prev) => ({
                    ...prev, productName: e.target.value
                  }))
                  populateBondsListByStorageProduct(e.target.value)
                }
                }

                value={receiptFormInputs.productName || ""}
              >
                {renderDynamicOptions("ctc_types")}
              </Select>
            </FormControl>
          </Grid>
          {bondsListByStorageProduct && bondsListByStorageProduct.length > 0 && (<Grid item xs={4} align="center">
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: '85%' }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Bond No
              </InputLabel>
              <Select
                style={{ justifyContent: "center" }}
                label="Bond No"
                placeholder="Bond No"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="bondNo"
                onChange={(e) => {
                  console.log('BOND SELECTED :', e)
                  const filterBonDetails = ctc_bond_list_data.filter((each) => each._id === e.target.value)
                  console.log('GATHERING BOND DETAILS : ', filterBonDetails && filterBonDetails.length > 0 ? filterBonDetails[0] : "NO Recods matched")
                  setSelectedBondDetails(() => ctc_bond_list_data.filter((each) => each._id === e.target.value))
                  setReceiptFormInputs((prev) => (
                    {
                      ...prev,
                      bondId: e.target.value
                    }
                  )
                  )
                  checkIfReceiptIsValid(e.target.value)
                }}
                value={receiptFormInputs.bondId}
              >
                {bondsListByStorageProduct}
              </Select>
            </FormControl>
          </Grid>)
          }
          {selectedBondDetails && bondDetailsSection}
          {/* <Grid item xs={4} align="center">
            <TextField
              placeholder="Inventry Transfer Quantity"
              label="Inventry Transfer Quantity *"
              variant="outlined"
              size="small"
              name="inventryTransferQuantity"
              fullWidth
              onChange={handleChange}
              type="text"
              value={receiptFormInputs.inventryTransferQuantity || ""}
            />
          </Grid>
          <Grid item xs={4} align="center">
            <TextField
              placeholder="Vehicle No"
              label="Vehicles No *"
              variant="outlined"
              size="small"
              name="vehicleNo"
              fullWidth
              helperText={
                hasError("vehicleNo") ? formState.errors.vehicleNo[0] : null
              }
              error={hasError("vehicleNo")}
              onChange={handleChange}
              type="text"
              value={formState.values.vehicleNo || ""}
            />
          </Grid>
          <Grid item xs={4} align="center">
            <TextField
              placeholder="Remaining Stock in Kgs"
              label="Remaining Stock in Kgs *"
              variant="outlined"
              size="small"
              name="remainingStockInStorage"
              fullWidth
              onChange={handleChange}
              type="number"
              value={formState.values.remainingStockInStorage || ""}
            />
          </Grid> */}

          {/* <Grid item xs={4} align="center">
            <TextField
              placeholder="Receipt Date"
              label="Receipt Date *"
              variant="outlined"
              size="small"
              name="receiptDate"
              fullWidth
              type="text"
              disabled
              value={new Date().toLocaleString() || ""}
            />
          </Grid> */}
          {selectedBondDetails && receiptDetailsSection}
        </Grid>
        <Grid container spacing={2} justifyContent="center" marginTop="auto">
          <Grid item xs={4} align="center">
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                width:'20rem'
              }}
            >
              Create Receipt
            </Button>
          </Grid>
        </Grid>
      </form>
      </>
  );
};

export default ReceiptCreateForm;
