import axios from "axios";
import {
  CTC_RECEIPT_DETAILS_BY_ID_FAIL,
  CTC_RECEIPT_DETAILS_BY_ID_REQUEST,
  CTC_RECEIPT_DETAILS_BY_ID_SUCCESS,
  CTC_RECEIPT_DETAILS_BY_ID_RESET,

  CTC_RECEIPT_REGISTER_FAIL,
  CTC_RECEIPT_REGISTER_REQUEST,
  CTC_RECEIPT_REGISTER_SUCCESS,
  CTC_RECEIPT_REGISTER_RESET,
 
  CTC_RECEIPT_LIST_FAIL,
  CTC_RECEIPT_LIST_SUCCESS,
  CTC_RECEIPT_LIST_REQUEST,
  CTC_RECEIPT_LIST_RESET,

  CTC_RECEIPT_DELETE_REQUEST,
  CTC_RECEIPT_DELETE_SUCCESS,
  CTC_RECEIPT_DELETE_FAIL,
  CTC_RECEIPT_DELETE_RESET,

  CTC_RECEIPT_UPDATE_FAIL,
  CTC_RECEIPT_UPDATE_SUCCESS,
  CTC_RECEIPT_UPDATE_REQUEST,
  CTC_RECEIPT_UPDATE_RESET,

} from "../constants/receiptConstants.js";

    
export const create =
  ( coldStorageName, productName, bondId, bondDate , remainingStockInStorage, 
    vehicleNo, inventryTransferQuantity) =>
  async (dispatch) => {
    console.log('EXEC Bond Register  coldStorage, productName, bondId, bondDate , remainingStockInStorage, vehicleNo,  inventryTransferQuantity')
    try {
      dispatch({
        type: CTC_RECEIPT_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("Before Perfoming role logic : ");

      const { data } = await axios.post(
        "/api/ctc-receipt",
        { 
          coldStorageName, productName, bondId, bondDate , remainingStockInStorage, vehicleNo, inventryTransferQuantity
        },
        config
      );

      dispatch({
        type: CTC_RECEIPT_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CTC_RECEIPT_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getReceiptDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CTC_RECEIPT_DETAILS_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/ctc-receipt/${id}`);

    dispatch({
      type: CTC_RECEIPT_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_RECEIPT_DETAILS_BY_ID_FAIL,
      payload: message,
    });
  }
};

export const listReceipts = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CTC_RECEIPT_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/ctc-receipt`);

    dispatch({
      type: CTC_RECEIPT_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_RECEIPT_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteReceipt = (id) => async (dispatch, getState) => {
  console.log("deleteBond ", id);
  try {
    dispatch({
      type: CTC_RECEIPT_DELETE_REQUEST,
    });

    await axios.delete(`/api/ctc-receipt/${id}`);

    dispatch({ type: CTC_RECEIPT_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_RECEIPT_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateBond = (coldStorage, productName, bondId, bondDate , remainingStockInStorage, vehicleNo, inventryTransferQuantity) => async (dispatch, getState) => {
  console.log('updateBond : ',coldStorage, productName, bondId, bondDate , remainingStockInStorage, vehicleNo, inventryTransferQuantity)
  try {
    dispatch({
      type: CTC_RECEIPT_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/ctc-receipt/${id}`, {
      coldStorage, productName, bondId, bondDate , remainingStockInStorage, vehicleNo, inventryTransferQuantity
    }, {});

    dispatch({ type: CTC_RECEIPT_UPDATE_SUCCESS , payload: data });

    dispatch({ type: CTC_RECEIPT_UPDATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_RECEIPT_UPDATE_FAIL,
      payload: message,
    });
  }
};
