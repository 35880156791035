import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
// import { } from "../../actions/customerAction";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button } from "@mui/material";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../../Message";
import CustomBackdropSpinner from "../../CustomBackdropSpinner";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { listFactories } from "../../../actions/factoryAction";

const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const FactoryListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  const [factoriesData, setFactoriesData] = useState(() => []);

  useEffect(() => {
    console.log('Loading Cold Storages List Page')
    dispatch(listFactories())
  }, [])

  const factoryList = useSelector(
    (state) => state.factoryList
  );
  const { factory_list_loading, factory_list_success, factory_list_data, factory_list_error } = factoryList

  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const populateDataToGrid = () => {
    console.log('populateDataToGrid ...!')

    if (factory_list_data && factory_list_data.length > 0) {
      setFactoriesData((prev) => factory_list_data.map((factory, index) => {
        console.log("factory : ", factory);
        return {
          "#": index + 1,
          Name: factory.name,
          ContactNo: factory.contactNo,
          InchargeName: factory.inchargeName,
          InchargeContactNo: factory.inchargeContactNo,
          FactoryAddress: factory.factoryAddress,
          City: factory.city,
        };
      }))
    }
  }


  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };


  useEffect(() => {
    if (factory_list_data && factory_list_data.length > 0) {
      console.log('POPULATE FACTORY DATA WHEN ITS NOT EMPTY TO AG GRID')
      populateDataToGrid()
    }
  }, [factory_list_data])

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,

    },
    {
      field: "Name",
      headerName: "Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle : {
        textAlign : "center"
      }
    },
    {
      field: "Contact No",
      headerName: "Contact No",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true,
      cellStyle : {
        textAlign : "right"
      }
    },
    {
      field: "InchargeName",
      headerName: "Incharge Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
      cellStyle : {
        textAlign : "center"
      }
    },
    {
      field: "InchargeContactNo",
      headerName: "Incharge Contact No",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "FactoryAddress",
      headerName: "Factory Address",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "City",
      headerName: "City",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true,
      cellStyle : {
        textAlign : "center"
      }
    },
   
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const userLogin = useSelector(
    (state) => state.userLogin
  );
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const pos = storeName ? storeName : warehouseName ? warehouseName : ''
  const createColdStorageHandler = () => {
    console.log('Routing to New Cold Storage Register ')
    navigate('/ctc/factory/new');
  }

  const renderFactorisDataInAGGrid = (
    <>
      <div className="ag-theme-quartz" style={{ width: "75%", height: '65vh', marginLeft: 'auto', marginRight: 'auto' }}>
        <AgGridReact
          ref={gridRef}
          rowData={factoriesData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        ></AgGridReact>
      </div>
    </>
  );

  return (
    <React.Fragment>
      {factory_list_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  Factory
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={createColdStorageHandler}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "left", marginRight: "10px" }}
                    />
                    New
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderFactorisDataInAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default FactoryListPage;
