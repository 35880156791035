import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  listSuppliers,
  deleteSupplier,
  updateSupplier,
  listSuppliersByParentUser,
} from "../../actions/supplierAction";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Tooltip,
  Button,
  Divider,
  Grid,
  TextField,
  Alert,
} from "@mui/material";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../Message";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import validate from "validate.js";
import {
  SUPPLIER_DELETE_RESET,
  SUPPLIER_UPDATE_RESET,
} from "../../constants/supplierConstants";

const styles = {
  dialogPaper: {
    width: "50vw",
    maxWidth: "50vw",
    padding: "20px",
  },
  container: {
    maxHeight: 440,
  },
};

const validationRules = {
  name: { presence: { allowEmpty: false, message: "is required" } },
  contactNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: { is: 10, message: "must be exactly 10 digits" },
    numericality: { onlyInteger: true, message: "must be a number" },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: { message: "must be a valid email" },
  },
  billingAddress: { presence: { allowEmpty: false, message: "is required" } },
  city: { presence: { allowEmpty: false, message: "is required" } },
  pincode: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: { onlyInteger: true, message: "must be a number" },
  },
  gst: { presence: { allowEmpty: false, message: "is required" } },
};

const useStyles = makeStyles(styles);

const SupplierListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const pos = storeName ? storeName : warehouseName ? warehouseName : "";

  const { supplier_update_success } =
    useSelector((state) => state.supplierUpdate) || {};
  const { supplier_delete_success } =
    useSelector((state) => state.supplierDeleted) || {};

  const gridRef = useRef();
  const popupParent = useMemo(() => document.body, []);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteSupplierId, setDeleteSupplierId] = useState(null);
  const [action, setAction] = useState("");
  const [editableSupplierRecord, setEditableSupplierRecord] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditableSupplierRecord((prev) => ({ ...prev, [name]: value }));

    // Real-time validation
    const fieldError = validate(
      { [name]: value },
      { [name]: validationRules[name] }
    );
    setErrors((prev) => ({
      ...prev,
      [name]: fieldError ? fieldError[name][0] : "",
    }));
  };

  const handleEdit = (params) => {
    setOpen(true);
    setAction("edit");
    setEditableSupplierRecord({
      _id: params.data.Edit,
      name: params.data.Name,
      contactNo: params.data.ContactNo,
      email: params.data.Email,
      billingAddress: params.data.BillingAddress,
      city: params.data.City,
      pincode: params.data.Pincode,
      gst: params.data.Gst,
    });
    setErrors({});
  };

  // Function to open the delete confirmation dialog
  const handleDelete = (id) => {
    console.log("Id passed to handleDelete :==>> ", id);
    setDeleteSupplierId(id);
    setDeleteDialogOpen(true);
  };

  // Function to confirm and proceed with deletion
  const confirmDelete = () => {
    console.log("confirmDelete : ", deleteSupplierId);
    if (deleteSupplierId) {
      dispatch(deleteSupplier(deleteSupplierId));
    }
    setDeleteDialogOpen(false);
    setDeleteSupplierId(null);
  };

  // Function to close the delete confirmation dialog
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteSupplierId(null);
  };

  const handleReset = () => {
    setOpen(false);
    setEditableSupplierRecord({});
    setErrors({});
    setAction("");
  };

  const handleUpdate = () => {
    const validationErrors = validate(editableSupplierRecord, validationRules);
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    if (action === "edit") {
      dispatch(updateSupplier(editableSupplierRecord));
      handleReset();
    }
  };

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const columns = [
    { field: "#", width: 75 },
    { field: "Name", width: 350, filter: true ,cellStyle: { textAlign: "left" }},
    { field: "ContactNo", width: 150, filter: true },
    { field: "Email", width: 250, filter: true ,cellStyle: { textAlign: "left" }},
    { field: "BillingAddress", width: 400, filter: true ,cellStyle: { textAlign: "left" }},
    { field: "City", width: 125, filter: true,cellStyle: { textAlign: "center" } },
    { field: "Gst", width: 125, filter: true ,cellStyle: { textAlign: "right" },},
    {
      field: "Edit",
      width: 100,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <Tooltip title="Edit">
          <EditRoundedIcon
            onClick={() => handleEdit(params)}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      ),
    },
    {
      field: "Delete",
      width: 100,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <Tooltip title="Delete">
          <DeleteRoundedIcon
            onClick={() => handleDelete(params.data.Edit)}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      ),
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const supplierListByParentUser = useSelector(
    (state) => state.supplierListByParentUser
  );
  const {
    supplier_list_by_parent_user_loading,
    supplier_list_by_parent_user_error,
    supplier_list_by_parent_user,
  } = supplierListByParentUser;

  useEffect(() => {
    console.log(" Loading Suppliers List By Mapped User ");
    if (userInfo?.name) {
      dispatch(listSuppliersByParentUser(name));
    }
  }, [dispatch, userInfo?.name]);

  useEffect(() => {
    const fetchSuppliers = () => {
      dispatch(listSuppliersByParentUser(userInfo.name));
    };

    if (supplier_update_success) {
      dispatch({ type: SUPPLIER_UPDATE_RESET });
      fetchSuppliers();
    }

    if (supplier_delete_success) {
      const timer = setTimeout(() => {
        dispatch({ type: SUPPLIER_DELETE_RESET });
        fetchSuppliers();
      }, 500); // Hides message after 1 second
      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [
    dispatch,
    supplier_update_success,
    supplier_delete_success,
    userInfo.name,
  ]);

  const handleAddSupplier = (e) => {
    e.preventDefault();
    navigate("/admin/supplier/signup");
  };

  const rowData = supplier_list_by_parent_user?.map((supplier, index) => ({
    "#": index + 1,
    Name: supplier.name,
    Email: supplier.email,
    ContactNo: supplier.contactNo,
    BillingAddress: supplier.billingAddress,
    City: supplier.city,
    Gst: supplier.gst,
    Edit: supplier._id,
    Delete: supplier._id,
  }));

  return (
    <React.Fragment>
      {supplier_list_by_parent_user_loading && <CustomBackdropSpinner />}
      {supplier_delete_success && (
              <Alert variant="filled" severity="warning" sx={{ 
                position: "fixed", 
                top: "50%", 
                left: "50%", 
                transform: "translate(-50%, -50%)", 
                zIndex: 9999, 
               //  backgroundColor: "red", 
                color: "white", 
                padding: "10px 20px", 
                borderRadius: "8px",
                fontSize: "18px"
              }}>
              Supplier Deleted Successfuly
            </Alert>
          
            )}
      {/* <GridContainer> */}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <Typography variant="h5" align="center">
                <Tooltip title="Back">
                  <ArrowBackIcon
                    onClick={() => window.history.back()}
                    style={{ float: "left", cursor: "pointer" }}
                  />
                </Tooltip>
                Suppliers
                {role && role === "ADMIN_ROLE" && (
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={handleAddSupplier}
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "5px",
                      float: "right",
                      textAlign: "right",
                    }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "left", marginRight: "10px" }}
                    />
                    Create Supplier
                  </Button>
                )}
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {rowData && rowData.length > 0 && (
            <Tooltip title="Export > Excel" arrow>
              <IosShareRoundedIcon
                fontSize="medium"
                style={{ float: "right", margin: "1rem" }}
                onClick={onExportClick}
              />
            </Tooltip>
          )}
        </GridItem>
        <GridItem xs={12}>
          {supplier_list_by_parent_user_error && (
            <Message variant="danger">
              {supplier_list_by_parent_user_error}
            </Message>
          )}
          <div
            className="ag-theme-quartz"
            style={{
              width: "75%",
              height: "65vh",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columns}
              pagination
            />
          </div>
        </GridItem>
      </GridContainer>
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogContent>
          <Typography variant="h6" style={{textTransform:"none"}}>
            Are you sure you want to delete this Supplier?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={confirmDelete}
            >
              Yes, Delete
            </Button>
            <Button variant="outlined" onClick={closeDeleteDialog}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleReset}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <Card>
           <CardHeader >
                                           <Typography
                                             variant="h6"
                                             style={{ margin: "0.5rem", textAlign:"center" }}
                                             color="black"
                                           >
                                              Edit Supplier Details
                                           </Typography>
                                           </CardHeader>
            <Divider />
            <CardBody>
              <Grid container spacing={2}>
                {Object.keys(validationRules).map((field) => (
                  <Grid item xs={12} key={field}>
                    <TextField
                      label={field}
                      name={field}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editableSupplierRecord[field] || ""}
                      onChange={handleChange}
                      error={!!errors[field]}
                      helperText={errors[field]}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default SupplierListPage;
