import {
  STORE_LIST_REQUEST,
  STORE_LIST_SUCCESS,
  STORE_LIST_FAIL,
  STORE_DELETE_REQUEST,
  STORE_DELETE_SUCCESS,
  STORE_DELETE_FAIL,
  STORE_DELETE_RESET,
  STORE_CREATE_RESET,
  STORE_CREATE_FAIL,
  STORE_CREATE_SUCCESS,
  STORE_CREATE_REQUEST,
  STORE_UPDATE_REQUEST,
  STORE_UPDATE_SUCCESS,
  STORE_UPDATE_FAIL,
  STORE_UPDATE_RESET,
  STORE_BY_ID_REQUEST,
  STORE_BY_ID_SUCCESS,
  STORE_BY_ID_FAIL,
  STORE_BY_NAME_REQUEST,
  STORE_BY_NAME_SUCCESS,
  STORE_BY_NAME_FAIL,
  STORE_DETAILS_BY_NAME_REQUEST,
  STORE_DETAILS_BY_NAME_SUCCESS,
  STORE_DETAILS_BY_NAME_FAIL,
} from "../constants/storeConstants";

export const storeListReducer = (state = { stores: [] }, action) => {
  switch (action.type) {
    case STORE_LIST_REQUEST:
      return { stores_loading: true, stores: [] };
    case STORE_LIST_SUCCESS:
      return { stores_loading: false, stores: action.payload };
    case STORE_LIST_FAIL:
      return { stores_loading: false, stores_error: action.payload };
    default:
      return state;
  }
};

export const storeDetailsByIdReducer = (state = { store: {} }, action) => {
  switch (action.type) {
    case STORE_BY_ID_REQUEST:
      return { loading: true, store: {} };
    case STORE_BY_ID_SUCCESS:
      return { loading: false, store: action.payload };
    case STORE_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const storeDetailsByNameReducer = (state = { }, action) => {
  switch (action.type) {
    case STORE_BY_NAME_REQUEST:
      return { loading: true, store: {} };
    case STORE_BY_NAME_SUCCESS:
      return { loading: false, store: action.payload };
    case STORE_BY_NAME_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const storeDetailByNameReducer = (state = { }, action) => {
  switch (action.type) {
    case STORE_DETAILS_BY_NAME_REQUEST:
      return { store_details_name_loading: true, store_details_name: {} };
    case STORE_DETAILS_BY_NAME_SUCCESS:
      return { store_details_name_loading: false, store_details_name: action.payload };
    case STORE_DETAILS_BY_NAME_FAIL:
      return { store_details_name_loading: false, store_details_name_error: action.payload };
    default:
      return state;
  }
};
export const storeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_DELETE_REQUEST:
      return { loading: true };
    case STORE_DELETE_SUCCESS:
      return { loading: false, success_delete: true };
    case STORE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const storeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case STORE_CREATE_REQUEST:
      return { store_create_loading: true };
    case STORE_CREATE_SUCCESS:
      return { store_create_loading: false, store_create_success: true, store: action.payload };
    case STORE_CREATE_FAIL:
      return { store_create_loading: false, store_create_error: action.payload };
    case STORE_CREATE_RESET:
      return { store_create_success: false, store_create_loading: false };
    default:
      return state;
  }
};

export const storeUpdateByNameReducer = (state = { store: {} }, action) => {
  switch (action.type) {
    case STORE_UPDATE_REQUEST:
      return { loading: true };
    case STORE_UPDATE_SUCCESS:
      return { loading: false, success_update: true, store: action.payload };
    case STORE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case STORE_UPDATE_RESET:
      return { store: {}, loading: false, success_update: false };
    default:
      return state;
  }
};
