import axios from "axios";
import {
  VEHICLE_STOCK_LIST_BY_VEHICLE_NO_REQUEST,
  VEHICLE_STOCK_LIST_BY_VEHICLE_NO_SUCCESS,
  VEHICLE_STOCK_LIST_BY_VEHICLE_NO_FAIL,
  VEHICLE_STOCK_LIST_BY_VEHICLE_NO_RESET,

  VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST,
  VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL,
  VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET,
  VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS,

  VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST,
  VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS,
  VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL,
  VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET,
  DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_REQUEST,
  DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_SUCCESS,
  DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_FAIL,
  DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_RESET,
  VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL,
  VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET,
  VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS,
  VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST

} from "../constants/vehicleStockConstants";
// import { groupAndSum } from "../util/Utility";
import { groupByProductNameAndSumQuantityForVehicle } from "../util/Utility";


export const listStockByVehicleNo = (vehicleNo) => async (dispatch) => {
  try {
    console.log('vehicleNo : ', vehicleNo);
    dispatch({ type: VEHICLE_STOCK_LIST_BY_VEHICLE_NO_REQUEST });
    const { data } = await axios.get(`/api/vehicle-stock/${vehicleNo}`);
    dispatch({
      type: VEHICLE_STOCK_LIST_BY_VEHICLE_NO_SUCCESS,
      payload: data,
    });
    // dispatch({ type: VEHICLE_STOCK_LIST_BY_VEHICLE_NO_RESET });
  } catch (error) {
    dispatch({
      type: VEHICLE_STOCK_LIST_BY_VEHICLE_NO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const  createStockByVehicleNoAndProductName = (invoiceForm, invoiceProducts,fromSource,targetVehicleNo) => async (dispatch) => {
    console.log(
      "createStockByVehicleNoAndProductName -start ",
      invoiceForm,
      invoiceProducts,
      fromSource,
      targetVehicleNo
    );

    try {
      dispatch({
        type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST,
      });
      console.log('Vehicle No : ',targetVehicleNo);
      const { data } = await axios.post(`/api/vehicle-stock/${targetVehicleNo}/in`, {
        invoiceForm,
        invoiceProducts,
        fromSource,
        targetVehicleNo
      });
      console.log("createStockByVehicleNoAndProductName - end ");

      dispatch({
        type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS,
        payload: data,
      });

      // dispatch({
      //   type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET,
      // });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL,
        payload: message,
      });
    }
};
  
export const updateStockWhenInToVehicleNoAndProductName = (purchaseOrder, purchaseOrderProducts,targetVehicleNo) => async (dispatch, getState) => {
  console.log(
    "updateStockInToVehicleNoAndProductName -start ",
    purchaseOrder, 
    purchaseOrderProducts,
    targetVehicleNo
  );
  /** 
   * LSK : USE THIS WHILE CALCIULATING THE QUANTITY OF THE EACH PRODUCT WHULE INSERTING INTO THE WH-STOCK
   * 
   * const resultOfGroupAndSum = groupAndSum(purchaseOrderProducts);
  console.log("resultOfGroupAndSum : ", resultOfGroupAndSum)
  */
  try {
    dispatch({
      type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_REQUEST,
    });
    console.log('Vehicle No before delivering goods to Shop : ',targetVehicleNo);
    const { data } = await axios.post(`/api/vehicle-stock/${targetVehicleNo}/in`, {
      purchaseOrder, 
      purchaseOrderProducts,
      targetVehicleNo});
    console.log("updateStockInToVehicleNoAndProductName - end ");

    dispatch({
      type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_SUCCESS,
      payload: data,
    });
    // dispatch({
    //   type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET,
    //   payload: data,
    // });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_FAIL,
      payload: message,
    });
  }
};

export const updateStockWhenOutFromVehicleNoAndProductName = (invoiceDetails, invoiceProducts, targetVehicleNo) => async (dispatch, getState) => {
    console.log(
      "updateStockWhenOutFromVehicleNoAndProductName -start from Action ",
      "invoiceDetails :--> ",invoiceDetails,
      "invoiceProducts :--> ",invoiceProducts,
      "vehicleNo :--> ", targetVehicleNo
    );
  /** 
   * LSK : USE THIS WHILE CALCIULATING THE QUANTITY OF THE EACH PRODUCT WHULE INSERTING INTO THE WH-STOCK
   * 
   * const resultOfGroupAndSum = groupAndSum(purchaseOrderProducts);
  console.log("resultOfGroupAndSum : ", resultOfGroupAndSum)
  */
  try {
    dispatch({
      type: VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST,
    });
    console.log('Vehicle No before delivering goods to Shop : ',targetVehicleNo);
    const { data } = await axios.post(`/api/vehicle-stock/${targetVehicleNo}/out`, {
      invoiceDetails, invoiceProducts, targetVehicleNo});
    console.log("updateStockByVehicleNoAndProductName - end ");

    dispatch({
      type: VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS,
      payload: data,
    });
    dispatch({
      type: VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET,
      payload: data,
    });

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: VEHICLE_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL,
      payload: message,
    });
  }
};

export const updateReturnStockFromVehicleNoByProductName = (availableProductsInVehicle, vehicleNo) => async (dispatch, getState) => {
  console.log(
    "updateReturnStockFromVehicleNoByProductName -start from Action ",
    "availableProductsInVehicle :--> ",availableProductsInVehicle ? availableProductsInVehicle.length :0,
    "vehicleNo :--> ", vehicleNo
  );

/** 
 * THIS ACTION IS FOR RETURN STOCK
*/
try {
  dispatch({
    type: VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_REQUEST,
  });
  console.log('Vehicle No before delivering goods to Shop : ',vehicleNo);
  const { data } = await axios.post(`/api/vehicle-stock/return/${vehicleNo}`, {
    availableProductsInVehicle, vehicleNo});
  console.log("updateStockByVehicleNoAndProductName - end ");

  dispatch({
    type: VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_SUCCESS,
    payload: data,
  });
} catch (error) {
  const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message;
  dispatch({
    type: VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_FAIL,
    payload: message,
  });
}
};

export const deleteStockByVehicleNo = (vehicleNo) => async (dispatch) => {
  try {
    console.log('vehicleNo : ', vehicleNo);
    dispatch({ type: DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_REQUEST });
    const { data } = await axios.delete(`/api/vehicle-stock/${vehicleNo}/delete`);
    dispatch({
      type: DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_SUCCESS,
      payload: data,
    });
    dispatch({ type: DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_RESET });
  } catch (error) {
    dispatch({
      type: DELETE_STOCK_FROM_VEHICLE_BY_VEHICLE_NO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};