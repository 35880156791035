import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { Link, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Autocomplete from "@mui/material/Autocomplete";
import {
  createWarehousePurchaseOrder,
  listWarehousePurchaseOrdersByPosName,
} from "../../../actions/warehousePurchaseOrderAction";
import {
  createStorePurchaseOrder,
  listStorePurchaseOrdersByPosName,
} from "../../../actions/storePurchaseOrderAction";
import { createStockByWarehouseNameAndProductName } from "../../../actions/warehouseStockAction";
import { createStockByStoreNameAndProductName } from "../../../actions/storeStockAction";
import { Typography, Divider, Input, Paper, Box, Alert } from "@mui/material";
import {
  Grid,
  Button,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import greeTickImage from "../../../assets/images/green_tick.png";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { WAREHOUSE_PURCHASE_ORDER_CREATE_RESET } from "../../../constants/warehousePurchaseOrderConstants";
import { STORE_PURCHASE_ORDER_CREATE_RESET } from "../../../constants/storePurchaseOrderConstants";
import { WAREHOUSE_STOCK_IN_BY_WAREHOUSE_NAME_CREATE_RESET } from "../../../constants/warehouseStockConstants";
import {
  service_states,
  payment_modes,
  unit_of_meassures,
  warehouses,
  stores,
  po_status,
  warehouse_store,
  payment_types,
} from "../../../data/index";
import { listAllStores } from "../../../actions/storeAction";
import { listAllWarehouses } from "../../../actions/warehouseInfoAction";
import { listAllProducts } from "../../../actions/productAction";
import * as yup from "yup";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { formatToLocaleCurrency } from "../../../util/Utility";
import ThanksConfirmationDialog from "../../Dialog/ThanksConfirmationDialog";
import { listSuppliersByParentUser } from "../../../actions/supplierAction";
import { DatePicker } from "@mui/x-date-pickers";
// import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const PurchaseOrderCreateScreen = ({ match }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [fromSource, setFromSource] = useState(() => "");
  const warehousePurchaseOrderCreate = useSelector(
    (state) => state.warehousePurchaseOrderCreate
  );

  const storePurchaseOrderCreate = useSelector(
    (state) => state.storePurchaseOrderCreate
  );

  const {
    wh_po_create_loading,
    wh_po_create_success,
    wh_po_create_data,
    wh_po_create_error,
  } = warehousePurchaseOrderCreate;
  const {
    store_po_create_loading,
    store_po_create_success,
    store_po_create_data,
    store_po_create_error,
  } = storePurchaseOrderCreate;

  const currentDate = new Date();
  let threeMonthsOldDate = currentDate;
  threeMonthsOldDate = threeMonthsOldDate.setMonth(currentDate.getMonth() - 3);
  let threeMonthsFutureDate = currentDate;
  threeMonthsFutureDate = threeMonthsFutureDate.setMonth(
    currentDate.getMonth() + 6
  );

  const [productFormInputs, setProductFormInputs] = useState({
    name: "",
    countInStock: "",
    // metrics: "",
    pricePerUnit: 0.0,
    tax: 0.0,
    taxAmount: 0.0,
    mrp: 0.0,
    mfgDate: threeMonthsOldDate,
    expDate: threeMonthsFutureDate,
    batchNo: "",
    eachProductPrice: 0.0,
  });
  const [poFormInputs, setPoFormInputs] = useState({
    destinationWhStoreValue: "",
    billDate: "",
    billNo: "",
    supplierName: "",
    contactNo: "",
    description: "",
    totalPoAmount: "",
    stateOfSupply: "",
    purchaseOrderStatus: "",
    balanceAmount: 0,
    advanceAmount: 0,
    totalPoTaxAmount: "",
    totalPoQty: "",
    paymentType: "",
    upiRef: "",
  });
  const [open, setOpen] = React.useState(false);
  const [isDuplicateInvoice, setIsDuplicateInvoice] = React.useState(
    () => false
  );
  const [errorObject, setErrorObject] = useState(() => null);

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  let tempSuppliers = [];
  const userLogin = useSelector((state) =>
    state.userLogin ? state.userLogin : {}
  );
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName } = userInfo;
  const warehouseInfoList = useSelector((state) => state.warehouseInfoList);
  const { warehouseInfos } = warehouseInfoList;
  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;

  const storePurchaseOrdersListByPos = useSelector(
    (state) => state.storePurchaseOrdersListByPos
  );
  const { store_po_pos_loading, store_po_pos_data, store_po_pos_error } =
    storePurchaseOrdersListByPos;

  const supplierListByParentUser = useSelector(
    (state) => state.supplierListByParentUser
  );
  const { supplier_list_by_parent_user } = supplierListByParentUser;

  const [productsTobeAddedInPO, setProductsTobeAddedInPO] = useState(() => []);

  const [autoCompleteSupplier, setAutoCompleteSupplier] = useState(() => "");
  const [upiPaySelected, setUpiPaySelected] = useState(false);
  const [selectedSupplier, setSelectedSupplier] = useState(() => "");
  if (supplier_list_by_parent_user && supplier_list_by_parent_user.length > 0)
    tempSuppliers = supplier_list_by_parent_user;

  useEffect(() => {
    if (upiPaySelected) {
      const timer = setTimeout(() => {
        setUpiPaySelected(false);
      }, 1000); // Hides message after 1 second
      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [upiPaySelected]);

  useEffect(() => {
    dispatch(listAllProducts());
    dispatch(listAllStores());
    dispatch(listAllWarehouses());
    console.log(
      "UseEffect getting Called for Listing all the Prodcts, Stores, Warehouses."
    );
  }, []);

  useEffect(() => {
    if (name && name.length > 0)
      console.log("Exec listSuppliersByParentUser for name, userName", name);
    dispatch(listSuppliersByParentUser(name));
  }, [dispatch, name]);

  useEffect(() => {
    if (storeName && storeName.length > 0)
      console.log(
        "Exec listStorePurchaseOrdersByPosName for storeName : ",
        storeName
      );
    dispatch(listStorePurchaseOrdersByPosName(storeName));
  }, [dispatch, storeName]);

  useEffect(() => {
    if (warehouseName && warehouseName.length > 0)
      console.log(
        "Exec listWarehousePurchaseOrdersByPosName for warehouseName : ",
        warehouseName
      );
    dispatch(listWarehousePurchaseOrdersByPosName(warehouseName));
  }, [dispatch, warehouseName]);

  useEffect(() => {
    calculatedTotalAmountAfterAddProduct();
    console.log(
      "UseEffect Call back getting Called for productsTobeAddedInPO is Invoked...!"
    );
  }, [productsTobeAddedInPO]);

  useEffect(() => {
    if (poFormInputs.advanceAmount && poFormInputs.advanceAmount > 0) {
      calculatedBalance();
      console.log(
        "UseEffect Call back getting Called when advanceAmount is More than 0 or paymentType is Changed...!"
      );
    } else if (poFormInputs.advanceAmount === 0) {
      console.log(
        "UseEffect Call back getting Called when advanceAmount is Zero"
      );
      calculatedBalance();
      console.log("Done Balance Amount Calculation");
    }
  }, [poFormInputs.advanceAmount, poFormInputs.paymentType]);

  useEffect(() => {
    console.log(
      "UseEffect getting Called for Combined Logic to Capture the Destination of PO  START"
    );
    if (storeName && storeName.length > 0) {
      setFromSource(() => storeName);
    } else if (warehouseName && warehouseName.length > 0) {
      setFromSource(() => warehouseName);
    } else {
      console.log("Store Name is EMPTY..!");
    }
  }, [storeName, warehouseName]);

  useEffect(() => {
    console.log(
      "useEffect Getting Called PO Order Create Screen When its SUCCESS"
    );
    if (store_po_create_success) {
      console.log("store_po_create_success :==> ", store_po_create_success);
      dispatch({ type: STORE_PURCHASE_ORDER_CREATE_RESET });
      setOpen(() => true);
    }
    if (wh_po_create_success) {
      console.log("wh_po_create_success :==> ", wh_po_create_success);
      dispatch({ type: WAREHOUSE_PURCHASE_ORDER_CREATE_RESET });
      setOpen(() => true);
    }
  }, [dispatch, navigate, store_po_create_success, wh_po_create_success]);

  const checkIfDuplicateInvoice = () => {
    console.log(
      "Exec checkIfDuplicateInvoice of Purchase Order : ",
      poFormInputs.billDate,
      poFormInputs.billNo
    );
    let isDuplicate = false;
    if (store_po_pos_data && store_po_pos_data.length > 0) {
      isDuplicate = store_po_pos_data.some(
        (item) => item.billNo === poFormInputs.billNo
      );
      console.log("checkIfDuplicateInvoice of Purchase Order : ", isDuplicate);
      if (isDuplicate) {
        // console.log('checkIfDuplicateInvoice of Purchase Order is DUPLICATE INVOICE BY BILL NO : ', isDuplicate)
        setIsDuplicateInvoice(() => isDuplicate);
        setErrorObject({ errorMessage: "Bill No is Duplicate  " });
      } else {
        // console.log('checkIfDuplicateInvoice of Purchase Order is NOT DUPLICATE BILL NO : ', isDuplicate)
        setErrorObject(null);
      }
    }
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  const populateFromSource = (whSt) => {
    console.log(
      " Setting FromSourceValue from Store or Warehouse : ",
      storeName
    );
    setFromSource(() => whSt);
  };

  let renderContentUsingAGGrid = "";
  let gridApi = "";
  let rowData = [];
  const [isError, setIsError] = useState(false);
  const [billDate, setBillDate] = useState(new Date());

  const columnDefs = [
    {
      field: "SNo",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      minWidth: 300,
      cellStyle: { textAlign: "left" },
    },
    {
      field: "Qty",
      headerName: "Qty ",
      resizable: true,
      suppressSizeToFit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "UnitPrice",
      headerName: "Unit Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "Tax",
      headerName: "Tax %",
      resizable: true,
      suppressSizeToFit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "TaxAmount",
      headerName: "Tax Amount",
      resizable: true,
      suppressSizeToFit: true,
      width: 130,
      minWidth: 130,
      maxWidth: 130,
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "Amount",
      headerName: "Amount",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      cellStyle: {
        textAlign: "right",
      },
    },
    {
      field: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Delete" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="red"
              justify="center"
              onClick={() => {
                console.log(
                  "Deleting ",
                  params,
                  "productsTobeAddedInPO : ",
                  productsTobeAddedInPO
                );
                console.log(
                  " Removed : ",
                  productsTobeAddedInPO.splice(Number(params.data.SNo) - 1, 1)
                );
                setProductsTobeAddedInPO(() => [...productsTobeAddedInPO]);
                console.log(
                  "After Removal , Length of productsTobeAddedInPO : ",
                  productsTobeAddedInPO.length
                );
                handleDelete(Number(params.data.SNo));
              }}
              style={{ marginRight: "1rem", color: "red", paddingTop: "5px" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      cellStyle: {
        textAlign: "center",
      },
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );

  const allProducts = useSelector((state) => state.allProducts);
  const { loading, error, all_products } = allProducts;
  let customProducts = [];

  if (all_products && all_products.length > 0) {
    customProducts = all_products;
  }

  const [autoCompleteValue, setAutoCompleteValue] = useState(() => "");

  const handleProductChange = (event) => {
    setProductFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePoChange = (event) => {
    console.log(
      "handlePoChange is getting Called when advance Paid is Changed"
    );
    setPoFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePoPayModeChange = (event) => {
    console.log(
      "handlePoPayModeChange is getting Called when advance Paid is Changed"
    );
    setPoFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
    setUpiPaySelected(true);
  };

  const handleCreate = () => {
    console.log(
      "Handle Create is getting Exec  poFormInputs : ",
      poFormInputs,
      " productsTobeAddedInPO : ",
      productsTobeAddedInPO,
      "fromSource : ",
      fromSource
    );
    handleCreatePO(poFormInputs, productsTobeAddedInPO);
  };

  const handleCreatePO = (po, prds) => {
    console.log("handleCreatePO - START", po, prds);
    console.log("fromSource : ", fromSource);
    const thisIsStoreOrWarehouse = fromSource.includes("_WAREHOUSE")
      ? "Warehouse"
      : fromSource.includes("_STORE")
      ? "Store"
      : "";
    console.log(
      "Exec handleCreatePO  >==>>  fromSource :: ",
      fromSource,
      " thisIsStoreOrWarehouse :: ",
      thisIsStoreOrWarehouse
    );
    if (
      thisIsStoreOrWarehouse &&
      thisIsStoreOrWarehouse.length > 0 &&
      thisIsStoreOrWarehouse === "Warehouse"
    ) {
      console.log(
        "Exec createStockByWarehouseNameAndProductNameAs its for Warehouse",
        "Warehouse",
        fromSource
      );
      dispatch(createWarehousePurchaseOrder(po, prds, fromSource));
      dispatch(createStockByWarehouseNameAndProductName(po, prds, fromSource));
    } else if (
      thisIsStoreOrWarehouse &&
      thisIsStoreOrWarehouse.length > 0 &&
      thisIsStoreOrWarehouse === "Store"
    ) {
      console.log(
        "Exec createStockByWarehouseNameAndProductName its for Store",
        "Store",
        fromSource
      );
      dispatch(createStorePurchaseOrder(po, prds, fromSource));
      dispatch(createStockByStoreNameAndProductName(po, prds, fromSource));
    } else {
      console.log("Didnt match any WH or Store so defaulting to WH");
    }
    console.log("handleCreatePO - STOP");
  };

  const handleDelete = (index) => {
    console.log("handleDelete Exec... Index : ", index);
    setProductsTobeAddedInPO(
      productsTobeAddedInPO.filter((prd, i) => {
        console.log("index : ", index, " , i : ", i);
        return i !== index;
      })
    );
  };

  const handleClearPoForm = () => {
    setPoFormInputs((prevState) => {
      return {
        warehouseName: "",
        destinationName: "",
        destinationWhStoreValue: "",
        billDate: "",
        supplierName: "",
        contactNo: "",
        description: "",
        roundOff: "",
        totalPoAmount: "",
        stateOfSupply: "",
        advanceAmount: 0,
        totalPoTaxAmount: "",
        totalPoQty: "",
        purchaseOrderStatus: "",
        paymentType: "",
        upiRef: "",
      };
    });
  };

  const handleClearProductForm = () => {
    console.log("Clearing the Product Form Inouts");
    setProductFormInputs(() => {
      return {
        name: "",
        qty: 0,
        // metrics: "",
        countInStock: 0,
        // countOfDamagedStock: "",
        pricePerUnit: 0.0,
        tax: 0.0,
        mrp: 0.0,
        mfgDate: threeMonthsOldDate,
        expDate: threeMonthsFutureDate,
        batchNo: "",
        eachProductPrice: 0.0,
      };
    });
  };

  const calculatedTotalAmountAfterAddProduct = () => {
    console.log("Exec calculatedTotalAmountAfterAddProduct ..");
    const calculatedPoTotal = calculatePoTotal();
    if (calculatedPoTotal > 0) {
      console.log(
        "calculatedTotalAmountAfterAddProduct :: " + calculatedPoTotal
      );
      setPoFormInputs({
        ...poFormInputs,
        totalPoAmount: calculatedPoTotal,
      });
    }
  };

  const calculatedBalance = () => {
    const value = poFormInputs.advanceAmount;
    console.log(
      "Exec calculatedBalance for Advance Amount : ",
      poFormInputs.advanceAmount
    );
    const calculatedPoTotal = calculatePoTotal();
    const calculatedPoTaxTotalAmount = calculateTotalPoTaxAmount();
    const calculatedPoTotalQuantity = calculateTotalQuantity();

    if (value > 0 && calculatedPoTotal > 0) {
      setPoFormInputs({
        ...poFormInputs,
        advanceAmount: value,
        balanceAmount: calculatedPoTotal - value,
        totalPoAmount: calculatedPoTotal,
        totalPoTaxAmount: calculatedPoTaxTotalAmount,
        totalPoQty: calculatedPoTotalQuantity,
      });
    }
  };

  const calculatePoTotal = () => {
    let totalPOAmount = 0;
    if (productsTobeAddedInPO && productsTobeAddedInPO.length > 0) {
      productsTobeAddedInPO.map((eachProduct) =>
        console.log(
          "eachProduct.eachProductPrice : ",
          eachProduct.eachProductPrice
        )
      );
      totalPOAmount = productsTobeAddedInPO
        .map((eachProduct) => eachProduct.eachProductPrice)
        .reduce((acc, amount) => acc + amount);
    }
    console.log("totalPOAmount after Calc :: == >> ", totalPOAmount);
    return totalPOAmount;
  };

  const calculateTotalPoTaxAmount = () => {
    let totalPoTaxAmount = "";
    if (productsTobeAddedInPO && productsTobeAddedInPO.length > 0) {
      totalPoTaxAmount = productsTobeAddedInPO
        .map((eachProduct) => {
          const result = Math.ceil(
            ((Number(eachProduct.pricePerUnit) *
              Number(eachProduct.countInStock) *
              100) /
              (100 + Number(eachProduct.tax))) *
              (Number(eachProduct.tax) / 100)
          );
          console.log("Result after calculating Tax :  ", result);
          return result;
        })
        .reduce((acc, amount) => acc + amount);
    }
    console.log("totalPOTaxAmount after Calc :: == >> ", totalPoTaxAmount);
    return formatToLocaleCurrency(totalPoTaxAmount);
  };
  const calculateTotalQuantity = () => {
    let totalPoTotalQuantity = "";
    if (productsTobeAddedInPO && productsTobeAddedInPO.length > 0) {
      totalPoTotalQuantity = productsTobeAddedInPO
        .map((eachProduct) => Math.ceil(Number(eachProduct.countInStock)))
        .reduce((acc, amount) => acc + amount);
    }
    console.log(
      "totalPoTotalQuantity after Calc :: == >> ",
      totalPoTotalQuantity
    );
    return totalPoTotalQuantity;
  };

  const handleAddProduct = () => {
    console.log(
      "Handle Add Product Clicked autoCompleteValue :",
      autoCompleteValue
    );
    setProductsTobeAddedInPO([
      ...productsTobeAddedInPO,
      {
        productName: autoCompleteValue,
        // metrics: productFormInputs.metrics,
        countInStock: productFormInputs.countInStock,
        pricePerUnit: productFormInputs.pricePerUnit,
        eachProductPrice:
          productFormInputs.countInStock * productFormInputs.pricePerUnit,
        tax: productFormInputs.tax,
        taxAmount: formatToLocaleCurrency(Number(productFormInputs.taxAmount)),
        mrp: productFormInputs.mrp,
        mfgDate: productFormInputs.mfgDate
          ? productFormInputs.mfgDate
          : threeMonthsOldDate,
        expDate: productFormInputs.expDate
          ? productFormInputs.expDate
          : threeMonthsFutureDate,
        batchNo: productFormInputs.batchNo,
      },
    ]);
    handleClearProductForm();
  };

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "warehouses":
        return warehouses.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "payment_modes":
        return payment_modes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "service_states":
        return service_states.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "unit_of_meassures":
        return unit_of_meassures.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "stores":
        return stores.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "po_status":
        return po_status.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "warehouse_store":
        return warehouse_store.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "metrics":
        return unit_of_meassures.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "payment_types":
        return payment_types.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };
  if (productsTobeAddedInPO && productsTobeAddedInPO.length > 0) {
    rowData = productsTobeAddedInPO.map((eachProduct, index) => {
      console.log("eachProduct : ", eachProduct);
      console.log(
        " ****** RES ******  ",
        ((eachProduct.pricePerUnit * eachProduct.countInStock * 100) /
          (100 + eachProduct.tax)) *
          (eachProduct.tax / 100)
      );
      return {
        SNo: index + 1,
        ProductName: eachProduct.productName,
        Qty: eachProduct.countInStock
          ? formatToLocaleCurrency(Number(eachProduct.countInStock))
          : 0.0,
        UnitPrice: eachProduct.pricePerUnit
          ? formatToLocaleCurrency(Number(eachProduct.pricePerUnit))
          : 0.0,
        Tax: eachProduct.tax,
        TaxAmount: formatToLocaleCurrency(
          ((Number(eachProduct.pricePerUnit) *
            Number(eachProduct.countInStock) *
            100) /
            (100 + Number(eachProduct.tax))) *
            (Number(eachProduct.tax) / 100)
        ),
        Amount: eachProduct.eachProductPrice
          ? formatToLocaleCurrency(Number(eachProduct.eachProductPrice))
          : "",
        mrp: eachProduct.mrp,
        mfgDate: eachProduct.mfgDate,
        expDate: eachProduct.expDate,
        batchNo: eachProduct.batchNo,
        Delete: eachProduct._id,
      };
    });

    if (rowData) {
      renderContentUsingAGGrid = (
        <div
          className="ag-theme-quartz"
          style={{ width: "70%", height: "65vh", margin: "auto" }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            columnHoverHighlight={true}
            onGridReady={onGridReady}
            QtyIncludeFooter={true}
            QtyIncludeTotalFooter={true}
            pagination="true"
          ></AgGridReact>
        </div>
      );
    }
  }

  const validateProductBeforeAdd = () => {
    console.log("validateProductBeforeAdd ...! ");
    if (
      productFormInputs.productName !== "" &&
      productFormInputs.tax &&
      productFormInputs.batchNo &&
      productFormInputs.countInStock &&
      productFormInputs.mfgDate &&
      productFormInputs.expDate &&
      productFormInputs.mrp &&
      productFormInputs.batchNo
    )
      return true;
    else return false;
  };
  const handleClose = () => {
    // if (store_po_create_success || wh_po_create_success) {
    setOpen(false);
    navigate("/admin/purchase-orders");
    // }
  };

  return (
    <div>
      <div className={classes.root}>
        {upiPaySelected && (
          <Alert
            variant="filled"
            severity="warning"
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
              //  backgroundColor: "red",
              color: "white",
              padding: "10px 20px",
              borderRadius: "8px",
              fontSize: "18px",
            }}
          >
            If UPI Payment, then UPI Reference is Mandatory
          </Alert>
        )}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <GridContainer spacing={2} alignItems="center" justify="center">
              <Grid item xs={12}>
                <Paper
                  style={{ margin: "1rem", width: "15rem", height: "15rem" }}
                >
                  <div
                    style={{
                      margin: "1rem",
                      width: "10rem",
                      height: "10rem",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Purchase Order Placed"
                      src={greeTickImage}
                      style={{
                        margin: "1rem",
                        width: "9.5rem",
                        height: "9.5rem",
                      }}
                    />
                  </div>
                  <Typography
                    variant="h5"
                    color="black"
                    style={{
                      margin: "0.5rem",
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "600",
                      fontSize: "1rem",
                      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                    }}
                  >
                    Thanks , your Purchase Order is placed.
                  </Typography>
                </Paper>
              </Grid>
            </GridContainer>
          </DialogContent>
        </BootstrapDialog>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>

                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem", textAlign: "center" }}
                  color="black"
                >
                  <i>WAREHOUSE | STORE - PURCHASE ORDER</i>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Supplier Details
                <Divider></Divider>
              </Typography>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        console.log("Chosen Supplier :==> ", value);
                        setAutoCompleteSupplier(value);
                        setPoFormInputs((prev) => ({
                          ...prev,
                          supplierName: value.name,
                          contactNo: value.contactNo,
                        }));
                        return;
                      }}
                      id="combo-box-products"
                      options={
                        supplier_list_by_parent_user
                          ? supplier_list_by_parent_user
                          : []
                      }
                      size="small"
                      sx={{
                        md: {
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                        },
                      }}
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Supplier Name"
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          size="small"
                          value={
                            autoCompleteSupplier && autoCompleteSupplier.name
                              ? autoCompleteSupplier.name
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    style={{ textAlign: "center" }}
                  >
                    <Box>
                      <Link
                        to="/admin/supplier/signup"
                        style={{
                          color: "#900C3F",
                          textDecoration: "none",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        <Typography
                          variant="button"
                          color="#900C3F"
                          style={{
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                        >
                          Add Supplier
                        </Typography>
                      </Link>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    style={{ textAlign: "center" }}
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      style={{ justifyContent: "center", textAlign: "center" }}
                    >
                      <DatePicker
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                        value={billDate}
                        size="small"
                        name="billDate"
                        onChange={(newValue) => {
                          setBillDate(newValue);
                          console.log("newValue" + newValue);
                        }}
                        renderInput={(params) => (
                          <TextField size="small" {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    style={{ textAlign: "center" }}
                  >
                    <TextField
                      label="Bill No"
                      variant="outlined"
                      size="small"
                      required
                      name="billNo"
                      onChange={handlePoChange}
                      onBlur={checkIfDuplicateInvoice}
                      value={poFormInputs.billNo}
                      type="string"
                    />
                    {errorObject && errorObject.errorMessage && (
                      <div
                        variant="h5"
                        style={{
                          textAlign: "center",
                          fontSize: "1rem",
                          fontWeight: "600",
                          margin: "1rem",
                          color: "red",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {errorObject.errorMessage}
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    style={{ textAlign: "center" }}
                  >
                    <FormControl
                      style={{ justifyContent: "center", textAlign: "center" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        State of Supply
                      </InputLabel>
                      <Select
                        style={{ justifyContent: "center", width: "12rem" }}
                        label="State of Supply"
                        placeholder="State of Supply"
                        variant="outlined"
                        required
                        size="small"
                        name="stateOfSupply"
                        onChange={handlePoChange}
                        value={poFormInputs.stateOfSupply}
                      >
                        {renderDynamicOptions("service_states")}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PhoneInput
                      style={{
                        justifyContent: "center",
                      }}
                      country={"us"}
                      placeholder="Contact #"
                      hideDropdown="true"
                      disableCountryGuess="true"
                      defaultCountry="in"
                      name="contactNo"
                      size="small"
                      required
                      value={
                        autoCompleteSupplier && autoCompleteSupplier.contactNo
                          ? autoCompleteSupplier.contactNo
                          : ""
                      }
                      disabled={true}
                    />
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* PRODUCT DETAILS START */}
        {autoCompleteSupplier && !errorObject && poFormInputs.billNo ? (
          <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem" }}
                  color="black"
                >
                  Product Details
                  <Divider></Divider>
                </Typography>
                <CardBody>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Autocomplete
                        disablePortal
                        onChange={(event, value) => {
                          if (value) {
                            console.log("Value : ", value);
                            setAutoCompleteValue(value.name);
                            setProductFormInputs((prev) => ({
                              ...prev,
                              name: value.name,
                              tax: value.tax ? value.tax : 0.0,
                            }));
                          }
                        }}
                        id="combo-box-products"
                        options={customProducts}
                        size="small"
                        clearOnBlur={true}
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        getOptionLabel={(option) => `${option.name}`}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select Product"
                            size="small"
                            value={
                              productFormInputs.name
                                ? productFormInputs.name
                                : null
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ textAlign: "center" }}
                    >
                      <TextField
                        label="Qty"
                        variant="outlined"
                        size="small"
                        name="countInStock"
                        required
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only positive integers (No decimals, No negatives)
                          if (/^\d*$/.test(value)) {
                            handleProductChange(e);
                          }
                        }}
                        type="text"
                        inputProps={{
                          inputMode: "numeric", // Ensures a numeric keypad on mobile devices
                          pattern: "[0-9]*", // Prevents non-numeric input
                        }}
                        value={
                          productFormInputs.countInStock
                            ? productFormInputs.countInStock
                            : ""
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ textAlign: "center" }}
                    >
                      <TextField
                        label="Purchase Price / Unit"
                        variant="outlined"
                        size="small"
                        name="pricePerUnit"
                        required
                        inputProps={{
                          inputMode: "decimal", // Ensures mobile devices show a decimal keypad
                          pattern: "[0-9]*\\.?[0-9]*", // Allows only numbers and a single decimal
                        }}
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only positive numbers with optional decimals
                          if (/^\d*\.?\d*$/.test(value)) {
                            handleProductChange(e);
                          }
                        }}
                        type="number"
                        value={
                          productFormInputs.pricePerUnit
                            ? productFormInputs.pricePerUnit
                            : ""
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ textAlign: "center" }}
                    >
                      <TextField
                        label="MRP"
                        variant="outlined"
                        size="small"
                        name="mrp"
                        required
                        type="number"
                        inputProps={{
                          inputMode: "decimal", // Ensures mobile devices show a decimal keypad
                          pattern: "[0-9]*\\.?[0-9]*", // Allows only numbers and a single decimal
                        }}
                        value={
                          productFormInputs.mrp ? productFormInputs.mrp : ""
                        }
                        onChange={(e) => {
                          const value = e.target.value;
                          // Allow only positive numbers with optional decimals
                          if (/^\d*\.?\d*$/.test(value)) {
                            handleProductChange(e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ textAlign: "center" }}
                    >
                      <TextField
                        label="Batch No"
                        variant="outlined"
                        size="small"
                        name="batchNo"
                        type="string"
                        value={
                          productFormInputs.batchNo
                            ? productFormInputs.batchNo
                            : ""
                        }
                        onChange={handleProductChange}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ textAlign: "center" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Mfg Date"
                          value={
                            productFormInputs.mfgDate
                              ? productFormInputs.mfgDate
                              : threeMonthsOldDate
                          }
                          size="small"
                          name="mfgDate"
                          onChange={(newValue) => {
                            console.log("newValue  : ", newValue);
                            setProductFormInputs((prevState) => ({
                              ...prevState,
                              mfgDate: newValue,
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ textAlign: "center" }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Exp Date"
                          value={
                            productFormInputs.expDate
                              ? productFormInputs.expDate
                              : threeMonthsFutureDate
                          }
                          size="small"
                          name="expDate"
                          onChange={(newValue) => {
                            console.log("newValue  : ", newValue);
                            setProductFormInputs((prevState) => ({
                              ...prevState,
                              expDate: newValue,
                            }));
                          }}
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {autoCompleteValue &&
                    productFormInputs.pricePerUnit &&
                    productFormInputs.pricePerUnit > 0.0 &&
                    productFormInputs.mrp &&
                    productFormInputs.mrp > 0.0 &&
                    productFormInputs.countInStock &&
                    Number(productFormInputs.countInStock) > 0 ? (
                      <Grid item xs={12} align="center">
                        <Button
                          size="small"
                          variant="contained"
                          type="submit"
                          color="secondary"
                          justify="center"
                          onClick={handleAddProduct}
                          disabled={!validateProductBeforeAdd ? true : false}
                          style={{
                            textTransform: "capitalize",
                            width: "20vh",
                            borderRadius: "5px",
                          }}
                        >
                          Add Product
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        ) : (
          <></>
        )}
        {/* PRODUCT DETAILS END */}
        {autoCompleteSupplier && !errorObject && (
          <GridContainer spacing={2} alignItems="center" justify="center">
            {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
          </GridContainer>
        )}
        {productsTobeAddedInPO &&
          productsTobeAddedInPO.length > 0 &&
          autoCompleteSupplier &&
          !errorObject && (
            <GridContainer spacing={2} alignItems="center" justify="center">
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <Typography
                    variant="h6"
                    style={{ margin: "0.5rem" }}
                    color="black"
                  >
                    Order Summary
                    <Divider></Divider>
                  </Typography>
                  <CardBody>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{ textAlign: "center" }}
                      >
                        <FormControl
                          style={{ textAlign: "left", width: "50%" }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Payment Type
                          </InputLabel>
                          <Select
                            label="Payment Type"
                            variant="outlined"
                            required
                            size="small"
                            name="paymentType"
                            onChange={handlePoPayModeChange}
                            value={poFormInputs.paymentType}
                            fullWidth
                            style={{ textAlign: "center" }}
                          >
                            {renderDynamicOptions("payment_types")}
                          </Select>
                        </FormControl>
                      </Grid>
                      {poFormInputs.paymentType &&
                        poFormInputs.paymentType.toLocaleLowerCase() ===
                          "upi" && (
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            <TextField
                              label="Upi Ref"
                              variant="outlined"
                              size="small"
                              name="upiRef"
                              type="text"
                              onChange={handlePoChange}
                              value={poFormInputs.upiRef}
                            />
                          </Grid>
                        )}
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          label="Advance Paid"
                          variant="outlined"
                          size="small"
                          name="advanceAmount"
                          type="text"
                          value={poFormInputs.advanceAmount}
                          onChange={handlePoChange}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          label="Balance Amount"
                          variant="outlined"
                          disabled={true}
                          size="small"
                          name="balanceAmount"
                          type="number"
                          value={poFormInputs.balanceAmount}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          label="Total Amount"
                          variant="outlined"
                          size="small"
                          name="totalPoAmount"
                          disabled
                          type="number"
                          value={poFormInputs.totalPoAmount}
                          style={{
                            textAlign: "center",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          label="Total Tax : INR"
                          variant="outlined"
                          size="small"
                          name="totalPoTaxAmount"
                          disabled
                          type="number"
                          value={poFormInputs.totalPoTaxAmount}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          label="Total Qty"
                          variant="outlined"
                          size="small"
                          name="totalPoQty"
                          disabled
                          type="number"
                          value={poFormInputs.totalPoQty}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <TextField
                          label="Payment Status"
                          variant="outlined"
                          disabled={true}
                          size="small"
                          name="paymentStatus"
                          type="text"
                          value={
                            poFormInputs.balanceAmount &&
                            Number(poFormInputs.balanceAmount) > 0
                              ? "DUE"
                              : "PAID"
                          }
                        />
                      </Grid>
                      {productsTobeAddedInPO &&
                        productsTobeAddedInPO.length > 0 && (
                          <Grid item xs={12} sm={12} md={12}>
                            <Grid item xs={12} align="center">
                              <Button
                                size="small"
                                variant="contained"
                                type="submit"
                                color="secondary"
                                style={{
                                  textTransform: "capitalize",
                                  width: "20vh",
                                  borderRadius: "5px",
                                }}
                                disabled={
                                  poFormInputs.paymentType &&
                                  poFormInputs.paymentType.toLocaleLowerCase() ===
                                    "upi" &&
                                  poFormInputs.upiRef &&
                                  poFormInputs.upiRef.length > 0
                                    ? false
                                    : poFormInputs.paymentType &&
                                      poFormInputs.paymentType.toLocaleLowerCase() ===
                                        "cash"
                                    ? false
                                    : true
                                }
                                justify="center"
                                onClick={handleCreate}
                              >
                                Create
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )}
      </div>
    </div>
  );
};

export default PurchaseOrderCreateScreen;
