import {
  CTC_PURCHASE_ORDER_DETAILS_BY_ID_FAIL,
  CTC_PURCHASE_ORDER_DETAILS_BY_ID_REQUEST,
  CTC_PURCHASE_ORDER_DETAILS_BY_ID_SUCCESS,
  CTC_PURCHASE_ORDER_DETAILS_BY_ID_RESET,

  CTC_PURCHASE_ORDER_REGISTER_FAIL,
  CTC_PURCHASE_ORDER_REGISTER_REQUEST,
  CTC_PURCHASE_ORDER_REGISTER_SUCCESS,
  CTC_PURCHASE_ORDER_REGISTER_RESET,
 
  CTC_PURCHASE_ORDER_LIST_FAIL,
  CTC_PURCHASE_ORDER_LIST_SUCCESS,
  CTC_PURCHASE_ORDER_LIST_REQUEST,
  CTC_PURCHASE_ORDER_LIST_RESET,

  CTC_PURCHASE_ORDER_DELETE_REQUEST,
  CTC_PURCHASE_ORDER_DELETE_SUCCESS,
  CTC_PURCHASE_ORDER_DELETE_FAIL,
  CTC_PURCHASE_ORDER_DELETE_RESET,

  CTC_PURCHASE_ORDER_UPDATE_FAIL,
  CTC_PURCHASE_ORDER_UPDATE_SUCCESS,
  CTC_PURCHASE_ORDER_UPDATE_REQUEST,
  CTC_PURCHASE_ORDER_UPDATE_RESET,

} from "../constants/ctcPurchaseOrderConstants.js";


export const ctcPurchaseOrderRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_PURCHASE_ORDER_REGISTER_REQUEST:
      return { ctc_purchase_order_register_loading: true };
    case CTC_PURCHASE_ORDER_REGISTER_SUCCESS:
      return { ctc_purchase_order_register_loading: false,ctc_purchase_order_register_success: true, ctc_purchase_order_register_data: action.payload };
    case CTC_PURCHASE_ORDER_REGISTER_FAIL:
      return { ctc_purchase_order_register_loading: false, ctc_purchase_order_register_error: action.payload };
    case CTC_PURCHASE_ORDER_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const ctcPurchaseOrderDetailsByIdReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_PURCHASE_ORDER_DETAILS_BY_ID_REQUEST:
      return { ...state, ctc_purchase_order_details_by_id_loading: true };
    case CTC_PURCHASE_ORDER_DETAILS_BY_ID_SUCCESS :
      return { ctc_purchase_order_details_by_id_loading: false, ctc_purchase_order_details_data : action.payload };
    case CTC_PURCHASE_ORDER_DETAILS_BY_ID_FAIL:
      return { ctc_purchase_order_details_by_id_loading: false, ctc_purchase_order_details_by_id_error: action.payload };
    case CTC_PURCHASE_ORDER_DETAILS_BY_ID_RESET:
      return {  };
    default:
      return state;
  }
};

export const ctcPurchaseOrderListReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_PURCHASE_ORDER_LIST_REQUEST:
      return { ctc_purchase_order_list_loading: true };
    case CTC_PURCHASE_ORDER_LIST_SUCCESS:
      return { ctc_purchase_order_list_loading: false, ctc_purchase_order_list_success: false, ctc_purchase_order_list_data : action.payload };
    case CTC_PURCHASE_ORDER_LIST_FAIL:
      return { ctc_purchase_order_list_loading: false, ctc_purchase_order_list_error : action.payload };
    case CTC_PURCHASE_ORDER_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const ctcPurchaseOrderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_PURCHASE_ORDER_DELETE_REQUEST:
      return { ctc_purchase_order_delete_loading: true };
    case CTC_PURCHASE_ORDER_DELETE_SUCCESS:
      return { ctc_purchase_order_delete_loading: false, ctc_purchase_order_delete_success: true };
    case CTC_PURCHASE_ORDER_DELETE_FAIL:
      return { ctc_purchase_order_delete_loading: false, ctc_purchase_order_delete_error: action.payload };
    default:
      return state;
  }
};

export const ctcPurchaseOrderUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_PURCHASE_ORDER_UPDATE_REQUEST:
      return { ctc_purchase_order_update_loading: true };
    case CTC_PURCHASE_ORDER_UPDATE_SUCCESS:
      return { ctc_purchase_order_update_loading: false, ctc_purchase_order_update_success : true };
    case CTC_PURCHASE_ORDER_UPDATE_FAIL:
      return { ctc_purchase_order_update_loading: false, ctc_purchase_order_update_error: action.payload };
    case CTC_PURCHASE_ORDER_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
