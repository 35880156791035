import axios from "axios";
import {
  RAW_MATERIAL_STOCK_DETAILS_BY_ID_FAIL,
  RAW_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST,
  RAW_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS,
  RAW_MATERIAL_STOCK_DETAILS_BY_ID_RESET,

  RAW_MATERIAL_STOCK_REGISTER_FAIL,
  RAW_MATERIAL_STOCK_REGISTER_REQUEST,
  RAW_MATERIAL_STOCK_REGISTER_SUCCESS,
  RAW_MATERIAL_STOCK_REGISTER_RESET,
 
  RAW_MATERIAL_STOCK_LIST_FAIL,
  RAW_MATERIAL_STOCK_LIST_SUCCESS,
  RAW_MATERIAL_STOCK_LIST_REQUEST,
  RAW_MATERIAL_STOCK_LIST_RESET,

  RAW_MATERIAL_STOCK_DELETE_REQUEST,
  RAW_MATERIAL_STOCK_DELETE_SUCCESS,
  RAW_MATERIAL_STOCK_DELETE_FAIL,
  RAW_MATERIAL_STOCK_DELETE_RESET,

  RAW_MATERIAL_STOCK_UPDATE_FAIL,
  RAW_MATERIAL_STOCK_UPDATE_SUCCESS,
  RAW_MATERIAL_STOCK_UPDATE_REQUEST,
  RAW_MATERIAL_STOCK_UPDATE_RESET,
  RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_REQUEST,
  RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_FAIL,
  RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_RESET,
  RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_SUCCESS,

} from "../constants/rawMaterialStockConstants";


export const createRawMaterialStock =
  ( 
    rawMaterialStockFormInputs
  ) =>
  async (dispatch) => {
    console.log('EXEC createRawMaterialStock Register : rawMaterialStockFormInputs ', rawMaterialStockFormInputs)
    try {
      dispatch({
        type: RAW_MATERIAL_STOCK_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { 
        ctcSupplierName, ctcSupplierGstNo, ctcSupplierContactNo,
        productName, variety, bagsReceived, eachBagWeightInKgs, totalWeightInKgs,
        pricePerKg, taxPerKg, totalPrice, totalTaxPrice, stockMoveInDate, taxPrice
      } = rawMaterialStockFormInputs
      console.log("Before Perfoming API Call ctcSupplierName : ", ctcSupplierName);
      const { data } = await axios.post(
        "/api/raw-material-stock",
        { ctcSupplierName, ctcSupplierGstNo, ctcSupplierContactNo,
          productName, variety, bagsReceived, eachBagWeightInKgs, totalWeightInKgs,
          pricePerKg, taxPerKg, totalPrice, totalTaxPrice,stockMoveInDate, taxPrice
        },
        config
      );

      dispatch({
        type: RAW_MATERIAL_STOCK_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: RAW_MATERIAL_STOCK_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getRawMaterialStockDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: RAW_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/raw-material-stock/${id}`);

    dispatch({
      type: RAW_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: RAW_MATERIAL_STOCK_DETAILS_BY_ID_FAIL,
      payload: message,
    });
  }
};

export const listRawMaterialStocks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RAW_MATERIAL_STOCK_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/raw-material-stock`);

    dispatch({
      type: RAW_MATERIAL_STOCK_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: RAW_MATERIAL_STOCK_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteRawMaterialStock = (id) => async (dispatch, getState) => {
  console.log("deletePurchase Order ", id);
  try {
    dispatch({
      type: RAW_MATERIAL_STOCK_DELETE_REQUEST,
    });

    await axios.delete(`/api/raw-material-stock/${id}`);

    dispatch({ type: RAW_MATERIAL_STOCK_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: RAW_MATERIAL_STOCK_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateRawMaterialStock = ( productName, variety, bagsReceived , eachBagWeightInKgs, totalWeightInKgs, pricePerKg, totalPrice, stockMoveInDate) => async (dispatch, getState) => {
  console.log('updatePurchase Order : ', productName, variety, bagsReceived , eachBagWeightInKgs, totalWeightInKgs, pricePerKg, totalPrice, stockMoveInDate)
  try {
    dispatch({
      type: RAW_MATERIAL_STOCK_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/raw-material-stock/${id}`, {
      coldStorage, productName, variety, bagsReceived ,vehicleNo, wayBillNo, eachBagWeightInKgs, totalWeightInKgs, pricePerKg, totalPrice, bondDate
    }, {});

    dispatch({ type: RAW_MATERIAL_STOCK_UPDATE_SUCCESS , payload: data });

    dispatch({ type: RAW_MATERIAL_STOCK_UPDATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: RAW_MATERIAL_STOCK_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const updateRawMaterialStockPostManufacturing = ( productsVarietyStockRowData) => async (dispatch, getState) => {
  console.log('Exec updateRawMaterialStockPostManufacturing  : ', productsVarietyStockRowData)
  try {
    dispatch({
      type: RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_REQUEST,
    });

    const { data } = await axios.post(`/api/raw-material-stock/post-mfg`, {
      productsVarietyStockRowData
    }, {});

    dispatch({ type: RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_SUCCESS , payload: data });

    // dispatch({ type: RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_FAIL,
      payload: message,
    });
  }
};
