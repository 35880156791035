import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom";
import { register } from "../../../actions/factoryAction.js";
import { FACTORY_REGISTER_RESET } from "../../../constants/factoryConstants.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const schema = {
  name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  contactNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 10,
      maximum: 10,
    },
  },
  inchargeName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  inchargeContactNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
  factoryAddress: {
    presence: { allowEmpty: false, message: "is required" },
  },
  city: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 300,
    },
  },
};

const FactoryRegisterForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name , storeName, warehouseName } = userInfo;
  const factoryRegistered = useSelector((state) => state.factoryRegistered);
  const { factory_register_success, factory_register_error} = factoryRegistered
  

  const pos = storeName && storeName.length >0 ? storeName:warehouseName && warehouseName.length >0 ?warehouseName :""
  useEffect(()=>{
    if(factory_register_success){
      console.log('Registered factory so Routing to List Page: ')
      dispatch({
        type: FACTORY_REGISTER_RESET,
      });
      navigate('/ctc/factory');
    }
  },[factory_register_success,navigate, dispatch])

  
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "select"
            ? event.target.selected
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleSubmit = (event) => {
    console.log("Values Submitted are : ", formState.values);
    event.preventDefault();
    console.log("formState.isValid : ", formState.isValid);
    if (formState.isValid)
      dispatch(
        register(
          formState.values.name,
          formState.values.contactNo,
          formState.values.inchargeName,
          formState.values.inchargeContactNo,
          formState.values.factoryAddress,
          formState.values.city,
        )
      );

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
  };

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className={classes.root}>
      <form method="post" onSubmit={handleSubmit}>
        <Grid container spacing={2} justifyContent="center" marginTop="auto">
        <Grid item xs={12} align="center">{factory_register_error && (
            <Message variant="danger" severity="error">
              { factory_register_error }
            </Message>
          )}
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Name"
              label="Name*"
              variant="outlined"
              fullWidth
              size="small"
              name="name"
              helperText={
                hasError("name") ? formState.errors.name[0] : null
              }
              error={hasError("name")}
              onChange={handleChange}
              type="text"
              value={formState.values.name || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Contact No"
              label="Contact No*"
              variant="outlined"
              size="small"
              name="contactNo"
              fullWidth
              helperText={
                hasError("contactNo") ? formState.errors.contactNo[0] : null
              }
              error={hasError("contactNo")}
              onChange={handleChange}
              type="number"
              value={formState.values.contactNo || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Incharge Name"
              label="Incharge Name*"
              variant="outlined"
              fullWidth
              size="small"
              name="inchargeName"
              helperText={
                hasError("inchargeName") ? formState.errors.inchargeName[0] : null
              }
              error={hasError("inchargeName")}
              onChange={handleChange}
              type="text"
              value={formState.values.inchargeName || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Incharge Contact No"
              label="Incharge Contact No*"
              variant="outlined"
              size="small"
              name="inchargeContactNo"
              fullWidth
              helperText={
                hasError("inchargeContactNo") ? formState.errors.inchargeContactNo[0] : null
              }
              error={hasError("inchargeContactNo")}
              onChange={handleChange}
              type="number"
              value={formState.values.inchargeContactNo || ""}
            />
          </Grid>

          <Grid item xs={12} align="center">
            <TextField
              placeholder="Factory Address"
              label="Factory Address *"
              variant="outlined"
              size="small"
              name="factoryAddress"
              fullWidth
              helperText={
                hasError("factoryAddress") ? formState.errors.factoryAddress[0] : null
              }
              error={hasError("factoryAddress")}
              onChange={handleChange}
              type="text"
              maxRows="5"
              minRows="5"
              multiline
              value={formState.values.factoryAddress || ""}
            />
          </Grid>
         
          <Grid item xs={12} align="center">
            <TextField
              placeholder="City"
              label="City *"
              variant="outlined"
              size="small"
              name="city"
              fullWidth
              helperText={
                hasError("city") ? formState.errors.city[0] : null
              }
              error={hasError("city")}
              onChange={handleChange}
              type="text"
              value={formState.values.city || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              fullWidth
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
              }}
            >
              Register
            </Button>
          </Grid>
        </Grid>

      </form>
    </div>
  );
};

export default FactoryRegisterForm;
