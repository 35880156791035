import axios from "axios";
import {
  WAREHOUSE_PURCHASE_ORDER_CREATE_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_CREATE_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_CREATE_FAIL,
  WAREHOUSE_PURCHASE_ORDER_LIST_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_LIST_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_LIST_FAIL,
  WAREHOUSE_PURCHASE_ORDER_BY_ID_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_BY_ID_FAIL,
  WAREHOUSE_PURCHASE_ORDER_BY_ID_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS,
  WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_FAIL,
  WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST,
  WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL,
  WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS,
} from "../constants/warehousePurchaseOrderConstants";
import {
  WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_REQUEST,
  WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_RESET,
  WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_SUCCESS,
  WAREHOUSE_STOCK_BY_WAREHOUSE_NAME_FAIL,
} from "../constants/warehouseStockConstants";
import { groupAndSum } from "../util/Utility";

export const listWarehousePurchaseOrders = () => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_PURCHASE_ORDER_LIST_REQUEST });
    const { data } = await axios.get("/api/warehouse-po");
    dispatch({
      type: WAREHOUSE_PURCHASE_ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: WAREHOUSE_PURCHASE_ORDER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listWarehousePurchaseOrderById = (id) => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_PURCHASE_ORDER_BY_ID_REQUEST });
    const { data } = await axios.get(`/api/warehouse-po/po_id/${id}`);

    dispatch({
      type: WAREHOUSE_PURCHASE_ORDER_BY_ID_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ",data?data.length :0);
  } catch (error) {
    dispatch({
      type: WAREHOUSE_PURCHASE_ORDER_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listWarehousePurchaseOrdersByPosName = (pos) => async (dispatch) => {
  try {
    dispatch({ type: WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_REQUEST });
    const { data } = await axios.get(`/api/warehouse-po/pos/${pos}`);

    dispatch({
      type: WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_SUCCESS,
      payload: data,
    });
    console.log("Response Data: ",data?data.length :0);
  } catch (error) {
    dispatch({
      type: WAREHOUSE_PURCHASE_ORDER_LIST_BY_POS_NAME_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteWarehousePurchaseOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_REQUEST,
    });

    await axios.delete(`/api/warehouse-po/${id}`);

    dispatch({
      type: WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message) {
      dispatch({
        type: WAREHOUSE_PURCHASE_ORDER_DELETE_BY_ID_FAIL,
        payload: message,
      });
    }
  }
};

export const  createWarehousePurchaseOrder =
  (purchaseOrder, purchaseOrderProducts, whName) => async (dispatch) => {
    console.log(
      "createWarehousePurchaseOrder -start purchaseOrder :: ",
      purchaseOrder, "purchaseOrderProducts ::",
      purchaseOrderProducts," whName :: ",
      whName
    );
   
    try {
      dispatch({
        type: WAREHOUSE_PURCHASE_ORDER_CREATE_REQUEST,
      });

      const { data } = await axios.post(`/api/warehouse-po`, {purchaseOrder, purchaseOrderProducts, whName});
      console.log("createPurchaseOrder - end ");
      dispatch({
        type: WAREHOUSE_PURCHASE_ORDER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WAREHOUSE_PURCHASE_ORDER_CREATE_FAIL,
        payload: message,
      });
    }
    console.log('Now Dispatching of try catch [WAREHOUSE_STOCK_CREATE_REQUEST]');
    /**try {
      dispatch({
        type: WAREHOUSE_PURCHASE_ORDER_CREATE_REQUEST,
      });

      dispatch({ type: WAREHOUSE_PURCHASE_ORDER_CREATE_REQUEST });
      const { data } = await axios.post(`/api/warehouse-po`, {purchaseOrder, purchaseOrderProducts});
      console.log("createPurchaseOrder - end ");
      dispatch({
        type: WAREHOUSE_PURCHASE_ORDER_CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: WAREHOUSE_PURCHASE_ORDER_CREATE_FAIL,
        payload: message,
      });
    }*/
  };

export const updateWarehousePurchaseOrder = (id) => async (dispatch, getState) => {
  console.log("EXEC update purchaseOrder : " + id);
};
