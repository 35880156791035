import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import { useNavigate, useParams } from "react-router-dom";
import GridContainer from "../Grid/GridContainer.js";
import { cancelOrder, getOrderDetailsById } from "../../actions/orderAction";
import rupeeSvgIcon from "../../assets/images/currency-inr.svg";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import ShareIcon from "@mui/icons-material/Share";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import Box from "@mui/material/Box";
import {
  Paper,
  Grid,
  Typography,
  Tooltip,
  Button,
  Divider,
  Icon,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Message from "../Message.js";
import { DeleteForever } from "@mui/icons-material";
import { ORDER_DELETE_RESET } from "../../constants/orderConstants.js";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardBody from "../Card/CardBody.js";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { reasonsForCancellation } from "../../data/index";
import { listAllStores } from "../../actions/storeAction.js";
import { transferOrder } from "../../actions/orderAction.js";
import { formatToLocaleCurrency } from "../../util/Utility.js";
import { number } from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  imageIcon: {
    height: "0.75rem",
  },
  iconRoot: {
    textAlign: "center",
  },
}));

export default function OrderDetailsScreen() {
  const classes = useStyles();
  const theme = useTheme();
  const { id } = useParams();
  console.log("Order ID : ", id);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) dispatch(getOrderDetailsById(id));
    dispatch(listAllStores());
  }, [dispatch]);
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openSnackBar, setOpenSnackBar] = React.useState(true);
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackBar(false);
  };
  const vertical = "top";
  const horizontal = "center";
  
  const orderTransferred = useSelector((state) => state.orderTransferred);
  const { order_transfer_success } = orderTransferred;

  const orderDeleted = useSelector((state) => state.orderDeleted);
  const { delete_success } = orderDeleted;
  const orderDetails = useSelector((state) => state.orderDetails);
  const { loading, error, order } = orderDetails;
  const { shippingAddress, store, paymentDetails, user } = order;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;

  // paymentDetails
  // const { taxPrice, mode, upiAmountPaid, cashAmountPaid, paymentStatus, balanceAmount } = paymentDetails;
  const { name, email, role } = userInfo;
  const [open, setOpen] = useState(() => {
    return false;
  });

  const [openTransfer, setOpenTransfer] = useState(() => {
    return false;
  });
  const [cancellationReason, setCancellationReason] = useState(() => {
    return "";
  });
  const [transferReason, setTransferReason] = useState(() => {
    return "";
  });
  const [transferToStore, setTransferToStore] = useState(() => {
    return "";
  });
  const [detailedComments, setDetailedComments] = useState(() => {
    return "";
  });

  const handleCancelOrder = (id) => {
    console.log("Order Cancel by ID : ", id);
    setOpen(true);
  };
  const totalAmountPaid =
    paymentDetails && paymentDetails.upiAmountPaid
      ? Number(paymentDetails.upiAmountPaid)
      : 0.0 +
        (paymentDetails && paymentDetails.cashAmountPaid
          ? Number(paymentDetails.cashAmountPaid)
          : 0.0);

  const handleTransferOrder = () => {
    console.log("Opening TransferOrder Dialog ");
    setOpenTransfer(() => true);
  };

  const handleTraceOrder = () => {
    console.log("Order Tracing by ID : ", order._id);
    navigate(`/admin/order/track/${order._id}`);
    // dispatch(Order(id));
  };

  const submitHandler = () => {
    console.log("EXEC submitHandler");
    setOpen(false);
  };

  const transferringOrderNow = () => {
    console.log(
      "********* Exec transferringOrderNow Order Transfer by ID : ",
      order._id,
      " initStore :: ",
      order.store,
      "*********"
    );
    if (
      transferReason &&
      transferReason.length > 0 &&
      transferToStore &&
      order._id &&
      order.store &&
      order.store.length > 0
    ) {
      console.log(
        `Valid transferReason : ${transferReason} and transferToStore : ${transferToStore} So Dispatching to API.!`
      );
      dispatch(transferOrder(order._id, order.store, transferToStore._id));
    } else
      console.log(
        `In Valid transferReason : ${transferReason} and transferToStore : ${transferToStore} ,So Not doing Anything`
      );
    setOpenTransfer(false);
    setOpen(false);
  };

  const renderDynamicOptions = () => {
    return reasonsForCancellation.map((each, idx) => (
      <MenuItem key={idx} value={each}>
        {each}
      </MenuItem>
    ));
  };

  const renderStores = () => {
    console.log("store : ", store);
    const filteredStores = stores.filter((e) => e._id !== store);
    console.log("filteredStores : ", filteredStores);
    return filteredStores.map((each, idx) => (
      <MenuItem key={idx} value={each}>
        {each.storeName}
      </MenuItem>
    ));
  };

  const handleReasonChange = (event) => {
    setCancellationReason(() => event.target.value);
    console.log("Reason Selected : ", event.target.value);
  };

  const handleTransferReasonChange = (event) => {
    setTransferReason(() => event.target.value);
    console.log("Transfer Reason Selected : ", event.target.value);
  };
  const handleTransferToStore = (event) => {
    setTransferToStore(() => event.target.value);
    console.log("TransferToStore  : ", event.target.value);
  };

  return loading ? (
    <CustomBackdropSpinner />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <Box sx={{ flexGrow: 1 }}>
      <GridContainer>
        {/* {customError && customError.errorMessage && customError.errorMessage !== "" && (<Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
        <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
          Please Ensure Cash Amount doesn't exceed 2 Laks.!
        </Alert>
      </Snackbar>)} */}
        {console.log("order_transfer_success : ", order_transfer_success)}
        {order_transfer_success && (
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={handleCloseSnackBar}
            key={vertical + horizontal}
          >
            <Alert
              onClose={handleCloseSnackBar}
              severity="warning"
              sx={{ width: "100%", textTransform: "none" }}
            >
              Successfully Transferd the Order
            </Alert>
          </Snackbar>
        )}

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <GridItem xs={12} sm={12} md={12} style={{ margin: "1rem" }}>
                <Typography
                  variant="h4"
                  color="gray"
                  style={{
                    margin: "0.5rem",
                    textAlign: "center",
                    textTransform: "none",
                  }}
                >
                  Order Placed # {order._id}
                  <CheckCircleRoundedIcon
                    style={{ float: "center", color: "#26A541" }}
                  />
                </Typography>
              </GridItem>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid
                item
                xs={12}
                container
                justify="flex-start"
                marginTop="1rem"
                marginBottom="1rem"
              >
                <Typography variant="h5">Shipping Details</Typography>
              </Grid>
              <Grid container>
                <Grid item xs={5}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      textAlign: "left",
                      fontFamily: "Roboto ,Helvetica,Arial",
                      fontWeight: "600",
                      lineHeight: "1.25rem",
                      fontSize: "0.75rem",
                    }}
                  >
                    Name:
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">
                    {/* {userInfo.name ? userInfo.name.toUpperCase() : ""} */}
                    {user && user.name ? user.name : ""}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle2"
                  style={{
                    textAlign: "left",
                    fontFamily: "Roboto ,Helvetica,Arial",
                    fontWeight: "600",
                    lineHeight: "1.25rem",
                    fontSize: "0.75rem",
                  }}
                >
                  Email:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body1" style={{ textTransform: "none" }}>
                  {user && user.email ? user.email : ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle2"
                  style={{
                    textAlign: "left",
                    fontFamily: "Roboto ,Helvetica,Arial",
                    fontWeight: "600",
                    lineHeight: "1.25rem",
                    fontSize: "0.75rem",
                  }}
                >
                  Address:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body2">
                  {shippingAddress && shippingAddress.address
                    ? shippingAddress.address
                    : ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle2"
                  style={{
                    textAlign: "left",
                    fontFamily: "Roboto ,Helvetica,Arial",
                    fontWeight: "600",
                    lineHeight: "1.25rem",
                    fontSize: "0.75rem",
                  }}
                >
                  City:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body1">
                  {shippingAddress && shippingAddress.city
                    ? shippingAddress.city
                    : ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle2"
                  style={{
                    textAlign: "left",
                    fontFamily: "Roboto ,Helvetica,Arial",
                    fontWeight: "600",
                    lineHeight: "1.25rem",
                    fontSize: "0.75rem",
                  }}
                >
                  Postal Code:
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="body1">
                  {shippingAddress && shippingAddress.postalCode
                    ? shippingAddress.postalCode
                    : ""}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              item
              xs={12}
              container
              justify="flex-start"
              marginTop="1rem"
              marginBottom="1rem"
            >
              <Typography variant="h5">Order Details</Typography>
            </Grid>
            {order.orderItems.map((item, index) => {
              console.log("item : ", item);
              return (
                <Grid item key={index}>
                  <Grid container marginTop="1rem" marginBottom="1rem">
                    <Grid item xs={1}>
                      <img
                        className="img-thumbnail"
                        src={item.imageUrl}
                        alt={item.name}
                        style={{
                          height: "3.5rem",
                          width: "3.5rem",
                          marginRight: "1rem",
                        }}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Grid container>
                        <Grid
                          item
                          xs={5}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "1rem",
                            marginTop: "1rem",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="body2"
                            style={{
                              fontFamily: "Roboto ,Helvetica,Arial",
                              fontWeight: "600",
                              lineHeight: "1.25rem",
                              fontSize: "0.85rem",
                            }}
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2">
                            Qty : {item.quantity} X
                            <Icon classes={{ root: classes.iconRoot }}>
                              <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                className={classes.imageIcon}
                              />
                            </Icon>
                            {item.unitPrice
                              ? formatToLocaleCurrency(item.unitPrice)
                              : 0.0}
                          </Typography>
                        </Grid>
                        <Grid item xs={3} align="right">
                          <Typography variant="subtitle2">
                            <Icon classes={{ root: classes.iconRoot }}>
                              <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                className={classes.imageIcon}
                              />
                            </Icon>
                            {item.quantity && item.unitPrice
                              ? formatToLocaleCurrency(
                                  Number(item.quantity) * Number(item.unitPrice)
                                )
                              : 0.0}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              );
            })}
            {/* <Grid container>
              <Grid
                item
                xs={12}
                container
                justify="flex-start"
                marginTop="1rem"
                marginBottom="1rem"
              >
                <Typography variant="h5">Payment Details</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">Payment Method</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">{order.paymentMethod}</Typography>
              </Grid>
            </Grid> */}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid
                item
                xs={12}
                container
                justify="flex-start"
                marginBottom="1rem"
              >
                <Typography variant="h5" justify="flex-start">
                  Order Summary
                </Typography>
              </Grid>
            </Grid>

            <Divider />
            <Grid container spacing={1} justify="center">
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Items Cost
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      <Icon
                        classes={{ root: classes.iconRoot }}
                        style={{ verticalAlign: "center", color: "gray" }}
                      >
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{
                            verticalAlign: "center",
                            color: "gray",
                            paddingTop: "0.10rem",
                          }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {paymentDetails &&
                      paymentDetails.totalPrice &&
                      paymentDetails.totalPrice
                        ? formatToLocaleCurrency(paymentDetails.totalPrice)
                        : 0.0}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Tax
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      <Icon
                        classes={{ root: classes.iconRoot }}
                        style={{ verticalAlign: "center", color: "gray" }}
                      >
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{
                            verticalAlign: "center",
                            color: "gray",
                            paddingTop: "0.10rem",
                          }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {/* {taxPrice ? taxPrice.toLocaleString("en-IN") : 0.0} */}
                      {paymentDetails && paymentDetails.taxPrice
                        ? formatToLocaleCurrency(paymentDetails.taxPrice)
                        : 0.0}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Discount applied
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      <Icon
                        classes={{ root: classes.iconRoot }}
                        style={{ verticalAlign: "center", color: "gray" }}
                      >
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{
                            verticalAlign: "center",
                            color: "gray",
                            paddingTop: "0.10rem",
                          }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {/* {order && order.totalDiscountPrice ? order.totalDiscountPrice.toLocaleString("en-IN") : 0.0} */}
                      {order && order.totalDiscountPrice
                        ? formatToLocaleCurrency(order.totalDiscountPrice)
                        : 0.0}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Paid
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      <Icon
                        classes={{ root: classes.iconRoot }}
                        style={{ verticalAlign: "center", color: "gray" }}
                      >
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{
                            verticalAlign: "center",
                            color: "gray",
                            paddingTop: "0.10rem",
                            fontWeight: "600",
                          }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {totalAmountPaid
                        ? formatToLocaleCurrency(totalAmountPaid)
                        : 0.0}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Due Amount
                    </Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      <Icon
                        classes={{ root: classes.iconRoot }}
                        style={{ verticalAlign: "center", color: "gray" }}
                      >
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{
                            verticalAlign: "center",
                            color: "gray",
                            paddingTop: "0.10rem",
                          }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {paymentDetails && paymentDetails.balanceAmount
                        ? formatToLocaleCurrency(paymentDetails.balanceAmount)
                        : 0.0}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              {/* <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                  <Typography variant="subtitle2" align="left">Payment Status</Typography>
                  </Grid>
                  <Grid item xs={6} >
                    <Typography variant="body2" align= "left" style={ order.paymentStatus === "DUE" ?{
                      color:"#FF0000", fontFamily: "Roboto ,Helvetica,Arial", fontWeight: 700, lineHeight: 1.5, fontSize: "1rem" ,marginLeft: "1rem"}:{color:"#6B8E23", fontFamily: "Roboto ,Helvetica,Arial", fontWeight: 700, lineHeight: 1.5, fontSize: "1rem", marginLeft: "1rem" }}>
                      {order.paymentStatus }
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid> */}
              {order.totalDue && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="subtitle2" align="left">
                        Due Amount
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={6}>
                    <Typography variant="body2">
                      <Icon classes={{ root: classes.iconRoot }}>
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {order && order.totalDue ? order.totalDue.toLocaleString("en-IN") : 0.0}
                    </Typography>
                  </Grid> */}
                    <Grid item xs={6}>
                      <Typography
                        variant="body2"
                        style={{
                          textAlign: "right",
                          alignItems: "center",
                          justifyContent: "center",
                          marginRight: "1rem",
                          fontWeight: "600",
                        }}
                      >
                        <Icon
                          classes={{ root: classes.iconRoot }}
                          style={{ verticalAlign: "center", color: "gray" }}
                        >
                          <img
                            alt="curency inr"
                            src={rupeeSvgIcon}
                            style={{
                              verticalAlign: "center",
                              color: "gray",
                              paddingTop: "0.10rem",
                            }}
                            className={classes.imageIcon}
                          />
                        </Icon>
                        {/* {order && order.totalDue ? order.totalDue.toLocaleString("en-IN") : 0.0} */}
                        {order && order.totalDue
                          ? formatToLocaleCurrency(order.totalDue)
                          : 0.0}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              )}
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Total
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      <Icon
                        classes={{ root: classes.iconRoot }}
                        style={{ verticalAlign: "center", color: "gray" }}
                      >
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{
                            verticalAlign: "center",
                            color: "gray",
                            paddingTop: "0.10rem",
                          }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {paymentDetails.totalPrice
                        ? formatToLocaleCurrency(paymentDetails.totalPrice)
                        : 0.0}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Payment Mode
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      style={{
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "1rem",
                        fontWeight: "600",
                      }}
                    >
                      {paymentDetails && paymentDetails.mode
                        ? paymentDetails.mode.toUpperCase()
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Payment Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      align="right"
                      style={
                        paymentDetails &&
                        paymentDetails.paymentStatus &&
                        paymentDetails.paymentStatus === "DUE"
                          ? {
                              color: "#FF0000",
                              fontFamily: "Roboto ,Helvetica,Arial",
                              fontWeight: "700",
                              lineHeight: "1.25rem",
                              fontSize: "1rem",
                              textAlign: "right",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "1rem",
                            }
                          : {
                              color: "#6B8E23",
                              fontFamily: "Roboto ,Helvetica,Arial",
                              fontWeight: "700",
                              lineHeight: "1.25rem",
                              fontSize: "1rem",
                              textAlign: "right",
                              alignItems: "center",
                              justifyContent: "center",
                              marginRight: "1rem",
                            }
                      }
                    >
                      {paymentDetails && paymentDetails.paymentStatus
                        ? paymentDetails.paymentStatus
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography variant="subtitle2" align="left">
                      Order Status
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      variant="body2"
                      align="right"
                      style={{
                        color:
                          {
                            "PLACED ORDER": "#FFA500", // Orange
                            DELIVERED: "#008000", // Green
                            CANCEL: "#FF0000", // Red
                          }[order.orderStatus?.toUpperCase()] || "#6B8E23", // Default color (if status is unknown)
                        fontFamily: "Roboto, Helvetica, Arial",
                        fontWeight: "700",
                        lineHeight: "1.25rem",
                        fontSize: "1rem",
                        textAlign: "right",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: "1rem",
                      }}
                    >
                      {order.orderStatus ? order.orderStatus.toUpperCase() : ""}  
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Cancel Order" arrow>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    style={{
                      textTransform: "capitalize",
                      width: "20vh",
                      borderRadius: "5px",
                    }}
                    disabled = {role !== "STORE_INCHARGE_ROLE" }
                    startIcon={<DeleteForever />}
                    onClick={handleCancelOrder}
                  >
                    Cancel
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Track Order" arrow>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    style={{
                      textTransform: "capitalize",
                      width: "20vh",
                      borderRadius: "5px",
                    }}
                    disabled = {role !== "STORE_INCHARGE_ROLE" }
                    startIcon={<HourglassTopIcon />}
                    onClick={handleTraceOrder}
                  >
                    Track Status
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Tooltip title="Transfer Order" arrow>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth={true}
                    style={{
                      textTransform: "capitalize",
                      width: "20vh",
                      borderRadius: "5px",
                    }}
                    disabled = {role !== "STORE_INCHARGE_ROLE" }
                    startIcon={<ShareIcon />}
                    onClick={handleTransferOrder}
                  >
                    Transfer Order
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                {error && <Message variant="danger">{error}</Message>}
              </Grid>
              <Divider />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent style={{ width: "600px", height: "600px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <Typography
                    variant="h6"
                    style={{ margin: "0.5rem" }}
                    color="black"
                  ></Typography>
                  <h2 align="center"> Cancel Order </h2>
                  <Divider />
                </CardHeader>
                <CardBody>
                  <form onSubmit={submitHandler}>
                    <Grid
                      container
                      spacing={2}
                      style={{
                        marginTop: "1.5rem",
                        marginBottom: "1.5rem",
                        justifyContent: "center",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                    >
                      <Grid item xs={12} sm={4} md={3} style={{}}>
                        <FormControl>
                          <InputLabel id="demo-simple-select-label">
                            Reason
                          </InputLabel>
                          <Select
                            label="Reason"
                            placeholder="Reason"
                            variant="outlined"
                            required
                            size="small"
                            fullWidth
                            name="Reason for Cancellation"
                            onChange={handleReasonChange}
                            value={cancellationReason}
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              width: "15rem",
                            }}
                          >
                            {reasonsForCancellation.map((each, idx) => (
                              <MenuItem key={idx} value={each}>
                                {each}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        align="left"
                        style={{ marginBottom: "1rem" }}
                      >
                        <TextField
                          label="Detailed Comments "
                          variant="outlined"
                          name="detailedComments"
                          onChange={(e) => setDetailedComments(e.target.value)}
                          autoFocus
                          size="small"
                          maxRows="5"
                          minRows="5"
                          multiline
                          value={detailedComments}
                          type="text"
                          fullWidth
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        style={{
                          textTransform: "capitalize",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "auto",
                          marginTop: "1.5rem",
                          marginBottom: "1.5rem",
                        }}
                      >
                        <Button
                          size="small"
                          variant="contained"
                          type="submit"
                          color="secondary"
                          style={{
                            textTransform: "capitalize",
                            width: "20vh",
                            borderRadius: "5px",
                          }}
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>

      <Dialog open={openTransfer} onClose={() => setOpenTransfer(false)}>
        <DialogContent style={{ width: "600px", height: "600px" }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader>
                  <Typography
                    variant="h6"
                    style={{ margin: "0.5rem" }}
                    color="black"
                  ></Typography>
                  <h2 align="center"> Transfer Order </h2>
                  <Divider />
                </CardHeader>
                <CardBody>
                  <Grid
                    container
                    spacing={2}
                    style={{
                      marginTop: "1.5rem",
                      marginBottom: "1.5rem",
                      justifyContent: "center",
                      alignItems: "center",
                      marginLeft: "auto",
                    }}
                  >
                    <Grid item xs={12} sm={9} md={6}>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Reason
                        </InputLabel>
                        <Select
                          label="Reason"
                          placeholder="Reason"
                          variant="outlined"
                          required
                          size="small"
                          name="Reason for Cancellation"
                          onChange={handleTransferReasonChange}
                          value={transferReason}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "13rem",
                          }}
                        >
                          {reasonsForCancellation.map((each, idx) => (
                            <MenuItem key={idx} value={each}>
                              {each}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={9} md={6}>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Transferring Order To
                        </InputLabel>
                        <Select
                          label="Transferring Order To"
                          placeholder="Transferring Order To"
                          variant="outlined"
                          required
                          size="small"
                          name="Transferring Order To"
                          onChange={handleTransferToStore}
                          value={transferToStore}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            width: "13rem",
                          }}
                        >
                          {renderStores()}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={3}
                      style={{
                        textTransform: "capitalize",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        marginTop: "1.5rem",
                        marginBottom: "1.5rem",
                      }}
                    >
                      <Button
                        size="small"
                        variant="contained"
                        type="submit"
                        color="secondary"
                        onClick={transferringOrderNow}
                        style={{
                          textTransform: "capitalize",
                          width: "20vh",
                          borderRadius: "5px",
                        }}
                      >
                        Transfer Now
                      </Button>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
