import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Typography,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import PaymentIcon from '@mui/icons-material/Payment';
import { listAllWarehouses } from "../../../actions/warehouseInfoAction";
import { listAllStores } from "../../../actions/storeAction";
import { listWarehousePurchaseOrders, listWarehousePurchaseOrdersByPosName } from "../../../actions/warehousePurchaseOrderAction";
import { listStorePurchaseOrders, listStorePurchaseOrdersByPosName, deleteStorePurchaseOrder } from "../../../actions/storePurchaseOrderAction";
import { revertStockByStoreNameAndProductNamePoID } from "../../../actions/storeStockAction.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { formatToLocaleCurrency } from "../../../util/Utility.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
  warehouse_store,
} from "../../../data/index";
import { STORE_PURCHASE_ORDER_DELETE_BY_ID_RESET } from "../../../constants/storePurchaseOrderConstants.js";
import { convertInvoiceIdToDigits } from '../../../util/Utility.js'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { exportToExcel } from "../../../util/exportToExcelUtil.js";
import { createStockByStoreNameAndProductName } from "../../../actions/storeStockAction.js";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const PurchaseOrdersListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const gridRef = useRef();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [posName, setPosName] = useState(() => "");
  const [posStoreOrWarehouse, setPosStoreOrWarehouse] = useState(() => "");
  const [poByWarehouseOrStore, setPoByWarehouseOrStore] = useState(() => "");
  const [selectedPurchaseOrderId, setSelectedPurchaseOrderId] = useState(() => "");
  let storeOrWarehouseNameFiltered = null;
  const userLogin = useSelector(
    (state) => state.userLogin
  );
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;

  const warehouseInfoList = useSelector(
    (state) => state.warehouseInfoList
  );
  const storesList = useSelector(
    (state) => state.storesList
  );

  const storePurchaseOrderDeleteById = useSelector(
    (state) => state.storePurchaseOrderDeleteById
  );

  const storeStockRevertPostDeletePurchaseOrder = useSelector(
    (state) => state.storeStockRevertPostDeletePurchaseOrder
  );
  const { st_stock_revert_post_delete_po_loading, st_stock_revert_post_delete_po_success, st_stock_revert_post_delete_po_error } = storeStockRevertPostDeletePurchaseOrder

  const { store_po_delete_success, store_po_delete_error, store_po_delete_loading } = storePurchaseOrderDeleteById;
  const { loading_wh_infos, warehouseInfos, wh_infos_error } = warehouseInfoList;// warehouseInfoList
  const { stores_loading, stores, stores_error } = storesList; //storesList

  const warehousePurchaseOrdersList = useSelector(
    (state) => state.warehousePurchaseOrdersList
  );

  const storePurchaseOrdersListByPos = useSelector(
    (state) => state.storePurchaseOrdersListByPos
  );
  const storePurchaseOrdersList = useSelector(
    (state) => state.storePurchaseOrdersList
  );
  const warehousePurchaseOrdersListByPos = useSelector(
    (state) => state.warehousePurchaseOrdersListByPos
  );

  const { warehousePurchaseOrders, loading } = warehousePurchaseOrdersList;
  const { storePurchaseOrders, store_po_list_loading } = storePurchaseOrdersList;
  const { wh_po_pos_data, wh_po_pos_error, wh_po_pos_loading } = warehousePurchaseOrdersListByPos;
  const { store_po_pos_data, store_po_pos_error, store_po_pos_loading } = storePurchaseOrdersListByPos;

  // const { } = storePurchaseOrdersListByPos;
  let pointOfSale = null;

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const populatePosDetails = () => {
    if (warehouseName && warehouseName.length > 0 && warehouseName.includes("_WAREHOUSE")) {
      console.log("warehouseName  : ", warehouseName);
      setPosName(() => warehouseName);
      setPosStoreOrWarehouse(() => "Warehouse");
    } else if (storeName && storeName.length > 0 && storeName.includes("_STORE")) {
      console.log("storeName  : ", storeName);
      setPosName(() => storeName);
      setPosStoreOrWarehouse(() => "Store");
    }
  }

  const getPosNameByRole = (role, posId) => {
    let posObj = null;
    let targetStoreWhName;
    console.log('role, posId ::==>>  ', role, posId, stores.length, warehouseInfos.length);
    const posByRole = role === 'STORE_INCHARGE_ROLE' ? 'store' : role === 'WAREHOUSE_INCHARGE_ROLE' ? 'warehouse' : '';
    console.log('posByRole :: ', posByRole, stores, warehouseInfos);
    if (posByRole === 'store' && stores && stores.length > 0) {
      posObj = stores.filter(e => e._id === posId);
      console.log('Store posObj : ', posObj);
      targetStoreWhName = posObj[0].storeName;
    } else if (posByRole === 'warehouse' && warehouseInfos && warehouseInfos.length > 0) {
      posObj = warehouseInfos.filter(e => e._id === posId);
      targetStoreWhName = posObj[0].warehouseName;
      console.log('targetStoreWhName : ', targetStoreWhName);
    }
    console.log('targetStoreWhName : ', targetStoreWhName);
    return targetStoreWhName;
  }

  const routePaymentDetails = (id) => {
    console.log('ID Passed to routePaymentDetails : ', id)
    navigate(`/admin/purchase-orders/payment/${id}`);
  };

  const handleEdit = (params) => {
    console.log(
      "PO  params Passed :==> ",
      params
    );
    console.log("ID SELECTED : " + params.data ? params.data : "Empty");
  };

  useEffect(() => {
    console.log("Use Effect Gets Called for Listing All Store Warehouses...!");
    dispatch(listAllStores());
    dispatch(listAllWarehouses());
  }, [dispatch]);

  useEffect(() => {
    if (store_po_delete_success)
      dispatch({ type: STORE_PURCHASE_ORDER_DELETE_BY_ID_RESET });
    navigate('/admin/purchase-orders')
  }, [store_po_delete_success]);

  useEffect(() => {
    console.log("Use Effect Gets Called Only when PO is by WH or Store.");
    if (poByWarehouseOrStore !== "" && poByWarehouseOrStore === "Warehouse" && role === "CEO_ROLE")
      dispatch(listWarehousePurchaseOrders());
    else if (poByWarehouseOrStore !== "" && poByWarehouseOrStore === "Store" && role === "CEO_ROLE")
      dispatch(listStorePurchaseOrders());
    else if (role === "WAREHOUSE_INCHARGE_ROLE" && warehouseName) {// HANDLE FOR WH INCHARGE ROLES
      populatePosDetails();
      if (posStoreOrWarehouse && posStoreOrWarehouse.length > 0)
        dispatch(listWarehousePurchaseOrdersByPosName(posName));
    } else if (role === "STORE_INCHARGE_ROLE" && storeName) {// HANDLE FOR WH INCHARGE ROLES
      if (store_po_delete_success) {
        dispatch({ type: STORE_PURCHASE_ORDER_DELETE_BY_ID_RESET });
      }
      populatePosDetails();
      if (posStoreOrWarehouse && posStoreOrWarehouse.length > 0)
        dispatch(listStorePurchaseOrdersByPosName(posName));
    }

  }, [dispatch, poByWarehouseOrStore, posName, stores, warehouseInfos, store_po_delete_success]);


  useEffect(() => {
    if (st_stock_revert_post_delete_po_success) {
      console.log("EXEC USE EFFECT AFTER SUCCUESSFUL STOCK REVERT POST DELETE CLICK.")
      console.log('DISPATCHING TO delete StorePurchaseOrder by selectedPurchaseOrderId : ', selectedPurchaseOrderId)
      dispatch(deleteStorePurchaseOrder(selectedPurchaseOrderId))
      console.log('DONE DELETE StorePurchaseOrder by selectedPurchaseOrderId', selectedPurchaseOrderId)
    }

  }, [st_stock_revert_post_delete_po_success]);

  useEffect(() => {
    if (store_po_delete_success) {
      console.log("DONE DELETE STORE PO SUCCESS")
      navigate("/admin/purchase-orders");
    }
  }, [store_po_delete_success]);

  const createHandler = (category) => {
    navigate("/admin/purchase-orders/new");
  };

  const showPurchaseOrderDetails = (id) => {
    console.log("Clicked showPurchaseOrderDetails ", id);
    navigate(`/admin/purchase-order/${id}`);
  };

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  let renderContentUsingAGGrid = "";
  let gridApi = "";
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      cellStyle: { textAlign: "center" }
    },
    {
      field: "BillNo",
      headerName: "Bill No",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
      cellStyle: { textAlign: "center" }
    },
    {
      field: "BillDate",
      headerName: "Bill Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle: { textAlign: "center" }
    },
    {
      field: "SupplierName",
      headerName: "Supplier Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
      cellStyle: { textAlign: "left" }
    },
    {
      field: "PaymentType",
      headerName: "Payment Type ",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: true,
      cellStyle: { textAlign: "center" }
    },
    {
      field: "Total",
      headerName: "Total",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "right" }
    },
    {
      field: "AdvancePaid",
      headerName: "Advance Paid",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      cellStyle: { textAlign: "right" }
    },
    {
      field: "Balance",
      headerName: "Balance",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "right" }
    },
    {
      field: "PaymentStatus",
      headerName: "Payment Status",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      cellStyle: params => {
        if (params.value === 'DUE') {
          return { color: 'red', backgroundColor: 'white', textAlign: "center", fontWeight: "900" };
        }
        else if (params.value === 'PAID') {
          return { color: 'green', backgroundColor: 'white', textAlign: "center", fontWeight: "900" };
        }
        return null;
      }
    },
    {
      field: "Contact",
      headerName: "Contact",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      cellStyle: { textAlign: "right" }
    },

    {
      field: "StateOfSupply",
      headerName: "State Of Supply",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      cellStyle: { textAlign: "center" }
    },
    {
      field: "Id",
      headerName: "Details",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => showInvoiceDetails(params.data.Details, role)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "Id",
      headerName: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      cellRenderer: (params) => (
        <span >
          <Tooltip title="Delete Purchase Order" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              disabled={true}
              onClick={() => {
                console.log('DISPATCHING TO revertStockByStoreNameAndProductNamePoID...posName : ', posName, params, 'ID : ', params.data.Details)
                setSelectedPurchaseOrderId(() => params.data.Details)
                dispatch(revertStockByStoreNameAndProductNamePoID(posName, params.data.Details));
              }}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      cellStyle: { textAlign: "center" },
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: "Id",
      headerName: "Pay Now",
      resizable: true,
      suppressSizeToFit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      cellStyle: { textAlign: "center" },
      cellRenderer: (params) => (
        <span >
          <Tooltip title="Pay Now" arrow>
            <PaymentIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => routePaymentDetails(params.data.Details)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></PaymentIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const renderDynamicOptions = () => {
    return warehouse_store.map((each, idx) => (
      <MenuItem key={idx} value={each}>
        {each}
      </MenuItem>
    ));
  };

  const handlePurchaseOrderByChange = (event) => {
    console.log("Purchase Order Listing by : ", event.target.value);
    setPoByWarehouseOrStore(() => event.target.value
    );
  };

  const showInvoiceDetails = (id, role) => {
    console.log('Role Passed here is  : ', role);
    const whOrStore = role.includes('STORE') ? 'Store' : role.includes('WAREHOUSE') ? 'Warehouse' : null;
    console.log(" ###### Now showing Invoice Details ######  ", id, "whOrStore : ", whOrStore, "poByWarehouseOrStore : ", poByWarehouseOrStore);
    const target = poByWarehouseOrStore ? poByWarehouseOrStore : whOrStore;
    console.log('target  : ', target);
    navigate(`/admin/purchase-order/${id}/${target}`);
  };

  if (role === 'CEO_ROLE' && poByWarehouseOrStore === "Warehouse" && warehousePurchaseOrders && warehousePurchaseOrders.length > 0) {
    rowData = warehousePurchaseOrders.map((warehousePurchaseOrder, index) => {
      const invIdInDecimal = convertInvoiceIdToDigits(warehousePurchaseOrder._id)
      const balAmount = Number(warehousePurchaseOrder.totalPoAmount - warehousePurchaseOrder.advanceAmount)
      return {
        "#": index + 1,
        Id: invIdInDecimal,
        BillNo: warehousePurchaseOrder.billNo ? warehousePurchaseOrder.billNo : "",
        Name: warehousePurchaseOrder.warehouseName,
        SupplierName: warehousePurchaseOrder.supplierName,
        Contact: warehousePurchaseOrder.contactNo,
        BillDate: warehousePurchaseOrder.billDate ? warehousePurchaseOrder.billDate : "",
        StateOfSupply: warehousePurchaseOrder.stateOfSupply,
        PaymentType: warehousePurchaseOrder.paymentType,
        AdvancePaid: warehousePurchaseOrder.advanceAmount ? formatToLocaleCurrency(Number(warehousePurchaseOrder.advanceAmount)) : 0,
        Balance: balAmount,
        PaymentStatus: Number(balAmount) > 0 ? "DUE" : "PAID",
        Total: warehousePurchaseOrder.totalPoAmount,
        Details: warehousePurchaseOrder._id,
        Edit: warehousePurchaseOrder._id,
        Delete: warehousePurchaseOrder._id,
      };
    });

    renderContentUsingAGGrid = (
      <>
        {rowData && rowData.length > 0 && (
          <GridContainer spacing={2} alignItems="center" style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
            <GridItem xs={12} sm={9} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center", marginTop: "0.5rem", marginBottom: "0.5rem" }}>

              <Tooltip title="Download Purchase Order Report" arrow>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  onClick={onExportClick}
                  style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                >
                  <SaveAltIcon
                    fontSize="medium"
                    style={{ float: "left", marginRight: "10px" }}
                  />
                  Purchase Orders
                </Button>
              </Tooltip>
            </GridItem>
          </GridContainer>
        )
        }
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      </>
    );
  } else if (role === 'CEO_ROLE' && poByWarehouseOrStore === "Store" && storePurchaseOrders && storePurchaseOrders.length > 0) {
    rowData = storePurchaseOrders.map((storePurchaseOrder, index) => {
      const invIdInDecimal = convertInvoiceIdToDigits(storePurchaseOrder._id)
      const balAmount = Number(storePurchaseOrder.totalPoAmount - storePurchaseOrder.advanceAmount)
      return {
        "#": index + 1,
        Id: invIdInDecimal,
        Name: storePurchaseOrder.storeName,
        SupplierName: storePurchaseOrder.supplierName,
        Contact: storePurchaseOrder.contactNo,
        BillDate: storePurchaseOrder.billDate ? storePurchaseOrder.billDate : "",
        StateOfSupply: storePurchaseOrder.stateOfSupply,
        PaymentType: storePurchaseOrder.paymentType,
        AdvancePaid: storePurchaseOrder.advanceAmount ? formatToLocaleCurrency(Number(storePurchaseOrder.advanceAmount)) : 0,
        Balance: formatToLocaleCurrency(Number(storePurchaseOrder.totalPoAmount) - Number(storePurchaseOrder.advanceAmount)),
        PaymentStatus: Number(balAmount) > 0 ? "DUE" : "PAID",
        Total: storePurchaseOrder.totalPoAmount ? formatToLocaleCurrency(Number(storePurchaseOrder.totalPoAmount)) : 0,
        Details: storePurchaseOrder._id,
        Edit: storePurchaseOrder._id,
        Delete: storePurchaseOrder._id,
      };
    });

    renderContentUsingAGGrid = (
      <>
        {rowData && rowData.length > 0 && (<GridContainer spacing={2} alignItems="center" style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <GridItem xs={12} sm={9} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>

            <Tooltip title="Download Purchase Order Report" arrow>
              <Button
                size="small"
                variant="contained"
                type="submit"
                color="secondary"
                justify="center"
                onClick={onExportClick}
                style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px", marginTop: "0.5rem", marginBottom: "0.5rem" }}
              >
                <SaveAltIcon
                  fontSize="medium"
                  style={{ float: "left", marginRight: "10px" }}
                />
                Purchase Orders
              </Button>
            </Tooltip>
          </GridItem>
        </GridContainer>
        )
        }
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      </>
    );
  } else if (role === 'STORE_INCHARGE_ROLE' && store_po_pos_data && store_po_pos_data.length > 0) {
    rowData = store_po_pos_data.map((poByPos, index) => {
      const invIdInDecimal = convertInvoiceIdToDigits(poByPos._id)

      return {
        "#": index + 1,
        Id: invIdInDecimal,
        BillNo: poByPos.billNo ? poByPos.billNo : "",
        Name: poByPos.storeName ? poByPos.storeName : "",
        SupplierName: poByPos.supplierName ? poByPos.supplierName : "",
        Contact: poByPos.contactNo ? poByPos.contactNo : "",
        BillDate: poByPos.billDate ? poByPos.billDate : "",
        StateOfSupply: poByPos.stateOfSupply ? poByPos.stateOfSupply : "",
        PaymentType: poByPos.paymentType ? poByPos.paymentType : "",
        AdvancePaid: poByPos.advanceAmount ? formatToLocaleCurrency(Number(poByPos.advanceAmount)) : 0,
        Balance: poByPos.balanceAmount ? formatToLocaleCurrency(Number(poByPos.balanceAmount)) : 0,
        PaymentStatus: poByPos.balanceAmount ? (Number(poByPos.balanceAmount) > 0 ? "DUE" : "PAID") : "",
        Total: poByPos.totalPoAmount ? formatToLocaleCurrency(Number(poByPos.totalPoAmount)) : 0,
        Details: poByPos._id,
      };
    });

    renderContentUsingAGGrid = (
      <>
        {rowData && rowData.length > 0 && (<GridContainer spacing={2} alignItems="center" style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
          <GridItem xs={12} sm={9} md={6} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>

            <Tooltip title="Download Purchase Order Report" arrow>
              <Button
                size="small"
                variant="contained"
                type="submit"
                color="secondary"
                justify="center"
                onClick={onExportClick}
                style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px", marginTop: "0.5rem", marginBottom: "0.5rem" }}
              >
                <SaveAltIcon
                  fontSize="medium"
                  style={{ float: "left", marginRight: "10px" }}
                />
                Purchase Orders
              </Button>
            </Tooltip>
          </GridItem>
        </GridContainer>
        )
        }
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      </>
    );
  } else if (role === 'WAREHOUSE_INCHARGE_ROLE' && wh_po_pos_data && wh_po_pos_data.length > 0) {
    rowData = wh_po_pos_data.map((poByPos, index) => {
      const invIdInDecimal = convertInvoiceIdToDigits(poByPos._id)
      const balAmount = Number(poByPos.totalPoAmount - poByPos.advanceAmount)
      return {
        "#": index + 1,
        Id: invIdInDecimal,
        Name: poByPos.storeName ? poByPos.storeName : "",
        SupplierName: poByPos.supplierName ? poByPos.supplierName : "",
        Contact: poByPos.contactNo ? poByPos.contactNo : "",
        BillDate: poByPos.billDate
          ? poByPos.billDate : "",
        StateOfSupply: poByPos.stateOfSupply ? poByPos.stateOfSupply : "",
        PaymentType: poByPos.paymentType ? poByPos.paymentType : "",
        AdvancePaid: poByPos.advanceAmount ? formatToLocaleCurrency(Number(poByPos.advanceAmount)) : 0,
        Balance: balAmount ? formatToLocaleCurrency(Number(balAmount)) : 0,
        Total: poByPos.totalPoAmount ? formatToLocaleCurrency(Number(poByPos.totalPoAmount)) : 0,
        PaymentStatus: balAmount ? (Number(balAmount) > 0 ? "DUE" : "PAID") : "",
        Details: poByPos._id,
      };
    });

  }

  return (
    <React.Fragment>
      {(loading || stores_loading || loading_wh_infos) && <CustomBackdropSpinner />}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12} >
          <Card>
            <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                Purchase Orders
                {role && role === 'ADMIN_ROLE' || role === 'STORE_INCHARGE_ROLE' && (<Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  onClick={createHandler}
                  style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                >
                  <AddCircleIcon
                    fontSize="medium"
                    style={{ float: "right" }}
                    onClick={createHandler}
                  />
                  Purchase Order
                </Button>)}

                {role && role === 'ADMIN_ROLE' || role === 'STORE_INCHARGE_ROLE' && (<Tooltip title="Create Purchase Invoice" arrow>
                </Tooltip>
                )
                }
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {role === 'CEO_ROLE' && (<GridItem xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={4} md={3}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Purchase Order By</InputLabel>
              <Select
                label="POByWarehouseOrStore"
                placeholder="Warehouse or Store"
                variant="outlined"
                required
                size="small"
                style={{ width: "12rem" }}
                name="poByWarehouseOrStore"
                onChange={handlePurchaseOrderByChange}
                value={poByWarehouseOrStore}
              >
                {renderDynamicOptions()}
              </Select>
            </FormControl>
          </Grid>
        </GridItem>)}
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : null}
        </GridItem>
      </GridContainer>

    </React.Fragment>
  );
};
export default PurchaseOrdersListScreen;
