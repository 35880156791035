import {
  CTC_RECEIPT_DETAILS_BY_ID_FAIL,
  CTC_RECEIPT_DETAILS_BY_ID_REQUEST,
  CTC_RECEIPT_DETAILS_BY_ID_SUCCESS,
  CTC_RECEIPT_DETAILS_BY_ID_RESET,

  CTC_RECEIPT_REGISTER_FAIL,
  CTC_RECEIPT_REGISTER_REQUEST,
  CTC_RECEIPT_REGISTER_SUCCESS,
  CTC_RECEIPT_REGISTER_RESET,
 
  CTC_RECEIPT_LIST_FAIL,
  CTC_RECEIPT_LIST_SUCCESS,
  CTC_RECEIPT_LIST_REQUEST,
  CTC_RECEIPT_LIST_RESET,

  CTC_RECEIPT_DELETE_REQUEST,
  CTC_RECEIPT_DELETE_SUCCESS,
  CTC_RECEIPT_DELETE_FAIL,
  CTC_RECEIPT_DELETE_RESET,

  CTC_RECEIPT_UPDATE_FAIL,
  CTC_RECEIPT_UPDATE_SUCCESS,
  CTC_RECEIPT_UPDATE_REQUEST,
  CTC_RECEIPT_UPDATE_RESET,

} from "../constants/receiptConstants.js";


export const receiptRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_RECEIPT_REGISTER_REQUEST:
      return { ctc_receipt_register_loading: true };
    case CTC_RECEIPT_REGISTER_SUCCESS:
      return { ctc_receipt_register_loading: false,ctc_receipt_register_success: true, ctc_receipt_register_data: action.payload };
    case CTC_RECEIPT_REGISTER_FAIL:
      return { ctc_receipt_register_loading: false, ctc_receipt_register_error: action.payload };
    case CTC_RECEIPT_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const receiptDetailsByIdReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_RECEIPT_DETAILS_BY_ID_REQUEST:
      return { ...state, ctc_receipt_details_by_id_loading: true };
    case CTC_RECEIPT_DETAILS_BY_ID_SUCCESS :
      return { ctc_receipt_details_by_id_loading: false, ctc_receipt_details_data : action.payload };
    case CTC_RECEIPT_DETAILS_BY_ID_FAIL:
      return { ctc_receipt_details_by_id_loading: false, ctc_receipt_details_by_id_error: action.payload };
    case CTC_RECEIPT_DETAILS_BY_ID_RESET:
      return {  };
    default:
      return state;
  }
};

export const receiptListReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_RECEIPT_LIST_REQUEST:
      return { ctc_receipt_list_loading: true };
    case CTC_RECEIPT_LIST_SUCCESS:
      return { ctc_receipt_list_loading: false, ctc_receipt_list_success: false, ctc_receipt_list_data : action.payload };
    case CTC_RECEIPT_LIST_FAIL:
      return { ctc_receipt_list_loading: false, ctc_receipt_list_error : action.payload };
    case CTC_RECEIPT_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const receiptDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_RECEIPT_DELETE_REQUEST:
      return { ctc_receipt_delete_loading: true };
    case CTC_RECEIPT_DELETE_SUCCESS:
      return { ctc_receipt_delete_loading: false, ctc_receipt_delete_success: true };
    case CTC_RECEIPT_DELETE_FAIL:
      return { ctc_receipt_delete_loading: false, ctc_receipt_delete_error: action.payload };
    default:
      return state;
  }
};

export const receiptUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_RECEIPT_UPDATE_REQUEST:
      return { ctc_receipt_update_loading: true };
    case CTC_RECEIPT_UPDATE_SUCCESS:
      return { ctc_receipt_update_loading: false, ctc_receipt_update_success : true };
    case CTC_RECEIPT_UPDATE_FAIL:
      return { ctc_receipt_update_loading: false, ctc_receipt_update_error: action.payload };
    case CTC_RECEIPT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
