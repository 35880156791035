import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SupplierSignupForm from "./SupplierSignupForm";
import CardBody from "../Card/CardBody";
import GridItem from "../Grid/GridItem";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import GridContainer from "../Grid/GridContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    // maxWidth: '30%',
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const SupplierSignup = ({ location, history }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name } = userInfo;
  const parentUser = name && name.length > 0 ? name : ""
  console.log("parentUser : ", parentUser)
  return (
    <div style={{textAlign:"center", justifyContent:"center",alignItems:"center", maxWidth : '50%',width : '50%', marginLeft:"auto",  marginRight:"auto"}}>
      <GridContainer spacing={2}  >
        <GridItem xs={12} sm={12} md={12} lg={12} >
          <Card style={{textAlign:"center", justifyContent:"center",alignItems:"center"}}>
            <CardHeader>
              <h1 align="center"> Vishudha Tagline Traders </h1>
              <h2 align="center">Supplier Sign up </h2>
            </CardHeader>
            <CardBody>
              <SupplierSignupForm />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </div>
  );
};

export default SupplierSignup;
