import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
// import { } from "../../actions/customerAction";
import { useNavigate } from "react-router-dom";
import { Typography,Tooltip,Button } from "@mui/material";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import Message from "../../Message.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { listReceipts } from "../../../actions/receiptAction.js";
import { convertInvoiceIdToDigits } from '../../../util/Utility.js'


const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const ReceiptsListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const gridRef = useRef();
  const [receiptsRowData, setReceiptsRowData] = useState(null);
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  
  const columnDefs = [
    { field: "#" ,
    resizable: true, 
    suppressSizeToFit: true,
    width: 75, 
    minWidth: 75, 
    maxWidth: 75,
    
  },
    { field: "ColdStorage",
    headerName: "Cold Storage",
    resizable: true, 
    suppressSizeToFit: true, 
    width: 250,
    minWidth: 250,
    maxWidth: 250,
    filter: true,
    cellStyle:{
      textAlign :'center'
    }
   },
    { field: "ProductName",
    headerName: "Product Name",
    resizable: true, 
    suppressSizeToFit: true,
    width: 200, 
    minWidth: 200, 
    maxWidth: 200, 
    filter: true,
    cellStyle:{
      textAlign :'center'
    }
  },
    { field: "BondId",
    headerName: "Bond Id",
    resizable: true, 
    suppressSizeToFit: true,
    width: 300, 
    minWidth: 300, 
    maxWidth: 300,
    filter: true,
    cellStyle:{
      textAlign :'right'
    }
   },
    { field: "BondDate" ,
    headerName: "Bond Date",
    resizable: true, 
    suppressSizeToFit: true,
    width: 175, 
    minWidth: 175, 
    maxWidth: 175,
    filter: true,
    cellStyle:{
      textAlign :'right'
    }
  },
  { field: "VehicleNo" ,
    headerName: "Vehicle No",
    resizable: true, 
    suppressSizeToFit: true,
    width: 175, 
    minWidth: 175, 
    maxWidth: 175,
    filter: true,
    cellStyle:{
      textAlign :'right'
    }
  },
  { field: "InventryTransferQuantity" ,
    headerName: "Inventry Transfer Quantity",
    resizable: true, 
    suppressSizeToFit: true,
    width: 250, 
    minWidth: 250, 
    maxWidth: 250,
    filter: true,
    cellStyle:{
      textAlign :'right'
    }
  },
  { 
    field: "RemainingStockInStorage" ,
    headerName: "Remaining Stock In Storage",
    resizable: true, 
    suppressSizeToFit: true,
    width: 250, 
    minWidth: 250, 
    maxWidth: 250,
    filter: true,
    cellStyle:{
      textAlign :'right'
    }
  },
  
  { field: "ReceiptDate" ,
    headerName: "Receipt Date",
    resizable: true, 
    suppressSizeToFit: true,
    width: 175, 
    minWidth: 175, 
    maxWidth: 175,
    filter: true,
    cellStyle:{
      textAlign :'right'
    }
  },
  
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const receiptList = useSelector((state) => state.receiptList)
  const { ctc_receipt_list_loading, ctc_receipt_list_data, ctc_receipt_list_success, ctc_receipt_list_error } = receiptList

  const populateReceiptsRowData = () => {
    if (ctc_receipt_list_data && ctc_receipt_list_data.length > 0) {
      setReceiptsRowData(() => ctc_receipt_list_data.map((eachBond, index) => {
        console.log("eachBond  : ", eachBond);
        return {
          "#": index + 1,
          ColdStorage: eachBond.coldStorage,
          ProductName: eachBond.productName,
          BondId: convertInvoiceIdToDigits(eachBond.bondId),
          BondDate: eachBond.bondDate,
          VehicleNo: eachBond.vehicleNo,
          InventryTransferQuantity: eachBond.inventryTransferQuantity,
          RemainingStockInStorage : eachBond.remainingStockInStorage,
          ReceiptDate : eachBond.receiptDate,
        };
      }))
    }
  }

  useEffect(() => {
    console.log('Loading Cold Storages List Page')
    dispatch(listReceipts())
  }, [])


  useEffect(() => {
    if (ctc_receipt_list_success && ctc_receipt_list_data && ctc_receipt_list_data.length > 0)
      console.log('Populating  Receipts Row Data')
    populateReceiptsRowData()
  }, [ctc_receipt_list_data])


  const createReceiptHandler = ()=>{
    console.log('Routing to New Receipt ')
    navigate(`/ctc/receipts/new`);
  }


  const renderContentUsingAGGrid = (
    <div
      className="ag-theme-quartz"
      style={{ width: "100%", height: "65vh" }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={receiptsRowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowStyle={{ textAlign: "left", alignItems: "center" }}
        suppressExcelExport={false}
        pagination="true"
      ></AgGridReact>
    </div>
  );
  return (
    <React.Fragment>
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
              <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                CTC Receipts
                 <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick = {createReceiptHandler}
              style={{ textTransform: "capitalize", borderRadius: "5px" , float:'right', textAlign:'right'}}
            >
              <AddCircleIcon
              fontSize="medium"
              style={{ float: "right", marginRight:"10px" }}
            />
              New
            </Button>
              </Typography>
            </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default ReceiptsListPage;
