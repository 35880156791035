import React, { useState, useEffect, useMemo, useRef, Fragment } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Box, Switch } from "@mui/material";
import { listAllWarehouses } from "../../actions/warehouseInfoAction";
import { listAllStores } from "../../actions/storeAction";
import { listAllVehicles } from "../../actions/vehicleAction";
import { listAllProducts } from "../../actions/productAction";
import {
  Typography,
  Grid,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Divider
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiAlert from "@mui/material/Alert";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { warehouse_store_vehicle } from "../../data/index.js";
import { createStockByWarehouseNameAndProductName, listStockByWarehouseName, returnStockByWarehouseNameAndProductName } from "../../actions/warehouseStockAction";
import { createStockByStoreNameAndProductName, listProductsStockCountAcrossAllStores, listStockByStoreName, returnStockByStoreNameAndProductName } from "../../actions/storeStockAction";
import { listsStockByVehicleNo, deleteStockByVehicleNo, listStockByVehicleNo } from "../../actions/vehicleStockAction";
import SignpostIcon from "@mui/icons-material/Signpost";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import SyncIcon from '@mui/icons-material/Sync';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StockListScreen = ({ match }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [fromWHStoreVehName, setFromWHStoreVehName] = useState(null);
  const [fromWHStoreVehValue, setFromWHStoreVehValue] = useState(null);

  useEffect(() => {
    dispatch(listAllProducts());
    console.log("UseEffect getting Called for Listing all the Prodcts, Stores, Warehouses.")
  }, []);

  const clearDestinationValueState = () => {
    console.log('clear State Started..!')
    setFromWHStoreVehValue((prev) => null)
    console.log('Done clearDestinationValueState State!')
  }
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const allProducts = useSelector((state) => state.allProducts);
  const { loading, error, all_products } = allProducts;
  let customProducts = [];
  const [ contentWithUIUsingAGGrid , setContentWithUIUsingAGGrid ] = useState(()=>""); 

  if (all_products && all_products.length > 0) {
    customProducts = all_products;
  }
  const [open, setOpen] = useState(() => {
    return false;
  });
  const warehouseStockListByWarehouseName = useSelector((state) => state.warehouseStockListByWarehouseName);
  const storeStockListByStoreName = useSelector((state) => state.storeStockListByStoreName);
  const vehicleStockListByVehicleNo = useSelector((state) => state.vehicleStockListByVehicleNo);
  const { vehicle_stock_by_vehicle_no_data, vehicle_stock_by_vehicle_no_success } = vehicleStockListByVehicleNo;

  if (vehicle_stock_by_vehicle_no_data && vehicle_stock_by_vehicle_no_data.length > 0) {
    console.log('Setting Products by Vehicle No  : ', vehicle_stock_by_vehicle_no_data.length);
  }

  const warehouseInfoList = useSelector((state) => state.warehouseInfoList);
  const { warehouseInfos } = warehouseInfoList;

  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;

  const vehicleList = useSelector((state) => state.vehicleList);
  const { vehicles } = vehicleList;

  const productsStockCountAcrossAllStores = useSelector((state) => state.productsStockCountAcrossAllStores)
  const { products_stock_count_all_stores_data } = productsStockCountAcrossAllStores;
  const { wh_stock_loading, wh_stock_data, wh_stock_error } =
    warehouseStockListByWarehouseName;
  const { store_stock_loading, store_stock_data, store_stock_error } =
    storeStockListByStoreName;
  const columnDefs = [
    {
      field: "No",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
    },
    {
      field: "Product Name",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: false,
      width: 500,
      minWidth: 500,
      maxWidth: 500,
      filter: true,
    },
    {
      field: "CountInStock",
      headerName: "In Stock #",
      resizable: true,
      suppressSizeToFit: false,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
    }),
    []
  );
  let rowData = [];
  const [rowDataContent,setRowDataContent] = useState(()=>[]);

  const createContentUsingAGGrid = (data) => {
    console.log('EXEC createContentUsingAGGrid')
    switch (fromWHStoreVehName) {
      case "Store":
        if (data && data.length > 0) {
          setRowDataContent(()=>data.map((eachPrd, index) => {
            return {
              No: index + 1,
              "Product Name": eachPrd.productName,
              CountInStock: eachPrd.countInStock,
            };
          }))
        }else
        setRowDataContent(()=>[])
        break;
      case "Vehicle":
        if (data && data.length > 0 ) {
          setRowDataContent(()=>data.map((eachPrd, index) => {
            return {
              No: index + 1,
              "Product Name": eachPrd.productName,
              CountInStock: eachPrd.countInStock,
            };
          }))
        }else
        setRowDataContent(()=>[])
        break;
      case "Warehouse":
        if (data && data.length > 0 ) {
          setRowDataContent(()=>data.map((eachPrd, index) => {
            return {
              No: index + 1,
              "Product Name": eachPrd.productName,
              CountInStock: eachPrd.countInStock,
            };
          }))
        }else
        setRowDataContent(()=>[])
        break;
      default:
        break;
    }
  }
  const prepareUIUsingAGGrid = ()=>{
      console.log('STARTED TO CREATE AG GRID WITH ',rowDataContent ? rowDataContent.length : 0, ' ROW DATA  ')
      setContentWithUIUsingAGGrid((prev)=>(
        <div
          className="ag-theme-quartz"
          style={{ width: "65%", height: "65vh", marginLeft:"auto", marginRight:"auto"}}
          // style={{ width: "70%", height: "65vh", textAlign:"center", justifyContent:"center", alignItems:"center" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowDataContent}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      ));
  }

  useEffect(() => {
    dispatch(listAllWarehouses());
    dispatch(listAllStores());
    dispatch(listAllVehicles());
  }, [dispatch]);

  useEffect(() => {
    // console.log(' useEffect Getting Called for :==> fromWHStoreVehName : ', fromWHStoreVehName, ' fromWHStoreVehValue : ', fromWHStoreVehValue)
    if (fromWHStoreVehName && fromWHStoreVehName.length > 0 && fromWHStoreVehValue) {
      if (fromWHStoreVehName === "Warehouse" && fromWHStoreVehValue.warehouseName && fromWHStoreVehValue.warehouseName.length > 0) {
        // console.log(" As fromWHStoreVehName is Warehouse Invoking listStockByWarehouseName: ");
        dispatch(listStockByWarehouseName(fromWHStoreVehValue.warehouseName));
      } else if (fromWHStoreVehName === "Store" && fromWHStoreVehValue.storeName && fromWHStoreVehValue.storeName.length > 0) {
        // console.log(" As fromWHStoreVehName is Store Invoking listStockByStoreName: ");
        dispatch(listStockByStoreName(fromWHStoreVehValue.storeName));
      } else if (fromWHStoreVehName === "Vehicle" && fromWHStoreVehValue.vehicleNo && fromWHStoreVehValue.vehicleNo.length > 0) {
        // console.log(" As fromWHStoreVehName is Vehicle Invoking listStockByVehicleNo: ");
        dispatch(listStockByVehicleNo(fromWHStoreVehValue.vehicleNo));
      }
    }
  }, [dispatch, fromWHStoreVehName, fromWHStoreVehValue]);

  useEffect(() => {
    console.log(' useEffect Getting Called to Create Content of Store Stock using AG Grid');
    if (store_stock_data && store_stock_data.length>0 ) {
      console.log(" As store_stock_data  : ",store_stock_data ? store_stock_data.length : 0  );
      createContentUsingAGGrid(store_stock_data)
    }
    else{
      console.log(" As store_stock_data : I KNOW ITS DUMMY")
      createContentUsingAGGrid(store_stock_data)
    }
  }, [store_stock_data, ]);
  useEffect(() => {
    console.log('  useEffect Getting Called to Create Content of Warehouse Stock using AG Grid');
    // createContentUsingAGGrid(wh_stock_data)
    if (wh_stock_data && wh_stock_data.length>0 ) {
      console.log(" As wh_stock_data : " ,wh_stock_data ? wh_stock_data.length : 0 );
      createContentUsingAGGrid(wh_stock_data)
    }else{
      console.log(" As wh_stock_data : I KNOW ITS DUMMY")
      createContentUsingAGGrid(wh_stock_data)
    }
  }, [ wh_stock_data ]);
  useEffect(() => {
    console.log('  useEffect Getting Called to Create Content of Vehicle Stock using AG Grid');
    if ( vehicle_stock_by_vehicle_no_data && vehicle_stock_by_vehicle_no_data.length > 0) {
      console.log(" As vehicle_stock_by_vehicle_no_data : ", vehicle_stock_by_vehicle_no_data?vehicle_stock_by_vehicle_no_data.length : 0 );
      createContentUsingAGGrid(vehicle_stock_by_vehicle_no_data)
    }
    else{
      console.log(" As vehicle_stock_by_vehicle_no_data : I KNOW ITS DUMMY")
      createContentUsingAGGrid(vehicle_stock_by_vehicle_no_data)
    }
  }, [ vehicle_stock_by_vehicle_no_data]);

  useEffect(() => {
    console.log(' useEffect Getting Called to Render the UI using AG Grid');
    // if (rowDataContent && rowDataContent.length > 0 ) {
      console.log(" rowDataContent Records : ");
      prepareUIUsingAGGrid()
    // }
  }, [rowDataContent]);

  const handleDestinationKeyChange = (event) => {
    console.log('Clicked handleDestinationKeyChange : ', event)
    clearDestinationValueState()
    setFromWHStoreVehName((prev) => event.target.value);
  };

  const handleDestinationValueChange = (event) => {
    console.log('Clicked handleDestinationValueChange : ', event.target.value)
    setFromWHStoreVehValue((prev) => event.target.value);
  };

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "warehouse_store_vehicle":
        return warehouse_store_vehicle.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const renderDestinationOptions = () => {
    // console.log('renderDestinationOptions : ', fromWHStoreVehName)
    switch (fromWHStoreVehName) {
      case "Warehouse":
        return warehouseInfos.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each.warehouseName}
          </MenuItem>
        ));
      case "Store":
        return stores.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each.storeName}
          </MenuItem>
        ));
      case "Vehicle":
        return vehicles.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each.vehicleNo}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: '3em' }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  Track Stock <SignpostIcon
                    style={{
                      color: "Gray",
                      marginLeft: "2.5rem",
                      marginRight: "2.5rem",
                    }}
                  />
                  Warehouse
                  <SignpostIcon
                    style={{
                      color: "Gray",
                      marginLeft: "2.5rem",
                      marginRight: "2.5rem",
                    }}
                  />
                  Store
                  <SignpostIcon
                    style={{
                      color: "Gray",
                      marginLeft: "2.5rem",
                      marginRight: "2.5rem",
                    }}
                  />
                  Vehicle
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        {rowData && rowData.length > 0 && (
          <GridItem xs={12} sm={12} md={12}>
            <Tooltip title="Export > Excel" arrow>
              <IosShareRoundedIcon
                fontSize="medium"
                style={{ float: "right", margin: "0.5rem" }}
                onClick={onExportClick}
              />
            </Tooltip>
          </GridItem>
        )
        }
        {<GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
                    <FormControl style={{width:'100%'}}>
                      <InputLabel id="demo-simple-select-label">
                        From
                      </InputLabel>
                      <Select
                        label="Name"
                        placeholder="Name"
                        variant="outlined"
                        required
                        size="small"
                        fullWidth
                        name="fromWHStoreVehName"
                        onChange={handleDestinationKeyChange}
                        value={fromWHStoreVehName}
                      >
                        {renderDynamicOptions("warehouse_store_vehicle")}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }}>
                  <FormControl style={{width:'100%'}}>
                      <InputLabel id="demo-simple-select-label">
                        Destination
                      </InputLabel>
                      <Select
                        label="Destination"
                        placeholder="Destination"
                        disabled={!fromWHStoreVehName}
                        variant="outlined"
                        required
                        size="small"
                        // style={{ width: "12rem" }}
                        name="fromWHStoreVehValue"
                        onChange={handleDestinationValueChange}
                        value={fromWHStoreVehValue}
                      >
                        {renderDestinationOptions(fromWHStoreVehName)}
                        {/* {renderDynamicOptions("warehouse_store_vehicle")} */}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center" }} >
                    <Autocomplete
                      disablePortal
                      disabled={!fromWHStoreVehName}
                      isOptionEqualToValue={(option, value) => {
                        console.log('isOptionEqualToValue : ', option, value)
                        return option.warehouseName === value
                      }}
                      getOptionLabel={(option) => option.warehouseName ? option.warehouseName : option.storeName ? option.storeName : option.vehicleNo ? option.vehicleNo : ""}
                      onChange={(event, value) => {
                        console.log('on Change getting Invoked fromWHStoreVehValue value : ', value, '  fromWHStoreVehName : ', fromWHStoreVehName)
                        const targetValue = (fromWHStoreVehName === "Warehouse" && value.warehouseName
                          ? value.warehouseName
                          : (fromWHStoreVehName === "Store" && value.storeName
                            ? value.storeName
                            : (fromWHStoreVehName === "Vehicle" && value.vehicleNo ? value.vehicleNo : "")));
                        console.log('targetValue : ', targetValue)
                        setFromWHStoreVehValue((prev) => targetValue);
                      }}
                      id="combo-box-products"
                      options={
                        fromWHStoreVehName &&
                          fromWHStoreVehName === "Warehouse"
                          ? warehouseInfos
                          : fromWHStoreVehName === "Store"
                            ? stores
                            : fromWHStoreVehName === "Vehicle" ? vehicles : ""
                      }
                      size="small"
                      style={{ width: "12rem" }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select" />
                      )}
                    />
                  </Grid> */}
                  <Grid item xs={12} sm={6} md={4} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                    <Tooltip title="Back" arrow>
                      <Button
                        size="small"
                        variant="contained"
                        color="secondary"
                        style={{ textTransform: "capitalize", borderRadius: "5px" ,width:"12rem"}}
                        justify="center"
                        onClick={clearDestinationValueState}
                      >Stock
                        <SyncIcon
                          fontSize="medium"
                          onClick={clearDestinationValueState}
                        />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>}
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            {rowDataContent && rowDataContent.length>0 ?contentWithUIUsingAGGrid:(
                <div
                  className="ag-theme-quartz"
                  style={{ width: "100%", height: "65vh" }}
                >
                  <AgGridReact
                    ref={gridRef}
                    rowData={[]}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowStyle={{ textAlign: "left", alignItems: "center" }}
                    suppressExcelExport={false}
                    popupParent={popupParent}
                    pagination="true"
                  ></AgGridReact>
                </div>
            )}
          </GridItem>
        </GridContainer>
    </div>
  );
};

export default StockListScreen;
