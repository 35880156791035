import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Typography,
  Grid,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { listAllWarehouses } from "../../../actions/warehouseInfoAction.js";
import { listAllStores } from "../../../actions/storeAction.js";
import { listWarehousePurchaseOrders, listWarehousePurchaseOrdersByPosName } from "../../../actions/warehousePurchaseOrderAction.js";
import { listStorePurchaseOrders, listStorePurchaseOrdersByPosName , deleteStorePurchaseOrder} from "../../../actions/storePurchaseOrderAction.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
  warehouse_store,
} from "../../../data/index";
import { STORE_PURCHASE_ORDER_DELETE_BY_ID_RESET } from "../../../constants/storePurchaseOrderConstants.js";
import {convertInvoiceIdToDigits} from '../../../util/Utility.js'
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { exportToExcel } from "../../../util/exportToExcelUtil.js";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const PreProductionPOListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const gridRef = useRef();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [posName, setPosName] = useState(() => "");
  const [posStoreOrWarehouse, setPosStoreOrWarehouse] = useState(() => "");
  const [poByWarehouseOrStore, setPoByWarehouseOrStore] = useState(() => "");
  let storeOrWarehouseNameFiltered = null;
  const userLogin = useSelector(
    (state) => state.userLogin
  );
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;

  const warehouseInfoList = useSelector(
    (state) => state.warehouseInfoList
  );
  const storesList = useSelector(
    (state) => state.storesList
  );

  const storePurchaseOrderDeleteById = useSelector(
    (state) => state.storePurchaseOrderDeleteById
  ); 
  const { store_po_delete_success, store_po_delete_error, store_po_delete_loading } = storePurchaseOrderDeleteById;
  const { loading_wh_infos, warehouseInfos, wh_infos_error } = warehouseInfoList;
  const { stores_loading, stores, stores_error } = storesList; //storesList

  const warehousePurchaseOrdersList = useSelector(
    (state) => state.warehousePurchaseOrdersList
  );

  const storePurchaseOrdersListByPos = useSelector(
    (state) => state.storePurchaseOrdersListByPos
  );
  const storePurchaseOrdersList = useSelector(
    (state) => state.storePurchaseOrdersList
  );
  const warehousePurchaseOrdersListByPos = useSelector(
    (state) => state.warehousePurchaseOrdersListByPos
  );

  const { warehousePurchaseOrders, loading } = warehousePurchaseOrdersList;
  const { storePurchaseOrders, store_po_list_loading } = storePurchaseOrdersList;
  const { wh_po_pos_data, wh_po_pos_error, wh_po_pos_loading } = warehousePurchaseOrdersListByPos;
  const { store_po_pos_data, store_po_pos_error, store_po_pos_loading } = storePurchaseOrdersListByPos;

  const { } = storePurchaseOrdersListByPos;
  let pointOfSale = null;

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const populatePosDetails = () => {
    if (warehouseName && warehouseName.length > 0 && warehouseName.includes("_WAREHOUSE")) {
      console.log("warehouseName  : ", warehouseName);
      setPosName(() => warehouseName);
      setPosStoreOrWarehouse(() => "Warehouse");
    } else if (storeName && storeName.length > 0 && storeName.includes("_STORE")) {
      console.log("storeName  : ", storeName);
      setPosName(() => storeName);
      setPosStoreOrWarehouse(() => "Store");
    }
  }

  const getPosNameByRole = (role, posId) => {
    let posObj = null;
    let targetStoreWhName;
    console.log('role, posId ::==>>  ', role, posId, stores.length, warehouseInfos.length);
    const posByRole = role === 'STORE_INCHARGE_ROLE' ? 'store' : role === 'WAREHOUSE_INCHARGE_ROLE' ? 'warehouse' : '';
    console.log('posByRole :: ', posByRole, stores, warehouseInfos);
    if (posByRole === 'store' && stores && stores.length > 0) {
      posObj = stores.filter(e => e._id === posId);
      console.log('Store posObj : ', posObj);
      targetStoreWhName = posObj[0].storeName;
    } else if (posByRole === 'warehouse' && warehouseInfos && warehouseInfos.length > 0) {
      posObj = warehouseInfos.filter(e => e._id === posId);
      targetStoreWhName = posObj[0].warehouseName;
      console.log('targetStoreWhName : ', targetStoreWhName);
    }
    console.log('targetStoreWhName : ', targetStoreWhName);
    return targetStoreWhName;
  }

  const handleEdit = (params) => {
    console.log(
      "PO  params Passed :==> ",
      params
    );
    console.log("ID SELECTED : " + params.data ? params.data : "Empty");
  };

  const createHandler = (category) => {
    navigate("/ctc/pre-production-create/");
  };

  let renderContentUsingAGGrid = "";
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Id",
      headerName: "Id",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "BillDate",
      headerName: "Bill Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
    },
    {
      field: "SupplierName",
      headerName: "Supplier Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "PaymentType",
      headerName: "Payment Type ",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: true,
    },
    {
      field: "Total",
      headerName: "Total",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "AdvancePaid",
      headerName: "Advance Paid",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "Balance",
      headerName: "Balance",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "Name",
      headerName: "Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
    },
    {
      field: "Contact",
      headerName: "Contact",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },

    {
      field: "StateOfSupply",
      headerName: "State Of Supply",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "Id",
      headerName: "Details",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => showInvoiceDetails(params.data.Details, role)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "Id",
      headerName: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Edit Purchase Order" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: "Id",
      headerName: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      cellRenderer: (params) => (
        <span >
          <Tooltip title="Delete Purchase Order" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              disabled = {true}
              onClick={() => {
                if(role === "ADMIN_ROLE"){
                  console.log("Deleting ", params, "ID : ", params.data.Details)
                  dispatch(deleteStorePurchaseOrder(params.data.Details))
                }else{
                  console.log("Cant Delete , In Sufficient Role . Talk to your Administrator .")
                }
              }}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];

  const showInvoiceDetails = (id, role) => {
    console.log('Role Passed here is  : ', role);
  };

  if (role === 'PRODUCTION_INCHARGE_ROLE' ) {
    rowData = warehousePurchaseOrders.map((warehousePurchaseOrder, index) => {
      const invIdInDecimal =convertInvoiceIdToDigits(warehousePurchaseOrder._id)
      return {
        "#": index + 1,
        Id:invIdInDecimal,
        Name: warehousePurchaseOrder.warehouseName,
        SupplierName: warehousePurchaseOrder.supplierName,
        Contact: warehousePurchaseOrder.contactNo,
        BillDate: warehousePurchaseOrder.billDate ? warehousePurchaseOrder.billDate : "",
        StateOfSupply: warehousePurchaseOrder.stateOfSupply,
        PaymentType: warehousePurchaseOrder.paymentType,
        AdvancePaid: warehousePurchaseOrder.advanceAmount,
        Balance: Number(warehousePurchaseOrder.totalPoAmount - warehousePurchaseOrder.advanceAmount),
        Total: warehousePurchaseOrder.totalPoAmount,
        Details: warehousePurchaseOrder._id,
        Edit: warehousePurchaseOrder._id,
        Delete: warehousePurchaseOrder._id,
      };
    });
    renderContentUsingAGGrid = (
      <>
        {rowData && rowData.length > 0 && ( 
           <GridContainer spacing={2} alignItems="center" style={{textAlign : "center", justifyContent : "center", alignItems:"center"}}>
          <GridItem xs={12} sm={9} md={6} style={{textAlign : "center", justifyContent : "center", alignItems:"center"}}>
        
            <Tooltip title="Download Purchase Order Report" arrow>
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick={onExportClick}
              style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            >
              <SaveAltIcon
              fontSize="medium"
              style={{ float: "left", marginRight:"10px" }}
            />
              Purchase Orders
            </Button>
            </Tooltip>
          </GridItem>
          </GridContainer>
        )
        }
        <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      </>
    );
  } 

  return (
    <React.Fragment>
      {/* { loading && <CustomBackdropSpinner />} */}
      {
        (role === 'PRODUCTION_INCHARGE_ROLE' ) && (<GridItem xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>
          <Tooltip title="Create Pre Production Invoice" arrow>
            <AddCircleIcon
              fontSize="medium"
              style={{ float: "left" }}
              onClick={createHandler}
            />
          </Tooltip>
        </GridItem>)
      }
      <GridContainer spacing={2} alignItems="center" justify="center">

        <GridItem xs={12} sm={12} md={12} >
          <Card>
            <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                Pre Production Invoice
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {role === 'PRODUCTION_INCHARGE_ROLE' && (<GridItem xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={4} md={3}>
          </Grid>
        </GridItem>)}
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : null}
        </GridItem>
      </GridContainer>

    </React.Fragment>
  );
};
export default PreProductionPOListScreen;
