import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../../Grid/GridContainer";
import GridItem from "../../../Grid/GridItem";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import CardHeader from "../../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { PhoneInput } from "react-international-phone";
import 'react-international-phone/style.css';
import {
  Divider,
  Autocomplete,
} from "@mui/material";
import { updateStockWhenOutFromVehicleNoAndProductName } from "../../../../actions/vehicleStockAction";
import { listDomesticByProductId } from "../../../../actions/domesticAction";
import { listStockByWarehouseName } from "../../../../actions/warehouseStockAction";
import { listStockByStoreName } from "../../../../actions/storeStockAction";
import { listAllProducts } from "../../../../actions/productAction";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Box,
} from "@mui/material";
import { VEHICLE_INVOICE_TO_SHOP_CREATE_RESET } from "../../../../constants/vehicleSalesInvoiceConstants";
import {
  payment_modes,
  unit_of_meassures,
  stock_transfer_invoice_status,
  vehicle_shifts,
  order_type,
  customer_type,
  warehouse_store,
} from "../../../../data/index";
import CustomBackdropSpinner from "../../../CustomBackdropSpinner";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { addToCart, removeFromCart } from "../../../../actions/cartAction";
import { addInvoiceDetailsToStorage, addVehicleDetailsToStorage } from "../../../../actions/storageAction";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { addDecimals, calculateTax, formatToLocaleCurrency, preferredCustomerDiscount } from "../../../../util/Utility";
import WalkinSalePayment from "../../../Payment/WalkinSalePayment";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../../assets/images/green_tick.png";
import Dialog from '@mui/material/Dialog';
import Switch from '@mui/material/Switch';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { WALKIN_SALES_INVOICE_CREATE_RESET } from "../../../../constants/walkinSalesInvoiceConstants";
import { listCustomersByParentUser } from "../../../../actions/customerAction";
import { listWalkinSaleInvoices, listWalkinSaleInvoicesByPosName } from "../../../../actions/walkinSaleInvoiceAction";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers";
import FormControlLabel from '@mui/material/FormControlLabel';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const WalkinSalesInvoiceCreateScreen = ({ match }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const gridRef = useRef();
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName, role, userName } = userInfo;
  // const posName = storeName && storeName.length > 0 ? storeName : warehouseName && warehouseName.length > 0 ? warehouseName : ""
  // const posStoreOrWarehouse = posName && posName.length > 0 && posName.includes('STORE') ? 'Store' : posName.includes('WAREHOUSE') ? 'Warehouse' : null
  const [errorObject, setErrorObject] = useState(() => null);
  const [posName, setPosName] = useState(() => "");
  const [posStoreOrWarehouse, setPosStoreOrWarehouse] = useState(() => "");

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const [invoiceFormInputs, setInvoiceFormInputs] = useState({
    description: "",
    total: 0.0,
    fromName: "",
    fromWhStoreName: "",
    customerName: "",
    customerContact: "",
    customerEmail: "",
    customerGst: "",
    stockTransferInvoiceStatus: "",
    billDate: "",
    billNo: "",
    isCashSale : false
  });
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);
  const [isDuplicateInvoice, setIsDuplicateInvoice] = React.useState(()=>false);
  const handleClose = () => {
    setOpen(false);
    if (walkin_sales_invoice_create_success) {
      dispatch({ type: WALKIN_SALES_INVOICE_CREATE_RESET });
      navigate("/admin/walkin-sales-invoice/list");
    }
  };
  const [autoCompleteCustomer, setAutoCompleteCustomer] =
    useState(() => "");
  const [billNo, setBillNo] =
    useState(() => "");
  const [billDate, setBillDate] =
    useState(() => new Date());


  const [selectedCustomer, setSelectedCustomer] = useState(() => "");

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  let tempCustomers = [];

  let renderContentUsingAGGrid = "";
  const columnDefs = [
    {
      field: "No",
      resizable: true,
      suppressSizeToFit: true,
      width: 60,
      filter: false,
      floatingFilter: false,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Name",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 500,
      minWidth: 500,
      maxWidth: 500,
      cellStyle: params => {
        return { textAlign: "left" };
    },
    },
    {
      field: "OrderTypeSelected",
      headerName: "Order Type",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: "Uom",
      headerName: "Unit of Meassure",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: params => {
        return { textAlign: "center" };
    },
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      editable: true,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "InKgs",
      headerName: "In Kgs | Pcs | Ltr",
      resizable: true,
      suppressSizeToFit: true,
      width: 325,
      minWidth: 325,
      maxWidth: 325,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "UnitPrice",
      headerName: "Selling Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "ProductTax",
      headerName: "Product Tax %",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "ProductTaxAmount",
      headerName: "Product Tax Amount",
      resizable: true,
      suppressSizeToFit: true, width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "DiscountPercent",
      headerName: "Discount [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "DiscountStartsFrom",
      headerName: "Discount from [kgs]",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "ItemsDiscountPrice",
      headerName: "Items Discount Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "ItemCost",
      headerName: "Item Cost",
      resizable: true,
      suppressSizeToFit: true,
      width: 175,
      minWidth: 175,
      maxWidth: 175,
      cellStyle: params => {
        return { textAlign: "right" };
    },
    },
    {
      field: "Id",
      headerName: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Delete Bulk Details" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => {
                console.log("Deleting ", params.data, 'No : ', params.data.No );
                console.log("productsTobeAddedInInvoice : ", productsTobeAddedInInvoice);
                console.log(" Item to be Deleted at Index : ", Number(params.data.No) - 1);
                console.log(" Removed : ", productsTobeAddedInInvoice.splice(Number(params.data.No) - 1, 1));
                setProductsTobeAddedInInvoice(() => [...productsTobeAddedInInvoice]);
                console.log("After Removal , Length of productsTobeAddedInInvoice : ", productsTobeAddedInInvoice.length);
              }
              }

              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const dispatch = useDispatch();

  const populatePosDetails = () => {
    if (warehouseName && warehouseName.length > 0 && warehouseName.includes("_WAREHOUSE")) {
      console.log("warehouseName  : ", warehouseName);
      setPosName(() => warehouseName);
      setPosStoreOrWarehouse(() => "Warehouse");
    } else if (storeName && storeName.length > 0 && storeName.includes("_STORE")) {
      console.log("storeName  : ", storeName);
      setPosName(() => storeName);
      setPosStoreOrWarehouse(() => "Store");
    }
  }

  useEffect(() => {
    console.log("Use Effect Gets Called Only when PO is by WH or Store.");

    if (role === "WAREHOUSE_INCHARGE_ROLE" && warehouseName) {// HANDLE FOR WH INCHARGE ROLES
      populatePosDetails();
      if (posStoreOrWarehouse && posStoreOrWarehouse.length > 0)
        dispatch(listWalkinSaleInvoicesByPosName(posName));
    }
    else if (role === "STORE_INCHARGE_ROLE" && storeName) {// HANDLE FOR WH INCHARGE ROLES
      populatePosDetails();
      if (posStoreOrWarehouse && posStoreOrWarehouse.length > 0)
        dispatch(listWalkinSaleInvoicesByPosName(posName));
    }
  }, [ dispatch, posName ]);


  useEffect(() => {
    dispatch(listAllProducts())
  }, []);

  useEffect(() => {
    if (name && name.length > 0 && role && role === "VEHICLE_SALES_INCHARGE_ROLE")
      console.log('Exec listCustomersByParentUser for name, userName', name, userName)
    dispatch(listCustomersByParentUser(name));
  }, [dispatch, role, name]);

  useEffect(() => {
    if (invoiceFormInputs.billDate && invoiceFormInputs.billNo){
      console.log('Exec listCustomersByParentUser for name, userName', name, userName)
    checkIfDuplicateInvoice()
    }
  }, [invoiceFormInputs.billDate]);

  const walkinSalesInvoicesListByPos = useSelector(
    (state) => state.walkinSalesInvoicesListByPos
  );
  const { walkin_sales_invoice_pos_loading, walkin_sales_invoice_pos_data, walkin_sales_invoice_pos_error } = walkinSalesInvoicesListByPos; 


  const cashSaleLabel = { inputProps: { 'aria-label': 'is Cash Sale' } };
  const warehouseStockList = useSelector((state) => state.warehouseStockListByWarehouseName);
  const { wh_stock_data, wh_stock_error } = warehouseStockList;
  const storeStockList = useSelector((state) => state.storeStockListByStoreName);
  const { store_stock_data, store_stock_error } = storeStockList;
  const walkinSalesInvoiceCreate = useSelector((state) => state.walkinSalesInvoiceCreate);
  const { walkin_sales_invoice_create_success, walkin_sales_invoice_create_error } = walkinSalesInvoiceCreate;
  const customerListByParentUser = useSelector((state) => state.customerListByParentUser);
  const { customer_list_parent_user, customer_list_parent_user_error, customer_list_parent_user_success } = customerListByParentUser;
  let customProducts = [];
  const [maxQuantityAllowed, setMaxQuantityAllowed] = React.useState(() => 0);
  const [productsAveragePrice, setProductsAveragePrice] = React.useState(() => 0);
  const [maxQuantityAllowedErrorMessage, setMaxQuantityAllowedErrorMessage] = React.useState("");

  const [productsTobeAddedInInvoice, setProductsTobeAddedInInvoice] = useState(
    () => []
  );
  const [selectedProduct, setSelectedProduct] = useState(() => "");
  const [uom, setUom] = useState(() => "");
  const [quantitySelected, setQuantitySelected] = useState(() => {
    return 0;
  });
  const [calculatedUnitPrice, setCalculatedUnitPrice] = useState(() => {
    return 0.0;
  });

  const [productFormInputs, setProductFormInputs] = useState({
    name: "",
    orderTypeSelected: "Domestic",
    unitOfMeassure: "",
    uom: "",
    quantity: "",
    inKgs: "",
    unitPrice: "",
    cgst: "",
    sgst: "",
    igst: "",
    tcs: "",
    productTax: "",
    productTaxAmount: "",
    cgstPrice: "",
    sgstPrice: "",
    igstPrice: "",
    tcsPrice: "",
    subCategory: "",
    discountPercent: "",
    preferreDiscountPercent: "",
    discountStartsFrom: "",
    itemsDiscountPrice: "",
    itemCost: "",
    productsAvgPrice: "",
  });



  let [orderTypeSelected, setOrderTypeSelected] = useState(() => {
    return "Domestic";
  });

  let [customerTypeSelected, setCustomerTypeSelected] = useState(() => {
    return "Regular";
  });

  let [preferredDiscountSelected, setPreferredDiscountSelected] = useState(() => {
    return 0.5;
  });

  let [regularDiscount, setRegularDiscount] = useState(() => {
    return 0.0;
  });
  const domesticListByProductId = useSelector(
    (state) => state.domesticListByProductId
  );
  const { domestic } = domesticListByProductId;

  const allProducts = useSelector((state) => state.allProducts);
  const { all_products } = allProducts;

  if (customer_list_parent_user && customer_list_parent_user.length > 0)
    tempCustomers = customer_list_parent_user;


  if (wh_stock_data && wh_stock_data.length > 0) {
    customProducts = wh_stock_data;
    console.log('wh_stock_data Before Filter of Out Of Stock : ', wh_stock_data.length);
    const productsInStock = wh_stock_data.filter((prd => prd.countInStock > 0))
    console.log('wh_stock_data After Filter of Out Of Stock : ', productsInStock.length);
    customProducts = productsInStock;
  }
  if (store_stock_data && store_stock_data.length > 0) {
    console.log('store_stock_data Before Filter of Out Of Stock : ', store_stock_data.length);
    const productsInStock = store_stock_data.filter((prd => prd.countInStock > 0))
    console.log('store_stock_data After Filter of Out Of Stock : ', productsInStock.length);
    customProducts = productsInStock;
  }

  const renderUomOptions = () => {
    console.log("Exec renderUomOptions")
    if (productFormInputs.orderTypeSelected && productFormInputs.orderTypeSelected === "Domestic") {
      console.log("Exec Domestic Option Menu Code", productFormInputs.orderTypeSelected);

      if (domestic) {
        console.log("domestic Entries : ", domestic);
        return domestic.map((domesticItem, i) => {
          return (
            <MenuItem key={i} value={domesticItem.unitOfMessure}>
              {domesticItem.unitOfMessure}
            </MenuItem>
          );
        });
      } // NEED TO HANDLE IF domesstic is empty
    } else if (productFormInputs.orderTypeSelected && productFormInputs.orderTypeSelected === "Bulk") {
      console.log("Exec Bulk Option Menu Code", productFormInputs.orderTypeSelected);
      /**
      if (bulk) {
        return bulk.map((bulkItem, i) => {
          return (
            <MenuItem key={i} value={bulkItem.unitOfMessure}>
              {bulkItem.unitOfMessure}
            </MenuItem>
          );
        });
      } */
      // NEED TO HANDLE IF bulk is empty
    }
  };

  const calculateUnitPrice = (qtySelected) => {
    console.log("Exc calculateUnitPrice for QTY : " + qtySelected);
    if (productFormInputs.orderTypeSelected === "Domestic" && qtySelected > 0) {
      return domestic
        .filter((eachDomestic) => eachDomestic.unitOfMessure === productFormInputs.uom)
        .map((matchedRec) => {
          console.log("matchedRec : ", matchedRec);
          console.log(
            "matchedRec.unitPrice * counter : matchedRec.unitPrice -> " +
            matchedRec.unitPrice +
            " , qtySelected : -> " +
            qtySelected
          );
          console.log(
            "Result of CALC : -> " + matchedRec.unitPrice * qtySelected
          );
          return matchedRec.unitPrice * qtySelected;
        });
    } else if (productFormInputs.orderTypeSelected === "Bulk" && qtySelected > 0) {
      return bulk
        .filter((eachBulk) => eachBulk.unitOfMessure === productFormInputs.uom)
        .map((matchedRec) => {
          console.log(
            "matchedRec.unitPrice * counter : matchedRec.unitPrice -> " +
            matchedRec.unitPrice +
            " , qtySelected : -> " +
            qtySelected
          );
          console.log("Result of CALC" + matchedRec.unitPrice * qtySelected);
          return matchedRec.unitPrice * qtySelected;
        });
    }
  };

  const handleChangeUom = (event) => {
    const uomSelected = event.target.value;
    console.log("Handler for Change of UOM : Value :-> " + uomSelected);
    setUom((u) => uomSelected);

    setProductFormInputs((prevState) => ({
      ...prevState,
      uom: uomSelected
    }));

    setQuantitySelected(() => 0);
    console.log(
      "orderTypeSelected: ",
      productFormInputs.orderTypeSelected,
      "event.target.value  : ",
      uomSelected
    );
    let foundItem = null;
    if (productFormInputs.orderTypeSelected === "Bulk" && bulk && bulk.length > 0) {
      bulk.map((each) => console.log(each));
      foundItem = bulk.filter((each) => each.unitOfMessure === uomSelected);
    } else if (
      productFormInputs.orderTypeSelected === "Domestic" &&
      domestic &&
      domestic.length > 0
    ) {
      domestic.map((each) => console.log(each));
      foundItem = domestic.filter((each) => each.unitOfMessure === uomSelected);
    }
    console.log("foundItem : ", foundItem);
    if (foundItem && foundItem.length > 0) {
      console.log('$$$$$$$$ purchasedAvgPrice  $$$$$$ : ', foundItem[0].purchasedAvgPrice ? Number(foundItem[0].purchasedAvgPrice) : 0)
      setProductFormInputs((prevState) => ({
        ...prevState,
        discountPercent: foundItem[0].discountPercent,
        // regularDiscount: foundItem[0].discountPercent,
        preferreDiscountPercent: foundItem[0].preferreDiscountPercent,
        discountStartsFrom: foundItem[0].discountStartsFrom,
        unitPrice: foundItem[0].unitPrice,
        cgst: foundItem[0].cgst ?foundItem[0].cgst : 0,
        sgst: foundItem[0].sgst ? foundItem[0].sgst : 0,
        igst: foundItem[0].igst ? foundItem[0].igst : 0,
        tcs: foundItem[0].tcs ? foundItem[0].tcs : 0,
        inKgs: foundItem[0].inKgs ? foundItem[0].inKgs : 0,
        productsAvgPrice : foundItem[0].purchasedAvgPrice ? Number(foundItem[0].purchasedAvgPrice) : 0,
        //CAPTURE THE PURCHASED AVG PRICE
      }));
      setRegularDiscount((prev) => foundItem[0].discountPercent);
    }
    setCalculatedUnitPrice(0.0);
  };

  const handleProductChange = (event) => {
    setProductFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const checkIfDuplicateInvoice = ( ) => {
    console.log('Exec checkIfDuplicateInvoice', invoiceFormInputs.billNo, posName)
    let isDuplicate = false;
    if(walkin_sales_invoice_pos_data && walkin_sales_invoice_pos_data.length > 0){
      isDuplicate =  walkin_sales_invoice_pos_data.some(item =>
      // item.billDate === new Date(invoiceFormInputs.billDate).toLocaleDateString() &&
      item.billNo === invoiceFormInputs.billNo &&
      item.posName === posName
    );
    if (isDuplicate) {
      console.log('checkIfDuplicateInvoice Response :', isDuplicate)
      setIsDuplicateInvoice(()=>isDuplicate)
      setErrorObject({ errorMessage: "Bill No is Duplicate  "  });
    }else
      setErrorObject(null);
    }
  }

  const checkIfValidQuantity = (event) => {
    let isValid = false;
    if (Number(event.target.value) > Number(maxQuantityAllowed)) {
      setMaxQuantityAllowedErrorMessage("Max Allowed Quantity is : " + maxQuantityAllowed)
      isValid = false
    } else isValid = true
    return isValid
  }

  const handleQuantityChange = (event) => {
    // CHECK IF VALID QUANTITY IS ENTERED & RESTRICT HIM ENETERING MORE THAN WHAT WE HAVE AT STORE
    if (selectedProduct && selectedProduct._id !== null) {
      // Check Max Quantity Allowed 
      if (checkIfValidQuantity(event)) {
        console.log('Is Valid Quantity !')
        setMaxQuantityAllowedErrorMessage(() => "")
      } else {
        console.log(" handleQuantityChange Check is Invalid Quantity.! ")
        setMaxQuantityAllowedErrorMessage("Max Allowed Quantity is : " + maxQuantityAllowed)
      }
      setProductFormInputs((prevState) => ({
        ...prevState,
        quantity: event.target.value ? Number(event.target.value) : 0,
      }));
    }
  }

  const resetPriceDetailsOnProductBlur = () => {
    console.log("Exec resetPriceDetailsOnProductBlur ...")
    handleClearProductForm();
  }
  const showMaximumQuantityAllowed = (prd) => {
    console.log("Getting the Max Quantity Allowed for Product : ", prd, "posStoreOrWarehouse : ", posStoreOrWarehouse, "posName : ", posName);
    let selectedProductsQuantity = null;
    let maxQuantityAllowed = 0;
    if (posStoreOrWarehouse === "Warehouse" && prd && prd !== null) {
      if (wh_stock_data && wh_stock_data.length > 0) {
        selectedProductsQuantity = wh_stock_data.filter(e => e.productName === prd.productName);
        if (selectedProductsQuantity && selectedProductsQuantity.length > 0) {
          console.log("selectedProductsQuantity : ", selectedProductsQuantity[0]);
          maxQuantityAllowed = selectedProductsQuantity[0].countInStock;
          console.log("Max Quantity that can be Selected from WH : ", maxQuantityAllowed);
        }
      } else {
        console.log("Failed Getting the Max Quantity Allowed from WH ", posName, "for Product : ", prd, " As wh_stock_data is null");
      }
    }
    if (posStoreOrWarehouse === "Store" && prd && prd !== null) {
      if (store_stock_data && store_stock_data.length > 0) {
        selectedProductsQuantity = store_stock_data.filter(e => e.productName === prd.productName);
        if (selectedProductsQuantity && selectedProductsQuantity.length > 0) {
          console.log("selectedProductsQuantity : ", selectedProductsQuantity[0]);
          maxQuantityAllowed = selectedProductsQuantity[0].countInStock;
          console.log("Max Quantity that can be Selected from Store : ", maxQuantityAllowed);
        }
      } else {
        console.log("Failed Getting the Max Quantity Allowed  from STore ", posName, "for Product : ", prd, " As store_stock_data is null");
      }
    }
    console.log('Displaying maxQuantityAllowed : ', maxQuantityAllowed, ' ofor Product ', prd.productName)
    setMaxQuantityAllowed(() => maxQuantityAllowed);
  };

  const showProductsAveragePrice = (prd) => {
    console.log("Getting the Products Average Price for Product : ", prd);
    let selectedProduct = null;
    let purchasedAvgPrice = 0;
    if (posStoreOrWarehouse === "Warehouse" && prd && prd !== null) {
      // NEED TO HANDLE SAME LIKE STORE
    }
    if (posStoreOrWarehouse === "Store" && prd && prd !== null) {
      if (store_stock_data && store_stock_data.length > 0) {
        selectedProduct = store_stock_data.filter(e => e.productName === prd.productName);
        if (selectedProduct && selectedProduct.length > 0) {
          console.log("selectedProduct : ", selectedProduct[0]);
          purchasedAvgPrice = selectedProduct[0].purchasedAvgPrice;
          console.log('Displaying purchasedAvgPrice : ', purchasedAvgPrice, ' for Product ', prd.productName)
          setProductsAveragePrice((prev) => purchasedAvgPrice);
        } else {
          console.log("Products Avg Price that can be shown for Product ", posName, "for Product : ", prd, " As store_stock_data is null");
          setProductsAveragePrice((prev) => 0);
        }
      }
    }
  }


  const showSuccessDialog = () => {
    console.log("Exe showSuccessDialog Displaying Success Image");
    setOpen(() => true);
  }

  useEffect(() => {
    if (storeName && storeName.length > 0) {
      console.log(" Pulling All Products from Store : ", storeName);
      dispatch(listStockByStoreName(storeName));
    }
    else if (warehouseName && warehouseName.length > 0) {
      console.log(" As From Name is Warehouse puling All Products from WH : ", warehouseName);
      dispatch(listStockByWarehouseName(warehouseName));
    }
  }, [dispatch, storeName, warehouseName]);


  useEffect(() => {
    console.log(" ******* selectedProduct useEffect getting Called ******* : ", selectedProduct)
    if (selectedProduct) {
      // renderUomOptionsContent = renderUomOptions()
      const product = getProductIdByProductName(selectedProduct);
      console.log(product, all_products);
      if (product && product.length > 0 && product[0] && product[0]._id) {
        console.log(
          "Found the Product Id so Invoking the Bulk or Domestic for Product Name : ",
          selectedProduct,
          product[0]._id
        );
        // dispatch(listBulkByProductId(product[0]._id));
        dispatch(listDomesticByProductId(product[0]._id));
      } else {
        console.log(
          "Didnt find the Product Id so Not Invoking the Bulk or Domestic for Product Name : ",
          selectedProduct
        );
      }
    }
  }, [dispatch, selectedProduct]);

  useEffect(() => {
    console.log("Change in productFormInputs", productFormInputs);
    /** CALCULATION OF TAX AND DISCOUNT LOGIC */
    calculateItemCostPriceIncludingTax(
      productFormInputs.unitPrice,
      productFormInputs.inKgs,
      productFormInputs.quantity,
      productFormInputs.cgst,
      productFormInputs.sgst,
      productFormInputs.igst,
      productFormInputs.tcs,
      productFormInputs.discountPercent,
      productFormInputs.discountStartsFrom,
      productFormInputs.itemsDiscountPrice,
      productFormInputs.customerType
    );
  }, [productFormInputs.quantity, productFormInputs.discountPercent]);


  useEffect(() => {
    console.log("useEffect Getting Called on WalkinSaleInvoiceCreate Screen as Success on Create New Invoice", walkin_sales_invoice_create_success);
    if (walkin_sales_invoice_create_success) {
      console.log(
        "walkin_sales_invoice_create_success :==> ",
        walkin_sales_invoice_create_success, " Dispatching to RESET"
      );
      setOpen(() => true);
      // dispatch({ type: STORE_PURCHASE_ORDER_CREATE_RESET });
      // setOpen(()=>true);
    }
  }, [walkin_sales_invoice_create_success]);

  const handleInvoiceChange = (event) => {
    setInvoiceFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleIsCashFlowChange = (event) => {
    console.log('Exec handleIsCashFlowChange ...' , event.target.checked)
    setInvoiceFormInputs((prevState) => ({
      ...prevState,
      isCashSale: event.target.checked,
    }));
  };

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "payment_modes":
        return payment_modes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "unit_of_meassures":
        return unit_of_meassures.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "stock_transfer_invoice_status":
        return stock_transfer_invoice_status.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "order_type":
        return order_type.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      case "customer_type":
        return customer_type.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const handleOrderTypeChange = (e) => {
    console.log("Order Type Changed :--> " + e.target.value);
    setProductFormInputs((prevState) => ({
      ...prevState,
      orderTypeSelected: "Domestic"
    }));
  };

  const handlePreferredDiscountChange = (e) => {
    console.log("PreferredDiscoun Changed :--> " + e.target.value);
    setPreferredDiscountSelected(e.target.value);
    setProductFormInputs((prevState) => ({
      ...prevState,
      discountPercent: e.target.value
    }));
  };
  const handleClearProductForm = () => {
    setProductFormInputs(() => {
      return {
        name: "",
        uom: "",
        orderTypeSelected: "Domestic",
        quantity: "",
        unitPrice: "",
        discountPercent: "",
        discountStartsFrom: "",
        itemsDiscountPrice: "",
        cgst: "",
        sgst: "",
        igst: "",
        tcs: "",
        inKgs: "",
        productTax: "",
        productTaxAmount: "",
        subCategory: "",
        itemCost: "",
        productsAvgPrice : "",
      };
    });
    setOrderTypeSelected(() => "Domestic");
  };

  const handleAddProduct = () => {
    console.log("Before Adding Checking invoiceFormInputs Details : ", invoiceFormInputs,
      "Sale Invoice from : ", invoiceFormInputs.fromName,
      "selectedProduct : ",
      selectedProduct
    );
    //LSK TODO ACTION : NEED TO REMOVE  addInvoiceDetailsToStorageHandler
    // addInvoiceDetailsToStorageHandler(invoiceFormInputs);
    const productTaxAmount = calculateTax( productFormInputs.unitPrice , productFormInputs.quantity ,productFormInputs.sgst ,productFormInputs.cgst) 
    console.log('productTaxAmount calc : ', productTaxAmount)
      // const productTax = poProduct.sgst && poProduct.cgst ?  formatToLocaleCurrency(Number(poProduct.sgst) + Number(poProduct.cgst)) :0.0
    setProductsTobeAddedInInvoice([
      ...productsTobeAddedInInvoice,
      {
        name: selectedProduct.productName,
        fromWhStoreValue: "TO BE CAPTURED FROM LOGIN USER DETAILS",
        inKgs: productFormInputs.inKgs,
        orderTypeSelected: productFormInputs.orderTypeSelected,
        uom: productFormInputs.uom,
        quantity: productFormInputs.quantity,
        unitPrice: formatToLocaleCurrency(productFormInputs.unitPrice),
        discountPercent: productFormInputs.discountPercent,
        discountStartsFrom: productFormInputs.discountStartsFrom,
        itemsDiscountPrice: productFormInputs.itemsDiscountPrice ? formatToLocaleCurrency(productFormInputs.itemsDiscountPrice) : 0.0,
        cgst: productFormInputs.cgst,
        cgstPrice: productFormInputs.cgstPrice,
        sgst: productFormInputs.sgst,
        sgstPrice: productFormInputs.sgstPrice,
        igst: productFormInputs.igst,
        igstPrice: productFormInputs.igstPrice,
        tcs: productFormInputs.tcs,
        tcsPrice: productFormInputs.tcsPrice,
        productTax: Number(productFormInputs.sgst) + Number(productFormInputs.cgst),
        productTaxAmount:productTaxAmount ? formatToLocaleCurrency(productTaxAmount):0.0,
        subCategory: "",
        itemCost: Number(productFormInputs.quantity) * Number(productFormInputs.unitPrice),
        // inKgs: productFormInputs.inKgs
      },
    ]);
    // ADDING ITEMS TO CART 
    // PENDING NEED TO CALC ITEMS COST - LSK FOR NOW 0
    handleClearProductForm();
  };


  // const addInvoiceDetailsToStorageHandler = (invoiceDetailsObj) => {
  //   console.log(" ******** ADDING INVOICE DETAILS TO STORAGE ********  ", invoiceDetailsObj);
  //   dispatch(addInvoiceDetailsToStorage(invoiceDetailsObj));
  //   // invoiceDetailsFromStorage
  // };

  // const renderPreferredDiscounts = () => {
  //   const prefDiscountValus = preferredCustomerDiscount();
  //   return prefDiscountValus.map((each, idx) => (
  //     <MenuItem key={idx} value={each}>
  //       {each}
  //     </MenuItem>
  //   ));
  // }

  const getProductIdByProductName = (prdObject) => {
    console.log("getProductIdByProductName : ", prdObject, "all_products.length : ", all_products.length);
    const result = all_products.filter(
      (product) => product.name === prdObject.productName
    );
    console.log(
      "Filter RESULT ::==>> ",
      result ? result.length : " Filter couldnt foind any..! ",
      result
    );
    return result;
  };

  const calculateItemCostPriceIncludingTax = (
    unitPrice,
    inKgs,
    quantity,
    cgst,
    sgst,
    igst,
    tcs,
    discountPercent,
    discountStartsFrom,
    customerType
  ) => {
    console.log(
      "unitPrice, quanity, tax, discount : ",
      unitPrice,
      inKgs,
      quantity,
      cgst,
      sgst,
      igst,
      tcs,
      discountPercent,
      discountStartsFrom,
      customerType
    );

    const cgstPrice = unitPrice * quantity * (cgst / 100);
    const sgstPrice = unitPrice * quantity * (sgst / 100);
    const igstPrice = unitPrice * quantity * (igst / 100);
    const tcsPrice = unitPrice * quantity * (tcs / 100);
    let itemsDiscountPrice = 0;
    let totalTaxPrice = 0;
    console.log(" cgst : ", cgst, "cgstPrice : ", cgstPrice, "sgst : ", sgst, "sgstPrice : ", sgstPrice, "igst : ",
      igst, "igstPrice : ", igstPrice, "tcs : ", tcs, "tcsPrice : ", tcsPrice);
    const itemsActualWeight = quantity * inKgs;
    console.log("inKgs : ", inKgs, "items Quantity Ordered : ", quantity * inKgs);
    const eachItemIndividualTotalPrice = unitPrice * quantity;
    let calculatedItemCostPriceIncludingDiscountAndTax = 0;

    if (itemsActualWeight >= discountStartsFrom) {
      console.log(
        "Eligible for Discount Price on this Item inKgs : ",
        inKgs,
        "items Quantity Ordered : ",
        quantity * inKgs, "discountPercent : ", discountPercent
      );
      itemsDiscountPrice = ((unitPrice * quantity) * (discountPercent / 100));
      console.log("itemsDiscountPrice ::==>>  ", itemsDiscountPrice);

      console.log("cgstPrice , sgstPrice , igstPrice , tcsPrice : ", cgstPrice, sgstPrice, igstPrice, tcsPrice);
      totalTaxPrice = (cgstPrice + sgstPrice + igstPrice + tcsPrice);
      console.log("eachItemIndividualTotalPrice : ", eachItemIndividualTotalPrice, " , itemsDiscountPrice : ", itemsDiscountPrice, "totalTaxPrice : ", totalTaxPrice);
      calculatedItemCostPriceIncludingDiscountAndTax = eachItemIndividualTotalPrice - itemsDiscountPrice;
      console.log("calculatedItemCostPriceIncludingDiscountAndTax : ", calculatedItemCostPriceIncludingDiscountAndTax);
    } else {
      console.log(
        "NOT Eligible for Discount Price on this Item , itemsActualWeight: ",
        itemsActualWeight,
        "discountStartsFrom : ",
        discountStartsFrom
      );
      calculatedItemCostPriceIncludingDiscountAndTax =
        eachItemIndividualTotalPrice; // COMMENTING AS UNIT PRICE ALREADY INCLUDES TAX [TICS] + totalTaxPrice;
      console.log("calculatedItemCostPriceIncludingDiscountAndTax : ", calculatedItemCostPriceIncludingDiscountAndTax);
    }

    console.log(
      "calculatedItemCostPriceIncludingDiscountAndTax : ",
      calculatedItemCostPriceIncludingDiscountAndTax
    );
    setProductFormInputs((prevState) => ({
      ...prevState,
      itemCost: calculatedItemCostPriceIncludingDiscountAndTax,
      cgstPrice: cgstPrice,
      sgstPrice: sgstPrice,
      igstPrice: igstPrice,
      tcsPrice: tcsPrice,
      itemsDiscountPrice: itemsDiscountPrice,
      // productsAvgPrice : foundItem[0].purchasedAvgPrice ? Number(foundItem[0].purchasedAvgPrice) : 0,
    }));
  };

  if (productsTobeAddedInInvoice && productsTobeAddedInInvoice.length > 0) {
    rowData = productsTobeAddedInInvoice.map((poProduct, index) => {
      console.log("poProduct : ", poProduct);
      const productTaxAmount = calculateTax( poProduct.unitPrice , poProduct.quantity ,poProduct.sgst,poProduct.cgst) 
      const productTax = poProduct.sgst && poProduct.cgst ?  formatToLocaleCurrency(Number(poProduct.sgst) + Number(poProduct.cgst)) :0.0
      return {
        No: index + 1,
        Name: poProduct.name ? poProduct.name : '',
        OrderTypeSelected: poProduct.orderTypeSelected ? poProduct.orderTypeSelected : '',
        Uom: poProduct.uom ? poProduct.uom : '',
        Quantity: poProduct.quantity ? poProduct.quantity :0.0,
        InKgs: poProduct.inKgs ? Number(poProduct.inKgs) : '',
        UnitPrice: poProduct.unitPrice ? formatToLocaleCurrency(poProduct.unitPrice) : 0.0,
        DiscountPercent: poProduct.discountPercent ? formatToLocaleCurrency(poProduct.discountPercent) : 0.0,
        DiscountStartsFrom: poProduct.discountStartsFrom ? poProduct.discountStartsFrom : 0.0 ,
        ItemsDiscountPrice: poProduct.itemsDiscountPrice ? formatToLocaleCurrency(poProduct.itemsDiscountPrice) : 0.0,
        ProductTax: productTax,
        ProductTaxAmount: productTaxAmount ? formatToLocaleCurrency(productTaxAmount.toFixed(2)) :0.0,
        ItemCost: poProduct.itemCost ? formatToLocaleCurrency(poProduct.itemCost) : 0.0,
        Id: poProduct._id,
        Id: poProduct._id,

      };
    });
    renderContentUsingAGGrid = (
      <>
        <div
          className="ag-theme-quartz"
          style={{ width: "100%", height: "65vh" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      </>
    );
  }

  const validateProductBeforeAdd = () => {
    let status = false;
    console.log("validateProductBeforeAdd ...! ");
    if (productFormInputs.productName && selectedProduct.productName.length > 0 && productFormInputs.orderTypeSelected && productFormInputs.orderTypeSelected.length > 0 && productFormInputs.uom && productFormInputs.uom.length > 0 && productFormInputs.quantity && productFormInputs.quantity > 0)
      status = false;
    else
      status = true;
    return status;
  }

  return (
    <div className={classes.root}>
      <><BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <Grid item xs={12} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  Thanks , your Invoice is Generated.
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>
      </>
      <form name="password-reset-form" method="post" onSubmit={() => console.log("Clicked")}>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem", textAlign: "center" }}
                  color="black"
                >
                  <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em" }}
                    />
                  </Tooltip>
                  Walkin Sales
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        {/* Customer Details : Start */}
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <div>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem" }}
                  color="black"
                >
                  Customer Details
                </Typography>
              </div>
              <CardBody>
                <Grid container spacing={2}>
                  {!invoiceFormInputs.isCashSale && (<><Grid item xs={12} sm={9} md={9} style={{ textAlign: "center", alignItems: "center", justifyContent: "center" }}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        console.log("Chosen Customer :==> ", value);
                        if (value) {
                          setAutoCompleteCustomer(value);
                          setSelectedCustomer(value.name);
                          return;
                        }
                      }}
                      id="combo-box-products"
                      options={tempCustomers}
                      size="small"
                      clearOnBlur={true}
                      getOptionLabel={(option) => {
                        if (option) {
                          return `${option.name}`
                        }
                      }
                      }
                      disabled = {invoiceFormInputs.isCashSale}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customer Name"
                          size="small"
                          value={autoCompleteCustomer && autoCompleteCustomer.name ? autoCompleteCustomer.name : ""}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <Box>
                      <Link to="/admin/customer/signup" style={{
                        color: '#900C3F',
                        textDecoration: "none",
                        fontWeight: "600",
                        fontSize: "14px"
                      }}>
                        <Typography variant="button" color="#900C3F" style={{
                          fontWeight: "600",
                          fontSize: "14px"
                        }}>
                          Add Customer
                        </Typography>
                      </Link>
                    </Box>
                  </Grid></>)}
                  <Grid item xs={12} sm={4} md={4} style={{ textAlign: "center" }}>
                    <FormControlLabel control={<Switch defaultChecked={invoiceFormInputs.isCashSale} onChange={handleIsCashFlowChange}/>} label="is Cash Sale" />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} style={{ textAlign: "center" }}>
                    <TextField
                      label="Bill No"
                      variant="outlined"
                      size="small"
                      required
                      name="billNo"
                      onChange={handleInvoiceChange}
                      onBlur={checkIfDuplicateInvoice}
                      value={invoiceFormInputs.billNo}
                      type="string"
                    />
                    {errorObject && errorObject.errorMessage && (
                      <div variant ="h5" style={{ textAlign: "center", fontSize: "1rem", fontWeight: "600", margin: "1rem", color: "red" ,justifyContent:"center" , alignItems:"center"}}>
                        {errorObject.errorMessage}
                      </div>)}
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker value={billDate} label="Bill Date" style={{ width: "25vh", margin: "1rem" }} size="small" onChange={(newValue) => {
                        setBillDate(newValue);
                        setInvoiceFormInputs((prevState) => ({
                          ...prevState,
                          billDate: newValue,
                        }));
                        console.log("newValue" + newValue);
                      }} renderInput={(params) => <TextField size="small" {...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  {!invoiceFormInputs.isCashSale && (<><Grid item xs={12} sm={4} md={4} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                    <PhoneInput
                      label="Contact #"
                      hideDropdown="true"
                      disableCountryGuess="true"
                      defaultCountry="in"
                      name="customerContact"
                      size="small"
                      required
                      disabled={true}
                      value={autoCompleteCustomer && autoCompleteCustomer.contactNo ? autoCompleteCustomer.contactNo : ""}
                      style={{ textAlign: "center", justifyContent: "center", alignItems: "center"}}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} style={{ textAlign: "center" }}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      size="small"
                      required
                      name="customerEmail"
                      disabled={true}
                      value={autoCompleteCustomer && autoCompleteCustomer.email ? autoCompleteCustomer.email : ""}
                      type="string"
                    />
                  </Grid></>)}
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* Customer Details : End */}

        {/** PRODUCT DETAILS START */}
        {!(errorObject && errorObject.errorMessage) && invoiceFormInputs.billNo && (<GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Product Details
                <Divider></Divider>
              </Typography>
              <CardBody>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9} md={9} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => {
                        if (value && value.productName && value.productName.length > 0) {
                          handleClearProductForm()
                          setSelectedProduct(value);
                          showMaximumQuantityAllowed(value)
                          showProductsAveragePrice(value)
                          console.log('setSelectedProduct : ', value ? value.productName : '')
                        } else {
                          console.log('setSelectedProduct is NULL')
                          resetPriceDetailsOnProductBlur();
                        }
                      }}
                      id="combo-box-products"
                      options={customProducts}
                      size="small"
                      clearOnBlur={true}
                      style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}
                      getOptionLabel={(option) => {
                        // console.log('option : ', option);
                        return `${option.productName}`
                      }}
                      renderInput={(params) => {
                        // console.log('params : ', params);
                        return (
                          <TextField
                            {...params}
                            label="Select Product"
                            size="small"
                            value={productFormInputs.name ? productFormInputs.name : ""}
                          />
                        )
                      }}
                    />
                  </Grid>

                  {productFormInputs.orderTypeSelected && productFormInputs.orderTypeSelected !== "" && (
                    <Grid item xs={12} sm={3} md={3} style={{ textAlign: "center" }}>
                      <FormControl>
                        <InputLabel id="demo-simple-select-label">
                          Unit Of Measure
                        </InputLabel>
                        <Select
                          label="Unit Of Measure"
                          variant="outlined"
                          required
                          size="small"
                          placeholder="Unit Of Measure"
                          style={{ width: "9rem" }}
                          value={productFormInputs.uom}
                          onChange={handleChangeUom}
                        >
                          {renderUomOptions()}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
                  {productFormInputs.orderTypeSelected &&
                    productFormInputs.orderTypeSelected !== "" &&
                    productFormInputs.uom &&
                    productFormInputs.uom !== "" && (
                      <>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="Quantity"
                            variant="outlined"
                            size="small"
                            name="quantity"
                            required
                            onChange={handleQuantityChange}
                            type="number"
                            value={productFormInputs.quantity || null}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <FormControl disabled={true}>
                            <InputLabel id="demo-simple-select-label">
                              Order Type
                            </InputLabel>
                            <Select
                              label="Order Type"
                              // placeholder="Order Type"
                              variant="outlined"
                              required
                              size="small"
                              name="orderType"
                              // onChange={handleOrderTypeChange}
                              disabled={true}
                              value={productFormInputs.orderTypeSelected}
                              style={{ width: "9rem" }}
                            >
                              {renderDynamicOptions("order_type")}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="Max Quantity allowed "
                            variant="outlined"
                            size="small"
                            disabled={true}
                            type="text"
                            value={maxQuantityAllowed || 0}
                          />
                        </Grid>
                        {/**
                        {customerTypeSelected && customerTypeSelected === 'Preferred' && (<Grid item xs={12} sm={4} md={3}>
                          <FormControl>
                            <InputLabel id="demo-simple-select-label">
                              Preferred Discount
                            </InputLabel>
                            <Select
                              label="Preferred Discount"
                              placeholder="Preferred Discount"
                              variant="outlined"
                              required
                              size="small"
                              name="preferredDiscount"
                              onChange={handlePreferredDiscountChange}
                              value={preferredDiscountSelected}
                              style={{ width: "12rem" }}
                            >
                              {renderPreferredDiscounts()}
                            </Select>
                          </FormControl>
                        </Grid>)}*/}
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="Unit Price"
                            variant="outlined"
                            size="small"
                            name="unitPrice"
                            // onChange={handleProductChange}
                            type="number"
                            disabled
                            value={productFormInputs.unitPrice ? formatToLocaleCurrency(productFormInputs.unitPrice) : 0.0}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="Discount Percent"
                            variant="outlined"
                            size="small"
                            name="discountPercent"
                            disabled
                            onChange={handleProductChange}
                            type="number"
                            value={regularDiscount ? formatToLocaleCurrency(regularDiscount) : 0.0}
                          // value={customerTypeSelected && customerTypeSelected === 'Preferred' ? preferredDiscountSelected : regularDiscount}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="Discount Applicable for [Kgs]"
                            variant="outlined"
                            size="small"
                            name="discountStartsFrom"
                            disabled
                            onChange={handleProductChange}
                            type="number"
                            value={
                              productFormInputs.discountStartsFrom ? productFormInputs.discountStartsFrom : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="In Kgs"
                            variant="outlined"
                            size="small"
                            name="inKgs"
                            disabled
                            // onChange={handleProductChange}
                            type="number"
                            value={
                              productFormInputs.inKgs ? productFormInputs.inKgs : 0
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="Items Discount Price"
                            variant="outlined"
                            size="small"
                            name="itemsDiscountPrice"
                            disabled
                            // onChange={handleProductChange}
                            value={productFormInputs.itemsDiscountPrice ? formatToLocaleCurrency(productFormInputs.itemsDiscountPrice) : 0.0}
                            type="number"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="Item Cost"
                            variant="outlined"
                            size="small"
                            name="itemCost"
                            disabled
                            type="number"
                            value={productFormInputs.itemCost ? formatToLocaleCurrency(Number(productFormInputs.itemCost)) : 0.0}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{ textAlign: "center" }}>
                          <TextField
                            label="Purchased Avg Price"
                            variant="outlined"
                            size="small"
                            name="purchasedAvgPrice"
                            disabled
                            type="number"
                            value={productsAveragePrice ? formatToLocaleCurrency(Number(productsAveragePrice)) : 0.0}
                          />
                        </Grid>
                      </>
                    )}
                  <Grid item xs={12} align="center">
                    <Typography
                      variant="body2"
                      style={{ margin: "0.5rem", textAlign: "center", fontWeight: "bold", verticalAlign: "middle", justifyContent: "center", alignItems: "center" }}
                      color="red"
                    >{maxQuantityAllowedErrorMessage}</Typography>
                  </Grid>
                  {productFormInputs.orderTypeSelected &&
                    productFormInputs.orderTypeSelected !== "" &&
                    productFormInputs.uom &&
                    productFormInputs.uom !== "" &&
                    productFormInputs.quantity &&
                    Number(productFormInputs.quantity) > 0 && (
                      <Grid item xs={12} align="center">
                        <Button
                          size="small"
                          variant="contained"
                          type="submit"
                          color="secondary"
                          justify="center"
                          onClick={handleAddProduct}
                          disabled={validateProductBeforeAdd && maxQuantityAllowedErrorMessage}
                          style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                        >
                          Add to Cart
                        </Button>
                      </Grid>)}
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>)}
        {/** PRODUCT DETAILS  END */}

        {/** PRODUCTS TAX DETAILS START */}
        {selectedProduct && productFormInputs.uom && productFormInputs.uom.length > 0 && (
          <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <Typography
                  variant="h6"
                  style={{ margin: "0.5rem" }}
                  color="black"
                >
                  Products Tax Details
                  <Divider></Divider>
                </Typography>
                <CardBody>
                  <Grid container spacing={2} style={{ justifyContent: "center", textAlign: "center" }}>
                    <Grid item xs={12} sm={8} md={6} style={{ paddingTop: "0px" }}>
                      <Card style={{ marginTop: "10px" }} >
                        <CardBody
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <TextField
                            label="CGST [%]"
                            variant="outlined"
                            size="small"
                            name="cgst"
                            disabled
                            type="number"
                            value={productFormInputs.cgst ? formatToLocaleCurrency(productFormInputs.cgst) : 0.0}
                            style={{ textAlign: "center", margin: "1rem" }}
                          />
                          <TextField
                            label="CGST INR"
                            variant="outlined"
                            size="small"
                            name="cgstPrice"
                            disabled
                            type="number"
                            value={productFormInputs.cgstPrice ? formatToLocaleCurrency(productFormInputs.cgstPrice) : 0.0}
                            style={{ textAlign: "center", margin: "1rem" }}
                          />
                        </CardBody>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} style={{ paddingTop: "0px" }}>
                      <Card style={{ marginTop: "10px" }} >
                        <CardBody
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <TextField
                            label="SGST [%]"
                            variant="outlined"
                            size="small"
                            name="sgst"
                            disabled
                            type="number"
                            value={productFormInputs.sgst ? formatToLocaleCurrency(productFormInputs.sgst) : 0.0}
                            style={{ textAlign: "center", margin: "1rem" }}
                          />
                          <TextField
                            label="SGST INR"
                            variant="outlined"
                            size="small"
                            name="sgstPrice"
                            disabled
                            type="number"
                            value={productFormInputs.sgstPrice ? formatToLocaleCurrency(productFormInputs.sgstPrice) : 0.0}
                            style={{ textAlign: "center", margin: "1rem" }}
                          />
                        </CardBody>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} style={{ paddingTop: "0px" }}>
                      <Card style={{ marginTop: "10px" }} >
                        <CardBody
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <TextField
                            label="IGST [%]"
                            variant="outlined"
                            size="small"
                            name="igst"
                            width="10rem"
                            disabled
                            value={productFormInputs.igst ? formatToLocaleCurrency(productFormInputs.igst) : 0.0}
                            style={{ textAlign: "center", margin: "1rem" }}
                          />
                          <TextField
                            label="IGST INR"
                            variant="outlined"
                            size="small"
                            name="igstPrice"
                            disabled
                            type="number"
                            value={productFormInputs.igstPrice ? formatToLocaleCurrency(productFormInputs.igstPrice) : 0.0}
                            style={{ textAlign: "center", margin: "1rem" }}
                          />
                        </CardBody>
                      </Card>
                    </Grid>
                    <Grid item xs={12} sm={8} md={6} style={{ paddingTop: "0px" }}>
                      <Card style={{ marginTop: "10px" }} >
                        <CardBody
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <TextField
                            label="TCS [%]"
                            variant="outlined"
                            size="small"
                            name="tcs"
                            disabled
                            type="number"
                            value={productFormInputs.tcs ? formatToLocaleCurrency(productFormInputs.tcs) : 0.0}
                            style={{ textAlign: "center", margin: "1rem" }}
                          />
                          <TextField
                            label="TCS INR"
                            variant="outlined"
                            size="small"
                            name="tcsPrice"
                            disabled
                            type="number"
                            value={productFormInputs.tcsPrice ? formatToLocaleCurrency(productFormInputs.tcsPrice) : 0.0}
                            style={{ textAlign: "center", margin: "1rem" }}
                          />
                        </CardBody>
                      </Card>
                    </Grid>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        {/** PRODUCTS TAX DETAILS END */}

        {/** PRODUCTS ADDED SO FAR DETAILS START */}
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            {renderContentUsingAGGrid ? renderContentUsingAGGrid : null}
          </GridItem>
        </GridContainer>

        {/** PRODUCTS ADDED SO FAR DETAILS END */}
        {/** WALKIN SALE PAYMENT START */}
      {/* REFACTOR INsteAD OF productsTobeAddedInInvoice TO rowData */}
        {productsTobeAddedInInvoice && productsTobeAddedInInvoice.length > 0 && (
          <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <WalkinSalePayment
                customerDetails={autoCompleteCustomer}
                storeName={storeName}
                warehouseName={warehouseName}
                invoiceDetails={invoiceFormInputs}
// rowDataCartItems = {rowData}
                productsInCart={productsTobeAddedInInvoice} >

              </WalkinSalePayment>
            </GridItem>
          </GridContainer>
        )}

        {/** WALKIN SALE PAYMENT START */}
      </form>
    </div>
  );
};

export default WalkinSalesInvoiceCreateScreen;
