export const COLD_STORAGE_REGISTER_REQUEST = "COLD_STORAGE_REGISTER_REQUEST";
export const COLD_STORAGE_REGISTER_SUCCESS = "COLD_STORAGE_REGISTER_SUCCESS";
export const COLD_STORAGE_REGISTER_FAIL = "COLD_STORAGE_REGISTER_FAIL";
export const COLD_STORAGE_REGISTER_RESET = "COLD_STORAGE_REGISTER_RESET";

export const COLD_STORAGE_DETAILS_BY_NAME_REQUEST = "COLD_STORAGE_DETAILS_BY_NAME_REQUEST";
export const COLD_STORAGE_DETAILS_BY_NAME_SUCCESS = "COLD_STORAGE_DETAILS_BY_NAME_SUCCESS";
export const COLD_STORAGE_DETAILS_BY_NAME_FAIL = "COLD_STORAGE_DETAILS_BY_NAME_FAIL";
export const COLD_STORAGE_DETAILS_BY_NAME_RESET = "COLD_STORAGE_DETAILS_BY_NAME_RESET";

export const COLD_STORAGE_LIST_REQUEST = "COLD_STORAGE_LIST_REQUEST";
export const COLD_STORAGE_LIST_SUCCESS = "COLD_STORAGE_LIST_SUCCESS";
export const COLD_STORAGE_LIST_FAIL = "COLD_STORAGE_LIST_FAIL";
export const COLD_STORAGE_LIST_RESET = "COLD_STORAGE_LIST_RESET";

export const COLD_STORAGE_DELETE_REQUEST = "COLD_STORAGE_DELETE_REQUEST";
export const COLD_STORAGE_DELETE_SUCCESS = "COLD_STORAGE_DELETE_SUCCESS";
export const COLD_STORAGE_DELETE_FAIL = "COLD_STORAGE_DELETE_FAIL";

export const COLD_STORAGE_UPDATE_REQUEST = "COLD_STORAGE_UPDATE_REQUEST";
export const COLD_STORAGE_UPDATE_SUCCESS = "COLD_STORAGE_UPDATE_SUCCESS";
export const COLD_STORAGE_UPDATE_FAIL = "COLD_STORAGE_UPDATE_FAIL";
export const COLD_STORAGE_UPDATE_RESET = "COLD_STORAGE_UPDATE_RESET";