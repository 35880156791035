import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom";
import { ctcTypes, destinations } from "../../../data/index.js";
import { listVarieties } from "../../../actions/varietyAction.js";
import { each } from "jquery";
import { listAvailableVehicles, listVehiclesAvailability } from "../../../actions/vehicleAction.js";
import { listColdStorages } from "../../../actions/coldStorageAction.js";
import { create, createCtcPurchaseOrder } from "../../../actions/ctcPurchaseOrderAction.js";
import { formatToLocaleCurrency } from "../../../util/Utility.js";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../assets/images/green_tick.png";
import { styled } from '@mui/material/styles';
import { CTC_PURCHASE_ORDER_REGISTER_REQUEST, CTC_PURCHASE_ORDER_REGISTER_RESET } from "../../../constants/ctcPurchaseOrderConstants.js";
import GridContainer from "../../Grid/GridContainer.js";
import { listFactories } from "../../../actions/factoryAction.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const CtcPurchaseOrderCreateForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const varietyList = useSelector((state) => state.varietyList);
  const { ctc_variety_list_loading, ctc_variety_list_success, ctc_variety_list_data } = varietyList;
  const coldStorageList = useSelector((state) => state.coldStorageList);
  const { cold_storage_list_loading, cold_storage_list, cold_storage_list_success, cold_storage_list_error } = coldStorageList;

  const availableVehicleList = useSelector((state) => state.availableVehicleList);
  const { loading_available_vehicles, vehicles_available, loading_available_error } = availableVehicleList;
  const ctcPurchaseOrderRegistered  = useSelector((state) => state.ctcPurchaseOrderRegistered)
  const { ctc_purchase_order_register_success, ctc_purchase_order_register_error } = ctcPurchaseOrderRegistered

  const [filteredVariety, setFilteredVariety] = useState(() => null);
  const [coldStorages, setColdStorages] = useState(() => null);
  const [availableVehicles, setAvailableVehicles] = useState(() => null);
  const { name, storeName, warehouseName } = userInfo;
  const pos = storeName && storeName.length > 0 ? storeName : warehouseName && warehouseName.length > 0 ? warehouseName : ""
  const [ctcPurchaseOrderFormInputs, setCtcPurchaseOrderFormInputs] = useState({
    ctcSupplierName:"",
    ctcSupplierGstNo: "",
    ctcSupplierContactNo:"",
    productName: "",
    variety: "",
    vehicleNo: "",
    bagsReceived: "",
    eachBagWeightInKgs: "",
    pricePerKg: "",
    taxPerKg: "",
    wayBillNo: "",
    totalPrice: "",
    totalTaxPrice: "",
    totalWeightInKgs: "",
    purchaseOrderDate: new Date().toLocaleString(),
    initialTotalWeightInKgs : "",
    destination : "",
    paymentDetails : ""
  });
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    if (ctc_purchase_order_register_success) {
      dispatch({ type: CTC_PURCHASE_ORDER_REGISTER_RESET });
      navigate("/ctc/purchase-orders");
    }
  };

  useEffect(() => {
    console.log('FETCHING VARIETIES')
    dispatch(listVarieties())
    dispatch(listColdStorages())
    dispatch(listFactories())
    dispatch(listAvailableVehicles())
  }, [])

  useEffect(() => {
    if (ctcPurchaseOrderFormInputs.productName && ctcPurchaseOrderFormInputs.productName.length > 0 && ctc_variety_list_data && ctc_variety_list_data.length > 0) {
      console.log('ctcPurchaseOrderFormInputs.productName : ', ctcPurchaseOrderFormInputs.productName, 'ctc_variety_list_data : ', ctc_variety_list_data ? ctc_variety_list_data.length : 0);
      populateVarietiesByProduct(ctcPurchaseOrderFormInputs.productName)
    } else {
      console.log('Not PERFORMING POPULATE VARIETIES ')
    }
  }, [ctcPurchaseOrderFormInputs.productName, ctc_variety_list_data])

  useEffect(() => {
    if (cold_storage_list && cold_storage_list.length > 0) {
      console.log('cold_storage_list : ', cold_storage_list ? cold_storage_list.length : 0);
      populateColdStorages()
    } else {
      console.log('Not PERFORMING POPULATE ColdStorages ')
    }
  }, [cold_storage_list])

  useEffect(() => {
    if (vehicles_available && vehicles_available.length > 0) {
      console.log('vehicles_available: ', vehicles_available ? vehicles_available.length : 0);
      populateAvailableVehicles()
    } else {
      console.log('Not PERFORMING POPULATE VARIETIES ')
    }
  }, [vehicles_available])

  useEffect(() => {
    if (ctc_purchase_order_register_success) {
      console.log('Routing to List Screen as New Bond is Created. !');
      setOpen(() => true);
    }
  }, [ctc_purchase_order_register_success])


  const populateColdStorages = () => {
    setColdStorages((prev) => cold_storage_list.map((eachStorage, idx) => {
      return (
        <MenuItem key={idx} value={eachStorage.name}>
          {eachStorage.name}
        </MenuItem>
      );
    }));
  }

  const populateVarietiesByProduct = (productName) => {
    setFilteredVariety((prev) => ctc_variety_list_data.filter(each => each.ctcProductName === productName).map((eachVariety, idx) => {
      console.log(eachVariety, idx)
      return (
        <MenuItem key={idx} value={eachVariety.variety}>
          {eachVariety.variety}
        </MenuItem>
      );
    }));
  }

  const populateAvailableVehicles = () => {
    setAvailableVehicles((prev) => vehicles_available.map((eachVehicle, idx) => {
      return (
        <MenuItem key={eachVehicle._id} value={eachVehicle.vehicleNo}>
          {eachVehicle.vehicleNo}
        </MenuItem>
      );
    }));
  }
  const handleSubmit = (event) => {
    console.log("Values Submitted are : ", ctcPurchaseOrderFormInputs);
    event.preventDefault();
    dispatch(
      createCtcPurchaseOrder(ctcPurchaseOrderFormInputs)
        // {ctcPurchaseOrderFormInputs.ctcSupplierName,
        // ctcPurchaseOrderFormInputs.ctcSupplierGstNo,
        // ctcPurchaseOrderFormInputs.ctcSupplierContactNo,
        // ctcPurchaseOrderFormInputs.ctcSupplierContactNo,
        // ctcPurchaseOrderFormInputs.coldStorage,
        // ctcPurchaseOrderFormInputs.productName,
        // ctcPurchaseOrderFormInputs.variety,
        // ctcPurchaseOrderFormInputs.bagsReceived,
        // ctcPurchaseOrderFormInputs.vehicleNo,
        // ctcPurchaseOrderFormInputs.eachBagWeightInKgs,
        // ctcPurchaseOrderFormInputs.totalWeightInKgs,
        // ctcPurchaseOrderFormInputs.pricePerKg,
        // ctcPurchaseOrderFormInputs.taxPerKg,
        // ctcPurchaseOrderFormInputs.totalTaxPrice,
        // ctcPurchaseOrderFormInputs.totalPrice,
        // ctcPurchaseOrderFormInputs.initialTotalWeightInKgs,
        // ctcPurchaseOrderFormInputs.purchaseOrderDate,
        // ctcPurchaseOrderFormInputs.destination,
  // })
    );
  };

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "ctc_types":
        return ctcTypes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const renderDestinationOptions = (menu_type) => {
    switch (menu_type) {
      case "destinations":
        return destinations.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const handleCtcChange = (event) => {
    console.log("handleCtcChange is getting Called when advance Paid is Changed")
    setCtcPurchaseOrderFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div >
      {ctc_purchase_order_register_success && (<BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
             <Grid item xs={3} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  CTC Purchase Order is Created
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>)}
      <form method="post" onSubmit={handleSubmit}>

        <Grid container spacing={2} marginTop="auto" >
        <Grid item xs={3} align="center">
            <TextField
              placeholder="Supplier Name"
              label="Supplier Name *"
              variant="outlined"
              size="small"
              name="ctcSupplierName"
              fullWidth
              onChange={(e) => setCtcPurchaseOrderFormInputs((prev) => ({
                ...prev,
                ctcSupplierName: e.target.value,
              }))}
              type="text"
              value={ctcPurchaseOrderFormInputs.ctcSupplierName || ""}
            />
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              placeholder="Supplier Contact"
              label="Supplier Contact *"
              variant="outlined"
              size="small"
              name="ctcSupplierContactNo"
              fullWidth
              onChange={(e) => setCtcPurchaseOrderFormInputs((prev) => ({
                ...prev,
                ctcSupplierContactNo: e.target.value,
              }))}
              type="text"
              value={ctcPurchaseOrderFormInputs.ctcSupplierContactNo || ""}
            />
          </Grid>

          <Grid item xs={3} align="center">
            <TextField
              placeholder="Supplier GST"
              label="Supplier GST *"
              variant="outlined"
              size="small"
              name="ctcSupplierGstNo"
              fullWidth
              onChange={(e) => setCtcPurchaseOrderFormInputs((prev) => ({
                ...prev,
                ctcSupplierGstNo: e.target.value,
              }))}
              type="text"
              value={ctcPurchaseOrderFormInputs.ctcSupplierGstNo || ""}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Destination
              </InputLabel>
              <Select
                label="Destination"
                placeholder="Destination"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="destination"
                value={ctcPurchaseOrderFormInputs.destination || ""}
                onChange={(e) => {
                  console.log('SELECTED COLD STORAGE or FACTORY : ', e.target.value)
                  setCtcPurchaseOrderFormInputs((prev) => ({ ...prev, destination: e.target.value }))
                }
                }>
               {renderDestinationOptions("destinations")}
              </Select>
            </FormControl>
          </Grid>
          { ctcPurchaseOrderFormInputs.destination  && ctcPurchaseOrderFormInputs.destination === 'Cold Storage' && (<Grid item xs={3}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Cold Storage
              </InputLabel>
              <Select
                label="Cold Storage"
                placeholder="Cold Storage"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="coldStorage"
                value={ctcPurchaseOrderFormInputs.coldStorage || ""}
                onChange={(e) => {
                  console.log('SELECTED COLD STORAGE : ', e.target.value)
                  setCtcPurchaseOrderFormInputs((prev) => ({ ...prev, coldStorage: e.target.value }))
                }
                }>
                {coldStorages}
              </Select>
            </FormControl>
          </Grid>)}
          {/* { ctcPurchaseOrderFormInputs.destination  && ctcPurchaseOrderFormInputs.destination === 'Factory' && (<Grid item xs={3}>
            <TextField
              placeholder="Factory"
              label="Bags Received *"
              variant="outlined"
              size="small"
              name="bagsReceived"
              fullWidth
              onChange={handleCtcChange}
              type="number"
              value={ctcPurchaseOrderFormInputs.bagsReceived || ""}
            />
          </Grid>)} */}
           <Grid item xs={3}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Product Name
              </InputLabel>
              <Select
                label="Product Name"
                placeholder="Product Name"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="productName"
                onChange={(e) => {
                  console.log('Product Name : ', e)
                  setCtcPurchaseOrderFormInputs((prev) => ({ ...prev, productName: e.target.value }))
                }
                }
                value={ctcPurchaseOrderFormInputs.productName || ""}
              >
                {renderDynamicOptions("ctc_types")}
              </Select>
            </FormControl>
          </Grid>
           <Grid item xs={3}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Variety
              </InputLabel>
              <Select
                label="Variety"
                placeholder="Variety"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="variety"
                onChange={(e) => setCtcPurchaseOrderFormInputs((prev) => ({ ...prev, variety: e.target.value }))}
                value={ctcPurchaseOrderFormInputs.variety || ""}
              >
                {filteredVariety}
              </Select>
            </FormControl>
          </Grid>
           <Grid item xs={3} align="center">
            <TextField
              placeholder="Bags Received"
              label="Bags Received *"
              variant="outlined"
              size="small"
              name="bagsReceived"
              fullWidth
              onChange={handleCtcChange}
              type="number"
              value={ctcPurchaseOrderFormInputs.bagsReceived || ""}
            />
          </Grid>

           <Grid item xs={3} align="center">
            <TextField
              placeholder="Each Bag Weight In Kgs"
              label="Each Bag Weight In Kgs *"
              variant="outlined"
              size="small"
              name="eachBagWeightInKgs"
              fullWidth
              onChange={(e) => setCtcPurchaseOrderFormInputs((prev) => ({
                ...prev,
                eachBagWeightInKgs: e.target.value,
                totalWeightInKgs: Number(e.target.value) * Number(ctcPurchaseOrderFormInputs.bagsReceived)
              }))}
              type="text"
              value={ctcPurchaseOrderFormInputs.eachBagWeightInKgs || ""}
            />
          </Grid>
           <Grid item xs={3} align="center">
            <TextField
              placeholder="Price per Kg"
              label="Price per Kg *"
              variant="outlined"
              size="small"
              name="pricePerKg"
              fullWidth
              onChange={(e) => setCtcPurchaseOrderFormInputs((prev) => ({
                ...prev,
                pricePerKg: e.target.value,
                totalTaxPrice: Number(e.target.value)  * Number(ctcPurchaseOrderFormInputs.bagsReceived) * Number(ctcPurchaseOrderFormInputs.eachBagWeightInKgs),
                totalPrice: Number(e.target.value) * Number(ctcPurchaseOrderFormInputs.bagsReceived) * Number(ctcPurchaseOrderFormInputs.eachBagWeightInKgs)
              }))}
              type="number"
              value={ctcPurchaseOrderFormInputs.pricePerKg || ""}
            />
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              placeholder="Tax per Kg"
              label="Tax per Kg *"
              variant="outlined"
              size="small"
              name="taxPerKg"
              fullWidth
              onChange={(e) => setCtcPurchaseOrderFormInputs((prev) => ({
                ...prev,
                taxPerKg: e.target.value,
              }))}
              type="number"
              value={ctcPurchaseOrderFormInputs.taxPerKg || ""}
            />
          </Grid>
           <Grid item xs={3}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Vehicle No
              </InputLabel>
              <Select
                label="Vehicle No"
                placeholder="Vehicle No *"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="vehicleNo"
                onChange={handleCtcChange}
                value={ctcPurchaseOrderFormInputs.vehicleNo || ""}
              >
                {availableVehicles}
              </Select>
            </FormControl>
          </Grid>
           <Grid item xs={3} align="center">
            <TextField
              placeholder="Way Bill No"
              label="Way Bill No *"
              variant="outlined"
              size="small"
              name="wayBillNo"
              fullWidth
              onChange={handleCtcChange}
              type="text"
              value={ctcPurchaseOrderFormInputs.wayBillNo || ""}
            />
          </Grid>
           <Grid item xs={3} align="center">
            <InputLabel id="demo-simple-select-label" style={{ textAlign: "left" }}>
              Total Price
            </InputLabel>
            <TextField
              variant="outlined"
              size="small"
              name="totalPrice"
              fullWidth
              disabled
              onChange={handleCtcChange}
              type="text"
              value={ctcPurchaseOrderFormInputs.totalPrice || null}
            />
          </Grid>
           <Grid item xs={3} align="center">
            <InputLabel id="demo-simple-select-label" style={{ textAlign: "left" }}>
              Total Weight In Kgs
            </InputLabel>
            <TextField
              variant="outlined"
              size="small"
              name="totalWeightInKgs"
              fullWidth
              disabled
              type="text"
              value={ctcPurchaseOrderFormInputs.totalWeightInKgs || null}
            />
          </Grid>
           <Grid item xs={3} align="center">
            <TextField
              placeholder="Purchase Order Date"
              label="Purchase Order Date  *"
              variant="outlined"
              size="small"
              name="ctcPurchaseOrderDate"
              disabled
              fullWidth
              type="text"
              value={ctcPurchaseOrderFormInputs.purchaseOrderDate || new Date().toLocaleString()}
            />
          </Grid>
           {/* <Grid item xs={3} align="center">
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                width: "12rem"
              }}
            >
              Save
            </Button>
          </Grid> */}
        </Grid>
        <Grid container spacing={2} marginTop="auto" >
        <Grid item xs={12} align="center" style={{textAlign:"center"}}>
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                width: "12rem",
                textAlign: "center"
              }}
            >
              Save
            </Button>
          </Grid>
          </Grid>

      </form>
    </div>
  );
};

export default CtcPurchaseOrderCreateForm;
