import { createTheme } from "@mui/material/styles";
/**Adding New based styles of MUI */
import colors from "../assets/theme/base/colors";
import breakpoints from "../assets/theme/base/breakpoints";
// import typography from "../assets/theme/base/typography";
import boxShadows from "../assets/theme/base/boxShadows";
import borders from "../assets/theme/base/borders";
import globals from "../assets/theme/base/globals";

// // Material React helper functions
import boxShadow from "../assets/theme/functions/boxShadow";
import hexToRgb from "../assets/theme/functions/hexToRgb";
import linearGradient from "../assets/theme/functions/linearGradient";
import pxToRem from "../assets/theme/functions/pxToRem";
import rgba from "../assets/theme/functions/rgba";
import container from "../assets/theme/components/container";
const nlGreen = "#26A541";
const nlMeroon = "#800000";
const nlGray = "#868686";
const nlBlack = "#000000";
const fkOrange = "#f0c14b";

export default createTheme({
  palette: {
    common: {
      blue: nlGreen,
      orange: nlMeroon,
    },
    primary: {
      main: nlGreen,
    },
    secondary: {
      main: fkOrange,
    },
  },
  layout: {
    contentWidth: 1236,
  },
  typography: {
    tab: {
      fontFamily: 'Montserrat, Arial, sans-serif',

      textTransform: "none",
      fontWeight: 700,
      color: "white",
      fontSize: "1rem",
    },
    estimate: {
      fontFamily: 'Montserrat, Arial, sans-serif',
      fontSize: "1rem",
      textTransform: "none",
      color: "white",
    },
    h2: {
      fontFamily: 'Montserrat, Arial, sans-serif',
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: "2.5rem",
      color: nlGray,
    },
    h3: {
      fontFamily: 'Montserrat, Arial, sans-serif',
      fontSize: "2.0rem",
      color: nlGray,
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h4: {
      fontFamily: 'Montserrat, Arial, sans-serif',
      fontSize: "1.75rem",
      color: nlGray,
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
      fontFamily: 'Montserrat, Arial, sans-serif',
      color: nlGray,
    },
    subtitle1: {
      fontSize: "0.80rem",
      fontWeight: 500,
      color: nlGray,
      fontFamily: 'Montserrat, Arial, sans-serif',
    },
    productTitle: {
      fontSize: "0.80rem",
      fontWeight: 300,
      color: nlGray,
      fontFamily: 'Montserrat, Arial, sans-serif',
    },
    subtitle2: {
      fontSize: "0.85rem",
      fontWeight: 600,
      color: nlBlack,
      fontFamily: "Roboto ,Helvetica,Arial",
    },
    body1: {
      fontSize: "0.85rem",
      color: nlBlack,
      fontWeight: 500,
      fontFamily: 'Montserrat, Arial, sans-serif',
      textTransform: "capitalize",
      textAlign: "left",
    },
    body2: {
      fontSize: "0.80rem",
      color: nlBlack,
      fontWeight: 300,
      fontFamily: 'Montserrat, Arial, sans-serif',
      textTransform: "capitalize",
      textAlign: "left",
    },
    caption: {
      fontSize: "1rem",
      fontWeight: 300,
      color: nlGray,
      fontFamily: 'Montserrat, Arial, sans-serif',
    },
    learnButton: {
      borderColor: nlBlack,
      borderWidth: 2,
      textTransform: "none",
      color: nlGray,
      borderRadius: 50,
      fontFamily: 'Montserrat, Arial, sans-serif',
      fontWeight: "bold",
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        color: "green",
      },
      colorSecondary: {
        "&$checked": {
          color: nlGray,
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: nlBlack,
        fontSize: "1rem",
        fontFamily: 'Montserrat, Arial, sans-serif',
      },
    },
    MuiOutlinedInput: {
      root: {
        width: "auto",
      },
    },
    MuiMenuItem: {
      root: {
        color: nlGray,
        "&$selected": {
          // <-- mixing the two classes
          backgroundColor: "transparent",
        },
      },
    },
    MuiInput: {
      input: {
        "&::placeholder": {
          color: nlGray,
          fontSize: "0.75rem",
          fontWeight: 400,
          fontFamily: 'Montserrat, Arial, sans-serif',
        },
        color: "gray",
        fontSize: "0.85rem",
        fontWeight: 500,
        fontFamily: 'Montserrat, Arial, sans-serif', // if you also want to change the color of the input, this is the prop you'd use
      },
      root: {
        color: nlGray,
        fontWeight: 300,
        fontFamily: 'Montserrat, Arial, sans-serif',
      },
      underline: {
        "&:before": {
          borderBottom: `2px solid ${nlGray}`,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: `2px solid ${nlGray}`,
        },
      },
    },
    MuiTableCell:{
      padding:"5px"
    }
  },
  boxShadows: { ...boxShadows },
  borders: { ...borders },
  functions: {
    boxShadow,
    hexToRgb,
    linearGradient,
    pxToRem,
    rgba,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ...globals,
        ...container,
      },
    },
    // MuiDrawer: { ...sidenav },
    // MuiList: { ...list },
    // MuiListItem: { ...listItem },
    // MuiListItemText: { ...listItemText },
    // MuiCard: { ...card },
    // MuiCardMedia: { ...cardMedia },
    // MuiCardContent: { ...cardContent },
    // MuiButton: { ...button },
    // MuiIconButton: { ...iconButton },
    // MuiInput: { ...input },
    // MuiInputLabel: { ...inputLabel },
    // MuiOutlinedInput: { ...inputOutlined },
    // MuiTextField: { ...textField },
    // MuiMenu: { ...menu },
    // MuiMenuItem: { ...menuItem },
    // MuiSwitch: { ...switchButton },
    // MuiDivider: { ...divider },
    // MuiTableContainer: { ...tableContainer },
    // MuiTableHead: { ...tableHead },
    //  MuiTableCell: { ...tableCell },
    // MuiLinearProgress: { ...linearProgress },
    // MuiBreadcrumbs: { ...breadcrumbs },
    // MuiSlider: { ...slider },
    // MuiAvatar: { ...avatar },
    // MuiTooltip: { ...tooltip },
    // MuiAppBar: { ...appBar },
    // MuiTabs: { ...tabs },
    // MuiTab: { ...tab },
    // MuiStepper: { ...stepper },
    // MuiStep: { ...step },
    // MuiStepConnector: { ...stepConnector },
    // MuiStepLabel: { ...stepLabel },
    // MuiStepIcon: { ...stepIcon },
    // MuiSelect: { ...select },
    // MuiFormControlLabel: { ...formControlLabel },
    // MuiFormLabel: { ...formLabel },
    // MuiCheckbox: { ...checkbox },
    // MuiRadio: { ...radio },
    // MuiAutocomplete: { ...autocomplete },
    // MuiPopover: { ...popover },
    // MuiButtonBase: { ...buttonBase },
    // MuiIcon: { ...icon },
    // MuiSvgIcon: { ...svgIcon },
    // MuiLink: { ...link },
    // MuiDialog: { ...dialog },
    // MuiDialogTitle: { ...dialogTitle },
    // MuiDialogContent: { ...dialogContent },
    // MuiDialogContentText: { ...dialogContentText },
    // MuiDialogActions: { ...dialogActions },
  },
});
