export const RAW_MATERIAL_STOCK_REGISTER_REQUEST = "RAW_MATERIAL_STOCK_REGISTER_REQUEST";
export const RAW_MATERIAL_STOCK_REGISTER_SUCCESS = "RAW_MATERIAL_STOCK_REGISTER_SUCCESS";
export const RAW_MATERIAL_STOCK_REGISTER_FAIL = "RAW_MATERIAL_STOCK_REGISTER_FAIL";
export const RAW_MATERIAL_STOCK_REGISTER_RESET = "RAW_MATERIAL_STOCK_REGISTER_RESET";

export const RAW_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST = "RAW_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST";
export const RAW_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS = "RAW_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS";
export const RAW_MATERIAL_STOCK_DETAILS_BY_ID_FAIL = "RAW_MATERIAL_STOCK_DETAILS_BY_ID_FAIL";
export const RAW_MATERIAL_STOCK_DETAILS_BY_ID_RESET = "RAW_MATERIAL_STOCK_DETAILS_BY_ID_RESET";

export const RAW_MATERIAL_STOCK_LIST_REQUEST = "RAW_MATERIAL_STOCK_LIST_REQUEST";
export const RAW_MATERIAL_STOCK_LIST_SUCCESS = "RAW_MATERIAL_STOCK_LIST_SUCCESS";
export const RAW_MATERIAL_STOCK_LIST_FAIL = "RAW_MATERIAL_STOCK_LIST_FAIL";
export const RAW_MATERIAL_STOCK_LIST_RESET = "RAW_MATERIAL_STOCK_LIST_RESET";

export const RAW_MATERIAL_STOCK_DELETE_REQUEST = "RAW_MATERIAL_STOCK_DELETE_REQUEST";
export const RAW_MATERIAL_STOCK_DELETE_SUCCESS = "RAW_MATERIAL_STOCK_DELETE_SUCCESS";
export const RAW_MATERIAL_STOCK_DELETE_FAIL = "RAW_MATERIAL_STOCK_DELETE_FAIL";
export const RAW_MATERIAL_STOCK_DELETE_RESET = "RAW_MATERIAL_STOCK_DELETE_RESET";

export const RAW_MATERIAL_STOCK_UPDATE_REQUEST = "RAW_MATERIAL_STOCK_UPDATE_REQUEST";
export const RAW_MATERIAL_STOCK_UPDATE_SUCCESS = "RAW_MATERIAL_STOCK_UPDATE_SUCCESS";
export const RAW_MATERIAL_STOCK_UPDATE_FAIL = "RAW_MATERIAL_STOCK_UPDATE_FAIL";
export const RAW_MATERIAL_STOCK_UPDATE_RESET = "RAW_MATERIAL_STOCK_UPDATE_RESET";

export const RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_REQUEST = "RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_REQUEST";
export const RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_SUCCESS = "RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_SUCCESS";
export const RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_FAIL = "RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_FAIL";
export const RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_RESET = "RAW_MATERIAL_STOCK_UPDATE_POST_MANUFACTURING_RESET";