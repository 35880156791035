import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
// import { } from "../../actions/customerAction";
import { useNavigate } from "react-router-dom";
import { Typography,Tooltip,Button } from "@mui/material";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../../Message";
import CustomBackdropSpinner from "../../CustomBackdropSpinner";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { listVarieties } from "../../../actions/varietyAction";

const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const VarietyListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  const gridRef = useRef();
  const [varietiesData, setVarietiesData] = useState(() => []);

  useEffect(() => {
    console.log('Loading Varieties')
    dispatch(listVarieties())
  }, [])

  const varietyList = useSelector(
    (state) => state.varietyList
  );
  const { ctc_variety_list_loading, ctc_variety_list_success, ctc_variety_list_data, ctc_variety_list_error} = varietyList

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const columnDefs = [
    { field: "#" ,
    resizable: true, 
    suppressSizeToFit: true,
    width: 75, 
    minWidth: 75, 
    maxWidth: 75,
    
  },
    { field: "CtcProductName",
    headerName: "CTC Product",
    resizable: true, 
    suppressSizeToFit: true, 
    width: 250,
    minWidth: 250,
    maxWidth: 250,
    filter: true,
    cellStyle : {
      textAlign:"left"
    }
   },
    { field: "Variety",
    headerName: "Variety",
    resizable: true, 
    suppressSizeToFit: true,
    width: 250, 
    minWidth: 250, 
    maxWidth: 250, 
    filter: true,
    cellStyle : {
      textAlign:"left"
    }
  },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  
  const createProductVarietyHandler = ()=>{
    console.log('Routing to New product Variety ')
    navigate(`/ctc/variety/new`);
  }

  const populateDataToGrid = () => {
    console.log('populateDataToGrid ...!')

    if (ctc_variety_list_data && ctc_variety_list_data.length > 0) {
      setVarietiesData((prev) => ctc_variety_list_data.map((varietyObj, index) => {
        console.log("variety: ", varietyObj);
        return {
          "#": index + 1,
          CtcProductName: varietyObj.ctcProductName,
          Variety: varietyObj.variety,
          
        };
      }))
    }
  }

  useEffect(() => {
    if (ctc_variety_list_data && ctc_variety_list_data.length > 0) {
      console.log('POPULATE VARIETY DATA WHEN ITS NOT EMPTY TO AG GRID')
      populateDataToGrid()
    }
  }, [ctc_variety_list_data])

  const renderVarietiesDataInAGGrid = (
    <>
      <div className="ag-theme-quartz" style={{ width: "40%", height: '65vh', marginLeft: 'auto', marginRight: 'auto' }}>
        <AgGridReact
          ref={gridRef}
          rowData={varietiesData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        ></AgGridReact>
      </div>
    </>
  );

  return (
    <React.Fragment>
      {ctc_variety_list_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
              <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                Product Variety
                 <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick = {createProductVarietyHandler}
              style={{ textTransform: "capitalize", borderRadius: "5px" , float:'right', textAlign:'right'}}
            >
              <AddCircleIcon
              fontSize="medium"
              style={{ float: "right", marginRight:"10px" }}
            />
              New Variety
            </Button>
              </Typography>
            </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {renderVarietiesDataInAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default VarietyListPage;
