import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ctcTypes } from "../../../data";
import { register } from "../../../actions/varietyAction";
import { CTC_VARIETY_REGISTER_RESET } from "../../../constants/varietyConstants";
import Message from "../../Message";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const VarietyRegisterForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ctcFormInputs, setCtcFormInputs] = useState({
    productName: "",
    variety: "",
  });

  const varietyRegistered = useSelector((state) => state.varietyRegistered);
  const { ctc_variety_register_success, ctc_variety_register_error, ctc_variety_register_data} = varietyRegistered

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "ctc_types":
        return ctcTypes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const handleCtcChange = (event) => {
    console.log("handleCtcChange is getting Called when advance Paid is Changed")
    setCtcFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(()=>{
    if(ctc_variety_register_success){
      console.log('Registered CTC Variety so Routing to List Page: ')
      dispatch({
        type: CTC_VARIETY_REGISTER_RESET
      });
      navigate('/ctc/variety');
    }
  },[ctc_variety_register_success,navigate, dispatch])

  const handleSubmit = (event) => {
    console.log("Values Submitted are : ", ctcFormInputs);

    event.preventDefault();
      dispatch(
        register(
          ctcFormInputs.productName ? ctcFormInputs.productName :"",
          ctcFormInputs.variety,
        )
      );
  };

 
  return (
    <div className={classes.root}>
      <form method="post" onSubmit={handleSubmit}>

      {ctc_variety_register_error && (
            <Message style={{color:"red", fontWeight:'600'}}>
              {ctc_variety_register_error}
            </Message>
          )}
        <Grid container spacing={2} justifyContent="center" marginTop="auto">
          <Grid item xs={12} align="center">
            <FormControl style={{ justifyContent: "center", textAlign: "center" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                CTC Type
              </InputLabel>
              <Select
                style={{ justifyContent: "center", width: "12rem" }}
                label="CTC Type"
                placeholder="CTC Type"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="productName"
                onChange={handleCtcChange}
                value={ctcFormInputs.productName || ""}
              >
                {renderDynamicOptions("ctc_types")}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} align="center">
            <TextField
              placeholder="Product Variety"
              label="Product Variety*"
              variant="outlined"
              size="small"
              name="variety"
              fullWidth
              onChange={handleCtcChange}
              type="text"
              value={ctcFormInputs.variety || ""}
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              fullWidth
              style={{
                textTransform: "capitalize",

                borderRadius: "5px",
              }}
            >
              Create
            </Button>
          </Grid>
        </Grid>

      </form>
    </div>
  );
};

export default VarietyRegisterForm;
