import React from 'react'
import { Grid, TextField, Tooltip, Typography, Divider, InputLabel } from '@mui/material';
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardHeader from "../../Card/CardHeader";
import { formatDate, formatToLocaleCurrency } from '../../../util/Utility';


const RenderOrderSummary = (props) => {
  const { currentData, storeData } = props;
  console.log('props passed to RenderOrderSummary : ', props)
  // const {
  //   billNo,
  //   advanceAmount,
  //   balanceAmount,
  //   contactNo,
  //   description,
  //   partyName,
  //   paymentType,
  //   purchaseOrderStatus,
  //   stateOfSupply,
  //   totalPoAmount,
  //   totalPoQty,
  //   totalPoTaxAmount } = storeData;

  return (
    <GridItem xs={12} sm={12} md={12}>
      <Card>
        <Typography
          variant="h6"
          style={{ margin: "0.5rem" }}
          color="black"
        >
          Order Summary
          <Divider></Divider>
        </Typography>
        <CardBody>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
              <TextField
                label="Bill No"
                variant="outlined"
                size="small"
                disabled
                InputLabelProps={{ shrink: true }}
                value={''}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
              <TextField
                label="Advance Paid"
                variant="outlined"
                size="small"
                disabled
                InputLabelProps={{ shrink: true }}
                // value={advanceAmount ? formatToLocaleCurrency(Number(advanceAmount)) : 0}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
              <TextField
                label="Balance Amount"
                variant="outlined"
                size="small"
                disabled
                InputLabelProps={{ shrink: true }}
                // value={balanceAmount ? formatToLocaleCurrency(Number(balanceAmount)) : 0}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
              <TextField
                label="Total Amount"
                variant="outlined"
                size="small"
                name="totalPoAmount"
                disabled
                InputLabelProps={{ shrink: true }}
                // value={totalPoAmount ? formatToLocaleCurrency(Number(totalPoAmount)) : 0}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
              <TextField
                label="Total Tax : INR"
                variant="outlined"
                size="small"
                name="totalPoTaxAmount"
                disabled
                InputLabelProps={{ shrink: true }}
                // value={totalPoTaxAmount ? formatToLocaleCurrency(Number(totalPoTaxAmount)) : 0}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
              <TextField
                label="Total Qty"
                variant="outlined"
                size="small"
                name="totalPoQty"
                disabled
                InputLabelProps={{ shrink: true }}
                // value={totalPoQty ? formatToLocaleCurrency(Number(totalPoQty)) : 0}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
              <TextField
                variant="outlined"
                label="Payment Type"
                size="small"
                disabled
                InputLabelProps={{ shrink: true }}
                // value={paymentType || ''}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3} style={{ textAlign: "center" }}>
              <Typography style={{ textAlign: "center", fontWeight: '900', lineHeight: "1.25rem", height: "1.25rem", 
             // color: balanceAmount && balanceAmount > 0 ? 'red' : 'green' 
            }}
              > 
              {/* {balanceAmount && balanceAmount > 0 ? 'DUE' : 'PAID'}  */}
              </Typography>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </GridItem>
  )
}

export default RenderOrderSummary
