import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { listBonds } from "../../../actions/bondAction.js";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button } from "@mui/material";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import Message from "../../Message.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { listCtcPurchaseOrders } from "../../../actions/ctcPurchaseOrderAction.js";
import { listCtcProductions } from "../../../actions/ctcProductionAction.js";

const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const CtcProductionListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const gridRef = useRef();
  const [ctcPurchaseOrdersRowData, setCtcPurchaseOrdersRowData] = useState(null);
  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "ProductName",
      headerName: "ProductName",
      resizable: true,
       suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "Units",
      headerName: "Ctc Supplier Gst No",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "CtcSupplierContactNo",
      headerName: "Ctc Supplier Contact No",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "Variety",
      headerName: "Variety",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "BagsReceived",
      headerName: "Bags Received",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "EachBagWeightInKgs",
      headerName: "Each Bag Weight In Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "PricePerKg",
      headerName: "Price Per Kg",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "TaxPerKg",
      headerName: "Tax Per Kg",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "TotalPrice",
      headerName: "Total Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "TotalTaxPrice",
      headerName: "Total Tax Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "TotalWeightInKgs",
      headerName: "Total Weight In Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "VehicleNo",
      headerName: "VehicleNo",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "WayBillNo",
      headerName: "Way Bill No",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "InitialTotalWeightInKgs",
      headerName: "Initial Total Weight Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "PurchaseOrderDate",
      headerName: "Purchase Order Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "Destination",
      headerName: "Destination",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },

  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const ctcPurchaseOrderList = useSelector((state) => state.ctcPurchaseOrderList)
  const { ctc_production_list_loading, ctc_production_list_data, ctc_production_list_success, ctc_production_list_error } = ctcPurchaseOrderList

  const createCtcProductionHandler = () => {
    console.log('Routing to New CTC Production Entry ')
    navigate(`/ctc/production/new`);
  }
  const populateCtcProductionRowData = () => {
    if (ctc_production_list_data && ctc_production_list_data.length > 0) {
      setCtcPurchaseOrdersRowData(() => ctc_production_list_data.map((eachPo, index) => {
        console.log("eachPo  : ", eachPo);
        return {
          "#": index + 1,
          Id: eachPo._id,
          CtcSupplierName:eachPo.ctcSupplierName,
          CtcSupplierGstNo:eachPo.ctcSupplierGstNo,
          CtcSupplierContactNo:eachPo.ctcSupplierContactNo,
          ColdStorage: eachPo.coldStorage,
          ProductName: eachPo.productName,
          Variety: eachPo.variety,
          VehicleNo: eachPo.vehicleNo,
          BagsReceived: eachPo.bagsReceived,
          EachBagWeightInKgs: eachPo.eachBagWeightInKgs,
          PricePerKg: eachPo.pricePerKg,
          TaxPerKg: eachPo.taxPerKg,
          TotalPrice: eachPo.totalPrice,
          WayBillNo : eachPo.wayBillNo,
          TotalTaxPrice: eachPo.totalTaxPrice,
          Destination: eachPo.destination,
          TotalWeightInKgs: eachPo.totalWeightInKgs,
          InitialTotalWeightInKgs: eachPo.initialTotalWeightInKgs,
          PurchaseOrderDate: eachPo.purchaseOrderDate,
        };
      }))
    }
  }

  useEffect(() => {
    console.log('Loading CtcPurchaseOrders List Page')
    dispatch(listCtcPurchaseOrders())
    dispatch(listCtcProductions())
  }, [])

  useEffect(() => {
    if (ctc_production_list_success && ctc_production_list_data && ctc_production_list_data.length > 0)
      console.log('Populating  Bonds Row Data')
    populateCtcProductionRowData()
  }, [ctc_production_list_data])

  const renderContentUsingAGGrid = (
    <div
      className="ag-theme-quartz"
      style={{ width: "100%", height: "65vh" }}
    >
      <AgGridReact
        // ref={gridRef}
        rowData={ctcPurchaseOrdersRowData && ctcPurchaseOrdersRowData.length > 0 ? ctcPurchaseOrdersRowData : [] }
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowStyle={{ textAlign: "left", alignItems: "center" }}
        suppressExcelExport={false}
        pagination="true"
      ></AgGridReact>
    </div>
  );
  return (
    <React.Fragment>
      {ctc_production_list_error && <Message>{ctc_production_list_error}</Message>}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  CTC Product Production Entry 
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={createCtcProductionHandler}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "right", marginRight: "10px" }}
                    />
                    New
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default CtcProductionListPage;
