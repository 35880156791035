import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Icon,
  Divider
} from "@mui/material";
import rupeeSvgIcon from "../../../assets/images/currency-inr.svg";

import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom";
import { ctcTypes, destinations, product_dimensons, all_products_sku } from "../../../data/index.js";
import { each } from "jquery";
import { listCtcPurchaseOrders } from "../../../actions/ctcPurchaseOrderAction.js";
import { formatToLocaleCurrency } from "../../../util/Utility.js";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../assets/images/green_tick.png";
import { styled } from '@mui/material/styles';
import { CTC_PURCHASE_ORDER_REGISTER_REQUEST, CTC_PRODUCTION_REGISTER_RESET } from "../../../constants/ctcProductionConstants.js";
import GridContainer from "../../Grid/GridContainer.js";
import { listCtcProductions } from "../../../actions/ctcProductionAction.js";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import GridItem from "../../Grid/GridItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const CtcProductionCreateForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const [productDimensions, setProductDimensions] = useState(() => []);
  const [purchaseOrderIds, setPurchaseOrderIds] = useState(null);
  const [ctcProductNames, setCtcProductNames] = useState(() => []);
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(() => null);
  const ctcPurchaseOrderList = useSelector((state) => state.ctcPurchaseOrderList);
  const { ctc_purchase_order_list_success, ctc_purchase_order_list_data } = ctcPurchaseOrderList
  const ctcProductionRegistered = useSelector((state) => state.ctcProductionRegistered);
  const { ctc_production_register_loading, ctc_production_register_success, ctc_production_register_data, ctc_production_register_error } = ctcProductionRegistered


  const populateNetWeightsByProductName = () => {
    if (ctcProductionFormInputs.productName && ctcProductionFormInputs.productName.length > 0) {
      console.log('####### EXE populateNetWeightsByProductName productName : ', ctcProductionFormInputs.productName)
      console.log('####### EXE populateNetWeightsByProductName productName : ')
      setProductDimensions(prev => product_dimensons.filter(pd => ctcProductionFormInputs.productName.includes(pd.productName))[0].dimensions)
    }
  }

  const populateAllProductsNetWeights = () => {
    console.log('####### EXE populateAllProductsNetWeights  ')
    setProductDimensions(prev => all_products_sku.map(sku => sku))
  }

  const filterNetWeights = () => {
    console.log('####### EXE filterNetWeights productDimensions : ', productDimensions, finishedProductEntries, ctcProductionFormInputs.netWeight);
    const filterdPD = productDimensions.filter(eachDimension => eachDimension !== ctcProductionFormInputs.netWeight)
    console.log('filterdPD : ', filterdPD);
    setProductDimensions((prev) => filterdPD)
  }

  const [ctcProductionFormInputs, setCtcProductionFormInputs] = useState({
    productName: "",
    netWeight: "",
    mrp: "",
    unitsQuantity: "",
    productAvgPrice: "",
    batchNo: "",
    mfgDate: "",
    expDate: "",
    ctcPurchaseOrderId: "",
    sku: "",
    packedDate: new Date().toLocaleString(),
  });

  const clearCtcProductionFormInputs = () => {
    console.log('EXEC clearCtcProductionFormInputs')
    setCtcProductionFormInputs({
      // productName: "",
      // netWeight: "",
      mrp: "",
      unitsQuantity: "",
      productAvgPrice: "",
      batchNo: "",
      mfgDate: "",
      expDate: "",
      ctcPurchaseOrderId: "",
      packedDate: new Date().toLocaleString(),
    });
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);
  const [productAlreadyAdded, setProductAlreadyAdded] = React.useState(false);
  const [finishedProductEntries, setFinishedProductEntries] = useState(() => [])

  const populateCtcProductNames = () => {
    setCtcProductNames((prev) => product_dimensons.map((eachPd, idx) => (
      <MenuItem key={eachPd.idx} value={eachPd.productName}>
        {eachPd.productName}
      </MenuItem>
    )
    ));
  }

  const handleClose = () => {
    setOpen(false);
    if (ctc_production_register_success) {
      dispatch({ type: CTC_PRODUCTION_REGISTER_RESET });
      navigate("/ctc/production");
    }
  };

  useEffect(() => {
    dispatch(listCtcPurchaseOrders())
    populateCtcProductNames()
  }, [])


  useEffect(() => {
    if (ctcProductionFormInputs.productName && ctcProductionFormInputs.productName.length > 0) {
      populateNetWeightsByProductName()
    }
  }, [ctcProductionFormInputs.productName])

  useEffect(() => {
    populateAllProductsNetWeights()
  }, [])

  useEffect(() => {
    if (ctcProductionFormInputs.productName && ctcProductionFormInputs.productName.length > 0 && ctc_purchase_order_list_data && ctc_purchase_order_list_data.length > 0) {
      populatePurchaseOrderIds()
    }
  }, [ctcProductionFormInputs.productName])


  const handleSubmit = (event) => {
    dispatch(
      createCtcPurchaseOrder(ctcProductionFormInputs)
    );
  };

  const populatePurchaseOrderIds = () => {
    console.log('Exec populatePurchaseOrderIds for Product Name  ...!', ctcProductionFormInputs.productName)
    const filteredData = ctc_purchase_order_list_data.filter((each) => ctcProductionFormInputs.productName.toLowerCase().includes(each.productName.toLowerCase()))
    setPurchaseOrderIds((prev) => filteredData.map((eachPo, idx) => (
      <MenuItem key={eachPo.idx} value={eachPo._id}>
        {eachPo._id}
      </MenuItem>
    )
    ));
  }

  const handleCtcProductionInputsChange = (event) => {
    console.log("handleCtcProductionInputsChange ")
    setCtcProductionFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const addEntryToFinishedProduct = () => {
    console.log('Exec addEntryToFinishedProduct .!', 'productName : ', ctcProductionFormInputs, 'selectedPurchaseOrder productName ::==>> ', selectedPurchaseOrder && selectedPurchaseOrder.productName ? selectedPurchaseOrder.productName : "")
    setFinishedProductEntries((prev) => ([...prev, {
      productName: selectedPurchaseOrder && selectedPurchaseOrder.productName ? selectedPurchaseOrder.productName : "",
      sku: ctcProductionFormInputs.sku ? ctcProductionFormInputs.sku : "",
      mrp: ctcProductionFormInputs.mrp ? ctcProductionFormInputs.mrp : 0.0,
      unitsQuantity: ctcProductionFormInputs.unitsQuantity ? ctcProductionFormInputs.unitsQuantity : 0.0,
      productAvgPrice: ctcProductionFormInputs.productAvgPrice ? ctcProductionFormInputs.productAvgPrice : 0.0,
      batchNo: ctcProductionFormInputs.batchNo ? ctcProductionFormInputs.batchNo : "",
      mfgDate: ctcProductionFormInputs.mfgDate ? ctcProductionFormInputs.mfgDate : 0.0,
      expDate: ctcProductionFormInputs.expDate ? ctcProductionFormInputs.expDate : 0.0,
      ctcPurchaseOrderId: ctcProductionFormInputs.ctcPurchaseOrderId ? ctcProductionFormInputs.ctcPurchaseOrderId : 0.0,
      packedDate: new Date().toLocaleString(),
    }]))
    // filterNetWeights()
    clearCtcProductionFormInputs()
    console.log(" $$$$$$$$$$$ Remove the Added Net Weight Entry from Drop Down $$$$$$$$$$$ ")
  }

  const productDetailsContent = (
    <>
      <Grid container spacing={2} marginTop="auto" >
        {/* <Grid item xs={3} align="center">
          <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
            <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
              Net Weight
            </InputLabel>
            <Select
              label="Net Weight"
              placeholder="Net Weight *"
              variant="outlined"
              required
              size="small"
              fullWidth
              name="Net Weight"
              onChange={(e) => {
                console.log('Net Weight : ', e)
                setCtcProductionFormInputs((prevState) => ({
                  ...prevState,
                  netWeight: e.target.value,
                }));
              }}
              value={ctcProductionFormInputs.netWeight || ""}
            >
              {productDimensions && productDimensions .length >0 && productDimensions.map((each, idx) => (<MenuItem key={idx} value={each}> {each} </MenuItem>))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid item xs={3} align="center">
          <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
            <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
              SKU
            </InputLabel>
            <Select
              label="SKU"
              placeholder="SKU *"
              variant="outlined"
              required
              size="small"
              fullWidth
              name="sku"
              onChange={(e) => {
                console.log('SKU : ', e)
                setCtcProductionFormInputs((prevState) => ({
                  ...prevState,
                  sku: e.target.value,
                }));
              }}
              value={ctcProductionFormInputs.sku || ""}
            >
              {productDimensions && productDimensions.length > 0 && productDimensions.map((each, idx) => (<MenuItem key={idx} value={each}> {each} </MenuItem>))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} align="center">
          <TextField
            placeholder="MRP"
            label="MRP *"
            variant="outlined"
            size="small"
            name="mrp"
            fullWidth
            onChange={(e) => setCtcProductionFormInputs((prev) => ({
              ...prev,
              mrp: e.target.value,
            }))}
            type="number"
            value={ctcProductionFormInputs.mrp || ""}
          />
        </Grid>
        <Grid item xs={3} align="center">
          <TextField
            placeholder="Units Quantity"
            label="Units Quantity *"
            variant="outlined"
            size="small"
            name="unitsQuantity"
            fullWidth
            onChange={handleCtcProductionInputsChange}
            type="number"
            value={ctcProductionFormInputs.unitsQuantity || ""}
          />
        </Grid>

        <Grid item xs={3} align="center">
          <TextField
            placeholder="productAvgPrice"
            label="productAvgPrice *"
            variant="outlined"
            size="small"
            name="productAvgPrice"
            fullWidth
            onChange={(e) => setCtcProductionFormInputs((prev) => ({
              ...prev,
              productAvgPrice: e.target.value,
            }))}
            type="text"
            value={ctcProductionFormInputs.productAvgPrice || ""}
          />
        </Grid>
        <Grid item xs={3} align="center">
          <TextField
            placeholder="Batch No"
            label="Batch No *"
            variant="outlined"
            size="small"
            name="batchNo"
            fullWidth
            onChange={(e) => setCtcProductionFormInputs((prev) => ({
              ...prev,
              batchNo: e.target.value,
            }))}
            type="text"
            value={ctcProductionFormInputs.batchNo || ""}
          />
        </Grid>
        <Grid item xs={3} align="center">
          <TextField
            placeholder="Mfg Date"
            label="Mfg Date *"
            variant="outlined"
            size="small"
            name="mfgDate"
            fullWidth
            onChange={(e) => setCtcProductionFormInputs((prev) => ({
              ...prev,
              mfgDate: e.target.value,
            }))}
            type="text"
            value={ctcProductionFormInputs.mfgDate || ""}
          />
        </Grid>
        <Grid item xs={3} align="center">
          <TextField
            placeholder="Exp Date"
            label="Exp Date *"
            variant="outlined"
            size="small"
            name="expDate"
            fullWidth
            onChange={(e) => setCtcProductionFormInputs((prev) => ({
              ...prev,
              expDate: e.target.value,
            }))}
            type="text"
            value={ctcProductionFormInputs.expDate || ""}
          />
        </Grid>
        <Grid item xs={3} align="center">
          <TextField
            placeholder="Packed Date"
            label="Packed Date *"
            variant="outlined"
            size="small"
            name="packedDate"
            fullWidth
            onChange={handleCtcProductionInputsChange}
            type="text"
            value={ctcProductionFormInputs.packedDate || ""}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} marginTop="auto" >
        <Grid item xs={12} align="center" style={{ textAlign: "right" }}>
          <Button
            size="small"
            variant="contained"
            type="submit"
            color="secondary"
            justify="center"
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              // width: "12rem",
              textAlign: "right"
            }}
            onClick={addEntryToFinishedProduct}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
    </>
  )

  const renderFinishedProductEntriesUsingCards = (
    <>
      {finishedProductEntries && finishedProductEntries.length > 0 && finishedProductEntries.map((finishedProductEntry) => (
        <>
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ backgroundColor: "#F2F2F2", margin: "0.5rem", padding :"0px" }}>
              <CardBody style={{ backgroundColor: "#F2F2F2", margin: "0.5rem", padding :"0px" }}>
                <GridContainer spacing={2} alignItems="center" justify="center">
                  <GridItem xs={8} >
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '600', fontSize: "1.25rem", textAlign: "left" }}
                      color="black"
                    >{finishedProductEntry.sku ? finishedProductEntry.sku : ""}
                    </Typography>
                  </GridItem>
                  {/* <Divider style={{ margin: "0.25rem" }} /> */}
                  <GridItem xs={4} >
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '500', fontSize: "0.85rem", alignItems: "left", justifyContent: "left", textAlign: "left" }}
                      color="black"
                    >M.R.P :
                      <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {finishedProductEntry.mrp ? finishedProductEntry.mrp : 0.0}
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} >
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '500', fontSize: "0.85rem", alignItems: "left"}}
                      color="black"
                    > Units / Quantity :
                      <span style={{ marginLeft: "0.5rem" }}>{finishedProductEntry.unitsQuantity ? finishedProductEntry.unitsQuantity : 0.0}</span>
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} >
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '500', fontSize: "0.85rem", alignItems: "left" }}
                      color="black"
                    > Batch No :
                      <span style={{ marginLeft: "0.5rem" }}>{finishedProductEntry.batchNo ? finishedProductEntry.batchNo : 0.0}</span>
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} >
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '500', fontSize: "0.85rem", alignItems: "left" }}
                      color="black"
                    > Mfg Date :
                      <span style={{ marginLeft: "0.5rem" }}>{finishedProductEntry.mfgDate ? finishedProductEntry.mfgDate : 0.0}</span>
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} >
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '500', fontSize: "0.85rem", alignItems: "left"}}
                      color="black"
                    > Exp Date :
                      <span style={{ marginLeft: "0.5rem" }}>{finishedProductEntry.expDate ? finishedProductEntry.expDate : 0.0}</span>
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} >
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '500', fontSize: "0.85rem", alignItems: "left"}}
                      color="black"
                    > Packaged Date :
                      <span style={{ marginLeft: "0.5rem" }}>{finishedProductEntry.packedDate ? finishedProductEntry.packedDate : 0.0}</span>
                    </Typography>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </>
      )
      )
      }
      <GridItem xs={12} sm={12} md={12}>
        <Button
          size="small"
          variant="contained"
          type="submit"
          color="secondary"
          justify="center"
          style={{
            textTransform: "capitalize",
            borderRadius: "5px",
            textAlign: "right"
          }}
          onClick={() => (console.log('Clicked Save'))}
        >
          Save Finished Goods
        </Button>
      </GridItem>
    </>
  )

  useEffect(() => {
    if (ctc_production_register_success) {
      console.log('Routing to List Screen as New Bond is Created. !');
      setOpen(() => true);
    }
  }, [ctc_production_register_success])

  return (
    <div >
      {ctc_production_register_success && (<BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <Grid item xs={3} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  CTC Purchase Order is Created
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>)}
      {/* <form method="post" onSubmit={handleSubmit}> */}
      {/* {purchaseOrderContent} */}
      {productDetailsContent}
      {finishedProductEntries && finishedProductEntries.length > 0 ? renderFinishedProductEntriesUsingCards : null}
      {/* </form> */}
    </div>
  );
};

export default CtcProductionCreateForm;
