import axios from "axios";
import {
  CTC_PURCHASE_ORDER_DETAILS_BY_ID_FAIL,
  CTC_PURCHASE_ORDER_DETAILS_BY_ID_REQUEST,
  CTC_PURCHASE_ORDER_DETAILS_BY_ID_SUCCESS,
  CTC_PURCHASE_ORDER_DETAILS_BY_ID_RESET,

  CTC_PURCHASE_ORDER_REGISTER_FAIL,
  CTC_PURCHASE_ORDER_REGISTER_REQUEST,
  CTC_PURCHASE_ORDER_REGISTER_SUCCESS,
  CTC_PURCHASE_ORDER_REGISTER_RESET,
 
  CTC_PURCHASE_ORDER_LIST_FAIL,
  CTC_PURCHASE_ORDER_LIST_SUCCESS,
  CTC_PURCHASE_ORDER_LIST_REQUEST,
  CTC_PURCHASE_ORDER_LIST_RESET,

  CTC_PURCHASE_ORDER_DELETE_REQUEST,
  CTC_PURCHASE_ORDER_DELETE_SUCCESS,
  CTC_PURCHASE_ORDER_DELETE_FAIL,
  CTC_PURCHASE_ORDER_DELETE_RESET,

  CTC_PURCHASE_ORDER_UPDATE_FAIL,
  CTC_PURCHASE_ORDER_UPDATE_SUCCESS,
  CTC_PURCHASE_ORDER_UPDATE_REQUEST,
  CTC_PURCHASE_ORDER_UPDATE_RESET,

} from "../constants/ctcPurchaseOrderConstants.js";


export const createCtcPurchaseOrder =
  ( 
    ctcPurchaseOrderFormInputs
  ) =>
  async (dispatch) => {
    console.log('EXEC Purchase Order Register : ctcPurchaseOrderFormInputs ', ctcPurchaseOrderFormInputs)
    try {
      dispatch({
        type: CTC_PURCHASE_ORDER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { ctcSupplierName, ctcSupplierGstNo, ctcSupplierContactNo,
        productName, variety, bagsReceived, eachBagWeightInKgs, totalWeightInKgs,
        pricePerKg, taxPerKg, totalPrice, totalTaxPrice, vehicleNo, wayBillNo,
        initialTotalWeightInKgs, currentTotalWeightInKgs, purchaseOrderDate,
        taxPrice, destination
      } = ctcPurchaseOrderFormInputs
      console.log("Before Perfoming API Call ctcSupplierName : ", ctcSupplierName, ",  destination : ", destination);
      const { data } = await axios.post(
        "/api/ctc-purchase-order",
        { ctcSupplierName, ctcSupplierGstNo, ctcSupplierContactNo,
          productName, variety, bagsReceived, eachBagWeightInKgs, totalWeightInKgs,
          pricePerKg, taxPerKg, totalPrice, totalTaxPrice, vehicleNo, wayBillNo,
          initialTotalWeightInKgs, currentTotalWeightInKgs, purchaseOrderDate,
          taxPrice, destination
        },
        config
      );

      dispatch({
        type: CTC_PURCHASE_ORDER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CTC_PURCHASE_ORDER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getCtcPurchaseOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CTC_PURCHASE_ORDER_DETAILS_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/ctc-purchase-order/${id}`);

    dispatch({
      type: CTC_PURCHASE_ORDER_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_PURCHASE_ORDER_DETAILS_BY_ID_FAIL,
      payload: message,
    });
  }
};

export const listCtcPurchaseOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CTC_PURCHASE_ORDER_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/ctc-purchase-order`);

    dispatch({
      type: CTC_PURCHASE_ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_PURCHASE_ORDER_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteCtcPurchaseOrder = (id) => async (dispatch, getState) => {
  console.log("deletePurchase Order ", id);
  try {
    dispatch({
      type: CTC_PURCHASE_ORDER_DELETE_REQUEST,
    });

    await axios.delete(`/api/ctc-purchase-order/${id}`);

    dispatch({ type: CTC_PURCHASE_ORDER_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_PURCHASE_ORDER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateCtcPurchaseOrder = (coldStorage, productName, variety, bagsReceived ,vehicleNo, wayBillNo, eachBagWeightInKgs, totalWeightInKgs, pricePerKg, totalPrice, bondDate) => async (dispatch, getState) => {
  console.log('updatePurchase Order : ',coldStorage, productName, variety, bagsReceived ,vehicleNo, wayBillNo, eachBagWeightInKgs, totalWeightInKgs, pricePerKg, totalPrice, bondDate)
  try {
    dispatch({
      type: CTC_PURCHASE_ORDER_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/ctc-purchase-order/${id}`, {
      coldStorage, productName, variety, bagsReceived ,vehicleNo, wayBillNo, eachBagWeightInKgs, totalWeightInKgs, pricePerKg, totalPrice, bondDate
    }, {});

    dispatch({ type: CTC_PURCHASE_ORDER_UPDATE_SUCCESS , payload: data });

    dispatch({ type: CTC_PURCHASE_ORDER_UPDATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_PURCHASE_ORDER_UPDATE_FAIL,
      payload: message,
    });
  }
};
