// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-theme-quartz .ag-row:nth-child(odd) {
  background-color: #D3D3D3;
}
.ag-theme-quartz .ag-row:nth-child(even) {
  background-color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/CTC/Factory/AGGridStyle.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B","sourcesContent":[".ag-theme-quartz .ag-row:nth-child(odd) {\n  background-color: #D3D3D3;\n}\n.ag-theme-quartz .ag-row:nth-child(even) {\n  background-color: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
