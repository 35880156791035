import axios from "axios";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import {
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  ORDER_DETAILS_SUCCESS,
  ORDER_DETAILS_REQUEST,
  ORDER_PAY_FAIL,
  ORDER_PAY_SUCCESS,
  ORDER_PAY_REQUEST,
  LIST_MY_ORDERS_REQUEST,
  LIST_MY_ORDERS_SUCCESS,
  LIST_MY_ORDERS_FAIL,
  ORDER_LIST_FAIL,
  ORDER_LIST_SUCCESS,
  ORDER_LIST_REQUEST,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_SUCCESS,
  ORDER_DELIVER_REQUEST,
  ORDER_DETAILS_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_LIST_BY_STORE_REQUEST,
  ORDER_LIST_BY_STORE_FAIL,
  ORDER_LIST_BY_STORE_SUCCESS,
  ORDER_UPDATE_PAYMENT_DETAILS_SUCCESS,
  ORDER_UPDATE_PAYMENT_DETAILS_FAIL,
  ORDER_INVOICE_GENERATE_EMAIL_RESET,
  ORDER_INVOICE_GENERATE_EMAIL_REQUEST,
  ORDER_INVOICE_GENERATE_EMAIL_SUCCESS,
  ORDER_INVOICE_GENERATE_EMAIL_FAIL,
  ORDER_TRANSFER_REQUEST,
  ORDER_TRANSFER_SUCCESS,
  ORDER_TRANSFER_FAIL,
  ORDER_TRANSFER_RESET,
  ORDER_LIST_BY_MAPPED_STORE_REQUEST,
  ORDER_LIST_BY_MAPPED_STORE_SUCCESS,
  ORDER_LIST_BY_MAPPED_STORE_FAIL,
  ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_REQUEST,
  ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_SUCCESS,
  ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_FAIL,
  ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_RESET,
  ALL_ORDERS_LIST_REQUEST,
  ALL_ORDERS_LIST_SUCCESS,
  ALL_ORDERS_LIST_FAIL,
} from "../constants/orderConstants";

import { logout } from "./userAction";

export const createOrder = (order) => async (dispatch, getState) => {
  console.log("Inside craeteOrder ...", order);
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    // console.log("userInfo: ", userInfo);
    console.log("userInfo[0]._id : ", userInfo._id);
    const { data } = await axios.post(`/api/orders`, { order, userInfo: userInfo._id ? userInfo._id : null }, config);

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    });
    localStorage.removeItem("cartItems");
    localStorage.removeItem("paymentMethod");
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateOrder =
  (order, id, orderStatus) => async (dispatch, getState) => {
    console.log("Inside updateOrder ...", id, orderStatus);
    try {
      dispatch({
        type: ORDER_UPDATE_REQUEST,
      });

      const { data } = await axios.put(
        `/api/orders/${id}/${orderStatus}`,
        order
      );
      // const { data } = await axios.put(`/api/orders`, order);

      dispatch({
        type: ORDER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: ORDER_UPDATE_FAIL,
        payload: message,
      });
    }
  };

export const transferOrder =
  (id, initStore, transferringStore) => async (dispatch, getState) => {
    console.log("Inside transferOrder ...", id, initStore, transferringStore);
    try {

      dispatch({
        type: ORDER_TRANSFER_REQUEST,
      });
      const { data } = await axios.put(
        `/api/orders/transfer-order`,
        { id, initStore, transferringStore }
      );
      dispatch({
        type: ORDER_TRANSFER_SUCCESS,
        payload: data,
      });

    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: ORDER_TRANSFER_FAIL,
        payload: message,
      });
    }
  };

export const getOrderDetailsById = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/orders/${id}`, config);

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const payOrder =
  (orderId, paymentResult) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_PAY_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/orders/${orderId}/pay`,
        paymentResult,
        config
      );

      dispatch({
        type: ORDER_PAY_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (message === "Not authorized, token failed") {
        dispatch(logout());
      }
      dispatch({
        type: ORDER_PAY_FAIL,
        payload: message,
      });
    }
  };

export const deliverOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DELIVER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/orders/${order._id}/deliver`,
      {},
      config
    );

    dispatch({
      type: ORDER_DELIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DELIVER_FAIL,
      payload: message,
    });
  }
};

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LIST_MY_ORDERS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/orders/myorders`, config);

    dispatch({
      type: LIST_MY_ORDERS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: LIST_MY_ORDERS_FAIL,
      payload: message,
    });
  }
};

export const listOrdersByStore = (storeId) => async (dispatch, getState) => {
  console.log("Listing Orders By Store ", storeId);
  try {
    dispatch({
      type: ORDER_LIST_BY_STORE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/orders/store/${storeId}`, config);

    dispatch({
      type: ORDER_LIST_BY_STORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_LIST_BY_STORE_FAIL,
      payload: message,
    });
  }
};

export const listOrdersByMappedStore = (pos) => async (dispatch, getState) => {
  console.log("Listing Orders By Mapped Store ", pos);
  try {
    dispatch({
      type: ORDER_LIST_BY_MAPPED_STORE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/orders/mapped-store/${pos}`, config);

    dispatch({
      type: ORDER_LIST_BY_MAPPED_STORE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_LIST_BY_MAPPED_STORE_FAIL,
      payload: message,
    });
  }
};

export const listAllOrders = () => async (dispatch, getState) => {
  console.log("List All Orders..!");
  try {
    dispatch({
      type: ALL_ORDERS_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.get(`/api/orders`, config);

    dispatch({
      type: ALL_ORDERS_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ALL_ORDERS_LIST_FAIL,
      payload: message,
    });
  }
};

export const listAllOrdersByPos = (pos) => async (dispatch, getState) => {
  console.log("List All Orders by POS : ", pos);
  try {
    dispatch({
      type: ORDER_LIST_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/orders `, config);

    dispatch({
      type: ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_LIST_FAIL,
      payload: message,
    });
  }
};

export const cancelOrder = (id) => async (dispatch, getState) => {
  console.log("Inside cancelOrder ...", id);
  try {
    dispatch({
      type: ORDER_DELETE_REQUEST,
    });

    const { data } = await axios.put(`/api/orders/${id}/cancel`);

    dispatch({
      type: ORDER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: ORDER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateOrderPaymentDetailsById =
  (orderPayDetails) =>
    async (dispatch, getState) => {
      const {id, paymntDetails} = orderPayDetails
      console.log(
        "Inside updateOrderPaymentDetailsById ...",
        paymntDetails
      );
      try {
        dispatch({
          type: ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_REQUEST,
        });

        const { data } = await axios.put(`/api/orders/${id}/paymentdetails`, {
          paymntDetails
        });

        dispatch({
          type: ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        if (message === "Not authorized, token failed") {
          dispatch(logout());
        }
        dispatch({
          type: ORDER_UPDATE_PAYMENT_DETAILS_BY_ID_FAIL,
          payload: message,
        });
      }
    };

export const generateInvoiceEmail =
  (order, customerName, adminEmail, storeManagerEmail, customerEmail) =>
    async (dispatch, getState) => {
      console.log(" Inside generateInvoiceEmail ACTION...order : ", order, "customerName", customerName, "adminEmail : ", adminEmail,
        "storeManagerEmail : ", storeManagerEmail, "customerEmail : ", customerEmail);
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        dispatch({
          type: ORDER_INVOICE_GENERATE_EMAIL_REQUEST,
        });

        const { data } = await axios.post(
          `/api/orders/invoice/email`,
          { order, customerName, adminEmail, storeManagerEmail, customerEmail },
          config
        );

        dispatch({
          type: ORDER_INVOICE_GENERATE_EMAIL_SUCCESS,
          payload: data,
        });
      } catch (error) {
        const message =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        dispatch({
          type: ORDER_INVOICE_GENERATE_EMAIL_FAIL,
          payload: message,
        });
      }
    };
