export const POWDER_MATERIAL_STOCK_REGISTER_REQUEST = "POWDER_MATERIAL_STOCK_REGISTER_REQUEST";
export const POWDER_MATERIAL_STOCK_REGISTER_SUCCESS = "POWDER_MATERIAL_STOCK_REGISTER_SUCCESS";
export const POWDER_MATERIAL_STOCK_REGISTER_FAIL = "POWDER_MATERIAL_STOCK_REGISTER_FAIL";
export const POWDER_MATERIAL_STOCK_REGISTER_RESET = "POWDER_MATERIAL_STOCK_REGISTER_RESET";

export const POWDER_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST = "POWDER_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST";
export const POWDER_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS = "POWDER_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS";
export const POWDER_MATERIAL_STOCK_DETAILS_BY_ID_FAIL = "POWDER_MATERIAL_STOCK_DETAILS_BY_ID_FAIL";
export const POWDER_MATERIAL_STOCK_DETAILS_BY_ID_RESET = "POWDER_MATERIAL_STOCK_DETAILS_BY_ID_RESET";

export const POWDER_MATERIAL_STOCK_LIST_REQUEST = "POWDER_MATERIAL_STOCK_LIST_REQUEST";
export const POWDER_MATERIAL_STOCK_LIST_SUCCESS = "POWDER_MATERIAL_STOCK_LIST_SUCCESS";
export const POWDER_MATERIAL_STOCK_LIST_FAIL = "POWDER_MATERIAL_STOCK_LIST_FAIL";
export const POWDER_MATERIAL_STOCK_LIST_RESET = "POWDER_MATERIAL_STOCK_LIST_RESET";

export const POWDER_MATERIAL_STOCK_DELETE_REQUEST = "POWDER_MATERIAL_STOCK_DELETE_REQUEST";
export const POWDER_MATERIAL_STOCK_DELETE_SUCCESS = "POWDER_MATERIAL_STOCK_DELETE_SUCCESS";
export const POWDER_MATERIAL_STOCK_DELETE_FAIL = "POWDER_MATERIAL_STOCK_DELETE_FAIL";
export const POWDER_MATERIAL_STOCK_DELETE_RESET = "POWDER_MATERIAL_STOCK_DELETE_RESET";

export const POWDER_MATERIAL_STOCK_UPDATE_REQUEST = "POWDER_MATERIAL_STOCK_UPDATE_REQUEST";
export const POWDER_MATERIAL_STOCK_UPDATE_SUCCESS = "POWDER_MATERIAL_STOCK_UPDATE_SUCCESS";
export const POWDER_MATERIAL_STOCK_UPDATE_FAIL = "POWDER_MATERIAL_STOCK_UPDATE_FAIL";
export const POWDER_MATERIAL_STOCK_UPDATE_RESET = "POWDER_MATERIAL_STOCK_UPDATE_RESET";