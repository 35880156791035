import {
  CTC_FETCH_BATCH_NO_REQUEST,
  CTC_FETCH_BATCH_NO_SUCCESS,
  CTC_FETCH_BATCH_NO_FAIL,
  CTC_FETCH_BATCH_NO_RESET,
} from "../constants/ctcBatchNoConstants.js";


export const ctcFetchBatchNoReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_FETCH_BATCH_NO_REQUEST:
      return { ...state, ctc_batch_no_loading: true };
    case CTC_FETCH_BATCH_NO_SUCCESS :
      return { ctc_batch_no_loading: false, ctc_batch_no_data : action.payload };
    case CTC_FETCH_BATCH_NO_FAIL:
      return { ctc_batch_no_loading: false, ctc_batch_no_error: action.payload };
    case CTC_FETCH_BATCH_NO_RESET:
      return {  };
    default:
      return state;
  }
};

