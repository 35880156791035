import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { listBonds } from "../../../actions/bondAction";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button } from "@mui/material";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import Message from "../../Message.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const BondsListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const gridRef = useRef();
  const [bondsRowData, setBondsRowData] = useState(null);
  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,

    },
    {
      field: "Id",
      headerName: "Bold Id",
      resizable: true,
       suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "ColdStorage",
      headerName: "Cold Storage",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true,
    },
    {
      field: "Variety",
      headerName: "Variety",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "VehicleNo",
      headerName: "VehicleNo",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "BagsReceived",
      headerName: "Bags Received",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "EachBagWeightInKgs",
      headerName: "Each Bag Weight In Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "PricePerKg",
      headerName: "Price Per Kg",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "TotalPrice",
      headerName: "Total Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "TotalWeightInKgs",
      headerName: "Total Weight In Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "BondDate",
      headerName: "Bond Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },

  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const bondList = useSelector((state) => state.bondList)
  const { ctc_bond_list_loading, ctc_bond_list_data, ctc_bond_list_success, ctc_bond_list_error } = bondList

  const createBondHandler = () => {
    console.log('Routing to New Cold Storage Register ')
    navigate(`/ctc/bonds/new`);
  }
  const populateBondsRowData = () => {
    if (ctc_bond_list_data && ctc_bond_list_data.length > 0) {
      setBondsRowData(() => ctc_bond_list_data.map((eachBond, index) => {
        console.log("eachBond  : ", eachBond);
        return {
          "#": index + 1,
          Id: eachBond._id,
          ColdStorage: eachBond.coldStorage,
          ProductName: eachBond.productName,
          Variety: eachBond.variety,
          VehicleNo: eachBond.vehicleNo,
          BagsReceived: eachBond.bagsReceived,
          EachBagWeightInKgs: eachBond.eachBagWeightInKgs,
          PricePerKg: eachBond.pricePerKg,
          TotalPrice: eachBond.totalPrice,
          TotalWeightInKgs: eachBond.totalWeightInKgs,
          BondDate : eachBond.bondDate
        };
      }))
    }
  }

  useEffect(() => {
    console.log('Loading Cold Storages List Page')
    dispatch(listBonds())
  }, [])

  useEffect(() => {
    if (ctc_bond_list_success && ctc_bond_list_data && ctc_bond_list_data.length > 0)
      console.log('Populating  Bonds Row Data')
    populateBondsRowData()
  }, [ctc_bond_list_data])

  const renderContentUsingAGGrid = (
    <div
      className="ag-theme-quartz"
      style={{ width: "100%", height: "65vh" }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={bondsRowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowStyle={{ textAlign: "left", alignItems: "center" }}
        suppressExcelExport={false}
        pagination="true"
      ></AgGridReact>
    </div>
  );
  return (
    <React.Fragment>
      {/* {ctc_bond_list_loading && <CustomBackdropSpinner />} */}
      {ctc_bond_list_error && <Message>{ctc_bond_list_error}</Message>}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  CTC Bonds
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={createBondHandler}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "right", marginRight: "10px" }}
                    />
                    New Bond
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default BondsListPage;
