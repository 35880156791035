import axios from "axios";
import {
  CTC_FETCH_BATCH_NO_REQUEST,
  CTC_FETCH_BATCH_NO_SUCCESS,
  CTC_FETCH_BATCH_NO_DATA,
  CTC_FETCH_BATCH_NO_FAIL

} from "../constants/ctcBatchNoConstants.js";


export const fetchCtcBatchNo = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CTC_FETCH_BATCH_NO_REQUEST,
    });
    const { data } = await axios.get(`/api/ctc-batch-no`);
    dispatch({
      type: CTC_FETCH_BATCH_NO_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_FETCH_BATCH_NO_FAIL,
      payload: message,
    });
  }
};