import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardBody from "../../components/Card/CardBody.js";
import DialogContent from "@mui/material/DialogContent";
import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import {
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  Dialog,
  FormControl,
  Tooltip,
  Divider,
  Paper,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import { listCategories } from "../../actions/categoryAction";
import {
  listSubCategoriesByCategoryId,
  updateSubCategory,
  deleteSubCategory,
} from "../../actions/subCategoryAction";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import {
  SUB_CATEGORY_CREATE_BY_CATEGORY_ID_RESET,
  SUB_CATEGORY_DELETE_RESET,
} from "../../constants/subCategoryConstants.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },

  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const SubCategoryListScreen = (props) => {
  const useStyles = makeStyles(styles);
  const navigate = useNavigate();
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const [openSnackBar, setOpenSnackBar] = React.useState(true);

  const handleClickSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") return;
    setOpenSnackBar(false);
  };
  const vertical = "top";
  const horizontal = "center";
  let renderContentUsingAGGrid = "";
  const gridRef = useRef();
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Name",
      resizable: true,
      suppressSizeToFit: true,
      headerName: "Name",
      width: 250,
      minWidth: 250,
      maxWidth: 250
    },
    {
      field: "Description",
      resizable: true,
      suppressSizeToFit: true,
      headerName: "Description",
      //  width: 125,
      //  minWidth: 125,
      //  maxWidth: 125
    },
    {
      field: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Edit SubCategory" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
    {
      field: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Edit SubCategory" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("Deleting ", params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const export_label = "Export > CSV";
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const classes = useStyles();
  const [categorySelected, setCategorySelected] = useState(() => {
    return "";
  });
  const [idSelected, setIdSelected] = useState(() => {
    return "";
  });
  const dispatch = useDispatch();
  const [open, setOpen] = useState(() => false);

  const [filteredSubCat, setFilteredSubCat] = useState(() => { });
  const subCategoryCreated = useSelector((state) => state.subCategoryCreated);
  const { success_create } = subCategoryCreated;
  const subCategoryUpdated = useSelector((state) => state.subCategoryUpdated);
  const { success_subcat_update } = subCategoryUpdated;
  const subCategoryDeleted = useSelector((state) => state.subCategoryDeleted);
  const { success_delete } = subCategoryDeleted;
  if (success_delete) {
    console.log("success_delete : ", success_delete);
  }
  const categoryList = useSelector((state) => state.categoryList);
  const { loading_categories, error_loading_categories, categories } =
    categoryList;

  useEffect(() => {
    console.log("useEffect Getting Called CategoryListScreen");
    if (success_delete) {
      // setCategorySelected("");
      dispatch({ type: SUB_CATEGORY_DELETE_RESET });
      // dispatch(listCategories());
      navigate("/admin/subcategories");
    } else if (success_create || success_subcat_update) {
      setCategorySelected("");
      dispatch({ type: SUB_CATEGORY_CREATE_BY_CATEGORY_ID_RESET });
      dispatch(listCategories());
    } else {
      dispatch(listCategories());
    }
  }, [dispatch, navigate, success_delete, success_create, success_subcat_update]);

  useEffect(() => {
    if (categorySelected && categorySelected.length > 1)
      dispatch(listSubCategoriesByCategoryId(categorySelected));
  }, [dispatch, categorySelected]);

  const subCategoriesByCategory = useSelector(
    (state) => state.subCategoryListByCategory
  );

  let cats;
  if (categories) {
    console.log(categories);
    cats = categories.categories;
  }

  const { subcategories } = subCategoriesByCategory;
  let renderCategoriesOptions = "";
  if (cats && cats.length > 0) {
    renderCategoriesOptions = cats.map((eachCategory, idx) => {
      return (
        <MenuItem key={idx} value={eachCategory._id}>
          {eachCategory.name}
        </MenuItem>
      );
    });
  }

  const handleChangeCategory = (e) => {
    console.log("Category Changed  " + e.target.value);
    setCategorySelected(() => e.target.value);
  };

  const createHandler = (category) => {
    navigate("/admin/subcategory/new");
  };

  const handleEdit = (params) => {
    console.log(
      "catg params",
      params.data.Id,
      params.data.Name,
      params.data.Description
    );
    setOpen(true);
    console.log("Sub Cat ID Selected : ", params);
    setFilteredSubCat({
      id: params.data.Edit,
      name: params.data.Name,
      description: params.data.Description,
    });
  };

  const handleDelete = (params) => {
    console.log("handleDelete Exec...", params);
    // setIdSelected(subcatg._id);
    // console.log("ID SELECTED : " + subcatg._id);
    // dispatch(deleteSubCategory(subcatg._id));
    // setCategorySelected("");
  };

  const nameChangeHandler = (nm) => {
    setFilteredSubCat({ ...filteredSubCat, name: nm });
    console.log(filteredSubCat);
  };

  const descChangeHandler = (dsc) => {
    setFilteredSubCat({ ...filteredSubCat, description: dsc });
    console.log(filteredSubCat);
  };

  const submitHandler = () => {
    console.log("EXEC submitHandler");
    console.log(filteredSubCat);
    dispatch(
      updateSubCategory(
        filteredSubCat._id,
        filteredSubCat.name,
        filteredSubCat.description
      )
    );
    setOpen(false);
    setFilteredSubCat({});
  };
  const updateHandler = () => {
    console.log("EXEC updateHandler filteredSubCat :==> ", filteredSubCat);
    if (filteredSubCat && filteredSubCat.id) {
      dispatch(updateSubCategory(filteredSubCat.id, filteredSubCat.name, filteredSubCat.description))
    }
    else
      console.log("Unable to Submit as ._id is NULL");
    setOpen(false);
    setFilteredSubCat({});
  };
  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };
  let renderContent = "";
  if (subcategories && subcategories.length > 0 && categorySelected !== "") {
    rowData = subcategories.map((eachSubCat, index) => {
      console.log("eachSubCat : ", eachSubCat);
      return {
        "#": index + 1,
        Name: eachSubCat.name,
        Description: eachSubCat.description,
        Edit: eachSubCat._id,
        Delete: eachSubCat._id,
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        // domLayout='autoHeight'
        ></AgGridReact>
      </div>
    );
  }

  return (
    <React.Fragment>
      {error_loading_categories && (
        <Message variant="danger">{error_loading_categories}</Message>
      )}
      {loading_categories && <CustomBackdropSpinner />}
      <GridContainer>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={openSnackBar}
          autoHideDuration={3000}
          onClose={handleCloseSnackBar}
          key={vertical + horizontal}
        >
          <Alert
            onClose={handleCloseSnackBar}
            severity="info"
            sx={{ width: "100%" }}
          >
            Please Select Category to proceed!
          </Alert>
        </Snackbar>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{ fontWeight: 500, textAlign: "center" }}>
                  <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em" }}
                    />
                  </Tooltip>
                  Sub-Categories List
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={createHandler}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "left", marginRight: "10px" }}
                    />
                    Create Sub Category
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <FormControl
            style={{
              textTransform: "capitalize",
              margin: "1rem",
              float: "left",
            }}
          >
            <InputLabel id="demo-simple-select-label">
              Select Category
            </InputLabel>
            <Select
              label="Select Category"
              placeholder="Select Category"
              variant="outlined"
              required
              size="small"
              name="Select Category"
              value={categorySelected}
              onChange={handleChangeCategory}
              style={{ width: "15rem" }}
            >
              {renderCategoriesOptions}
            </Select>
          </FormControl>
          <GridItem xs={12} sm={12} md={12}>
            <Tooltip title="Export > Excel" arrow>
              <IosShareRoundedIcon
                fontSize="medium"
                style={{ float: "right", margin: "0.5rem" }}
                onClick={onExportClick}
              />
            </Tooltip>
          </GridItem>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : ""}
        </GridItem>
        <Dialog open={open}>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader >
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    >

                    </Typography>
                    <h2 align="center"> Edit Sub Category </h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                    {/* <form onSubmit={submitHandler}> */}
                    <Grid container spacing={2}>
                      <Grid item xs={12} align="center">
                        <TextField
                          className={classes.inputText}
                          placeholder="Name"
                          variant="outlined"
                          name="name"
                          onChange={(e) => nameChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredSubCat && filteredSubCat.name
                              ? filteredSubCat.name
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} align="center">
                        <TextField
                          className={classes.inputText}
                          placeholder="Description"
                          variant="outlined"
                          name="description"
                          id="description"
                          onChange={(e) => descChangeHandler(e.target.value)}
                          type="text"
                          size="small"
                          value={
                            filteredSubCat && filteredSubCat.description
                              ? filteredSubCat.description
                              : ""
                          }
                          InputProps={{
                            classes: { input: classes.input },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} align="center">
                        <Button
                          size="small"
                          variant="contained"
                          type="submit"
                          color="secondary"
                          justify="center"
                          style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                          onClick={updateHandler}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Grid>
                    {/* </form> */}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default SubCategoryListScreen;
