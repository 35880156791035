export const team = [
  {
    title: "CEO",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg 2x",
    },
    authorName: "Rajesh Sharma",
  },
  {
    title: "HR",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg 2x",
    },
    authorName: "KV Rao",
  },
  {
    title: "Administration",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg 2x",
    },
    authorName: "Ganesh",
  },
  {
    title: "Store Manager",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg 2x",
    },
    authorName: "Prasad",
  },
  {
    title: "CTO / Co-Founder",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg 2x",
    },
    authorName: "Rajesh",
  },
  {
    title: "Delivery Head",
    authorPhoto: {
      src: "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/people/alex-johnson.jpg 2x",
    },
    authorName: "Rajesh S",
  },
];

export const companies = [
  {
    name: "Airbnb",
    logo: "/assets/images/logos/airbnb.svg",
  },
  {
    name: "Coinbase",
    logo: "/assets/images/logos/coinbase.svg",
  },
  {
    name: "Dribbble",
    logo: "/assets/images/logos/dribbble.svg",
  },
];

export const mapData = [
  {
    location: {
      y: 45.453211,
      x: 9.248383,
      address: "Via A.G. Alaimo 147, 55027",
    },
  },
  {
    location: {
      y: 45.419211,
      x: 9.021383,
      address: "Via Rocca de Baldi 95, 440368",
    },
  },
  {
    location: {
      y: 45.473211,
      x: 9.298383,
      address: "Via Firenze 134, 45588",
    },
  },
  {
    location: {
      y: 45.461211,
      x: 9.000383,
      address: "Via Cavour 29, 201558",
    },
  },
  {
    location: {
      y: 45.413211,
      x: 9.398383,
      address: "Via Bologna 33, 220156",
    },
  },
  {
    location: {
      y: 45.569211,
      x: 9.128383,
      address: "Vicolo Tre Marchetti 127, 350125",
    },
  },
  {
    location: {
      y: 45.483211,
      x: 9.148383,
      address: "Via Lombardi 146, 45830",
    },
  },
  {
    location: {
      y: 45.313211,
      x: 9.012383,
      address: "Via Guantai Nuovi 99, 56989",
    },
  },
];

export const gallery = [
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg 2x",
    },
    title: "gallery 1",
    cols: 1,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg 2x",
    },
    title: "gallery",
    cols: 3,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg 2x",
    },
    title: "gallery",
    cols: 3,
  },
  {
    image: {
      src: "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg",
      srcSet:
        "https://assets.maccarianagency.com/the-front/photos/expo-gallery/gallery1.jpg 2x",
    },
    title: "gallery",
    cols: 1,
  },
];

export const modules = [
  {
    title: "Store",
    subtitle: "Store Wise",
  },
  {
    title: "Store Incharge",
    subtitle: "Store Incharge Wise",
  },
  {
    title: "Store Manager",
    subtitle: "Store Manager Wise",
  },
  {
    title: "Category",
    subtitle: "Category Wise",
  },
  {
    title: "Sub Category",
    subtitle: "Sub Category Wise",
  },
  {
    title: "Product",
    subtitle: "Product Wise",
  },
  {
    title: "Customer",
    subtitle: "Customer Wise",
  },
  {
    title: "Delivery",
    subtitle: "Delivery Tracking",
  },
  {
    title: "Stock",
    subtitle: "Stock Report",
  },
];

export const units_by_metric = {
    Kg: ['26','30'],
    Litre: ['1 Ltr'],
    Piece: ['0.5 kg','1 kg','5 kg','10 kg'],
    Tin: ['15 Ltrs','15 Kgs']
}

export const service_states = ['Andhra Pradesh','Maharastra','Telangana','Karnataka','Uttar Pradesh']

export const payment_modes = ['Cash','Cheque','UPI','Partial Payment']

export const warehouse_store = ['Warehouse','Store']

export const warehouse_store_vehicle = ['Warehouse','Store','Vehicle']

export const stock_transfer_invoice_status = ['Dispatched','Arrived','Verified - Shortage', 'Verified - Some Stock Damaged','Verified - All Good '] 

export const unit_of_meassures = ['Kg','Litre','Piece','Tin']

export const stores = ['Kukatpally','Kokapet','Gachibowli','Jadcherla']

export const warehouses = ['Gachibowli','Kukatpally','Nacharam','Vinayak Nagar']

export const po_status = ['Paid','Due']

export const vehicle_shifts = ['Morning','Noon','Evening']

export const customer_type = ['Regular','Preferred']

export const online_walkin = ['Online','Walkin']

/**  
 * ENABLE WHEN BOTH BULK AND DOMESTIC ARE AVAILABLE FOR A PRODUCT
 * export const order_type = ['Bulk','Domestic'];
*/
export const order_type = ['Domestic'];

export const payment_mode_single_multiple = ['Single','Multiple']
export const payment_types = ['upi','cash']
export const role_types = ['CEO_ROLE','ADMIN_ROLE','FINANCE_MANAGER_ROLE','WAREHOUSE_INCHARGE_ROLE','STORE_INCHARGE_ROLE',
'VEHICLE_SALES_INCHARGE_ROLE','DRIVER_ROLE', 'VEHICLES_INCHARGE_ROLE']
 
export const adminInformation = {
  adminEmail: "contact_ho@taglinetraders.com",
  phoneNo: "8459258752",
  address:
    "PLOT NO-4 LAXMI APARTMENT VINAYAKA NAGAR UCO BANK STREET,GACHIBOWLI-BACK SIDE BAJAJ ELECTRONICS, HYDERABAD, TELANGANA",
  zipCode: "500032",
  lat_long: "17.432050,78.372660",
};

export const reasonsForCancellation = ['Out Of Stock','Prices came Down','Lack of Man power','Location is Remote']
export const cancel_message = {
  message : "Cancelling items in this order may cause you to lose prootional offers and/or cashbacks on the undispatched items in this and /or other Orders. Additionally, if discounted items have already been dispatched you may not be able to cancel some of the items in this order."};

export const monthOptions = ['January','February','March','April','May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

export const quarterlyOptions = ['Q1 [April till June]','Q2 [July till September]','Q3 [October till December]','Q4 [January till March]']

export const financialYearOptions = ['2023','2024']

export const reportOptions = ['Monthly','Quarterly','Financial Year']

export const vishudhaWhatsAppNumber = '7032405627'

export const reportDurations = [
  { title: "Current Month", value : "currentMonth" },
  { title: "Previous Month", value : "previousMonth"  },
  // { title: "Quarterly" , value : "quarterly" },
  // { title: "Yearly" , value : "yearly" },
  { title: "Custom" , value : "custom" }
];

export const walkinSalesReportDurations = [
  { title: "Current Month", value : "currentMonth" },
  { title: "Previous Month", value : "previousMonth"  },
  { title: "Custom", value : "custom"  },
];
  
export const orderTypes = [
  { label: "online", value : "online-sale" },
  { label: "walkin", value : "walkin-sale" },
  { label: "vehicle", value : "vehicle-sale" },
]

// export const ctcTypes = [
//   { label: "chilli", value : "Chilli" },
//   { label: "turmeric", value : "Turmeric" },
//   { label: "Corriander", value : "Corriander" },
// ]

export const ctcTypes = [
  "Chilli" ,
  "Turmeric",
  "Corriander"
]

export const product_dimensons = [
  {
    productName: "VISHUDHA RED CHILLI POWDER ",
    dimensions : ["VISHUDHA RED CHILLI POWDER 50 GM Pack", "VISHUDHA RED CHILLI POWDER 100 GM Pack", "VISHUDHA RED CHILLI POWDER 200 GM Pack" , "VISHUDHA RED CHILLI POWDER 500 GM Pack"]
  },
  {
    productName: "VISHUDHA TURMERIC POWDER ",
    dimensions : ["VISHUDHA TURMERIC POWDER  50 GM Pack", "VISHUDHA TURMERIC POWDER  100 GM Pack", "VISHUDHA TURMERIC POWDER  200 GM Pack" , "VISHUDHA TURMERIC POWDER 500 GM Pack"]
  },
  {
    productName: "VISHUDHA CORIANDER POWDER ",
    dimensions : ["VISHUDHA CORIANDER POWDER 50 GM Pack", "VISHUDHA CORIANDER POWDER 100 GM Pack", "VISHUDHA CORIANDER POWDER 500 GM Pack"]
  }
]

export const all_products_sku = [ 
  "VISHUDHA RED CHILLI POWDER 50 GM Pack", "VISHUDHA RED CHILLI POWDER 100 GM Pack", "VISHUDHA RED CHILLI POWDER 200 GM Pack" , "VISHUDHA RED CHILLI POWDER 500 GM Pack",
  "VISHUDHA TURMERIC POWDER  50 GM Pack", "VISHUDHA TURMERIC POWDER  100 GM Pack", "VISHUDHA TURMERIC POWDER  200 GM Pack" , "VISHUDHA TURMERIC POWDER 500 GM Pack",
  "VISHUDHA CORIANDER POWDER 50 GM Pack", "VISHUDHA CORIANDER POWDER 100 GM Pack", "VISHUDHA CORIANDER POWDER 500 GM Pack"
]

export const destinations = [
  "Factory" ,
  "Cold Storage",
]


export const vishudhaWhatsAppAuditNo = "91.7032405627"

