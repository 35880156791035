export const CTC_VARIETY_REGISTER_REQUEST = "CTC_VARIETY_REGISTER_REQUEST";
export const CTC_VARIETY_REGISTER_SUCCESS = "CTC_VARIETY_REGISTER_SUCCESS";
export const CTC_VARIETY_REGISTER_FAIL = "CTC_VARIETY_REGISTER_FAIL";
export const CTC_VARIETY_REGISTER_RESET = "CTC_VARIETY_REGISTER_RESET";

export const CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_REQUEST = "CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_REQUEST";
export const CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_SUCCESS = "CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_SUCCESS";
export const CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_FAIL = "CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_FAIL";
export const CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_RESET = "CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_RESET";

export const CTC_VARIETY_LIST_REQUEST = "CTC_VARIETY_LIST_REQUEST";
export const CTC_VARIETY_LIST_SUCCESS = "CTC_VARIETY_LIST_SUCCESS";
export const CTC_VARIETY_LIST_FAIL = "CTC_VARIETY_LIST_FAIL";
export const CTC_VARIETY_LIST_RESET = "CTC_VARIETY_LIST_RESET";

export const CTC_VARIETY_DELETE_REQUEST = "CTC_VARIETY_DELETE_REQUEST";
export const CTC_VARIETY_DELETE_SUCCESS = "CTC_VARIETY_DELETE_SUCCESS";
export const CTC_VARIETY_DELETE_FAIL = "CTC_VARIETY_DELETE_FAIL";

export const CTC_VARIETY_UPDATE_REQUEST = "CTC_VARIETY_UPDATE_REQUEST";
export const CTC_VARIETY_UPDATE_SUCCESS = "CTC_VARIETY_UPDATE_SUCCESS";
export const CTC_VARIETY_UPDATE_FAIL = "CTC_VARIETY_UPDATE_FAIL";
export const CTC_VARIETY_UPDATE_RESET = "CTC_VARIETY_UPDATE_RESET";