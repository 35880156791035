import React, { useState, useEffect } from "react";
import { resetCartItems, savePaymentDetails, savepaymentType } from "../../../../actions/cartAction";
import {
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Button,
    Tooltip,
    Box,
    InputLabel,
    Select,
    MenuItem,
    Divider,
    Icon,
    Typography,
    TextField,
} from "@mui/material";
import Message from "../../../Message";
import vttUpiScanner from "../../../../assets/images/VTT_UPI_SCANNER.jpeg";
import rupeeSvgIcon from "../../../../assets/images/currency-inr.svg";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from "@mui/styles";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import CustomizedDialogs from "../../../Dialog/CustomizedDialogs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import { payment_mode_single_multiple, payment_types } from "../../../../data/index";
import GridItem from "../../../Grid/GridItem";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { updateProductStockPostPlacingOrder } from "../../../../actions/stockAction";
import { createOrder } from "../../../../actions/orderAction";
import { ORDER_CREATE_RESET } from "../../../../constants/orderConstants";
import { createInvoice } from "../../../../actions/vehicleSalesInvoiceAction";
import { VEHICLE_INVOICE_TO_SHOP_CREATE_RESET, VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET } from "../../../../constants/vehicleSalesInvoiceConstants";
import { updateStockWhenOutFromVehicleNoAndProductName } from "../../../../actions/vehicleStockAction";
import GridContainer from "../../../Grid/GridContainer";
import CardHeader from "../../../Card/CardHeader";
import { updateStockOutByWarehouseProduct } from "../../../../actions/warehouseStockAction";
import { updateStockOutByStoreProduct } from "../../../../actions/storeStockAction";
import { createWalkinSaleInvoice } from "../../../../actions/walkinSaleInvoiceAction";
import { createVehicleSalesInvoice } from "../../../../actions/vehicleSalesInvoiceAction";
import { WALKIN_SALES_INVOICE_CREATE_RESET } from "../../../../constants/walkinSalesInvoiceConstants";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { addDecimals, formatToLocaleCurrency } from "../../../../util/Utility";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../../assets/images/green_tick.png";
import { createPdfInvoice } from "../../../../actions/pdfInvoiceAction";
import { createWhatsAppInvoice } from "../../../../actions/whatsAppInvoiceAction";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        justifyContent: "center",
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: "center",
        color: theme.palette.text.secondary,
    },

    control: {
        padding: theme.spacing(2),
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0",
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF",
        },
    },
    cardTitleGreen: {
        color: "#26A541",
        marginTop: "0px",
        minHeight: "auto",
        fontFamily: "Roboto",
        marginBottom: "3px",
        textDecoration: "none",
        fontSize: "1rem",
        fontWeight: 500,
        textTransform: "capitalize",
        textAlign: "left",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1",
        },
    },
}));

const VehicleSalesPayment = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { invoiceDetails, productsInCart, vehicleNo, customerDetails, paymentDetails } = props
    console.log('props Passed are : ', props)
    console.log('invoiceDetails : ', invoiceDetails, "productsInCart : ", productsInCart, "customerDetails : ", customerDetails,' paymentDetails : ', paymentDetails)
    console.log('vehicleNo : ', vehicleNo)
    const [isCash, setIsCash] = useState(() => false);
    const [isUpi, setIsUpi] = useState(() => false);
    const [isLazyPay, setIsLazyPay] = useState(() => false);
    const [isCashRadioItem, setIsCashRadioItem] = useState(() => false);
    const [isUpiRadioItem, setIsUpiRadioItem] = useState(() => false);
    const [isLazyPayRadioItem, setIsLazyPayRadioItem] = useState(() => false);
    const [paymentMode, setPaymentMode] = useState(() => "Single");
    const [paymentType, setPaymentType] = useState(() => []);
    const [cashAmount, setCashAmount] = useState(() => 0.0);
    const [upiAmount, setUpiAmount] = useState(() => 0.0);
    const [lazyPayAmount, setLazyPayAmount] = useState(() => 0.0);
    const [amountPaid, setAmountPaid] = useState(() => 0.0);
    const [paidTo, setPaidTo] = useState(() => "");
    const [upiReference, setUpiReference] = useState(() => "");
    const [orderTotal, setOrderTotal] = useState(() => 0.0);
    const [itemsDiscountPrice, setItemsDiscountPrice] = useState(() => null);
    const [balanceAmountTobePaid, setBalanceAmountTobePaid] = useState(() => 0.0);
    const [totalAmountTobePaid, setTotalAmountTobePaid] = useState(() => 0.0);
    const vertical = 'top';
    const horizontal = 'center';
    const [customError, setCustomError] = useState(() => []);

    const [errorObject, setErrorObject] = useState(() => null);
    const [itemsPriceObject, setItemsPriceObject] = useState(() => { });
    const [checked, setChecked] = React.useState(false);
    let renderCashDetails = "";
    let renderUpiDetails = "";
    let renderLazyPayDetails = "";
    let paymentMadeDetails = {};
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        if (vehicle_shop_invoice_create_success) {
            dispatch({ type: VEHICLE_SALE_INVOICE_TO_SHOP_CREATE_RESET });
            navigate("/admin/vehicle-sales-invoice/list");
        }
    };

    const vehicleStockOutUpdateByVehicleNo = useSelector((state) => state.vehicleStockOutUpdateByVehicleNo);
    const { vehicle_stock_out_update_success, vehicle_stock_out_update_error } = vehicleStockOutUpdateByVehicleNo;

    const vehicleSalesInvoiceCreatedByVehicleNo = useSelector((state) => state.vehicleSalesInvoiceCreatedByVehicleNo);
    const { vehicle_shop_invoice_create_success, vehicle_shop_invoice_create_error } = vehicleSalesInvoiceCreatedByVehicleNo;

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const [openSnackBar, setOpenSnackBar] = React.useState(true);

    const placeOrderHandler = () => {
        // event.preventDefault();
        console.log(" *******  Clicked  placeOrderHandler Vehicle Sale Invoice *******  ");
        console.log("Products in AG Grid :==> ", productsInCart);
        console.log("invoiceDetails :==> ", invoiceDetails);
        console.log("vehicleNo :==> ", vehicleNo);
        console.log("customerDetails :==> ", customerDetails);
        console.log("itemsPriceObject :==> ", itemsPriceObject);
        console.log("balanceAmountTobePaid :==> ", balanceAmountTobePaid);

        if(paymentDetails){
            paymentMadeDetails = paymentDetails 
        }else{
               paymentMadeDetails = {
            cashAmountPaid: cashAmount ? cashAmount : 0.0,
            upiAmountPaid: upiAmount ? upiAmount : 0.0,
            paidTo: (paidTo) ? paidTo : "",
            discountApplied: itemsDiscountPrice ? itemsDiscountPrice : 0.0,
            amountPaid: amountPaid ? amountPaid : 0.0,
            balanceAmount: balanceAmountTobePaid ? balanceAmountTobePaid : 0.0,
            orderTotal: itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice - itemsPriceObject.totalDiscountPrice : itemsPriceObject.totalCartItemsPrice,
        };
        }
     
        console.log("paymentMadeDetails :==> ", paymentMadeDetails);

        if (productsInCart && invoiceDetails && vehicleNo) {
            const calculatedItemsPriceResult = calculateItemsPriceObject(productsInCart);
            console.log("calculatedItemsPriceResult : ", calculatedItemsPriceResult);

            /** 
               LOGIC FOR STOCK OUT OF VEHICLE... IS HANDLED HERE.!!!
            */
            if (vehicleNo && customerDetails && productsInCart && paymentMadeDetails) {
                console.log(" ******* NOW DISPATCHING TO createVehicleSaleInvoice ******* ");
                console.log('******* BEFORE HITTING THE BE API LOGGING TOTAL CART PRICE AND TAX PRICE *******')
                console.log('itemsPriceObject : ', itemsPriceObject)
                console.log('calculatedItemsPriceResult : ', calculatedItemsPriceResult)
                dispatch(createVehicleSalesInvoice({
                    customerDetails,
                    invoiceDetails,
                    paymentMadeDetails,
                    productsInCart,
                    vehicleNo,
                    itemsPrice: itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice : 0.0,
                    taxPrice: itemsPriceObject.totalTaxPrice ? itemsPriceObject.totalTaxPrice : 0.0,
                }))
                console.log(" ******* NOW DISPATCHING TO updateStockWhenOutFromVehicleNoAndProductName ******* ");
                dispatch(
                    updateStockWhenOutFromVehicleNoAndProductName(
                        invoiceDetails,
                        productsInCart,
                        vehicleNo
                    )
                );
            }
        }
        else
            console.log("UNABLE TO PROCEED AS productsInCart && invoiceDetails && vehicleNoare NULL .!");
        {/**
            LOGIC TO CREATE PDF INVOICE MORE OR LESS IF WAREHOUSE || STORE  ||.
        */}
        {
        /** console.log('******* NOW DISPATCHING TO createPdfInvoice *******')
        dispatch(createPdfInvoice(
            invoiceDetails, productsInCart, paymentMadeDetails
        ))
        */}
        {/**
            LOGIC TO SEND INVOICE OVER WHATSAPP MORE OR LESS IF WAREHOUSE || STORE  ||.
        */}
        console.log('COMMENTING THE WHATS APP CODE FOR TESTING VEH SALE INVOICE CREATION')
        console.log('******* NOW DISPATCHING TO createWhatsAppInvoice *******')
        dispatch(createWhatsAppInvoice(
            "vehicle-sale",invoiceDetails, productsInCart, paymentMadeDetails,
        ))
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === 'clickaway')
            return;
        setOpenSnackBar(false);
    };

    const calculateItemsPriceObject = (items) => {
        let itmsPriObj;
        console.log('Exec calculateItemsPriceObject')
        console.log('itemsPrice of Items in Cart: ')
        items.map((eachItem) => console.log('Each Items eachItem.unitPrice:', eachItem.unitPrice, 'Quantity Selected : ', eachItem.quantity))

        let itemsPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) => acc + eachItem.unitPrice * eachItem.quantity,
                0
            )
        );
        console.log('itemsDiscountPrice of Items in Cart: ')
        items.map((eachItem) => console.log('Each Items eachItem.itemsDiscountPrice:', eachItem.itemsDiscountPrice))

        let itemsDiscountPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.itemsDiscountPrice && eachItem.itemsDiscountPrice !== 0 && eachItem.itemsDiscountPrice !== null && eachItem.itemsDiscountPrice !== undefined && eachItem.itemsDiscountPrice !== "" ? eachItem.itemsDiscountPrice : 0.0),
                0
            )
        );
        console.log('cgstTaxPrice of Items in Cart: ')
        items.map((eachItem) => console.log('Each Items eachItem.cgstPrice:', eachItem.cgstPrice))

        let cgstTaxPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.cgstPrice && eachItem.cgstPrice !== 0 && eachItem.cgstPrice !== null && eachItem.cgstPrice !== undefined && eachItem.cgstPrice !== "" ? eachItem.cgstPrice : 0.0),
                0
            )
        );
        let sgstTaxPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.sgstPrice && eachItem.sgstPrice !== 0 && eachItem.sgstPrice !== null && eachItem.sgstPrice !== undefined && eachItem.sgstPrice !== "" ? eachItem.sgstPrice : 0.0),
                0
            )
        );
        let igstTaxPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.igstPrice && eachItem.igstPrice !== 0 && eachItem.igstPrice !== null && eachItem.igstPrice !== undefined && eachItem.igstPrice !== "" ? eachItem.igstPrice : 0.0),
                0
            )
        );
        let tcsTaxPrice = formatToLocaleCurrency(
            items.reduce(
                (acc, eachItem) =>
                    acc + (eachItem.tcsPrice && eachItem.tcsPrice !== 0 && eachItem.tcsPrice !== null && eachItem.tcsPrice !== undefined && eachItem.tcsPrice !== "" ? eachItem.tcsPrice : 0.0),
                0
            )
        );
        itmsPriObj = {
            totalTaxPrice:
                (cgstTaxPrice ? Number(cgstTaxPrice) : 0.0) +
                (sgstTaxPrice ? Number(sgstTaxPrice) : 0.0),
            // totalCartItemsPrice: Number(itemsPrice),
            totalDiscountPrice: itemsDiscountPrice ? Number(itemsDiscountPrice) : 0.0,
            totalCgstTaxPrice: cgstTaxPrice ? Number(cgstTaxPrice) : 0.0,
            totalSgstTaxPrice: sgstTaxPrice ? Number(sgstTaxPrice) : 0.0,
            totalIgstTaxPrice: igstTaxPrice ? Number(igstTaxPrice) : 0.0,
            totalTcsTaxPrice: tcsTaxPrice ? Number(tcsTaxPrice) : 0.0,
            totalCartItemsPrice: itemsPrice ? Number(itemsPrice) : 0.0,
        };
        console.log('CALCULATED itmsPriObj : ', itmsPriObj, ' NOW SETTING TO STATE')
        setItemsPriceObject(() => itmsPriObj);
    };

    const showSuccessDialog = () => {
        console.log("Exe showSuccessDialog Displaying Success Image");
        setOpen(() => true);
    }

    useEffect(() => {
        console.log('useEffect getting Called as productsInCart are getting Changed')
        if (productsInCart && productsInCart.length > 0) {
            calculateItemsPriceObject(productsInCart);
        }
    }, [productsInCart]);

    useEffect(() => {
        console.log("useEffect Getting Called on VehicleSalesPayment Screen as Success on Create New Invoice", vehicle_shop_invoice_create_success);
        if (vehicle_shop_invoice_create_success) {
            console.log(
                "vehicle_shop_invoice_create_success :==> ",
                vehicle_shop_invoice_create_success, " Dispatching to RESET"
            );
            setOpen(() => true);
        }
    }, [vehicle_shop_invoice_create_success]);

    const handlePaymentModeChange = (e) => {
        console.log("Exec handlePaymentModeChange ", e);
        console.log(
            "e.target.name :==> ",
            e.target.name,
            " , e.target.value :==> ",
            e.target.value
        );
        clearFormValueForPaymentModeChange();
        clearFormValueForSinglePaymentType();
        clearFormValueForMultiplePaymentType();
        setPaymentMode((prev) => e.target.value)
    };

    const handleChangeCheckbox = (event) => {
        console.log("event : ", event.target.name, event.target.checked);
        switch (event.target.name) {
            case "isCash":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsCash(() => true)
                    setPaymentType(() => [...paymentType, "cash"])

                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.UN checked ***** ", event.target.name, event.target.checked)
                    setIsCash(() => false)
                    setAmountPaid((prev) => prev - (lazyPayAmount + upiAmount));
                    console.log(" ***** Deducting cashAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setCashAmount(() => 0.0)
                    setPaidTo(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i === "cash")],
                    );
                }
                break;
            case "isUpi":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsUpi(() => true)
                    setPaymentType(() => [...paymentType, "upi"],
                    );
                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target. UN checked ***** ", event.target.name, event.target.checked)
                    setIsUpi(() => false)
                    setAmountPaid((prev) => prev - (cashAmount + lazyPayAmount));
                    console.log(" ***** Deducting upiAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setUpiAmount(() => 0.0)
                    setUpiReference(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i !== "upi")],
                    );
                }
                break;
            case "isLazyPay":
                if (event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.checked ***** ", event.target.name, event.target.checked)
                    setIsLazyPay(() => true)
                    setPaymentType(() => [...paymentType, "lazyPay"])

                } else if (!event.target.checked) {
                    console.log(" ***** When handleChangeCheckbox event.target.UN checked ***** ", event.target.name, event.target.checked)
                    setIsLazyPay(() => false)
                    setAmountPaid((prev) => prev - (cashAmount + upiAmount));
                    console.log(" ***** Deducting cashAmount from amountPaid ***** amountPaid : ", amountPaid)
                    setCashAmount(() => 0.0)
                    setPaidTo(() => "")
                    setPaymentType(() => [...paymentType.filter((i) => i === "lazyPay")],
                    );
                }
                break;
            default:
                break;
        }
    };

    const handleChangeRadioOptions = (event) => {
        event.persist();
        clearFormValueForSinglePaymentType();
        console.log(
            "handleChange Radio event : ",
            event.target.name,
            event.target.value
        );
        if (event.target.value === "cash") {
            setIsCashRadioItem(() => true)
            setIsUpiRadioItem(() => false)
            setIsLazyPayRadioItem(() => false)
            setPaymentType(["cash"])
            console.log("Have Set Cash Radio Item..");
        }
        else if (event.target.value === "upi") {
            setIsCashRadioItem(() => false)
            setIsUpiRadioItem(() => true)
            setIsLazyPayRadioItem(() => false)
            setPaymentType(["upi"])
            console.log("Have Set upi  Radio Item..");
        }
        else if (event.target.value === "lazyPay") {
            setIsCashRadioItem(() => false)
            setIsUpiRadioItem(() => false)
            setIsLazyPayRadioItem(() => true)
            setPaymentType(["lazyPay"])
            console.log("Have Set lazyPay  Radio Item..");
        }
    };

    const handleChangeUpiReference = (event) => {
        event.persist();
        const upiReferenceValue = event.target.value;
        setUpiReference(() => upiReferenceValue);
    };

    const handleChangePaidTo = (event) => {
        event.persist();
        const paidToValue = event.target.value;
        setPaidTo(() => paidToValue);
    };

    const clearFormValueForPaymentModeChange = () => {
        console.log("clearFormValueForPaymentModeChange ");
        clearFormValueForSinglePaymentType();
        clearFormValueForMultiplePaymentType();
    };

    const clearFormValueForSinglePaymentType = () => {
        console.log("clearFormValueForSinglePaymentType ");
        setIsCashRadioItem((prev) => false)
        setIsUpiRadioItem((prev) => false)
        setIsLazyPayRadioItem((prev) => false)
        setCashAmount(() => 0.0)
        setUpiAmount(() => 0.0)
        setLazyPayAmount(() => 0.0)
        setUpiReference(() => "")
        setPaidTo(() => "")
        setAmountPaid(() => 0.0)
    };

    const clearFormValueForMultiplePaymentType = () => {
        console.log("clearFormValueForMultiplePaymentType ");
        setIsCash((prev) => false)
        setIsUpi((prev) => false)
        setIsLazyPay((prev) => false)
        setCashAmount(() => 0.0)
        setUpiAmount(() => 0.0)
        setLazyPayAmount(() => 0.0)
        setUpiReference(() => "")
        setPaidTo(() => "")
        setAmountPaid(() => 0.0)
    };

    const renderDiscTaxTotalAmountContentUsingCards = (
        <Card style={{ backgroundColor: "#F2F2F2" }}>
            <GridContainer spacing={2} alignItems="center" justify="center">
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: "left", justifyContent: "center", alignItems: "center" }}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem" }}
                        color="black"
                    >
                        Total Discount :
                        <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                            <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                className={classes.imageIcon}
                            />
                        </Icon>
                        {itemsPriceObject && itemsPriceObject.totalDiscountPrice ? formatToLocaleCurrency(itemsPriceObject.totalDiscountPrice) : 0.0}
                    </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} style={{ textAlign: 'right', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'right', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Total Tax :
                        <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                            <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                className={classes.imageIcon}
                            />
                        </Icon>
                        {itemsPriceObject && itemsPriceObject.totalTaxPrice ? formatToLocaleCurrency(itemsPriceObject.totalTaxPrice) : 0.0}
                        {/* {itemsPriceObject && itemsPriceObject.totalTaxPrice ? itemsPriceObject.totalTaxPrice.toLocaleString("en-IN") : 0.0} */}
                    </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Total Quantity : 2</Typography>
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'right', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Total Amount : <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                            <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                className={classes.imageIcon}
                            />
                        </Icon>
                        {itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? formatToLocaleCurrency(itemsPriceObject.totalCartItemsPrice - itemsPriceObject.totalDiscountPrice) : 0.0}
                    </Typography>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'left', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Amount paid : 
                         {paymentDetails && paymentDetails.amountPaid ? formatToLocaleCurrency(Number(paymentDetails.amountPaid).toFixed(2)) : 0.0}
                        </Typography>
                </GridItem>

                <GridItem xs={6} sm={6} md={6}>
                    <Typography
                        style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: 'right', justifyContent: 'center', alignItems: 'center' }}
                        color="black"
                    >
                        Balance Amount : <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                            <img
                                alt="curency inr"
                                src={rupeeSvgIcon}
                                style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                className={classes.imageIcon}
                            />
                        </Icon>
                        {paymentDetails && paymentDetails.balanceAmount ? formatToLocaleCurrency(Number(paymentDetails.balanceAmount).toFixed(2)) : itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? formatToLocaleCurrency((Number(itemsPriceObject.totalCartItemsPrice) - Number(itemsPriceObject.totalDiscountPrice))) : 0.0}
                        {/* {paymentDetails && paymentDetails.balanceAmount ? paymentDetails.balanceAmount.toLocaleString("en-IN") : 0.0} */}
                    </Typography>
                </GridItem>
            </GridContainer>
        </Card>
    );

    const renderUpiScannerInformation = (
        <Grid container spacing={1} justify="center" style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <Grid item xs={12} style={{ justifyContent: "center", alignItems: "center", verticalAlign: "center", marginLeft: "auto" }}>
                <img
                    alt="Vishudha UPI Scanner"
                    src={vttUpiScanner}
                    style={{ width: "10vh", height: "10vh", verticalAlign: "center", color: "gray", justifyContent: "center", alignItems: "center" }}
                    className={classes.imageIcon}
                />
            </Grid>
            <Divider />
        </Grid>
    );

    const renderPaymentSummary = (
        <Grid container spacing={1} justify="center">
            <Grid item xs={12}>
                <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ marginLeft: "3rem", fontWeight: "600" }}>
                            Paid
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ fontWeight: "600" }}>
                            <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                                <img
                                    alt="curency inr"
                                    src={rupeeSvgIcon}
                                    style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                    className={classes.imageIcon}
                                />
                            </Icon>
                            {/* {amountPaid && formatToLocaleCurrency(Number(amountPaid).toLocaleString("en-IN"))}  */}
                            {amountPaid ? formatToLocaleCurrency(amountPaid) : 0.0} 
                            </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ marginLeft: "3rem", fontWeight: "600" }}>
                            Balance
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="body2" style={{ fontWeight: "600" }}>
                            <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                                <img
                                    alt="curency inr"
                                    src={rupeeSvgIcon}
                                    style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                                    className={classes.imageIcon}
                                />
                            </Icon>
                            {formatToLocaleCurrency(balanceAmountTobePaid)} </Typography>
                    </Grid>
                </Grid>
                <Divider />
            </Grid>
        </Grid>
    );

    renderUpiDetails =
        (
            <Grid container spacing={2}>
                {checked && (<Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={3000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
                    <Alert onClose={handleCloseSnackBar} severity="warning" sx={{ width: '100%', textTransform: "none" }}>
                        Please make the payment and enter the Valid Transaction id in the UPI Ref field
                    </Alert>
                </Snackbar>)}
                <GridItem item xs={12} sm={12} md={12} style={{ margin: "0.5rem", textAlign: "center" }}>
                    <TextField
                        label="UPI Amount"
                        required
                        variant="outlined"
                        size="small"
                        name="upiAmount"
                        fullWidth
                        style={{
                            textAlign: "right",
                        }}
                        onChange={(e) => {
                            const upiValue = e.target.value;
                            console.log("UPI Amount : ", upiValue)
                            validateInputLessThanOrderTotal(Number(upiValue));
                            setUpiAmount((prev) => Number(upiValue));
                            setAmountPaid((prev) => {
                                if (isCash) {
                                    return Number(cashAmount) + (upiValue ? Number(upiValue) : 0.0)
                                } else {
                                    return upiValue ? Number(upiValue) : 0.0
                                }
                            })
                            calculateBalanceAmountIfVer(Number(upiValue), 0);
                        }}
                        value={upiAmount ? Number(upiAmount) : ""}
                        type="number"
                    />
                    {errorObject && errorObject.errorMessage && (<Typography
                        variant="h5"
                        style={{ textAlign: "left", fontSize: "0.75rem", margin: "1rem", color: "red" }}
                    >
                        {errorObject.errorMessage}
                    </Typography>)}
                </GridItem>
                <GridItem item xs={12} sm={12} md={12} style={{ margin: "0.5rem", textAlign: "center" }}>
                    <TextField
                        label="Upi Ref"
                        required
                        variant="outlined"
                        size="small"
                        name="upiReference"
                        xs={{ width: "8rem" }}
                        fullWidth
                        style={{
                            textAlign: "right",
                        }}
                        onChange={handleChangeUpiReference}
                        value={upiReference ? upiReference : ""}
                        type="text"
                    />
                </GridItem>
            </Grid>
        );

    const validateInputLessThanOrderTotal = (val) => {
        console.log("amountPaid : ", amountPaid, upiAmount, cashAmount);
        const orderItemsTotalPrice = itemsPriceObject && itemsPriceObject.totalCartItemsPrice ? itemsPriceObject.totalCartItemsPrice : 0.0;
        if (val > orderItemsTotalPrice) {
            setErrorObject({ errorMessage: "Amount can't exceed  " + orderItemsTotalPrice });
        }
        else
            setErrorObject(null);
        return;
    }

    renderCashDetails =
        (
            <Grid container spacing={2}>
                <GridItem item xs={12} sm={12} md={12} style={{ textAlign: "right", margin:'0.5rem' ,marginLeft:'auto'}}>
                    <TextField
                        label="Cash Amount"
                        required
                        variant="outlined"
                        size="small"
                        name="cashAmount"
                        fullWidth
                        style={{
                            textAlign: "right",
                        }}
                        onChange={(e) => {
                            const cashValue = e.target.value;
                            console.log("CASH Amount : ", cashValue)
                            validateInputLessThanOrderTotal(cashValue);
                            setCashAmount((prev) => Number(cashValue));
                            setAmountPaid((prev) => {
                                if (isUpi) {
                                    return Number(upiAmount) + (cashValue ? Number(cashValue) : 0.0)
                                } else
                                    return cashValue ? Number(cashValue) : 0.0

                            })
                            calculateBalanceAmountIfVer(0, Number(cashValue));
                        }}
                        value={cashAmount ? Number(cashAmount) : null}
                        type="number"
                    />
                    {errorObject && errorObject.errorMessage && (<Typography
                        variant="h5"
                        style={{ textAlign: "center", fontSize: "1rem", margin: "1rem", color: "red" }}
                    >
                        {errorObject.errorMessage}
                    </Typography>)}
                </GridItem>
                <GridItem item xs={12} sm={12} md={12} style={{ textAlign: "center",margin:'0.5rem' }}>
                    <TextField
                        label="Cash Paid To"
                        required
                        variant="outlined"
                        size="small"
                        name="paidTo"
                        fullWidth
                        style={{
                            textAlign: "right",
                        }}
                        onChange={handleChangePaidTo}
                        value={paidTo ? paidTo : ""}
                        type="text"
                    />
                </GridItem>
            </Grid>
        );

    renderLazyPayDetails =
        (
            <Grid container spacing={2} style={{ height: "23vh" }}>
                <GridItem item xs={12} sm={12} md={12} style={{ marginBottom: "0.25rem", textAlign: "center" }}>

                    <Typography
                        variant="h5"
                        style={{ textAlign: "center", fontSize: "1rem", margin: "1rem", color: "black" }}
                    >
                        Coming soon...
                    </Typography>
                </GridItem>

            </Grid>
        );

    const renderMultiplePaymentModesContent = (
        <React.Fragment>
            <FormControl
                sx={{ marginRight: "3rem" }}
                component="fieldset"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    height: "3vh"
                }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isCash}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isCash}
                            name="isCash"
                        />
                    }
                    label="Cash"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isUpi}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isUpi}
                            name="isUpi"
                        />
                    }
                    label="UPI"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isLazyPay}
                            onChange={(e) => handleChangeCheckbox(e)}
                            value={isLazyPay}
                            name="isLazyPay"
                        />
                    }
                    label="Lazy Pay"
                />
            </FormControl>
        </React.Fragment>
    );

    const renderSinglePaymentModeContent = (
        <React.Fragment>
            <FormControl
                component="fieldset"
                sx={{ marginRight: "3rem" }}
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    margin: "1rem"

                    // height: "3vh"
                }}
            >
                <RadioGroup
                    row={true}
                    aria-label="paymentType"
                    name="paymentType"
                    value={paymentType}
                    style={{ marginLeft: "0.5rem", textAlign: "center", alignItems: "center", justifyContent: "center" }}
                    onChange={(e) => handleChangeRadioOptions(e)}
                >
                    <FormControlLabel value="cash" control={<Radio />} label="Cash" />
                    <FormControlLabel value="upi" control={<Radio />} label="Upi" />
                    <FormControlLabel value="lazyPay" control={<Radio />} label="Lazy Pay" />
                </RadioGroup>
            </FormControl>
        </React.Fragment>
    );

    const calculateBalanceAmountIfVer = (upi, cash) => {
        let balanceAmount = 0.0;
        let totalOfDifferentModes = 0.0;
        console.log("Exec calculateBalanceAmountIfVer : UPI Amount : ", upi, " , cashAmount : ", cash);
        console.log(' ****** paymentMode ******  : ', paymentMode)
        if (paymentMode === "Single") {
            if (isUpiRadioItem) {
                totalOfDifferentModes = totalOfDifferentModes + (upi ? Number(upi) : 0.0);
                console.log("totalOfDifferentModes When isUpi is Selected :=> ", totalOfDifferentModes);
            } else if (isCashRadioItem) {
                totalOfDifferentModes = totalOfDifferentModes + (cash ? Number(cash) : 0.0);
                console.log("totalOfDifferentModes  When isCash is Selected:=> ", totalOfDifferentModes);
            }
        }

        if (paymentMode === "Multiple") {
            if (paymentType.includes("cash")) {
                console.log("totalOfDifferentModes When Multiple  && isCash is Selected :=> ", totalOfDifferentModes);
                totalOfDifferentModes =
                    totalOfDifferentModes +
                    (cash
                        ? Number(cash)
                        : 0.0)
            }
            else if (paymentType.includes("upi")) {
                console.log("totalOfDifferentModes When Multiple  && isUpi is Selected :=> ", totalOfDifferentModes);
                totalOfDifferentModes =
                    totalOfDifferentModes +
                    (upi
                        ? Number(upi)
                        : 0.0)
            }
            console.log("totalOfDifferentModes When Multiple  && isUpi is Selected :=> ", totalOfDifferentModes);
        }

        console.log("paymentType.includes(upi) ", paymentType.includes("upi"));
        console.log("paymentType.includes(cash) ", paymentType.includes("cash"));

        console.log("itemsPriceObject :=> ", itemsPriceObject);
        console.log("totalOfDifferentModes : ", totalOfDifferentModes, " UPI : ", upi, " CASH : ", cash, "itemsPriceObject.totalCartItemsPrice", itemsPriceObject.totalCartItemsPrice);
        balanceAmount = (Number(itemsPriceObject.totalCartItemsPrice) - Number(itemsPriceObject.totalDiscountPrice)) - Number(totalOfDifferentModes);
        console.log("balanceAmount : ", balanceAmount);
        setBalanceAmountTobePaid((prev) => balanceAmount);
    };

    return (
        <React.Fragment>
            {customError && customError.errorMessage && customError.errorMessage !== "" && (<Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar} autoHideDuration={6000} onClose={handleCloseSnackBar} key={vertical + horizontal}>
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                    Please Ensure Cash Amount doesn't exceed 2 Laks.!
                </Alert>
            </Snackbar>)}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <GridContainer spacing={2} alignItems="center" justify="center">
                        <Grid item xs={12} >
                            <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                                    <img
                                        alt="Order Placed"
                                        src={greeTickImage}
                                        style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                                    />
                                </div>
                                <Typography
                                    variant="h5"
                                    color="black"
                                    style={{
                                        margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                                        fontWeight: "600",
                                        fontSize: "1rem",
                                        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                                    }}
                                >
                                    Thanks , your order is placed.
                                </Typography>
                            </Paper>
                        </Grid>
                    </GridContainer>
                </DialogContent>
            </BootstrapDialog>
            <GridContainer spacing={2} alignItems="center" justify="center">
                <Grid item xs={12} sm={12} md={12}>
                    <Paper style={{ margin: "1rem" }}>
                        {renderDiscTaxTotalAmountContentUsingCards}
                    </Paper>
                </Grid>
            </GridContainer>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card >
                        <CardBody>
                            <GridContainer spacing={2}>
                                <GridItem item xs={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" ,width:'100%' }}>
                                        <InputLabel id="demo-simple-select-label">
                                            Payment Mode
                                        </InputLabel>
                                        <Select
                                            label="Payment Mode"
                                            placeholder="Payment Mode"
                                            variant="outlined"
                                            required
                                            size="small"
                                            name="paymentMode"
                                            onChange={handlePaymentModeChange}
                                            value={paymentMode}
                                            style={{ width:'50%' }}
                                        >
                                            <MenuItem key="Single" value="Single">Single</MenuItem>
                                            <MenuItem key="Multiple" value="Multiple">Multiple</MenuItem>
                                        </Select>
                                </GridItem>
                                <GridItem item xs={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                    {paymentMode === "Single" &&
                                        renderSinglePaymentModeContent}
                                    {paymentMode === "Multiple" &&
                                        renderMultiplePaymentModesContent}
                                </GridItem>
                                {isCash || isCashRadioItem ? (
                                    <GridItem xs={12} sm={12} md={12} >
                                        {renderCashDetails}
                                    </GridItem>
                                ) : null}
                                {isUpi || isUpiRadioItem ? (
                                    <GridItem xs={12} sm={12} md={12} >
                                        {renderUpiDetails}
                                    </GridItem>
                                ) : null}
                                {isLazyPay || isLazyPayRadioItem ? (
                                    <GridItem xs={12} sm={12} md={12} >
                                        {renderLazyPayDetails}
                                    </GridItem>
                                ) : null}

                                {(paymentType.includes("upi") || isUpi) && (
                                    <GridItem xs={12} sm={12} md={12} style={{ margin: "0.5rem" }}>
                                        {renderUpiScannerInformation}
                                    </GridItem>
                                )}
                                {/*
                            PLACE ORDER BUTTON : START
                            */}
                                <GridItem xs={12} sm={12} md={12} style={{
                                            textAlign:"center",
                                            justifyContent:"center",
                                            alignItems:"center"
                                        }}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        type="submit"
                                        color="secondary"
                                        onClick={placeOrderHandler}
                                        style={{
                                            textTransform: "capitalize",
                                            borderRadius: "5px",
                                            width:"25%",
                                        }}
                                        disabled={((isUpi === true || isUpiRadioItem === true) && upiReference && upiReference.length > 0) ?
                                            false : ((isCash === true || isCashRadioItem === true) && paidTo && paidTo.length > 0) ?
                                                false : (isLazyPay === true || isLazyPayRadioItem === true) ? false : (errorObject && errorObject.errorMessage && errorObject.errorMessage.length === 0 ? false : true)}
                                    >
                                        Place Order
                                    </Button>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default VehicleSalesPayment;
