import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Message from "../Message";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import CardBody from "../Card/CardBody.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Tooltip,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@mui/material";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { red } from "@mui/material/colors";
import {
  listDomesticByProductId,
  updateDomesticByProductId,
  deleteDomesticByProductId,
} from "../../actions/domesticAction";
import { listAllProducts } from "../../actions/productAction";
import { useNavigate, useParams } from "react-router-dom";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { formatToLocaleCurrency } from "../../util/Utility.js";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const DomesticListScreen = (props) => {
  const navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  const gridRef = useRef();
  let noDataFoundContent = ""
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "UnitOfMeassure",
      headerName: "UOM",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "InKgs",
      headerName: "In Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "center" },
    },
    {
      field: "UnitPrice",
      headerName: "Unit Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "Mrp",
      headerName: "MRP",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "right" },
    },
    // {
    //   field: "DiscountStartsFrom",
    //   headerName: "Discount from[Kgs]",
    //   resizable: true,
    //   suppressSizeToFit: true,
    //   width: 250,
    //   minWidth: 250,
    //   maxWidth: 250,
    // },
    // {
    //   field: "DiscountPercent",
    //   headerName: "Discount [%]",
    //   resizable: true,
    //   suppressSizeToFit: true,
    //   width: 250,
    //   minWidth: 250,
    //   maxWidth: 250,
    // },
    {
      field: "CGST",
      headerName: "CGST [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "SGST",
      headerName: "SGST [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "IGST",
      headerName: "IGST [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "TCS",
      headerName: "TCS [%]",
      resizable: true,
      suppressSizeToFit: true,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
      cellStyle: { textAlign: "right" },
    },
    {
      field: "Id",
      headerName: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Edit Bulk Details" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => handleEdit(params)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      cellStyle: { textAlign: "center" },
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "Id",
      headerName: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Delete Domestic Details" arrow>
            <DeleteIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("Deleting ", params.value)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteIcon>
          </Tooltip>
        </span>
      ),
      cellStyle: { textAlign: "center" },
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const export_label = "Export > CSV";

  const dispatch = useDispatch();
  const { id } = useParams();
  const productId = id;
  let productName = "";
  console.log("productId : " + productId);
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const [open, setOpen] = useState(() => {
    return false;
  });
  const [editableDomesticRecord, setEditableDomesticRecord] = useState(() => {
    return {};
  });

  const allProducts = useSelector((state) => state.allProducts);
  const { all_products } = allProducts;

  useEffect(() => {
    console.log("useEffect Exec /...");
    dispatch(listDomesticByProductId(productId));
  }, [dispatch, productId, editableDomesticRecord]);

  useEffect(() => {
    dispatch(listAllProducts());
  }, [dispatch]);

  const unitOfMessureChangeHandler = (unitOfMessure) => {
    setEditableDomesticRecord({
      ...editableDomesticRecord,
      unitOfMessure: unitOfMessure,
    });
    console.log(editableDomesticRecord);
  };
  const inKgsChangeHandler = (inKgs) => {
    setEditableDomesticRecord({
      ...editableDomesticRecord,
      inKgs: inKgs,
    });
    console.log(editableDomesticRecord);
  };
  const unitPriceChangeHandler = (unitPrice) => {
    setEditableDomesticRecord({
      ...editableDomesticRecord,
      unitPrice: unitPrice,
    });
    console.log(editableDomesticRecord);
  };
  const mrpChangeHandler = (mrp) => {
    setEditableDomesticRecord({
      ...editableDomesticRecord,
      mrp: mrp,
    });
    console.log(editableDomesticRecord);
  };

  const discountChangeHandler = (discount) => {
    setEditableDomesticRecord({
      ...editableDomesticRecord,
      discountStartsFrom: discount,
    });
    console.log(editableDomesticRecord);
  };
  const discountPercentChangeHandler = (discountPercent) => {
    setEditableDomesticRecord({
      ...editableDomesticRecord,
      discountPercent: discountPercent,
    });
    console.log(editableDomesticRecord);
  };
  const cgstChangeHandler = (cgst) => {
    setEditableDomesticRecord({ ...editableDomesticRecord, cgst: cgst });
    console.log(editableDomesticRecord);
  };
  const sgstChangeHandler = (sgst) => {
    setEditableDomesticRecord({ ...editableDomesticRecord, sgst: sgst });
    console.log(editableDomesticRecord);
  };
  const igstChangeHandler = (igst) => {
    setEditableDomesticRecord({ ...editableDomesticRecord, igst: igst });
    console.log(editableDomesticRecord);
  };
  const tcsChangeHandler = (tcs) => {
    setEditableDomesticRecord({ ...editableDomesticRecord, tcs: tcs });
    console.log(editableDomesticRecord);
  };

  const getProductNameByProductId = (prdId) => {
    const result =
      all_products && all_products.length > 0 && all_products.filter((product) => product._id === prdId);
    console.log(
      "Filter RESULT ::==>> ",
      result ? result.length : " Filter couldnt foind any..! ",
      result
    );
    return result;
  };

  if (productId) {
    const resArray = getProductNameByProductId(productId);
    productName = resArray && resArray.length > 0 ? resArray[0].name : "";
  }

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem", textTransform: "none" }} color="gray">
              <i>No records found for  {productName ? productName : productId}</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );
  const handleEdit = (params) => {
    console.log("Domestic ID SELECTED : ", params.data.Id, params.data);
    setOpen(true);
    setEditableDomesticRecord({
      _id: params.data.Id,
      unitOfMessure: params.data.UnitOfMeassure,
      inKgs: params.data.InKgs,
      unitPrice: params.data.UnitPrice,
      mrp: params.data.Mrp,
      discountStartsFrom: params.data.DiscountStartsFrom,
      discountPercent: params.data.DiscountPercent,
      cgst: params.data.CGST,
      sgst: params.data.SGST,
      igst: params.data.IGST,
      tcs: params.data.TCS,
    });
  };

  const handleDelete = (rec) => {
    console.log("handleDelete Exec...ID SELECTED  ", rec);
    // dispatch(deleteDomesticByProductId(domesticRec._id));
  };

  const createDomesticItemHandler = () => {
    console.log("Before Push  product :" + productId);
    // setEditableDomesticRecord({});
    navigate(`/admin/domestic/new/${productId}`);
  };

  const submitHandler = () => {
    console.log("EXEC submitHandler");
    console.log(editableDomesticRecord);
    dispatch(
      updateDomesticByProductId(
        editableDomesticRecord._id,
        editableDomesticRecord.unitOfMessure,
        editableDomesticRecord.inKgs,
        editableDomesticRecord.unitPrice,
        editableDomesticRecord.mrp,
        editableDomesticRecord.discountStartsFrom,
        editableDomesticRecord.discountPercent,
        editableDomesticRecord.cgst,
        editableDomesticRecord.sgst,
        editableDomesticRecord.igst,
        editableDomesticRecord.tcs
      )
    );
    setOpen(false);
    setEditableDomesticRecord({});
  };

  const domesticList = useSelector((state) => state.domesticListByProductId);
  const { loading, domestic, error } = domesticList;

  let renderDomestic = "";

  if (domestic && domestic.length > 0) {
    rowData = domestic.map((eachEntry, index) => {
      console.log("eachEntry : ", eachEntry);
      return {
        "#": index + 1,
        UnitOfMeassure: eachEntry.unitOfMessure,
        InKgs: eachEntry.inKgs,
        UnitPrice: eachEntry.unitPrice ? formatToLocaleCurrency(Number(eachEntry.unitPrice)) : 0,
        Mrp: eachEntry.mrp ? formatToLocaleCurrency(Number(eachEntry.mrp)) : 0,
        DiscountStartsFrom: eachEntry.discountStartsFrom ? formatToLocaleCurrency(Number(eachEntry.discountStartsFrom)) : 0,
        DiscountPercent: eachEntry.discountPercent ? formatToLocaleCurrency(Number(eachEntry.discountPercent)) : 0,
        CGST: eachEntry.cgst? formatToLocaleCurrency(Number(eachEntry.cgst)) : 0,
        SGST: eachEntry.sgst? formatToLocaleCurrency(Number(eachEntry.sgst)) : 0,
        IGST: eachEntry.igst? formatToLocaleCurrency(Number(eachEntry.igst)) : 0,
        TCS: eachEntry.tcs? formatToLocaleCurrency(Number(eachEntry.tcs)) : 0,
        Id: eachEntry._id,
        Id: eachEntry._id,
      };
    });
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        ></AgGridReact>
      </div>
    );

  }

  return (
    <React.Fragment>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <CustomBackdropSpinner />}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "5rem" }}
                  />
                </Tooltip>

                Price Entries for Product :{" "}
                {productId ? productName : productId}
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  onClick={createDomesticItemHandler}
                  style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                >
                  <AddCircleIcon
                    fontSize="medium"
                    style={{ float: "right", marginRight: "10px" }}
                  />
                  New Entry
                </Button>
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>


        {renderContentUsingAGGrid && (<GridItem
          xs={12}
          sm={12}
          md={12}
        >
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon fontSize="medium" style={{ float: "right", margin: "1rem" }} onClick={onExportClick} />
          </Tooltip>
        </GridItem>)}

        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid ? renderContentUsingAGGrid : noDataFoundContent}
        </GridItem>
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader >
                    <Typography
                      variant="h6"
                      style={{ margin: "0.5rem" }}
                      color="black"
                    >

                    </Typography>
                    <h2 align="center"> Edit Domestic Entry </h2>
                    <Divider />
                  </CardHeader>
                  <CardBody>
                    <form onSubmit={submitHandler}>
                      <Grid
                        container
                        spacing={2}
                        style={{
                          marginTop: "1.5rem",
                          marginBottom: "1.5rem",
                        }}
                      >
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Units Of Messure"
                            variant="outlined"
                            name="uom"
                            onChange={(e) =>
                              unitOfMessureChangeHandler(e.target.value)
                            }
                            type="text"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.unitOfMessure
                                ? editableDomesticRecord.unitOfMessure
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="In Kgs"
                            variant="outlined"
                            name="inKgs"
                            onChange={(e) => inKgsChangeHandler(e.target.value)}
                            type="text"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.inKgs
                                ? editableDomesticRecord.inKgs
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Unit Selling Price"
                            variant="outlined"
                            name="unitPrice"
                            id="unitPrice"
                            onChange={(e) =>
                              unitPriceChangeHandler(e.target.value)
                            }
                            type="number"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.unitPrice
                                ? editableDomesticRecord.unitPrice
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="MRP"
                            variant="outlined"
                            name="mrp"
                            id="mrp"
                            onChange={(e) =>
                              mrpChangeHandler(e.target.value)
                            }
                            type="number"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.mrp
                                ? editableDomesticRecord.mrp
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Discount Starts From"
                            variant="outlined"
                            name="discountStartsFrom"
                            id="discountStartsFrom"
                            onChange={(e) =>
                              discountChangeHandler(e.target.value)
                            }
                            type="number"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.discountStartsFrom
                                ? editableDomesticRecord.discountStartsFrom
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="Discount [%]"
                            variant="outlined"
                            name="discountPercent"
                            id="discountPercent"
                            onChange={(e) =>
                              discountPercentChangeHandler(e.target.value)
                            }
                            type="number"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.discountPercent
                                ? editableDomesticRecord.discountPercent
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="CGST"
                            variant="outlined"
                            name="taxPercent"
                            id="cgst"
                            onChange={(e) => cgstChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.cgst
                                ? editableDomesticRecord.cgst
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="SGST"
                            variant="outlined"
                            name="sgst"
                            id="sgst"
                            onChange={(e) => sgstChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.sgst
                                ? editableDomesticRecord.sgst
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="IGST"
                            variant="outlined"
                            name="igst"
                            id="igst"
                            onChange={(e) => igstChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.igst
                                ? editableDomesticRecord.igst
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} md={4}>
                          <TextField
                            className={classes.inputText}
                            label="TCS"
                            variant="outlined"
                            name="tcs"
                            id="tcs"
                            onChange={(e) => tcsChangeHandler(e.target.value)}
                            type="number"
                            size="small"
                            value={
                              editableDomesticRecord &&
                                editableDomesticRecord.tcs
                                ? editableDomesticRecord.tcs
                                : ""
                            }
                            fullWidth
                            InputProps={{
                              classes: { input: classes.input },
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          md={3}
                          style={{
                            textTransform: "capitalize",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "auto",
                            marginTop: "1.5rem",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Button
                            size="small"
                            variant="contained"
                            type="submit"
                            color="secondary"
                            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
                          >
                            Update
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </GridContainer>
    </React.Fragment>
  );
};

export default DomesticListScreen;
