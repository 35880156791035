import mongoose from 'mongoose'
export const groupAndSum = (purchaseOrderProducts) => {
  let result = [];
  purchaseOrderProducts.reduce(function (res, eachProduct) {
    if (!res[eachProduct.name]) {
      res[eachProduct.name] = { name: eachProduct.name, unit: 0.0 };
      result.push(res[eachProduct.name]);
    }
    res[eachProduct.name].unit += eachProduct.unit * eachProduct.quantity;
    return res;
  }, {});

  console.log('groupAndSum', result);
  return result;
};

export const groupByProductNameAndSumQuantityForVehicle = (vehicleInvoiceProducts) => {
  console.log("Exec groupByProductNameAndSumQuantityForVehicle productsInCart : ", vehicleInvoiceProducts)
  let result = [];
  vehicleInvoiceProducts.reduce(function (res, eachProduct) {
    if (!res[eachProduct.name]) {
      res[eachProduct.name] = { name: eachProduct.name, unitPrice: 0.0 };
      result.push(res[eachProduct.name]);
    }
    res[eachProduct.name].unitPrice += (eachProduct.unitPrice ? Number(eachProduct.unitPrice) : 0.0) * eachProduct.quantity ? Number(eachProduct.quantity) : 0
    return res;
  }, {});

  console.log('groupByProductNameAndSumQuantityForVehicle', result);
  return result;
};

export const transformToDDMMYYY = (date) => {
  let transformedDate = ""
  if (date) {
    const dateArray = date.split('-');
    transformedDate = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0]
  }
  console.log('transformedDate : ', transformedDate)
  return transformedDate
}

export const buildColDef = (data) => {

}

export const preferredCustomerDiscount = () => {
  let x = 0;
  let values = [];
  for (var i = (x + 0.5); i <= 10.0; i += 0.5)
    values.push(i.toFixed(1));
  return values;
};

export const getMonthNameByInt = (i) => {
  console.log("Exec getMonthNameByInt .", typeof i)
  switch (i) {
    case 0:
      return "January"
    case 1:
      return "February"
    case 2:
      return "March"
    case 3:
      return "April"
    case 4:
      return "May"
    case 5:
      return "June"
    case 6:
      return "July"
    case 7:
      return "August"
    case 8: {
      console.log("Matched September ")
      return "September"
    }
    case 9:
      return "October"
    case 10:
      return "November"
    case 11:
      return "December"
    default:
      break;
  }
}

export const getStoreName = (id, stores) => {
  const storeObject = stores.filter(each => each._id === id)
  // )
  console.log("storeObject : ", storeObject[0]);
}

export const getStorOrWarehouseNameById = (stOrWhId) => {
  console.log("getStorOrWarehouseNameById for store : ", stOrWhId)
  let storeOrWarehouseObj;
  let storeOrWarehouseName = null;
  if (store && stores && store.length > 0 && stores.length > 0) {
    storeOrWarehouseObj = stores.filter(each => each._id === stOrWhId)
  } else if (warehouse && warehouse.length > 0 && warehouseInfos && warehouseInfos.length > 0) {
    storeOrWarehouseObj = warehouseInfos.filter(each => each._id === stOrWhId)
  }
  // storeOrWarehouseObj = store && stores && store.length > 0 && stores.length > 0 ? stores.filter(each => each._id === stOrWhId):(warehouse && warehouse.length > 0 && warehouseInfos && warehouseInfos.length > 0 ? warehouseInfos.filter(each => each._id === stOrWhId):"":"");
  if (storeOrWarehouseObj && storeOrWarehouseObj.length > 0) {
    console.log("******* storeOrWarehouseObj ********* : ", storeOrWarehouseObj);
    storeOrWarehouseName = storeOrWarehouseObj && storeOrWarehouseObj[0].storeName ? storeOrWarehouseObj[0].storeName : (storeOrWarehouseObj[0].warehouseName ? storeOrWarehouseObj[0].warehouseName : "");
    if (storeOrWarehouseName && storeOrWarehouseName.length > 0) {
      setAutoCompleteFromWhStoreValue(prev => storeOrWarehouseName);
      console.log("Exe getStorOrWarehouseNameById for  Id : ", stOrWhId, " Store Or Warehouse Name : ", storeOrWarehouseName);
    }
  }
}

export const convertInvoiceIdToDigits = (id) => {
  // Convert ObjectId to hexadecimal string
  // mongoose.Types.ObjectId()
  const objId = mongoose.Types.ObjectId(id)
  const hexStr = objId.toHexString();

  // Extract and convert parts
  const timestampHex = hexStr.substring(0, 8);
  const randomValueHex = hexStr.substring(8, 18);
  const incrementingCounterHex = hexStr.substring(18);
  const timestampDecimal = parseInt(timestampHex, 16);
  const randomValueDecimal = parseInt(randomValueHex, 16);
  const incrementingCounterDecimal = parseInt(incrementingCounterHex, 16);
  const result = timestampDecimal + '-' + randomValueDecimal + '-' + incrementingCounterDecimal
  {/**
const timestampMillis = timestampDecimal * 1000;
*/}


  // console.log(`result in  Decimal:-> ${result}`);
  return result
}

export const formatDate = (date) => {
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
}

export const getFirstAndlastDayOfCurrentMonth = () => {
  // console.log('Exec getFirstAndlastDayOfMonth')
  const now = new Date();

  // Get the first day of the current month
  const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);

  // Get the last day of the current month
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  // Format the dates
  const formattedFirstDay = formatDate(firstDay);
  const formattedLastDay = formatDate(lastDay);
  const firstAndlastDayOfMonth = {
    firstDay: formattedFirstDay,
    lastDay: formattedLastDay
  }
  // console.log('firstAndlastDayOfMonth : ', firstAndlastDayOfMonth)
  return firstAndlastDayOfMonth
}

export const getFirstAndlastDayOfPreviousMonth = () => {
  // console.log('Exec getFirstAndlastDayOfPreviousMonth')
  const now = new Date();

  // Get the first day of the Previous month
  const firstDay = new Date(now.getFullYear(), now.getMonth() - 1, 1);

  // Get the last day of the Previous month
  const lastDay = new Date(new Date().getFullYear(), (new Date().getMonth() - 1) + 1, 0);

  // Format the dates
  const formattedFirstDay = formatDate(firstDay);
  const formattedLastDay = formatDate(lastDay);
  const firstAndlastDayOfMonth = {
    firstDay: formattedFirstDay,
    lastDay: formattedLastDay
  }
  // console.log('firstAndlastDayOfMonth : ', firstAndlastDayOfMonth)
  return firstAndlastDayOfMonth
}

export const calculateTax = (unitPrice, quantity, sgst, cgst) => {
  const productTaxAmount = unitPrice && quantity ? (((Number(unitPrice) * Number(quantity)) * 100) / (100 + Number(sgst + cgst))) * (Number(sgst + cgst) / 100) : 0.0
  console.log('calculateTax : ', Number(productTaxAmount).toFixed(2))
  return productTaxAmount
}

export const formatToLocaleCurrency = (amount) => {
  // Convert the number to a string
  let numStr = amount && amount.toString() && amount.toString().length > 0 ? amount.toString() : "0";

  // Split the integer and decimal parts
  let [integerPart, decimalPart] = numStr.split('.');

  // Use regex to format the integer part in Indian numbering
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  // Group other digits in pairs of two
  const formattedIntegerPart = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + (otherDigits.length > 0 ? ',' : '') + lastThreeDigits;

  // Format the decimal part if it exists
  const formattedDecimalPart = decimalPart ? `.${decimalPart}` : '.00';

  // Combine and return the final formatted currency
  return formattedIntegerPart + formattedDecimalPart;
}

export const calculateCtcProductsAveragePrice = (obj) => {
  const {productName, oldEntry, newEntry} = obj
  let oldCtcProductsTotalQuantity, oldCtcProductsPricePerKg, oldCtcTotalValidPowderWeight, newCtcProductsTotalQuantity, newCtcProductsPricePerKg, newCtcTotalValidPowderWeight
  if (oldEntry) {
    ({
      oldCtcProductsTotalQuantity,
      oldCtcProductsPricePerKg,
      oldCtcTotalValidPowderWeight
    } = oldEntry);
  }

  if (newEntry) {
    ({
      newCtcProductsTotalQuantity,
      newCtcProductsPricePerKg,
      newCtcTotalValidPowderWeight
    } = newEntry);
  }
  console.log(' ############### calculateCtcProductsAveragePrice ... ############### ')
  console.log('Old Entry : ', oldEntry)
  console.log('New Entry : ', newEntry)
  let ctcProductAvgPrice
  if (oldCtcProductsTotalQuantity && oldCtcProductsPricePerKg && oldCtcTotalValidPowderWeight &&
    newCtcProductsTotalQuantity && newCtcProductsPricePerKg && newCtcTotalValidPowderWeight
  ) {
    console.log('PERFORMING PRODUCTS AVG PRICE CALCULATION AS BOTH OLD AND NEW ENTRY EXISTS...')
    ctcProductAvgPrice = ((oldCtcProductsTotalQuantity * oldCtcProductsPricePerKg) + (newCtcProductsTotalQuantity * newCtcProductsPricePerKg)) / (oldCtcTotalValidPowderWeight + newCtcTotalValidPowderWeight)
  } else if (newCtcProductsTotalQuantity && newCtcProductsPricePerKg && newCtcTotalValidPowderWeight && !oldCtcProductsPricePerKg && !oldEntry) {
    console.log('PERFORMING PRODUCTS AVG PRICE CALCULATION AS ONLY NEW ENTRY EXISTS...')
    ctcProductAvgPrice = (newCtcProductsTotalQuantity * newCtcProductsPricePerKg) / newCtcTotalValidPowderWeight
  }

  console.log('Calculated productAvgPrice  : ', ctcProductAvgPrice, ' For Product  : ', productName)
  return {
    product: productName, productsAvgPrice: ctcProductAvgPrice
  }
}


export const convertToMMDDYYYY = (dateStr) => {
  const [day, month, year] = dateStr.split("/"); // Split DD/MM/YYYY
  return `${month}/${day}/${year}`; // Rearrange to MM/DD/YYYY
}