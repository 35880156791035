import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.common.black,
    background: theme.palette.common.black,
    color: theme.palette.common.black,
    width: "100%",
    zIndex: 1302,
    position: "relative",
  },
  mainContainer: {
    position: "absolute",
    backgroundColor: "#26A541",
  },
  link: {
    color: "darkgray",
    fontFamily: "Arial",
    fontSize: "0.75rem",
    fontWeight: "bold",
    textDecoration: "none",
  },
  gridItem: {
    margin: "3em",
  },
}));

/*************  ✨ Codeium Command ⭐  *************/
/**
 * Footer component that renders the footer section of the webpage.
 * It contains a Grid container that centers its contents and displays 
 * a copyright message.
 *
 * @param {object} props - The properties passed to the component.
 */

/******  64517af9-99fb-4691-9750-933cd48abef7  *******/
export default function Footer(props) {
  const classes = useStyles();

  return (
    <footer className={classes.footer} style={{ position: "relative", bottom: '0px' }} >
      <Grid container justify="center" style={{ backgroundColor: "white", justifyContent: "center" }}>
        <div>
          <h3 style={{
            textTransform: "none", fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            fontSize: "0.75rem", fontWeight: "600", letterSpacing: "1px"
          }}>
            All rights reserved by novellogiks.com Copyright &copy; 2025 , Ver : 2.0.0-rp , 01-04-2025.
          </h3>
        </div>
      </Grid>
    </footer>
  );
}
