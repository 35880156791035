import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GridItem from "../Grid/GridItem.js";
import InputLabel from "@mui/material/InputLabel";

import GridContainer from "../Grid/GridContainer.js";
import {
  Tooltip,
  Typography,
  Button,
  Select,
  colors,
  Icon,
  IconButton,
  MenuItem,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { listVehicleSalesInvoices } from "../../actions/vehicleSalesInvoiceAction.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { exportVehicleSalesProductWiseToExcel } from "../../util/exportToExcelUtil.js";
import { convertInvoiceIdToDigits } from '../../util/Utility.js'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { listCustomersByParentUser } from "../../actions/customerAction.js";
import rupeeSvgIcon from "../../assets/images/currency-inr.svg";
import { listStockByVehicleNo } from "../../actions/vehicleStockAction.js";
import { LogarithmicScale } from "chart.js";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "300",
      lineHeight: "1",
    },
  },
};

const CtcTrackingScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, role, userName } = userInfo;

  const initialChips = [
    { id: 1, label: 'Cold Storages' },
    { id: 2, label: 'Factory' },
    { id: 3, label: 'Production' },
    { id: 4, label: 'Finished Goods' },
    { id: 5, label: 'Bonds' },
    { id: 6, label: 'Receipts' }
  
  ];

  // State to keep track of the selected chip ID
  const [selectedChipId, setSelectedChipId] = useState(() => null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const vehicle_sale_invoice_list_data = []
  let renderTransactionsUsingCards = "";
  let noDataFoundContent = null;
  const coldStorages = ["COLD STORAGE 1", "COLD STORAGE 2", "COLD STORAGE 3"]
  useEffect(() => {
    // dispatch(listVehicleSalesInvoices()); MAKE ACTUAL API CALL [FETCH COLD STORAGES, FACTORIES ...]
  }, [dispatch]);


  // useEffect(() => {
  //   console.log('Use Effect getting Called as Vehicle Sales Report By Products has Changed')
  //   if (selectedChipId === 2) {
  // [PERFORM THE ACTUAL LogIC]
  //   }
  // }, [vehicle_sale_invoice_list_data, selectedChipId]);

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  // Handler for chip click
  const handleChipClick = (chipId) => {
    console.log('chipId : ', chipId)
    setSelectedChipId(chipId);
  };


  const createHandler = (category) => {
    navigate("/admin/vehicle-sales-invoice/new");
  };

  const [categorySelected, setCategorySelected] = useState(() => {
    return "";
  });
  const handleChangeCategory = (e) => {
    console.log("Category Changed  " + e.target.value);
    setCategorySelected(() => e.target.value);
  };

  let renderColdStoragesOptions = "";
  if (coldStorages && coldStorages.length > 0) {
    renderColdStoragesOptions = coldStorages.map((eachColdStorage, idx) => {
      return (
        <MenuItem key={idx} value={eachColdStorage}>
          {eachColdStorage}
        </MenuItem>
      );
    })
  }

  return (
    <React.Fragment>
      {/* {vehicle_sale_invoice_list_loading && <CustomBackdropSpinner />} */}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12} style= {{marginTop:"1rem"}}>
          <Card>
          <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                CTC Tracking
                 {/* <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{ textTransform: "capitalize", borderRadius: "5px" , float:'right', textAlign:'right'}}
            >
              <AddCircleIcon
              fontSize="medium"
              style={{ float: "left", marginRight:"10px" }}
            />
              New
            </Button> */}
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      
      <GridContainer spacing={2} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
        <div>
          <Stack direction="row" spacing={10} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
            {initialChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                variant="outlined"
                onClick={() => handleChipClick(chip.id)}
                style={{
                  backgroundColor: selectedChipId === chip.id ? '#f0c14b' : '#D3D3D3',
                  color: selectedChipId === chip.id ? 'white' : 'black',
                  width: "10rem"
                }}
              />

            ))}
          </Stack>
        </div>
      </GridContainer>
      {selectedChipId && selectedChipId === 1 && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h4 >Cold Storages</h4>
          <InputLabel id="demo-simple-select-label">
            Select Cold storage
          </InputLabel>
          <Select
            label="Select Cold storage"
            placeholder="Select Cold storage"
            variant="outlined"
            required
            size="small"
            name="Select Cold storage"
            value={categorySelected}
            onChange={handleChangeCategory}
            style={{ width: "15rem" }}
          >
            {renderColdStoragesOptions}
          </Select>
        </GridItem>
      </GridContainer>
      
}
      {selectedChipId && selectedChipId === 2 && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h3 >cherlapally Factory </h3>
          
        </GridItem>
      </GridContainer>}
      {selectedChipId && selectedChipId === 3 && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h4 >Production </h4>
          
        </GridItem>
      </GridContainer>}
      {selectedChipId && selectedChipId === 4 && <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
        <h4 >Finished Goods</h4>
          
        </GridItem>
      </GridContainer>}
      
      
    </React.Fragment>
  );
};

export default CtcTrackingScreen;
