import React, { useEffect, useMemo, useRef , useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
// import { } from "../../actions/customerAction";
import { useNavigate } from "react-router-dom";
import { Typography,Tooltip,Button } from "@mui/material";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../../Message";
import CustomBackdropSpinner from "../../CustomBackdropSpinner";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { listColdStorages } from "../../../actions/coldStorageAction";

const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const ColdStorageListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const columnDefs = [
    { 
      field: "#" ,
    resizable: true, 
    suppressSizeToFit: true,
    width: 75, 
    minWidth: 75, 
    maxWidth: 75,
    
  },
    { 
      field: "Name",
    headerName: "Name",
    resizable: true, 
    suppressSizeToFit: true, 
    width: 250,
    minWidth: 250,
    maxWidth: 250,
    filter: true,
    cellStyle : {
      textAlign : "center"
    }
   },
    { field: "ContactNo",
    headerName: "Contact No",
    resizable: true, 
    suppressSizeToFit: true,
    width: 200, 
    minWidth: 200, 
    maxWidth: 200, 
    filter: true,
    cellStyle : {
      textAlign : "right"
    }
  },
    { field: "BillingAddress" ,
    headerName: "Billing Address",
    resizable: true, 
    suppressSizeToFit: true,
    width: 500, 
    minWidth: 500, 
    maxWidth: 500,
    filter: true,
    cellStyle : {
      textAlign : "center"
    }
  },
  { field: "City" ,
    headerName: "City",
    resizable: true, 
    suppressSizeToFit: true,
    width: 400, 
    minWidth: 400, 
    maxWidth: 400,
    filter: true,
    cellStyle :{
      textAlign:'center'
    }
  },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const [ coldStoragesData , setColdStoragesData ] = useState(()=>[]);
  const userLogin = useSelector(
    (state) => state.userLogin
  );

  const coldStorageList = useSelector(
    (state) => state.coldStorageList
  );

  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const pos = storeName ? storeName : warehouseName ? warehouseName : ''
  const { cold_storage_list_loading , cold_storage_list, cold_storage_list_error} = coldStorageList
  
  const populateDataToGrid = ()=>{
    console.log('populateDataToGrid ...!')

    if (cold_storage_list && cold_storage_list.length > 0) {
      setColdStoragesData(( prev ) => cold_storage_list.map((coldStorage, index) => {
        console.log("coldStorage : ", coldStorage);
        return {
          "#": index + 1,
          Name: coldStorage.name,
          ContactNo: coldStorage.contactNo,
          BillingAddress: coldStorage.billingAddress,
          City: coldStorage.city,
        };
      }))
    }
  }

  const createColdStorageHandler = ()=>{
    console.log('Routing to New Cold Storage Register ')
    navigate(`/ctc/cold-storage/new`);
  }
  useEffect(()=>{
    console.log('Loading Cold Storages List Page')
    dispatch(listColdStorages())
  },[])

  useEffect(()=>{
    if( cold_storage_list && cold_storage_list.length> 0 ){
      console.log('POPULATE COLD STORAGE DATA WHEN ITS NOT EMPTY TO AG GRID')
      populateDataToGrid()
    }
  },[cold_storage_list])
    
  const renderStoragesUsingAGGrid = (
    <>
      <div className="ag-theme-quartz" style={{ width: "75%", height: '65vh', marginLeft:'auto', marginRight:'auto' }}>
        <AgGridReact
          ref={gridRef}
          rowData={coldStoragesData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        ></AgGridReact>
      </div>
    </>
  );

  return (
    <React.Fragment>
      {cold_storage_list_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
            <GridItem xs={12} sm={12} md={12}>
              <Card>
              <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                Cold Storage
                 <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick = {createColdStorageHandler}
              style={{ textTransform: "capitalize", borderRadius: "5px" , float:'right', textAlign:'right'}}
            >
              <AddCircleIcon
              fontSize="medium"
              style={{ float: "right", marginRight:"10px" }}
            />
              New
            </Button>
              </Typography>
            </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
        </GridItem>
        <GridItem xs={12} sm={12} md={12} >
          {renderStoragesUsingAGGrid ? renderStoragesUsingAGGrid : null}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default ColdStorageListPage;
