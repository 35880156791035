import React, { useEffect, useMemo, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  listCustomersByParentUser,
  deleteCustomer,
  updateCustomer,
} from "../../actions/customerAction";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Tooltip,
  Button,
  Divider,
  TextField,
  Grid,
  Alert,
} from "@mui/material";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../Message";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import validate from "validate.js";
import {
  CUSTOMER_DELETE_RESET,
  CUSTOMER_UPDATE_RESET,
} from "../../constants/customerConstants";

const useStyles = makeStyles({
  dialogPaper: {
    width: "50vw",
    maxWidth: "50vw",
    padding: "20px",
  },
});

const validationRules = {
  name: { presence: { allowEmpty: false, message: "is required" } },
  contactNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: { is: 10, message: "must be exactly 10 digits" },
    numericality: { onlyInteger: true, message: "must be a number" },
  },
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: { message: "must be a valid email" },
  },
  billingAddress: { presence: { allowEmpty: false, message: "is required" } },
  city: { presence: { allowEmpty: false, message: "is required" } },
  pincode: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: { onlyInteger: true, message: "must be a number" },
  },
  gst: { presence: { allowEmpty: false, message: "is required" } },
};

const CustomerListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const gridRef = useRef();
  const popupParent = useMemo(() => document.body, []);
  const [open, setOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCustomerId, setDeleteCustomerId] = useState(null);
  const [action, setAction] = useState("");
  const [editableCustomerRecord, setEditableCustomerRecord] = useState({});
  const [errors, setErrors] = useState({});

  const { userInfo } = useSelector((state) => state.userLogin);
  const { customer_update_success } =
    useSelector((state) => state.customerUpdate) || {};
  const { customer_delete_success } =
    useSelector((state) => state.customerDeleted) || {};

  const {
    customer_list_parent_user_loading,
    customer_list_parent_user_error,
    customer_list_parent_user,
  } = useSelector((state) => state.customerListByParentUser);

  useEffect(() => {
    if (userInfo?.name) {
      dispatch(listCustomersByParentUser(userInfo.name));
    }
  }, [dispatch, userInfo?.name]);

  useEffect(() => {
    const fetchCustomers = () => {
      dispatch(listCustomersByParentUser(userInfo.name));
    };

    if (customer_update_success) {
      dispatch({ type: CUSTOMER_UPDATE_RESET });
      fetchCustomers();
    }

    if (customer_delete_success) {
      const timer = setTimeout(() => {
        dispatch({ type: CUSTOMER_DELETE_RESET });
        fetchCustomers();
      }, 1000); // Hides message after 1 second
      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [
    dispatch,
    customer_update_success,
    customer_delete_success,
    userInfo.name,
  ]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditableCustomerRecord((prev) => ({ ...prev, [name]: value }));

    // Real-time validation
    const fieldError = validate(
      { [name]: value },
      { [name]: validationRules[name] }
    );
    setErrors((prev) => ({
      ...prev,
      [name]: fieldError ? fieldError[name][0] : "",
    }));
  };

  const handleEdit = (params) => {
    setOpen(true);
    setAction("edit");
    setEditableCustomerRecord({
      _id: params.data.Edit,
      name: params.data.Name,
      contactNo: params.data.ContactNo,
      email: params.data.Email,
      billingAddress: params.data.BillingAddress,
      city: params.data.City,
      pincode: params.data.Pincode,
      gst: params.data.Gst,
    });
    setErrors({});
  };

  // Function to open the delete confirmation dialog
  const handleDelete = (id) => {
    console.log("Id passed to handleDelete :==>> ", id);
    setDeleteCustomerId(id);
    setDeleteDialogOpen(true);
  };

  // Function to confirm and proceed with deletion
  const confirmDelete = () => {
    console.log("confirmDelete : ", deleteCustomerId);
    if (deleteCustomerId) {
      dispatch(deleteCustomer(deleteCustomerId));
    }
    setDeleteDialogOpen(false);
    setDeleteCustomerId(null);
  };

  // Function to close the delete confirmation dialog
  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setDeleteCustomerId(null);
  };

  const handleReset = () => {
    setOpen(false);
    setEditableCustomerRecord({});
    setErrors({});
    setAction("");
  };

  const handleUpdate = () => {
    const validationErrors = validate(editableCustomerRecord, validationRules);
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    if (action === "edit") {
      dispatch(updateCustomer(editableCustomerRecord));
      handleReset();
    }
  };

  const handleAddCustomer = () => navigate("/admin/customer/signup");

  const columns = [
    { field: "#", width: 75 },
    { field: "Name", width: 350, filter: true },
    { field: "ContactNo", width: 150, filter: true },
    { field: "Email", width: 250, filter: true },
    { field: "BillingAddress", width: 400, filter: true },
    { field: "City", width: 125, filter: true },
    { field: "Gst", width: 125, filter: true },
    {
      field: "Edit",
      width: 100,
      cellRenderer: (params) => (
        <Tooltip title="Edit">
          <EditRoundedIcon
            onClick={() => handleEdit(params)}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      ),
    },
    {
      field: "Delete",
      width: 100,
      cellRenderer: (params) => (
        <Tooltip title="Delete">
          <DeleteRoundedIcon
            onClick={() => handleDelete(params.data.Edit)}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
      ),
    },
  ];

  const rowData = customer_list_parent_user?.map((customer, index) => ({
    "#": index + 1,
    Name: customer.name,
    Email: customer.email,
    ContactNo: customer.contactNo,
    BillingAddress: customer.billingAddress,
    City: customer.city,
    Gst: customer.gst,
    Edit: customer._id,
    Delete: customer._id,
  }));

  return (
    <>
      {customer_list_parent_user_loading && <CustomBackdropSpinner />}
      {customer_delete_success && (
        <Alert variant="filled" severity="warning" sx={{ 
          position: "fixed", 
          top: "50%", 
          left: "50%", 
          transform: "translate(-50%, -50%)", 
          zIndex: 9999, 
         //  backgroundColor: "red", 
          color: "white", 
          padding: "10px 20px", 
          borderRadius: "8px",
          fontSize: "18px"
        }}>
        Customer Deleted Successfuly
      </Alert>
    
      )}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader>
              <Typography variant="h5" align="center">
                <Tooltip title="Back">
                  <ArrowBackIcon
                    onClick={() => window.history.back()}
                    style={{ float: "left", cursor: "pointer" }}
                  />
                </Tooltip>
                Customers
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleAddCustomer}
                  style={{ float: "right" }}
                >
                  <AddCircleIcon /> New Customer
                </Button>
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>

        <GridItem xs={12}>
          {customer_list_parent_user_error && (
            <Message variant="danger">
              {customer_list_parent_user_error}
            </Message>
          )}
          <div
            className="ag-theme-quartz"
            style={{
              width: "75%",
              height: "65vh",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columns}
              pagination
            />
          </div>
        </GridItem>
      </GridContainer>
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogContent>
          <Typography variant="h6" style = {{textTransform:"none"}}>
            Are you sure you want to delete this customer?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={confirmDelete}
            >
              Yes, Delete
            </Button>
            <Button variant="outlined" onClick={closeDeleteDialog}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleReset}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <Card>
            <CardHeader >
                                <Typography
                                  variant="h6"
                                  style={{ margin: "0.5rem", textAlign:"center" }}
                                  color="black"
                                >
                                   Edit Customer Details
                                </Typography>
                                </CardHeader>
            <Divider />
            <CardBody>
              <Grid container spacing={2}>
                {Object.keys(validationRules).map((field) => (
                  <Grid item xs={12} key={field}>
                    <TextField
                      label={field}
                      name={field}
                      variant="outlined"
                      size="small"
                      fullWidth
                      value={editableCustomerRecord[field] || ""}
                      onChange={handleChange}
                      error={!!errors[field]}
                      helperText={errors[field]}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} style={{ textAlign: "center" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleUpdate}
                  >
                    Update
                  </Button>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CustomerListPage;
