import axios from "axios";
import {
  FACTORY_DETAILS_FAIL,
  FACTORY_DETAILS_REQUEST,
  FACTORY_DETAILS_SUCCESS,
  FACTORY_DETAILS_RESET,

  FACTORY_REGISTER_REQUEST,
  FACTORY_REGISTER_FAIL,
  FACTORY_REGISTER_SUCCESS,
  FACTORY_REGISTER_RESET,
 
  FACTORY_LIST_FAIL,
  FACTORY_LIST_SUCCESS,
  FACTORY_LIST_REQUEST,
  FACTORY_LIST_RESET,

  FACTORY_DELETE_REQUEST,
  FACTORY_DELETE_SUCCESS,
  FACTORY_DELETE_FAIL,
  FACTORY_DELETE_RESET,

  FACTORY_UPDATE_FAIL,
  FACTORY_UPDATE_SUCCESS,
  FACTORY_UPDATE_REQUEST,
  FACTORY_UPDATE_RESET,

} from "../constants/factoryConstants.js";

export const register =
  (name, contactNo, inchargeName, inchargeContactNo, factoryAddress, city) =>
  async (dispatch) => {
    console.log('EXEC Factory Register  name, contactNo, inchargeName, inchargeContactNo, factoryAddress', 
      name, contactNo, inchargeName, inchargeContactNo, factoryAddress, city)
    try {
      dispatch({
        type: FACTORY_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("Before Perfoming role logic : ");

      const { data } = await axios.post(
        "/api/factory",
        { name, contactNo, inchargeName, inchargeContactNo, factoryAddress, city},
        config
      );

      dispatch({
        type: FACTORY_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FACTORY_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getFactoryDetailsByName = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FACTORY_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/factory/name/${name}`);

    dispatch({
      type: FACTORY_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FACTORY_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const listFactories = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: FACTORY_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/factory`);

    dispatch({
      type: FACTORY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FACTORY_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteFactory = (id) => async (dispatch, getState) => {
  console.log("deleteFactory ", id);
  try {
    dispatch({
      type: FACTORY_DELETE_REQUEST,
    });

    await axios.delete(`/api/factory/${id}`);

    dispatch({ type: FACTORY_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FACTORY_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateFactory = (name, contactNo, inchargeName, inchargeContactNo, factoryAddress, city) => async (dispatch, getState) => {
  console.log('updateFactory : ',name, contactNo, inchargeName, inchargeContactNo, factoryAddress, city);
  try {
    dispatch({
      type: FACTORY_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/factory/details/${name}`, {
      name, contactNo, inchargeName, inchargeContactNo, factoryAddress, city},
      {});

    dispatch({ type: FACTORY_UPDATE_SUCCESS , payload: data });

    dispatch({ type: FACTORY_UPDATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: FACTORY_UPDATE_FAIL,
      payload: message,
    });
  }
};
