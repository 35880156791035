import { useNavigate, useParams } from "react-router-dom";
import ReceiptCreateForm from "./ReceiptCreateForm";
import CardBody from "../../Card/CardBody";
import GridItem from "../../Grid/GridItem";
import validate from "validate.js";
import {
  Grid,
  TextField,
  Tooltip,
  Typography,
  Paper,
  TableCell,
  TableBody,
  TableRow,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../assets/images/green_tick.png";
import { styled } from '@mui/material/styles';
import GridContainer from "../../Grid/GridContainer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { getBondDetails, listBonds } from "../../../actions/bondAction.js";
import { convertInvoiceIdToDigits } from '../../../util/Utility.js'
import { listColdStorages } from "../../../actions/coldStorageAction.js";
import { listAvailableVehicles } from "../../../actions/vehicleAction.js";
import { ctcTypes } from "../../../data";
import { create } from "../../../actions/receiptAction.js";
import { CTC_RECEIPT_REGISTER_RESET } from "../../../constants/receiptConstants.js";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const ReceiptCreate = ({ location, history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);
  const [bondIdList, setBondIdList] = useState(null);
  const bondList = useSelector((state) => state.bondList)
  const [selectedBondDetails, setSelectedBondDetails] = useState(() => null);
  const [isReceiptValid, setIsReceiptValid] = useState(() => false);
  const [receiptFormInputs, setReceiptFormInputs] = useState({
    coldStorageName: "",
    productName: "",
    bondId: "",
    bondDate: "",
    remainingStockInStorage: "",
    vehicleNo:"",
    inventryTransferQuantity:"",
    receiptDate:""

  });
  const [availableVehicles, setAvailableVehicles] = useState(() => null);
  const [coldStorageNamesList, setColdStorageNamesList] = useState(null);
  const [bondsListByStorageProduct, setBondsListByStorageProduct] = useState(null);
  const coldStorageList = useSelector((state) => state.coldStorageList)
  const [selectedColdStorage, setSelectedColdStorage] = useState(() => null);
  const availableVehicleList = useSelector((state)=>state.availableVehicleList);
  const { loading_available_vehicles, vehicles_available_success, vehicles_available } = availableVehicleList
  const receiptRegistered = useSelector((state)=>state.receiptRegistered)
  const {ctc_receipt_register_loading , ctc_receipt_register_success } = receiptRegistered
  const { ctc_bond_list_loading, ctc_bond_list_data, ctc_bond_list_success, ctc_bond_list_error } = bondList
  const { cold_storage_list_loading, cold_storage_list, cold_storage_list_success, cold_storage_list_error } = coldStorageList
  
  const handleClose = () => {
    setOpen(false);
    if (ctc_receipt_register_success) {
      dispatch({ type: CTC_RECEIPT_REGISTER_RESET });
      navigate("/ctc/receipts");
    }
  };

  const populateBondIdList = () => {
    setBondIdList((prev) => ctc_bond_list_data.map((eachBond, idx) => {
      return (
        <MenuItem key={eachBond.Grid_id} value={eachBond._id}>
          {convertInvoiceIdToDigits(eachBond._id)}
        </MenuItem>
      );
    }));
  }

  const populateBondsListByStorageProduct = (prdName) => {
    console.log(' receiptFormInputs : ', receiptFormInputs)
    const filtered = ctc_bond_list_data.filter((bond, id) => bond.coldStorage === receiptFormInputs.coldStorageName &&
      bond.productName === prdName)
    console.log('Filtered : ', filtered)
    setBondsListByStorageProduct((prev) => ctc_bond_list_data.filter((bond, id) => bond.coldStorage === receiptFormInputs.coldStorageName &&
      bond.productName === prdName)
      .map((eachBond, idx) => {
        return (
          <MenuItem key={eachBond._id} value={eachBond._id}>
            {convertInvoiceIdToDigits(eachBond._id)}
          </MenuItem>
        );
      }));
  }

  const populateColdStorageNames = () => {
    setColdStorageNamesList((prev) => cold_storage_list.map((eachStorage, idx) => {
      return (
        <MenuItem key={eachStorage._id} value={eachStorage.name}>
          {eachStorage.name}
        </MenuItem>
      );
    }));
  }

  const populateAvailableVehicles = () => {
    setAvailableVehicles((prev) => vehicles_available.map((eachVehicle, idx) => {
      return (
        <MenuItem key={eachVehicle._id} value={eachVehicle.vehicleNo}>
          {eachVehicle.vehicleNo}
        </MenuItem>
      );
    }));
  }

  const checkIfReceiptIsValid = (bondId) => {
    console.log('checkIfReceiptIsValid :  ', isReceiptValid)
    if (receiptFormInputs && receiptFormInputs.coldStorageName && receiptFormInputs.coldStorageName.length > 0 &&
      receiptFormInputs.productName && receiptFormInputs.productName.length > 0 &&
      bondId && bondId.length > 0) {
      setIsReceiptValid((prev) => true)
      console.log('RECEIPT IS VALID')
    } else {
      console.log('RECEIPT IS IN VALID', receiptFormInputs.coldStorageName, receiptFormInputs.productName, bondId)
      setIsReceiptValid((prev) => false)
    }
  }

  useEffect(() => {
    dispatch(listBonds())
    dispatch(listColdStorages())
    dispatch(listAvailableVehicles())
  }, [])

  useEffect(() => {
    if (ctc_bond_list_data && ctc_bond_list_data.length > 0) {
      populateBondIdList()
    }
  }, [ctc_bond_list_data])

  useEffect(() => {
    if (cold_storage_list && cold_storage_list.length > 0) {
      populateColdStorageNames()
    }
  }, [cold_storage_list])

  useEffect(() => {
    if (vehicles_available && vehicles_available.length > 0) {
      console.log('vehicles_available: ', vehicles_available ? vehicles_available.length : 0);
      populateAvailableVehicles()
    } else {
      console.log('No Vehicles available Yet ')
    }
  }, [vehicles_available])

  useEffect(() => {
    if (ctc_receipt_register_success) {
      console.log('Routing to List Screen as New Receipt is Created. !');
      setOpen(() => true);
    }
  }, [ctc_receipt_register_success])

  const handleReceiptChange = (event) => {
    console.log("handleCtcChange is getting Called when advance Paid is Changed")
    setReceiptFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Values Submitted are : ", receiptFormInputs);
    console.log("selectedBondDetails.initialTotalWeightInKgs  : ", selectedBondDetails);
    console.log("ptFormInputs.inventryTransferQuantity  : ", receiptFormInputs.inventryTransferQuantity);
    const remainingQtyInBond = Number(selectedBondDetails.totalWeightInKgs) - Number(receiptFormInputs.inventryTransferQuantity)
    console.log('remainingQtyInBond', remainingQtyInBond)
      dispatch(
        create(
          receiptFormInputs.coldStorageName,
          receiptFormInputs.productName,
          receiptFormInputs.bondId,
          selectedBondDetails && selectedBondDetails.bondDate ? selectedBondDetails.bondDate :"" ,
          remainingQtyInBond,
          receiptFormInputs.vehicleNo,
          receiptFormInputs.inventryTransferQuantity,//
        )
      );

    
  };

   const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "ctc_types":
        return ctcTypes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const bondDetailsSection = (<>
    <Grid item xs={4} align="center">
      <TextField
        placeholder="Bond Date"
        label="Bond Date"
        variant="outlined"
        fullWidth
        size="small"
        name="bondDate"
        type="text"
        disabled
        value={selectedBondDetails && selectedBondDetails.bondDate ? selectedBondDetails.bondDate : ""}
      />
    </Grid>
    <Grid item xs={4} align="center">
      <TextField
        placeholder="Bond Initial Quantity"
        label="Bond Initial Quantity"
        variant="outlined"
        fullWidth
        size="small"
        name="bondInitialQuantity"
        type="text"
        disabled
        value={selectedBondDetails && selectedBondDetails.totalWeightInKgs ? selectedBondDetails.totalWeightInKgs : ""}
      />
    </Grid>
    <Grid item xs={4} align="center">
      <TextField
        placeholder="Receipt Date"
        label="Receipt Date *"
        variant="outlined"
        size="small"
        name="receiptDate"
        fullWidth
        type="text"
        disabled
        value={new Date().toLocaleString() || ""}
      />
    </Grid></>
  )

  const receiptDetailsSection = (<>
    <Grid item xs={4} align="center">
      <TextField
        placeholder="Inventry Transfer Quantity"
        label="Inventry Transfer Quantity *"
        variant="outlined"
        size="small"
        name="inventryTransferQuantity"
        fullWidth
        onChange={handleReceiptChange}
        type="text"
        value={receiptFormInputs.inventryTransferQuantity || ""}
      />
    </Grid>
    <Grid item xs={4} align="center">
      <TextField
        placeholder="Remaining Stock in Kgs"
        label="Remaining Stock in Kgs *"
        variant="outlined"
        size="small"
        name="remainingStockInStorage"
        fullWidth
        onChange={handleReceiptChange}
        type="number"
        value={receiptFormInputs.remainingStockInStorage || ""}
      />
    </Grid>
  </>
  )
  return (
    <>
          {ctc_receipt_register_success && (<BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <Grid item xs={12} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  Receipt is Created
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>) || null}
    <Card style={{ marginLeft: "auto", marginRight: "auto" }}>
      <CardHeader>
        <b><h2 align="center">New CTC Receipt</h2></b>
      </CardHeader>
      <CardBody>
        <form method="post" onSubmit={handleSubmit}>
          <Grid container spacing={2} justifyContent="center" marginTop="auto">
            <Grid item xs={4} align="center" style={{ width: '25rem' }}>
              <FormControl style={{ justifyContent: "center", textAlign: "center", width: '85%' }}>
                <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                  Cold Storage
                </InputLabel>
                <Select
                  label="Cold Storage"
                  placeholder="Cold Storage"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  name="coldStorage"
                  onChange={(e) => setReceiptFormInputs((prev) => ({
                    ...prev, coldStorageName: e.target.value
                  }))}
                  value={receiptFormInputs.coldStorageName}
                >
                  {coldStorageNamesList}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} align="center">
              <FormControl style={{ justifyContent: "center", textAlign: "center", width: '85%' }}>
                <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                  CTC Product Name
                </InputLabel>
                <Select
                  style={{ justifyContent: "center" }}
                  label="CTC Type"
                  placeholder="CTC Type"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  name="productName"
                  onChange={(e) => {
                    console.log('selected Cold Storage, CTC Product  ', receiptFormInputs.coldStorageName, e.target.value)
                    setReceiptFormInputs((prev) => ({
                      ...prev, productName: e.target.value
                    }))
                    populateBondsListByStorageProduct(e.target.value)
                  }
                  }

                  value={receiptFormInputs.productName || ""}
                >
                  {renderDynamicOptions("ctc_types")}
                </Select>
              </FormControl>
            </Grid>
            {bondsListByStorageProduct && bondsListByStorageProduct.length > 0 && (<Grid item xs={4} align="center">
              <FormControl style={{ justifyContent: "center", textAlign: "center", width: '85%' }}>
                <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                  Bond No
                </InputLabel>
                <Select
                  style={{ justifyContent: "center" }}
                  label="Bond No"
                  placeholder="Bond No"
                  variant="outlined"
                  required
                  size="small"
                  fullWidth
                  name="bondNo"
                  onChange={(e) => {
                    console.log('BOND SELECTED :', e)
                    const filterBondDetails = ctc_bond_list_data.filter((each) => each._id === e.target.value)
                    if(filterBondDetails && filterBondDetails.length>0){
                      console.log('GATHERING BOND DETAILS : ', filterBondDetails && filterBondDetails.length > 0 ? filterBondDetails[0] : "NO Recods matched")
                      setSelectedBondDetails(() => filterBondDetails[0])
                    }
                    // setSelectedBondDetails(() => ctc_bond_list_data.filter((each) => each._id === e.target.value))
                    setReceiptFormInputs((prev) => (
                      {
                        ...prev,
                        bondId: e.target.value
                      }
                    )
                    )
                    checkIfReceiptIsValid(e.target.value)
                  }}
                  value={receiptFormInputs.bondId}
                >
                  {bondsListByStorageProduct}
                </Select>
              </FormControl>
            </Grid>)
            }
            {selectedBondDetails && bondDetailsSection}
            <Grid item xs={4}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Vehicle No
              </InputLabel>
              <Select
                label="Vehicle No"
                placeholder="Vehicle No *"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="vehicleNo"
                onChange={(e)=>{
                  console.log('Vehicle No Selected : ', e.target.value)
                  setReceiptFormInputs((prev)=>({ ... prev , vehicleNo :  e.target.value }))
                }
                }
                value={receiptFormInputs.vehicleNo || ""}
              >
                {availableVehicles}
              </Select>
            </FormControl>
          </Grid>
            {selectedBondDetails && receiptDetailsSection}
          </Grid>
          <Grid container spacing={2} justifyContent="center" marginTop="auto">
            <Grid item xs={4} align="center">
              <Button
                size="small"
                variant="contained"
                type="submit"
                color="secondary"
                justify="center"
                style={{
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  width: '20rem'
                }}
              >
                Create Receipt
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardBody>
    </Card>
    </>
  );
};

export default ReceiptCreate;
