import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import GridItem from "../../Grid/GridItem";
import { Grid, Tooltip, Typography } from "@mui/material";
import WarehouseForm from "./WarehouseForm";
import Section from "../../organisms/Section/Section";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import GridContainer from "../../Grid/GridContainer";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "50vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 'auto',
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const WarehouseCreate = ({ location, history }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <Grid container spacing={1}>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{ fontWeight: 500 }}>
                  <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em", textAlign: "center" }}
                    />

                  </Tooltip>
                  New Warehouse
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <Grid item xs={12}>
          <Section className={classes.section}>
            <div className={classes.formContainer}>
              <WarehouseForm />
            </div>
          </Section>
        </Grid>
      </Grid>
    </div>
  );
};

export default WarehouseCreate;
