import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  subCategoryListReducer,
  subCategoryListByCategoryIdReducer,
  subCategoryDeleteReducer,
  subCategoryCreateReducer,
  subCategoryUpdateReducer,
} from "./reducers/subCategoryReducers";
import {
  categoryListReducer,
  categoryDeleteReducer,
  categoryCreateReducer,
  categoryUpdateReducer,
  categoryDetailsByIdReducer,
} from "./reducers/categoryReducers";
import {
  productListReducer,
  productListBySubCategoryReducer,
  productDetailsBySubCategoryIdProductIdReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  productDetailsByProductIdReducer,
  productBestSellerReducer,
  productListByCategoryIdReducer,
} from "./reducers/productReducer";
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userListByRoleReducer
} from "./reducers/userReducers";
import {
  bulkListReducer,
  bulkCreateByProductIdReducer,
  bulkDeleteByProductIdReducer,
  bulkUpdateByProductIdReducer,
  bulkListByProductIdReducer,
} from "./reducers/bulkReducers";
import {
  domesticListReducer,
  domesticCreateByProductIdReducer,
  domesticDeleteByProductIdReducer,
  domesticUpdateByProductIdReducer,
  domesticListByProductIdReducer,
} from "./reducers/domesticReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListReducer,
  listMyOrdersReducer,
  orderUpdateReducer,
  orderTransferReducer,
  orderDeleteReducer,
  orderListByStoreReducer,
  orderUpdatePaymentDetailsReducer,
  ordersListByMappedStoreReducer
} from "./reducers/orderReducers";
import {
  allProductsCountInStockReducer,
  stockUpdateByProductIdReducer,
  stockCreateReducer,
} from "./reducers/stockReducers";
import {
  storeCreateReducer,
  storeDetailsByIdReducer,
  storeDeleteReducer,
  storeListReducer,
  storeUpdateByNameReducer,
  storeDetailByNameReducer,
} from "./reducers/storeReducers";

import {
  purchaseOrdersListReducer,
  purchaseOrderByIdReducer,
  purchaseOrderDeleteByIdReducer,
  purchaseOrderCreateReducer,
  purchaseOrderUpdateByIdReducer,
} from "./reducers/purchaseOrderReducer";

import {
 warehouseInvoiceByIdReducer,
warehouseInvoiceCreateReducer,
warehouseInvoiceDeleteByIdReducer,
warehouseInvoiceUpdateByIdReducer,
warehouseInvoicesListReducer,
} from "./reducers/warehouseReducer";

import {
  stockTransferInvoiceListReducer,
  stockTransferInvoiceListByPosReducer,
  stockTransferByIdReducer,
  stockTransferDeleteByIdReducer,
  stockTransferCreateReducer,
  stockTransferUpdateByIdReducer
  } from "./reducers/stockTransferInvoiceReducer";

import {
  warehouseInfoCreateReducer,
  warehouseInfoDeleteByIdReducer,
  warehouseInfoListReducer,
  warehouseInfoUpdateByIdReducer,
  } from "./reducers/warehouseInfoReducer";
  
import {
warehousePurchaseOrderByIdReducer,
warehousePurchaseOrderCreateReducer,
warehousePurchaseOrderDeleteByIdReducer,
warehousePurchaseOrderUpdateByIdReducer,
warehousePurchaseOrdersListReducer,
warehousePurchaseOrderByPosReducer
  } from "./reducers/warehousePurchaseOrderReducer";

  import {
    storePurchaseOrderByIdReducer,
    storePurchaseOrderCreateReducer,
    storePurchaseOrderDeleteByIdReducer,
    storePurchaseOrderUpdateByIdReducer,
    storePurchaseOrdersListReducer,
    storePurchaseOrderByPosReducer,
    storePurchaseOrderPaymentUpdateByIdReducer,
    storePurchaseOrderQtyUpdateByIdReducer
      } from "./reducers/storePurchaseOrderReducer";


import {
    vehicleCreateReducer,
    vehicleDeleteReducer,
    vehicleListReducer,
    updateByVehicleIdReducer,
    vehicleDetailsReducer,
    vehicleListByAssignedOrAvailabileListReducer,
    vehicleUpdateAvailabilityAndAssignedStatusReducer,
    availableVehicleListReducer,
    vehicleUpdateAssignedReducer,
    vehicleSalesInchargeReducer,
    vehicleDetailsByNoReducer,
    vehicleDetailsBySalesInchargeReducer
  } from "./reducers/vehicleReducers";  

import { 
  warehouseStockInUpdateByWarehouseNameReducer,
  warehouseStockListByWarehouseNameReducer,
  warehouseStockUpdateByWarehouseNameReducer,
  warehouseStockReturnByWarehouseNameReducer} from "./reducers/warehouseStockReducer";

import { 
  storeStockCreateByStoreNameReducer,
  storeStockListByStoreNameReducer,
  storeStockUpdateByStoreNameReducer ,
  storeStockReturnByStoreNameReducer,
  productsStockCountAcrossAllStoresReducer,
  storeStockRevertPostDeletePurchaseOrderReducer
} from "./reducers/storeStockReducer";

import { 
  vehicleStockInUpdateByVehicleNoReducer,
  vehicleStockOutUpdateByVehicleNoReducer,
  vehicleStockListByVehicleNoReducer,
  vehicleStockCreateByVehicleNoReducer,
  vehicleReturnStockOutUpdateByVehicleNoReducer
} 
  from "./reducers/vehicleStockReducer";

  import { 
    transitStockListByVehicleNoReducer,
    transitStockInUpdateByVehicleNoReducer,
    transitStockOutUpdateByVehicleNoReducer,
    transitStockDetailsByIdReducer,
    transitStockListByVehicleNoSourceDestinationReducer,
    transitStockAwaitingConfirmationStatusUpdateByVehicleNoReducer
  } 
    from "./reducers/transitStockReducer";

import { vehicleSalesInvoiceListReducer,vehicleSalesInvoiceByVehicleNoReducer,vehicleSalesInvoiceCreateByVehicleNoReducer,vehicleSalesInvoiceDeleteByVehicleNoReducer,vehicleSalesInvoiceUpdateByVehicleNoReducer,vehicleSalesInvoiceByInvoiceNoReducer, vehicleSalesInvoiceUpdateByInvoiceNoReducer, vehicleSalesInvoiceByInchargeNameReducer } from "./reducers/vehicleSalesInvoiceReducer"
import { cartReducer } from "./reducers/cartReducers";
import { vehicleDetailsToStorageReducer,invoiceDetailsToStorageReducer } from "./reducers/storageReducers";
import {newStockNotificationCreateReducer , newStockNotificationListReducer, newStockNotificationUpdateConfirmStatusReducer} from "./reducers/newStockNotificationReducer"
import { walkinSalesInvoiceCreateReducer, walkinSalesInvoiceListReducer, walkinSalesInvoiceDetailsByIdReducer, walkinSalesInvoicesListByPosReducer, walkinSalesInvoicePaymentDetailsUpdateByIdReducer} from "./reducers/walkinSalesInvoiceReducer"
import { pdfInvoiceListReducer, pdfInvoiceByIdReducer , pdfInvoiceCreateReducer , pdfInvoiceDetailsReducer } from "./reducers/pdfInvoiceReducer";
import { whatsAppInvoiceListReducer, whatsAppInvoiceByIdReducer , whatsAppInvoiceCreateReducer , whatsAppInvoiceDetailsReducer } from "./reducers/whatsAppInvoiceReducer";
import { customerRegisterReducer, customerDetailsReducer, customerDeleteReducer, customerListByParentUserReducer, customerListByPosReducer, customerListReducer, customerUpdateReducer } 
  from "./reducers/customerReducers";
import { versionUpdatesListReducer, versionUpdatesByIdReducer, versionUpdatesCreateReducer, versionUpdatesDeleteByIdReducer,versionUpdatesUpdateReducer } from "./reducers/versionUpdatesReducers";
import {supplierRegisterReducer, supplierDetailsReducer, supplierDeleteReducer, supplierListByParentUserReducer, supplierListReducer, supplierUpdateReducer } 
  from "./reducers/supplierReducers";
import { walkinVyaparSyncSalesInvoicesReducer } from "./reducers/walkinVyaparSaleReportReducer";
import { walkinVyaparSaleByPosReducer } from "./reducers/walkinVyaparSaleReportReducer";
import { walkinVyaparItemSaleByPosReducer, walkinVyaparItemSaleByStoreReducer, walkinVyaparSyncItemSalesInvoicesReducer } from "./reducers/walkinVyaparItemSaleReportReducer";
import { paymentsListByStoreReducer, storePaymentByIdReducer, storePaymentCreateReducer } from "./reducers/storePaymentReducer";
import { orderReceiptByIdReducer, orderReceiptCreateReducer, orderReceiptsListByStoreReducer } from "./reducers/orderReceiptReducer";
import { walkinSaleReceiptByIdReducer, walkinSaleReceiptCreateReducer, walkinSaleReceiptsListByStoreReducer } from "./reducers/walkinSaleReceiptReducer";
import { coldStorageDeleteReducer, coldStorageDetailsByNameReducer, coldStorageDetailsReducer, coldStorageListReducer, coldStorageRegisterReducer, coldStorageUpdateReducer } from "./reducers/coldStorageReducers";
import { factoryDeleteReducer, factoryDetailsByNameReducer, factoryDetailsReducer, factoryListReducer, factoryRegisterReducer, factoryUpdateReducer } from "./reducers/factoryReducers";
import { varietyDeleteReducer, varietyDetailsByNameReducer, varietyListReducer, varietyRegisterReducer, varietyUpdateReducer } from "./reducers/varietyReducers";
import { bondDeleteReducer, bondDetailsByIdReducer, bondListReducer, bondRegisterReducer, bondUpdateReducer } from "./reducers/bondReducers";
import { receiptDeleteReducer, receiptDetailsByIdReducer, receiptListReducer, receiptRegisterReducer, receiptUpdateReducer } from "./reducers/receiptsReducers";
import { ctcPurchaseOrderDeleteReducer, ctcPurchaseOrderDetailsByIdReducer, ctcPurchaseOrderListReducer, ctcPurchaseOrderRegisterReducer, ctcPurchaseOrderUpdateReducer } from "./reducers/ctcPurchaseOrderReducers";
import { ctcProductionDeleteReducer, ctcProductionDetailsByIdReducer, ctcProductionListReducer, ctcProductionRegisterReducer, ctcProductionUpdateReducer } from "./reducers/ctcProductionReducers";
import { ctcConversionDeleteReducer, ctcConversionDetailsByIdReducer, ctcConversionListReducer, ctcConversionRegisterReducer, ctcConversionUpdateReducer } from "./reducers/ctcConversionReducers";
import { ctcFetchBatchNoReducer } from "./reducers/ctcBatchNoReducers";
import { rawMaterialStockByIdReducer, rawMaterialStockCreateReducer, rawMaterialStockDeleteByIdReducer, rawMaterialStocksListReducer, rawMaterialStockUpdateByIdReducer } from "./reducers/rawMaterialStockReducer";
import { powderMaterialStockByIdReducer, powderMaterialStockCreateReducer, powderMaterialStockDeleteByIdReducer, powderMaterialStocksListReducer, powderMaterialStockUpdateByIdReducer } from "./reducers/powderMaterialStockReducer";


const reducer = combineReducers({
  subCategoryList: subCategoryListReducer,
  subCategoryListByCategory: subCategoryListByCategoryIdReducer,
  subCategoryDeleted: subCategoryDeleteReducer,
  subCategoryUpdated: subCategoryUpdateReducer,
  subCategoryCreated: subCategoryCreateReducer,
  categoryList: categoryListReducer,
  categoryDetailsById: categoryDetailsByIdReducer,
  categoryDeleted: categoryDeleteReducer,
  categoryUpdate: categoryUpdateReducer,
  categoryCreate: categoryCreateReducer,
  allProducts: productListReducer,
  productListByCategory: productListByCategoryIdReducer,
  productListBySubCategory: productListBySubCategoryReducer,
  productDetailsBySubCategoryIdProductId:
    productDetailsBySubCategoryIdProductIdReducer,
  productDetailsByProductId: productDetailsByProductIdReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  bestSellers: productBestSellerReducer,
  bulkList: bulkListReducer,
  bulkCreateByProductId: bulkCreateByProductIdReducer,
  bulkDeleteByProductId: bulkDeleteByProductIdReducer,
  bulkUpdateByProductId: bulkUpdateByProductIdReducer,
  bulkListByProductId: bulkListByProductIdReducer,
  domesticList: domesticListReducer,
  domesticCreateByProductId: domesticCreateByProductIdReducer,
  domesticDeleteByProductId: domesticDeleteByProductIdReducer,
  domesticUpdateByProductId: domesticUpdateByProductIdReducer,
  domesticListByProductId: domesticListByProductIdReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDeleted: userDeleteReducer,
  userUpdate: userUpdateReducer,
  userListByRole: userListByRoleReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderUpdated: orderUpdateReducer,
  orderTransferred: orderTransferReducer,
  orderUpdatePaymentDetails: orderUpdatePaymentDetailsReducer,
  orderDeliver: orderDeliverReducer,
  myOrdersList: listMyOrdersReducer,
  orderList: orderListReducer,
  ordersListByMappedStore : ordersListByMappedStoreReducer,
  orderListByStore: orderListByStoreReducer,
  orderDeleted: orderDeleteReducer,
  orderReceiptsListByStore : orderReceiptsListByStoreReducer,
  orderReceiptById : orderReceiptByIdReducer,
  orderReceiptCreated : orderReceiptCreateReducer,
  allProductsCountInStock: allProductsCountInStockReducer,
  storeCreated: storeCreateReducer,
  storeDetailsById: storeDetailsByIdReducer,
  storeDeleted: storeDeleteReducer,
  storesList: storeListReducer,
  storeUpdated: storeUpdateByNameReducer,
  stockUpdated: stockUpdateByProductIdReducer,
  stockCreated: stockCreateReducer,
  storeDetailByName : storeDetailByNameReducer,
  purchaseOrdersList: purchaseOrdersListReducer,
  purchaseOrder: purchaseOrderByIdReducer,
  purchaseOrderDeleted: purchaseOrderDeleteByIdReducer,
  purchaseOrderCreate: purchaseOrderCreateReducer,
  purchaseOrderUpdated: purchaseOrderUpdateByIdReducer,
  warehouseInvoice: warehouseInvoiceByIdReducer,
  warehouseInvoiceCreate:warehouseInvoiceCreateReducer,
  warehouseInvoiceDeleted:warehouseInvoiceDeleteByIdReducer,
  warehouseInvoiceUpdated :warehouseInvoiceUpdateByIdReducer,
  warehouseInvoicesList:warehouseInvoicesListReducer,
  warehouseInfoCreated:warehouseInfoCreateReducer,
  warehouseInfoList:warehouseInfoListReducer,
  warehousePurchaseOrderById: warehousePurchaseOrderByIdReducer,
  warehousePurchaseOrderCreate : warehousePurchaseOrderCreateReducer,
  warehousePurchaseOrderDeleteById : warehousePurchaseOrderDeleteByIdReducer,
  warehousePurchaseOrderUpdateById: warehousePurchaseOrderUpdateByIdReducer,
  warehousePurchaseOrdersList : warehousePurchaseOrdersListReducer,
  warehousePurchaseOrdersListByPos : warehousePurchaseOrderByPosReducer,
  storePurchaseOrderById: storePurchaseOrderByIdReducer,
  storePurchaseOrderCreate : storePurchaseOrderCreateReducer,
  storePurchaseOrderDeleteById : storePurchaseOrderDeleteByIdReducer,
  storePurchaseOrderUpdateById: storePurchaseOrderUpdateByIdReducer,
  storePurchaseOrderPaymentUpdateById : storePurchaseOrderPaymentUpdateByIdReducer,
  storePurchaseOrderQtyUpdateById : storePurchaseOrderQtyUpdateByIdReducer,
  storePurchaseOrdersList : storePurchaseOrdersListReducer,
  storePurchaseOrdersListByPos : storePurchaseOrderByPosReducer,
  paymentsListByStore : paymentsListByStoreReducer,
  storePaymentById : storePaymentByIdReducer,
  storePaymentCreate : storePaymentCreateReducer,
  vehicleCreated:vehicleCreateReducer,
  vehicleDeleted:vehicleDeleteReducer,
  vehicleDetails :vehicleDetailsReducer,
  vehicleDetailsByNo : vehicleDetailsByNoReducer,
  vehicleList:vehicleListReducer,
  vehicleListByAssignedOrAvailabile : vehicleListByAssignedOrAvailabileListReducer,
  warehouseStockInUpdate : warehouseStockInUpdateByWarehouseNameReducer,
  warehouseStockListByWarehouseName : warehouseStockListByWarehouseNameReducer,
  warehouseStockUpdated: warehouseStockUpdateByWarehouseNameReducer,
  warehouseStockReturned: warehouseStockReturnByWarehouseNameReducer,
  storeStockCreated : storeStockCreateByStoreNameReducer,
  storeStockListByStoreName : storeStockListByStoreNameReducer,
  storeStockUpdated: storeStockUpdateByStoreNameReducer ,
  storeStockReturned: storeStockReturnByStoreNameReducer,
  storeStockRevertPostDeletePurchaseOrder : storeStockRevertPostDeletePurchaseOrderReducer,
  vehicleStockListByVehicleNo : vehicleStockListByVehicleNoReducer,
  vehicleStockOutUpdateByVehicleNo : vehicleStockOutUpdateByVehicleNoReducer,
  vehicleReturnStockOutUpdateByVehicleNo : vehicleReturnStockOutUpdateByVehicleNoReducer,
  vehicleUpdateAvailabilityAndAssignedStatus : vehicleUpdateAvailabilityAndAssignedStatusReducer,
  vehicleStockInUpdated : vehicleStockInUpdateByVehicleNoReducer,
  transitStockListByVehicleNo : transitStockListByVehicleNoReducer,
  transitStockListByVehicleNoSourceDestination : transitStockListByVehicleNoSourceDestinationReducer,
  transitStockAwaitingConfirmationStatusUpdate:transitStockAwaitingConfirmationStatusUpdateByVehicleNoReducer,
  transitStockDetailsById : transitStockDetailsByIdReducer,
  transitStockInUpdated : transitStockInUpdateByVehicleNoReducer,
  transitStockOutUpdated : transitStockOutUpdateByVehicleNoReducer,
  vehicleSalesInvoicesList : vehicleSalesInvoiceListReducer,
  availableVehicleList : availableVehicleListReducer,
  vehicleSalesInvoiceDetailsByVehicleNo : vehicleSalesInvoiceByVehicleNoReducer,
  vehicleSalesInvoiceByInchargeName : vehicleSalesInvoiceByInchargeNameReducer,
  vehicleSalesInvoiceCreatedByVehicleNo : vehicleSalesInvoiceCreateByVehicleNoReducer,
  vehicleSalesInvoiceUpdatedByVehicleNo : vehicleSalesInvoiceUpdateByVehicleNoReducer,
  vehicleSalesInvoiceDeletedByVehicleNo : vehicleSalesInvoiceDeleteByVehicleNoReducer,
  vehicleSalesInvoiceUpdateByInvoiceNo : vehicleSalesInvoiceUpdateByInvoiceNoReducer,
  vehicleSalesInvoiceByInvoiceNo : vehicleSalesInvoiceByInvoiceNoReducer,
  vehicleUpdateAssigned : vehicleUpdateAssignedReducer,
  vehicleSalesIncharge : vehicleSalesInchargeReducer,
  vehicleDetailsBySalesIncharge : vehicleDetailsBySalesInchargeReducer,
  cart: cartReducer,
  vehicleDetailsFromStorage : vehicleDetailsToStorageReducer,
  invoiceDetailsFromStorage : invoiceDetailsToStorageReducer,
  newStockNotificationCreate : newStockNotificationCreateReducer,
  newStockNotificationList:  newStockNotificationListReducer,
  newStockNotificationUpdateConfirmStatus : newStockNotificationUpdateConfirmStatusReducer,
  walkinSalesInvoiceCreate : walkinSalesInvoiceCreateReducer,
  walkinSalesInvoiceList : walkinSalesInvoiceListReducer,
  walkinSalesInvoicesListByPos : walkinSalesInvoicesListByPosReducer,
  walkinSalesInvoiceDetailsById : walkinSalesInvoiceDetailsByIdReducer,
  walkinSalesInvoicePaymentDetailsUpdateById : walkinSalesInvoicePaymentDetailsUpdateByIdReducer,
  walkinSaleReceiptsListByStore : walkinSaleReceiptsListByStoreReducer,
  walkinSaleReceiptById : walkinSaleReceiptByIdReducer,
  walkinSaleReceiptCreate : walkinSaleReceiptCreateReducer,
  walkinVyaparSyncSalesInvoices : walkinVyaparSyncSalesInvoicesReducer,
  walkinVyaparSyncItemSalesInvoices : walkinVyaparSyncItemSalesInvoicesReducer,
  walkinVyaparSaleByPos: walkinVyaparSaleByPosReducer,
  walkinVyaparItemSaleByStore: walkinVyaparItemSaleByStoreReducer,
  productsStockCountAcrossAllStores:productsStockCountAcrossAllStoresReducer,
  pdfInvoiceList : pdfInvoiceListReducer,
  pdfInvoiceById : pdfInvoiceByIdReducer,
  pdfInvoiceCreate : pdfInvoiceCreateReducer,
  pdfInvoiceDetails: pdfInvoiceDetailsReducer,
  whatsAppInvoiceList : whatsAppInvoiceListReducer,
  whatsAppInvoiceById : whatsAppInvoiceByIdReducer,
  whatsAppInvoiceCreate : whatsAppInvoiceCreateReducer,
  whatsAppInvoiceDetails: whatsAppInvoiceDetailsReducer,
  customerRegistered : customerRegisterReducer,
  customerDetails: customerDetailsReducer,
  customerList: customerListReducer,
  customerDeleted: customerDeleteReducer,
  customerUpdate: customerUpdateReducer,
  customerListByParentUser: customerListByParentUserReducer,
  customerListByPos: customerListByPosReducer,
  versionUpdatesList : versionUpdatesListReducer,
  versionUpdatesCreated : versionUpdatesCreateReducer,
  versionUpdatesDeleted : versionUpdatesDeleteByIdReducer,
  versionUpdatesUpdated : versionUpdatesUpdateReducer,
  supplierRegistered : supplierRegisterReducer,
  supplierDetails: supplierDetailsReducer,
  supplierList: supplierListReducer,
  supplierDeleted: supplierDeleteReducer,
  supplierUpdate: supplierUpdateReducer,
  supplierListByParentUser: supplierListByParentUserReducer,
  stockTransferInvoiceById : stockTransferByIdReducer,
  stockTransferCreate : stockTransferCreateReducer,
  stockTransferDeleteById : stockTransferDeleteByIdReducer,
  stockTransferInvoiceListByPos : stockTransferInvoiceListByPosReducer,
  stockTransferInvoiceList : stockTransferInvoiceListReducer,
  coldStorageRegistered : coldStorageRegisterReducer,
  coldStorageDetails: coldStorageDetailsByNameReducer,
  coldStorageList: coldStorageListReducer,
  coldStorageDeleted: coldStorageDeleteReducer,
  coldStorageUpdated: coldStorageUpdateReducer,
  factoryRegistered : factoryRegisterReducer,
  factoryDetails: factoryDetailsByNameReducer,
  factoryList: factoryListReducer,
  factoryDeleted: factoryDeleteReducer,
  factoryUpdated: factoryUpdateReducer,
  varietyRegistered : varietyRegisterReducer,
  varietyDetails: varietyDetailsByNameReducer,
  varietyList: varietyListReducer,
  varietyDeleted: varietyDeleteReducer,
  varietyUpdated: varietyUpdateReducer,
  bondRegistered : bondRegisterReducer,
  bondDetails: bondDetailsByIdReducer,
  bondList: bondListReducer,
  bondDeleted: bondDeleteReducer,
  bondUpdated: bondUpdateReducer,
  receiptRegistered : receiptRegisterReducer,
  receiptDetails: receiptDetailsByIdReducer,
  receiptList: receiptListReducer,
  receiptDeleted: receiptDeleteReducer,
  receiptUpdated: receiptUpdateReducer,
  ctcPurchaseOrderRegistered : ctcPurchaseOrderRegisterReducer,
  ctcPurchaseOrderDetails: ctcPurchaseOrderDetailsByIdReducer,
  ctcPurchaseOrderList: ctcPurchaseOrderListReducer,
  ctcPurchaseOrderDeleted: ctcPurchaseOrderDeleteReducer,
  ctcPurchaseOrderUpdated: ctcPurchaseOrderUpdateReducer,
  ctcProductionRegistered : ctcProductionRegisterReducer,
  ctcProductionDetails: ctcProductionDetailsByIdReducer,
  ctcProductionList: ctcProductionListReducer,
  ctcProductionDeleted: ctcProductionDeleteReducer,
  ctcProductionUpdated: ctcProductionUpdateReducer,
  
  ctcConversionRegistered : ctcConversionRegisterReducer,
  ctcConversionDetails: ctcConversionDetailsByIdReducer,
  ctcConversionList: ctcConversionListReducer,
  ctcConversionDeleted: ctcConversionDeleteReducer,
  ctcConversionUpdated: ctcConversionUpdateReducer,
  ctcBatchNoFetched : ctcFetchBatchNoReducer,

  rawMaterialStockCreated : rawMaterialStockCreateReducer,
  rawMaterialStockDetails: rawMaterialStockByIdReducer,
  rawMaterialStocksList : rawMaterialStocksListReducer,
  rawMaterialStockDeletd: rawMaterialStockDeleteByIdReducer,
  rawMaterialStockUpdated: rawMaterialStockUpdateByIdReducer,

  powderMaterialStockCreated : powderMaterialStockCreateReducer,
  powderMaterialStockDetails: powderMaterialStockByIdReducer,
  powderMaterialStocksList : powderMaterialStocksListReducer,
  powderMaterialStockDeletd: powderMaterialStockDeleteByIdReducer,
  powderMaterialStockUpdated: powderMaterialStockUpdateByIdReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const vehicleDetailsFromStorage = localStorage.getItem("vehicleDetailsLocalStorage") &&localStorage.getItem("vehicleDetailsLocalStorage")!==undefined
  ? JSON.parse(localStorage.getItem("vehicleDetailsLocalStorage"))
  : null; 

const invoiceDetailsFromStorage = localStorage.getItem("invoiceDetailsLocalStorage") &&localStorage.getItem("invoiceDetailsLocalStorage")!==undefined
  ? JSON.parse(localStorage.getItem("invoiceDetailsLocalStorage"))
  : {}; 

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const storeInfoFromStorage =
  localStorage.getItem("storeInfo") !== "undefined"
    ? localStorage.getItem("storeInfo")
    : localStorage.getItem("storeInfoByZipcode") !== "undefined"
    ? localStorage.getItem("storeInfoByZipcode")
    : "undefined";

const paymentDetailsFromStorage = localStorage.getItem("paymentDetails")
  ? JSON.parse(localStorage.getItem("paymentDetails"))
  : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    storeInfo: storeInfoFromStorage,
    shippingAddress: shippingAddressFromStorage,
    paymentDetails: paymentDetailsFromStorage,
  },
  invoiceDetailsFromStorage: invoiceDetailsFromStorage,
  vehicleDetailsFromStorage: vehicleDetailsFromStorage,
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
