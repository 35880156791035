import {
  COLD_STORAGE_DETAILS_FAIL,
  COLD_STORAGE_DETAILS_REQUEST,
  COLD_STORAGE_DETAILS_SUCCESS,
  COLD_STORAGE_DETAILS_RESET,

  COLD_STORAGE_REGISTER_FAIL,
  COLD_STORAGE_REGISTER_REQUEST,
  COLD_STORAGE_REGISTER_SUCCESS,
  COLD_STORAGE_REGISTER_RESET,
 
  COLD_STORAGE_LIST_FAIL,
  COLD_STORAGE_LIST_SUCCESS,
  COLD_STORAGE_LIST_REQUEST,
  COLD_STORAGE_LIST_RESET,

  COLD_STORAGE_DELETE_REQUEST,
  COLD_STORAGE_DELETE_SUCCESS,
  COLD_STORAGE_DELETE_FAIL,
  COLD_STORAGE_DELETE_RESET,

  COLD_STORAGE_UPDATE_FAIL,
  COLD_STORAGE_UPDATE_SUCCESS,
  COLD_STORAGE_UPDATE_REQUEST,
  COLD_STORAGE_UPDATE_RESET,

  COLD_STORAGE_DETAILS_BY_NAME_REQUEST,
  COLD_STORAGE_DETAILS_BY_NAME_SUCCESS,
  COLD_STORAGE_DETAILS_BY_NAME_FAIL,
  COLD_STORAGE_DETAILS_BY_NAME_RESET,
} from "../constants/coldStorageConstants";


export const coldStorageRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case COLD_STORAGE_REGISTER_REQUEST:
      return { cold_storage_register_loading: true };
    case COLD_STORAGE_REGISTER_SUCCESS:
      return { cold_storage_register_loading: false,cold_storage_register_success: true, cold_storage_register_data: action.payload };
    case COLD_STORAGE_REGISTER_FAIL:
      return { cold_storage_register_loading: false, cold_storage_register_error: action.payload };
    case COLD_STORAGE_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const coldStorageDetailsByNameReducer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case COLD_STORAGE_DETAILS_BY_NAME_REQUEST:
      return { ...state, cold_storage_details_loading: true };
    case COLD_STORAGE_DETAILS_BY_NAME_SUCCESS :
      return { cold_storage_details_loading: false, cold_storage_details_details : action.payload };
    case COLD_STORAGE_DETAILS_BY_NAME_FAIL:
      return { cold_storage_details_loading: false, cold_storage_details_error: action.payload };
    case COLD_STORAGE_DETAILS_BY_NAME_RESET:
      return {  };
    default:
      return state;
  }
};

export const coldStorageListReducer = (state = { customers_list: [] }, action) => {
  switch (action.type) {
    case COLD_STORAGE_LIST_REQUEST:
      return { cold_storage_list_loading: true };
    case COLD_STORAGE_LIST_SUCCESS:
      return { cold_storage_list_loading: false, cold_storage_list_success: false, cold_storage_list : action.payload };
    case COLD_STORAGE_LIST_FAIL:
      return { cold_storage_list_loading: false, cold_storage_list_error : action.payload };
    case COLD_STORAGE_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const coldStorageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case COLD_STORAGE_DELETE_REQUEST:
      return { cold_storage_delete_loading: true };
    case COLD_STORAGE_DELETE_SUCCESS:
      return { cold_storage_delete_loading: false, cold_storage_delete_success: true };
    case COLD_STORAGE_DELETE_FAIL:
      return { cold_storage_delete_loading: false, cold_storage_delete_error: action.payload };
    default:
      return state;
  }
};

export const coldStorageUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case COLD_STORAGE_UPDATE_REQUEST:
      return { cold_storage_update_loading: true };
    case COLD_STORAGE_UPDATE_SUCCESS:
      return { cold_storage_update_loading: false, cold_storage_update_success : true };
    case COLD_STORAGE_UPDATE_FAIL:
      return { cold_storage_update_loading: false, cold_storage_update_error: action.payload };
    case COLD_STORAGE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
