import React, { useState, useEffect, useRef, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom"; ``
import { ctcTypes } from "../../../data/index.js";
import { listVarieties } from "../../../actions/varietyAction.js";
import { formatToLocaleCurrency } from "../../../util/Utility.js";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../assets/images/green_tick.png";
import { styled } from '@mui/material/styles';
import { RAW_MATERIAL_STOCK_REGISTER_REQUEST, RAW_MATERIAL_STOCK_REGISTER_RESET } from "../../../constants/rawMaterialStockConstants.js";
import GridContainer from "../../Grid/GridContainer.js";
import { listFactories } from "../../../actions/factoryAction.js";
import { createRawMaterialStock, listRawMaterialStocks, updateRawMaterialStockPostManufacturing } from "../../../actions/rawMaterialStockAction.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './AGGridStyle.css'; // Import custom CSS for zebra styling
import { createPowderMaterialStock } from "../../../actions/powderMaterialStockAction.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const CtcPowderStockCreateForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gridRef = useRef();

  const rawMaterialStocksList = useSelector((state) => state.rawMaterialStocksList)
  const { raw_material_stock_list_loading, raw_material_stock_list_success, raw_material_stock_list_data, raw_material_stock_list_error } = rawMaterialStocksList
  const [productsVarietyStockRowData, setProductsVarietyStockRowData] = useState(() => [])
  const [ctcPowderFormInputs, setCtcPowderFormInputs] = useState({
    productName: "",
    totalWeightInKgs: "",
    powderNetWeight: "",
    wastageNetWeight: "",
    manufacturedDate: new Date().toLocaleDateString('en-IN'),
    additionalCosts: [],
    totalEstimatedCost: 0
  });
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    if (ctc_purchase_order_register_success) {
      dispatch({ type: CTC_PURCHASE_ORDER_REGISTER_RESET });
      navigate("/ctc/factory-raw-material");
    }
  };

  useEffect(() => {
    console.log('FETCHING Raw Material Stocks INIT Process')
    dispatch(listRawMaterialStocks())
  }, [])

  useEffect(() => {
    if (ctcPowderFormInputs.productName && ctcPowderFormInputs.productName.length > 0 && raw_material_stock_list_data && raw_material_stock_list_data.length > 0) {
      console.log('ctcPowderFormInputs.productName : ', ctcPowderFormInputs.productName, 'raw_material_stock_list_data: ', raw_material_stock_list_data ? raw_material_stock_list_data.length : 0);
      populateProductVarietiesStockRowData()
    }
  }, [ctcPowderFormInputs.productName])

  const populateProductVarietiesStockRowData = () => {
    console.log('Exec populateProductVarietiesStockRowData ...')
    // console.log('raw_material_stock_list_data.filter(each => each.ctcProductName === ctcPowderFormInputs.productName : ', raw_material_stock_list_data.filter(each => each.productName === ctcPowderFormInputs.productName))
    if (raw_material_stock_list_data && raw_material_stock_list_data.length > 0) {
      setProductsVarietyStockRowData((prev) => raw_material_stock_list_data.filter(each => each.productName === ctcPowderFormInputs.productName)
        .map((eachRec, index) => {
          return {
            "#": index + 1,
            Variety: eachRec.variety,
            InputQuantity: 0,
            MaxQuantity: eachRec.totalWeightInKgs ? formatToLocaleCurrency(Number(eachRec.totalWeightInKgs)) : 0,
            Unit: "Kilo Grams [Kgs]",
            PurchaseUnitPrice: eachRec.pricePerKg ? formatToLocaleCurrency(Number(eachRec.pricePerKg)) : 0,
            EstimatedCost: 0
          };
        })
      )
    }
  }

  const handleSavePowderStock = () => {
    console.log("Exec handleSavePowderStock Values Submitted are : ", ctcPowderFormInputs, productsVarietyStockRowData);
    dispatch(
      createPowderMaterialStock(ctcPowderFormInputs, productsVarietyStockRowData)
    );
    dispatch(
      updateRawMaterialStockPostManufacturing(productsVarietyStockRowData)
    );
  };

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "ctc_types":
        return ctcTypes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };
  const [columnDefs] = useState([
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      cellStyle: (params) => {
        return {
          textAlign: 'center',
        };
      },
    },
    {
      field: 'Variety', headerName: 'Raw Material Variety', resizable: true, suppressSizeToFit: true,
      width: 350,
      minWidth: 250,
      maxWidth: 250,
      cellStyle: (params) => {
        return {
          textAlign: 'left',
        };
      },

    },
    {
      field: 'InputQuantity', headerName: 'Qty [Kgs]', editable: true, resizable: true, suppressSizeToFit: true,
      width: 150,
      minWidth: 250,
      maxWidth: 250,
      editable: true,
      cellStyle: (params) => {
        return {
          textAlign: 'right',
          color: 'black',
          backgroundColor: 'white',
          borderRadius: '5px',
          borderColor: 'white'
        };
      },
    },
    {
      field: 'MaxQuantity', headerName: 'Max Qty [Kgs]', editable: true, resizable: true, suppressSizeToFit: true,
      width: 150,
      minWidth: 250,
      maxWidth: 250,
      editable: true,
      cellStyle: (params) => {
        return {
          textAlign: 'right',
        };
      },
    },
    {
      field: 'Unit', headerName: 'Unit', resizable: true, suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      cellStyle: (params) => {
        return {
          textAlign: 'center',
        };
      },
    },
    {
      field: 'PurchaseUnitPrice', headerName: 'Purchase Unit Price', resizable: true, suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      cellStyle: (params) => {
        return {
          textAlign: 'right',
        };
      },
    },
    {
      field: 'EstimatedCost', headerName: 'Estimated Cost', resizable: true, suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      cellStyle: (params) => {
        return {
          textAlign: 'left',
        };
      },
    },
  ]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onCellValueChanged = (params) => {
    const updatedRowData = params.data;
    console.log('params : ', params)
    console.log('Updated Row Data OLD :', updatedRowData);
    console.log('params.data.InputQuantity:', params.data.InputQuantity, ' params.data.PurchaseUnitPrice : ', params.data.PurchaseUnitPrice);

    const updatedRow = {
      ...params.data,
      EstimatedCost: (params.data.InputQuantity ? Number(params.data.InputQuantity) : 0) * (params.data.PurchaseUnitPrice ? Number(params.data.PurchaseUnitPrice) : 0),
    };
    console.log('Updated Row Data LATEST :', updatedRow);

    const newData = productsVarietyStockRowData.map(row => {
      return row.Variety === updatedRow.Variety ? updatedRow : row
    }
    );
    setProductsVarietyStockRowData((prevState) => newData);
  };

  const calculateWastableQuantity = (val) => {
    console.log('calculateWastableQuantity ..! - START', productsVarietyStockRowData.map(e => e.InputQuantity))
    const total = productsVarietyStockRowData.reduce((acc, currentValue) => acc + (currentValue && currentValue.InputQuantity ? Number(currentValue.InputQuantity) : 0), 0);
    console.log('calculateWastableQuantity ..! - END', 'TOTAL WEIGHT : ', total, 'Powdered Value : ', val, 'Remaining : ', total - val)
    setCtcPowderFormInputs(prevState => ({ ...prevState, wastageNetWeight: total - val }))
    return total;
  };

  const calculateTotalNetWeight = () => {
    const total = productsVarietyStockRowData.reduce((acc, currentValue) => acc + (currentValue && currentValue.InputQuantity ? Number(currentValue.InputQuantity) : 0), 0);
    console.log('calculateTotalNetWeight : ', 'TOTAL NET WEIGHT : ', total)
    setCtcPowderFormInputs(prevState => ({ ...prevState, totalWeightInKgs: total }))
  };
  
  const calculateTotalEstimatedCost = () => {
    const totalEstimatedCost = productsVarietyStockRowData.reduce((acc, currentValue) => acc + (currentValue && currentValue.EstimatedCost ? Number(currentValue.EstimatedCost) : 0), 0);
    console.log('calculateTotalEstimatedCost : ', 'TOTAL EstimatedCost : ', totalEstimatedCost)
    setCtcPowderFormInputs(prevState => ({ ...prevState, totalEstimatedCost: totalEstimatedCost }))
  };

  return (
    <div >
      {/* {ctc_purchase_order_register_success && (<BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <Grid item xs={3} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  Moved Stock To Factory
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>)} */}
      {/* <form method="post" onSubmit={handleSavePowderStock}> */}
      <Grid container spacing={2} marginTop="auto" >
        <Grid item xs={6}>
          <FormControl style={{ justifyContent: "center", textAlign: "center" }}>
            <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
              Product Name
            </InputLabel>
            <Select
              label="Product Name"
              placeholder="Product Name"
              variant="outlined"
              required
              size="small"
              name="productName"
              style={{ width: '15rem' }}
              onChange={(e) => {
                console.log('Product Name : ', e.target.value)
                setCtcPowderFormInputs((prev) => ({ ...prev, productName: e.target.value }))
              }
              }
              value={ctcPowderFormInputs.productName || ""}
            >
              {renderDynamicOptions("ctc_types")}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ justifyContent: "center", textAlign: "center" }} >
          <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "right" }}>
            <Typography style={{ fontSize: "1rem", textAlign: "center" }} variant="body1">Manufactured Date : {new Date().toLocaleDateString()}</Typography>
          </InputLabel>
        </Grid>
      </Grid>
      <Divider style={{ margin: "1rem" }} />
      <Grid container spacing={2} marginTop="auto" style={{ width: '100%' }} >
        <div
          className="ag-theme-quartz"
          style={{ width: "70%", height: "40vh", textAlign: "center", justifyContent: "center", alignItems: "center", marginLeft: "auto", marginRight: "auto" }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={productsVarietyStockRowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            onCellValueChanged={onCellValueChanged}
            popupParent={popupParent}
          ></AgGridReact>
        </div>
      </Grid>
      <Divider style={{ margin: "1rem" }} />
      <Grid container spacing={2} marginTop="auto" >
        <Grid item xs={6} style={{ justifyContent: "center", textAlign: "center" }} >
          <TextField
            label="Total Powder Net Weight [Kgs] *"
            variant="outlined"
            size="small"
            name="powderNetWeight"
            style={{ justifyContent: "center", textAlign: "center" }}
            onChange={(e) => {
              console.log('e.target.Value : ', e.target.value)
              calculateWastableQuantity(e.target.value)
              calculateTotalEstimatedCost()
              calculateTotalNetWeight()
              setCtcPowderFormInputs((prev) => ({
                ...prev,
                powderNetWeight: e.target.value,
              }))
            }
            }
            type="number"
            value={ctcPowderFormInputs && ctcPowderFormInputs.powderNetWeight ? ctcPowderFormInputs.powderNetWeight : null}
          />
        </Grid>
      </Grid>
      <Divider style={{ margin: "1rem" }} />
      <Grid container spacing={2} marginTop="auto" >
        {ctcPowderFormInputs && ctcPowderFormInputs.powderNetWeight && ctcPowderFormInputs.powderNetWeight > 0 && (<Grid item xs={4} style={{ justifyContent: "center", textAlign: "center" }} >
          <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
            <Typography style={{ fontSize: "1rem", textAlign: "center", fontWeight: "600", color: "green" }} variant="body1">Total Estimated Value : {ctcPowderFormInputs && ctcPowderFormInputs.totalEstimatedCost ? formatToLocaleCurrency(ctcPowderFormInputs.totalEstimatedCost) : null}
            </Typography>
          </InputLabel>
        </Grid>)}
        {ctcPowderFormInputs && ctcPowderFormInputs.powderNetWeight && ctcPowderFormInputs.powderNetWeight > 0 && (<Grid item xs={4} style={{ justifyContent: "center", textAlign: "center" }} >
          <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
            <Typography style={{ fontSize: "1rem", textAlign: "center", fontWeight: "600", color: "green" }} variant="body1">Raw Material Net Weight [Kgs] : {ctcPowderFormInputs && ctcPowderFormInputs.totalWeightInKgs ? formatToLocaleCurrency(ctcPowderFormInputs.totalWeightInKgs) : null}
            </Typography>
          </InputLabel>
        </Grid>)}
        {ctcPowderFormInputs && ctcPowderFormInputs.powderNetWeight && ctcPowderFormInputs.powderNetWeight > 0 && (<Grid item xs={4} style={{ justifyContent: "center", textAlign: "center" }} >
          <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
            <Typography style={{ fontSize: "1rem", textAlign: "center", fontWeight: "600", color: "red" }} variant="body1">Wastage Net Weight [Kgs] : {ctcPowderFormInputs && ctcPowderFormInputs.wastageNetWeight ? formatToLocaleCurrency(ctcPowderFormInputs.wastageNetWeight) : null}
            </Typography>
          </InputLabel>
        </Grid>)}
      </Grid>
      <Divider style={{ margin: "1rem" }} />
      <Grid container spacing={2} marginTop="auto" >
        <Grid item xs={12} align="center" style={{ textAlign: "center" }}>
          <Button
            size="small"
            variant="contained"
            type="submit"
            onClick={handleSavePowderStock}
            color="secondary"
            justify="center"
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              width: "12rem",
              textAlign: "center"
            }}
          >
            Maunfactured Powder
          </Button>
        </Grid>
      </Grid>
      {/* </form> */}
    </div>
  );
};

export default CtcPowderStockCreateForm;
