import {
  CTC_VARIETY_DETAILS_FAIL,
  CTC_VARIETY_DETAILS_REQUEST,
  CTC_VARIETY_DETAILS_SUCCESS,
  CTC_VARIETY_DETAILS_RESET,

  CTC_VARIETY_REGISTER_FAIL,
  CTC_VARIETY_REGISTER_REQUEST,
  CTC_VARIETY_REGISTER_SUCCESS,
  CTC_VARIETY_REGISTER_RESET,
 
  CTC_VARIETY_LIST_FAIL,
  CTC_VARIETY_LIST_SUCCESS,
  CTC_VARIETY_LIST_REQUEST,
  CTC_VARIETY_LIST_RESET,

  CTC_VARIETY_DELETE_REQUEST,
  CTC_VARIETY_DELETE_SUCCESS,
  CTC_VARIETY_DELETE_FAIL,
  CTC_VARIETY_DELETE_RESET,

  CTC_VARIETY_UPDATE_FAIL,
  CTC_VARIETY_UPDATE_SUCCESS,
  CTC_VARIETY_UPDATE_REQUEST,
  CTC_VARIETY_UPDATE_RESET,

  CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_REQUEST,
  CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_SUCCESS,
  CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_FAIL,
  CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_RESET,
} from "../constants/varietyConstants";


export const varietyRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_VARIETY_REGISTER_REQUEST:
      return { ctc_variety_register_loading: true };
    case CTC_VARIETY_REGISTER_SUCCESS:
      return { ctc_variety_register_loading: false,ctc_variety_register_success: true, ctc_variety_register_data: action.payload };
    case CTC_VARIETY_REGISTER_FAIL:
      return { ctc_variety_register_loading: false, ctc_variety_register_error: action.payload };
    case CTC_VARIETY_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const varietyDetailsByNameReducer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_REQUEST:
      return { ...state, ctc_variety_details_loading: true };
    case CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_SUCCESS :
      return { ctc_variety_details_loading: false, ctc_variety_details_data : action.payload };
    case CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_FAIL:
      return { ctc_variety_loading: false, ctc_variety_error: action.payload };
    case CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_RESET:
      return {  };
    default:
      return state;
  }
};

export const varietyListReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_VARIETY_LIST_REQUEST:
      return { ctc_variety_list_loading: true };
    case CTC_VARIETY_LIST_SUCCESS:
      return { ctc_variety_list_loading: false, ctc_variety_list_success: false, ctc_variety_list_data : action.payload };
    case CTC_VARIETY_LIST_FAIL:
      return { ctc_variety_list_loading: false, ctc_variety_list_error : action.payload };
    case CTC_VARIETY_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const varietyDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_VARIETY_DELETE_REQUEST:
      return { ctc_variety_delete_loading: true };
    case CTC_VARIETY_DELETE_SUCCESS:
      return { ctc_variety_delete_loading: false, ctc_variety_delete_success: true };
    case CTC_VARIETY_DELETE_FAIL:
      return { ctc_variety_delete_loading: false, ctc_variety_delete_error: action.payload };
    default:
      return state;
  }
};

export const varietyUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_VARIETY_UPDATE_REQUEST:
      return { ctc_variety_update_loading: true };
    case CTC_VARIETY_UPDATE_SUCCESS:
      return { ctc_variety_update_loading: false, ctc_variety_update_success : true };
    case CTC_VARIETY_UPDATE_FAIL:
      return { ctc_variety_update_loading: false, ctc_variety_update_error: action.payload };
    case CTC_VARIETY_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
