import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import validate from "validate.js";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../Message.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { useNavigate } from "react-router-dom";``
import { ctcTypes } from "../../../data/index.js";
import { listVarieties } from "../../../actions/varietyAction.js";
import { formatToLocaleCurrency } from "../../../util/Utility.js";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../assets/images/green_tick.png";
import { styled } from '@mui/material/styles';
import { RAW_MATERIAL_STOCK_REGISTER_REQUEST,RAW_MATERIAL_STOCK_REGISTER_RESET } from "../../../constants/rawMaterialStockConstants.js";
import GridContainer from "../../Grid/GridContainer.js";
import { listFactories } from "../../../actions/factoryAction.js";
import { createRawMaterialStock } from "../../../actions/rawMaterialStockAction.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const RawMaterialStockCreateForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const varietyList = useSelector((state) => state.varietyList);
  const { ctc_variety_list_loading, ctc_variety_list_success, ctc_variety_list_data } = varietyList;
  const ctcPurchaseOrderRegistered = useSelector((state) => state.ctcPurchaseOrderRegistered)
  const { ctc_purchase_order_register_success, ctc_purchase_order_register_error } = ctcPurchaseOrderRegistered

  const [filteredVariety, setFilteredVariety] = useState(() => null);
 
  const [ctcFactoryStockFormInputs, setCtcFactoryStockFormInputs] = useState({
    ctcSupplierName: "",
    ctcSupplierGstNo: "",
    ctcSupplierContactNo: "",
    productName: "",
    variety: "",
    bagsReceived: "",
    eachBagWeightInKgs: "",
    pricePerKg: "",
    taxPerKg: "",
    totalPrice: "",
    totalTaxPrice: "",
    totalWeightInKgs: "",
    stockMoveInDate: new Date().toLocaleString(),
  });
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    if (ctc_purchase_order_register_success) {
      dispatch({ type: CTC_PURCHASE_ORDER_REGISTER_RESET });
      navigate("/ctc/factory-raw-material");
    }
  };

  useEffect(() => {
    console.log('FETCHING VARIETIES')
    dispatch(listVarieties())
  }, [])

  useEffect(() => {
    if (ctcFactoryStockFormInputs.productName && ctcFactoryStockFormInputs.productName.length > 0 && ctc_variety_list_data && ctc_variety_list_data.length > 0) {
      console.log('ctcFactoryStockFormInputs.productName : ', ctcFactoryStockFormInputs.productName, 'ctc_variety_list_data : ', ctc_variety_list_data ? ctc_variety_list_data.length : 0);
      populateVarietiesByProduct(ctcFactoryStockFormInputs.productName)
    } else {
      console.log('Not PERFORMING POPULATE VARIETIES ')
    }
  }, [ctcFactoryStockFormInputs.productName, ctc_variety_list_data])

  useEffect(() => {
    if (ctc_purchase_order_register_success) {
      console.log('Routing to List Screen as New Bond is Created. !');
      setOpen(() => true);
    }
  }, [ctc_purchase_order_register_success])


  const populateVarietiesByProduct = (productName) => {
    setFilteredVariety((prev) => ctc_variety_list_data.filter(each => each.ctcProductName === productName).map((eachVariety, idx) => {
      console.log(eachVariety, idx)
      return (
        <MenuItem key={idx} value={eachVariety.variety}>
          {eachVariety.variety}
        </MenuItem>
      );
    }));
  }

  const handleSubmit = (event) => {
    console.log("Values Submitted are : ", ctcFactoryStockFormInputs);
    event.preventDefault();
    dispatch(
      // createCtcPurchaseOrder(ctcFactoryStockFormInputs)
      createRawMaterialStock(ctcFactoryStockFormInputs)
    );
  };

  const renderDynamicOptions = (menu_type) => {
    switch (menu_type) {
      case "ctc_types":
        return ctcTypes.map((each, idx) => (
          <MenuItem key={idx} value={each}>
            {each}
          </MenuItem>
        ));
      default:
        break;
    }
  };

  const handleCtcChange = (event) => {
    console.log("handleCtcChange is getting Called when advance Paid is Changed")
    setCtcFactoryStockFormInputs((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div >
      {ctc_purchase_order_register_success && (<BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <Grid item xs={3} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  Moved Stock To Factory
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>)}
      <form method="post" onSubmit={handleSubmit}>

        <Grid container spacing={2} marginTop="auto" >
          <Grid item xs={3} align="center">
            <TextField
              placeholder="Supplier Name"
              label="Supplier Name *"
              variant="outlined"
              size="small"
              name="ctcSupplierName"
              fullWidth
              onChange={(e) => setCtcFactoryStockFormInputs((prev) => ({
                ...prev,
                ctcSupplierName: e.target.value,
              }))}
              type="text"
              value={ctcFactoryStockFormInputs.ctcSupplierName || ""}
            />
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              placeholder="Supplier Contact"
              label="Supplier Contact *"
              variant="outlined"
              size="small"
              name="ctcSupplierContactNo"
              fullWidth
              onChange={(e) => setCtcFactoryStockFormInputs((prev) => ({
                ...prev,
                ctcSupplierContactNo: e.target.value,
              }))}
              type="text"
              value={ctcFactoryStockFormInputs.ctcSupplierContactNo || ""}
            />
          </Grid>

          <Grid item xs={3} align="center">
            <TextField
              placeholder="Supplier GST"
              label="Supplier GST *"
              variant="outlined"
              size="small"
              name="ctcSupplierGstNo"
              fullWidth
              onChange={(e) => setCtcFactoryStockFormInputs((prev) => ({
                ...prev,
                ctcSupplierGstNo: e.target.value,
              }))}
              type="text"
              value={ctcFactoryStockFormInputs.ctcSupplierGstNo || ""}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Product Name
              </InputLabel>
              <Select
                label="Product Name"
                placeholder="Product Name"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="productName"
                onChange={(e) => {
                  console.log('Product Name : ', e)
                  setCtcFactoryStockFormInputs((prev) => ({ ...prev, productName: e.target.value }))
                }
                }
                value={ctcFactoryStockFormInputs.productName || ""}
              >
                {renderDynamicOptions("ctc_types")}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ justifyContent: "center", textAlign: "center", width: "100%" }}>
              <InputLabel id="demo-simple-select-label" style={{ justifyContent: "center", textAlign: "center" }}>
                Variety
              </InputLabel>
              <Select
                label="Variety"
                placeholder="Variety"
                variant="outlined"
                required
                size="small"
                fullWidth
                name="variety"
                onChange={(e) => setCtcFactoryStockFormInputs((prev) => ({ ...prev, variety: e.target.value }))}
                value={ctcFactoryStockFormInputs.variety || ""}
              >
                {filteredVariety}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              placeholder="Bags Received"
              label="Bags Received *"
              variant="outlined"
              size="small"
              name="bagsReceived"
              fullWidth
              onChange={handleCtcChange}
              type="number"
              value={ctcFactoryStockFormInputs.bagsReceived || ""}
            />
          </Grid>

          <Grid item xs={3} align="center">
            <TextField
              placeholder="Each Bag Weight In Kgs"
              label="Each Bag Weight In Kgs *"
              variant="outlined"
              size="small"
              name="eachBagWeightInKgs"
              fullWidth
              onChange={(e) => setCtcFactoryStockFormInputs((prev) => ({
                ...prev,
                eachBagWeightInKgs: e.target.value,
                totalWeightInKgs: Number(e.target.value) * Number(ctcFactoryStockFormInputs.bagsReceived)
              }))}
              type="text"
              value={ctcFactoryStockFormInputs.eachBagWeightInKgs || ""}
            />
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              placeholder="Price per Kg"
              label="Price per Kg *"
              variant="outlined"
              size="small"
              name="pricePerKg"
              fullWidth
              onChange={(e) => setCtcFactoryStockFormInputs((prev) => ({
                ...prev,
                pricePerKg: e.target.value,
                totalTaxPrice: Number(e.target.value) * Number(ctcFactoryStockFormInputs.bagsReceived) * Number(ctcFactoryStockFormInputs.eachBagWeightInKgs),
                totalPrice: Number(e.target.value) * Number(ctcFactoryStockFormInputs.bagsReceived) * Number(ctcFactoryStockFormInputs.eachBagWeightInKgs)
              }))}
              type="number"
              value={ctcFactoryStockFormInputs.pricePerKg || ""}
            />
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              placeholder="Tax per Kg"
              label="Tax per Kg *"
              variant="outlined"
              size="small"
              name="taxPerKg"
              fullWidth
              onChange={(e) => setCtcFactoryStockFormInputs((prev) => ({
                ...prev,
                taxPerKg: e.target.value,
              }))}
              type="number"
              value={ctcFactoryStockFormInputs.taxPerKg || ""}
            />
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              variant="outlined"
              // label="Total Price"
              size="small"
              name="totalPrice"
              fullWidth
              disabled
              onChange={handleCtcChange}
              type="text"
              value={ctcFactoryStockFormInputs.totalPrice ? formatToLocaleCurrency(ctcFactoryStockFormInputs.totalPrice) : 0}
            />
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              //  label="Total Weight In Kgs "
              variant="outlined"
              size="small"
              name="totalWeightInKgs"
              fullWidth
              disabled
              type="text"
              value={ctcFactoryStockFormInputs.totalWeightInKgs ? formatToLocaleCurrency(ctcFactoryStockFormInputs.totalWeightInKgs) : 0}
            />
          </Grid>
          <Grid item xs={3} align="center">
            <TextField
              // placeholder="Purchase Order Date"
              label="Purchase Order Date  *"
              variant="outlined"
              size="small"
              name="ctcPurchaseOrderDate"
              disabled
              fullWidth
              type="text"
              value={ctcFactoryStockFormInputs.stockMoveInDate || new Date().toLocaleString()}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} marginTop="auto" >
          <Grid item xs={12} align="center" style={{ textAlign: "center" }}>
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              style={{
                textTransform: "capitalize",
                borderRadius: "5px",
                width: "12rem",
                textAlign: "center"
              }}
            >
              Save Factory Stock
            </Button>
          </Grid>
        </Grid>

      </form>
    </div>
  );
};

export default RawMaterialStockCreateForm;
