import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";
import Card from "../Card/Card.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Typography,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Message from "../Message.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { online_walkin } from "../../data/index.js";
import { convertInvoiceIdToDigits, formatToLocaleCurrency } from '../../util/Utility.js'
import { listPaymentsByStore } from "../../actions/storePaymentAction.js";
import { listOrdersReceiptsByStore } from "../../actions/orderReceiptAction.js";
import { listWalkinSalesReceiptsByStore } from "../../actions/walkinSaleReceiptAction.js";

const PaymentsReceiptsListScreen = ({ match }) => {
  const [rowData, setRowData] = useState(() => [])
  const [paymentsRowData, setPaymentsRowData] = useState(() => [])
  const [onlineReceiptsRowData, setOnlineReceiptsRowData] = useState(() => [])
  const [walkinSaleReceiptsRowData, setWalkinSaleReceiptsRowData] = useState(() => [])
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const pos = storeName ? storeName : warehouseName ? warehouseName : ''

  const paymentsListByStore = useSelector((state) => state.paymentsListByStore);
  const { payments_by_store_loading, payments_by_store, payments_by_store_error } = paymentsListByStore;

  const orderReceiptsListByStore = useSelector((state) => state.orderReceiptsListByStore);
  const { order_receipts_by_store_list_loading, order_receipts_by_store_list, order_receipts_by_store_list_error } = orderReceiptsListByStore;

  const walkinSaleReceiptsListByStore = useSelector((state) => state.walkinSaleReceiptsListByStore);
  const { walkin_sale_receipts_by_store_list_loading, walkin_sale_receipts_by_store_list_success, walkin_sale_receipts_by_store_list , walkin_sale_receipts_by_store_list_error } = walkinSaleReceiptsListByStore;

  const walkinSalesInvoicesListByPos = useSelector(
    (state) => state.walkinSalesInvoicesListByPos
  );
  const { walkin_sales_invoice_pos_loading, walkin_sales_invoice_pos_data, walkin_sales_invoice_pos_error } = walkinSalesInvoicesListByPos;

  // State to keep track of the selected chip ID
  const [selectedChipId, setSelectedChipId] = useState(() => null);
  const [receiptType, setReceiptType] = useState(() => null);

  const initialChips = [
    { id: 1, label: ' Payments ' },
    { id: 2, label: 'Receipts' }
  ];

  const handleReceiptTypeChange = (event) => {
    console.log('Clicked handleReceiptTypeChange : ', event.target.value)
    setReceiptType((prev) => event.target.value);
  };

  const renderFilterOptions = () => {
    return online_walkin.map((each, idx) => (
      <MenuItem key={idx} value={each}>
        {each}
      </MenuItem>
    ));
  }

  const handleChipClick = (chipId) => {
    console.log('chipId : ', chipId)
    setSelectedChipId(chipId);
    if (chipId === 1 && payments_by_store) {
      populateStorePaymentsRowData()
      console.log('Chip ID : ', chipId, ' So populating Store Payments')
    }
  };

  let renderPaymentsContentUsingAGGrid = "";
  let renderReceiptsContentUsingAGGrid = "";

  const renderReceiptsFilter = (
    <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" ,marginBottom:"1rem"}}>
      <FormControl style={{ width: '25%' }}>
        <InputLabel id="demo-simple-select-label">
          Receipt Type
        </InputLabel>
        <Select
          label="Name"
          placeholder="Name"
          variant="outlined"
          required
          size="small"
          fullWidth
          name="receiptType"
          onChange={handleReceiptTypeChange}
          value={receiptType}
        >
          {renderFilterOptions ? renderFilterOptions() : []}
        </Select>
      </FormControl>
    </GridItem>
  )

  const populateStorePaymentsRowData = () => {
    console.log("Populating Store Payments ")
    if (payments_by_store && payments_by_store.length > 0) {
      setPaymentsRowData((prev) => {
        return payments_by_store.map((eachOrder, index) => {
          const totalAmountPaid = eachOrder.paymentDetails.cashAmountPaid ? Number(eachOrder.paymentDetails.cashAmountPaid) : 0 + eachOrder.paymentDetails.upiAmountPaid ? Number(eachOrder.paymentDetails.upiAmountPaid) : 0
          const paymentStatus = eachOrder && eachOrder.paymentDetails ? eachOrder.paymentDetails.paymentStatus :""  
          const invIdInDecimal = convertInvoiceIdToDigits(eachOrder._id)
          const totalPrice = eachOrder && eachOrder.paymentDetails && eachOrder.paymentDetails.totalPrice ? Number(eachOrder.paymentDetails.totalPrice).toFixed(2) : 0.0
          return {
            "#": index + 1,
            "Id": eachOrder._id,
            "Payment Mode" : eachOrder && eachOrder.paymentDetails && eachOrder.paymentDetails.mode
            ? eachOrder.paymentDetails.mode : "",
            "Order No " : eachOrder && eachOrder._id && eachOrder._id
            ? eachOrder._id: "",
            "Order No": invIdInDecimal,
            "Supplier Name": eachOrder && eachOrder.supplierName ? eachOrder.supplierName : "",
            "Total Price": totalPrice ? formatToLocaleCurrency(Number(totalPrice)) : 0.0,
            "Paid Amount": totalAmountPaid ? formatToLocaleCurrency(Number(totalAmountPaid)) : 0.0,
            "Payment Date": eachOrder && eachOrder.paymentDetails && eachOrder.paymentDetails.paymentDate
              ? eachOrder.paymentDetails.paymentDate : "",
          };
        }
        )
      }
      );
    } else
    setPaymentsRowData((prev) => [])
  }


  const populateStoreOnlineReceiptsRowData = () => {
    console.log("Populating Store Online Receipts ")
    if (order_receipts_by_store_list && order_receipts_by_store_list.length > 0) {
      setOnlineReceiptsRowData((prev) => {
        return order_receipts_by_store_list.map((eachOrder, index) => {
          const {paymentDetails } = eachOrder
          const totalAmountPaid = ( paymentDetails.cashAmountPaid ? Number(paymentDetails.cashAmountPaid) : 0) + (paymentDetails.upiAmountPaid ? Number(paymentDetails.upiAmountPaid) : 0)
          console.log('totalAmountPaid : ', totalAmountPaid)
          const dueOrPaid = (eachOrder.totalPrice - totalAmountPaid) > 0 ? "DUE" : "PAID";
          const invIdInDecimal = convertInvoiceIdToDigits(eachOrder._id)
          const totalPrice = paymentDetails && paymentDetails.totalPrice ? formatToLocaleCurrency(Number(paymentDetails.totalPrice)) : 0.0
          const balance = paymentDetails && paymentDetails.balanceAmount ? formatToLocaleCurrency(Number(paymentDetails.balanceAmount)) : 0.0
          const paidDate = paymentDetails && paymentDetails.paymentDate ? paymentDetails.paymentDate : ""
          const payStatus = paymentDetails && paymentDetails.paymentStatus ? paymentDetails.paymentStatus : ""

          return {
            "#": index + 1,
            "Id": eachOrder._id,
            "Order No": invIdInDecimal,
            "Customer Name": eachOrder.customer
              ? eachOrder.customer :"",
            "Payment Status": payStatus,
            "Total Price": totalPrice,
            "Received": totalAmountPaid ? totalAmountPaid : 0.0,
            "Balance":balance,
            "Payment Date": paidDate
          };
        }
        )
      }
      );
    } else
    setOnlineReceiptsRowData((prev) => [])
  }

  const populateStoreWalkinReceiptsRowData = () => {
    console.log("Populating Receipts Orders By Store")
    if (walkin_sale_receipts_by_store_list && walkin_sale_receipts_by_store_list.length > 0) {
      setWalkinSaleReceiptsRowData((prev) => {
        return walkin_sale_receipts_by_store_list.map((eachOrder, index) => {
          console.log("Each Order Details By POS :", eachOrder.posName)
          console.log('Bill Date : ', eachOrder.billDate)

          const {store, billDate , customer , walkinSaleInvoice, paymentDetails} = eachOrder
          const  { cashAmountPaid , upiAmountPaid , paymentStatus , totalPrice , balanceAmount, paymentDate } = paymentDetails
          const totalAmountPaid = (cashAmountPaid ? Number(cashAmountPaid) : 0) +(upiAmountPaid ? Number(upiAmountPaid) : 0)
          console.log('totalAmountPaid : ', totalAmountPaid)
          console.log('Balance Amount : ', balanceAmount)
          const dueOrPaid = ((totalPrice ? Number(totalPrice) : 0) - totalAmountPaid) > 0 ? "DUE" : "PAID";
          const invIdInDecimal = convertInvoiceIdToDigits(eachOrder._id)
          console.log("invIdInDecimal : ", invIdInDecimal)
          return {
            "#": index + 1,
            "Id": eachOrder._id,
            "Order No": walkinSaleInvoice,
            "Customer Name": customer ? customer : "",
            "Total Price": totalPrice ? formatToLocaleCurrency(Number(totalPrice)) : 0.0,
            "Received": totalAmountPaid ? formatToLocaleCurrency(Number(totalAmountPaid)) : 0.0,
            "Payment Status": dueOrPaid,
            "Payment Date": paymentDate ? paymentDate : "",
            "Balance": balanceAmount ? formatToLocaleCurrency(Number(balanceAmount)) : 0.0,
            "Order Date": eachOrder.billDate
              ? new Date(eachOrder.billDate).toLocaleString()
              : "",
          };
        }
        )
      }
      );
    } else {
      console.log("NO STORE WALKIN RECEIPTS SO SETTING EMPTY ARRAY")
      setWalkinSaleReceiptsRowData((prev) => [])
    }
  }

  const paymentsColumnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Id",
      headerName: "Id",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "Payment Mode",
      headerName: "Payment Mode",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "Order No",
      headerName: "Order No",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "Supplier Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
    },
    {
      field: "Total Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
      },
    },
    {
      field: "Paid Amount",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 300,
      cellStyle: params => {
        return { textAlign: "right" };
      },
    },
    {
      field: "Payment Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
    },

  ];

  const receiptsColumnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Id",
      headerName: "Id",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "Order No",
      headerName: "Order No",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
      cellStyle: params => {
        return { textAlign: "left" };
      },
    },
    {
      field: "Customer Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
      filter: true,
      cellStyle: params => {
        return { textAlign: "left" };
      },
    },
    {
      field: "Total Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 225,
      minWidth: 225,
      maxWidth: 225,
      cellStyle: params => {
        return { textAlign: "right" };
      },
    },
    {
      field: "Received",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 300,
      cellStyle: params => {
        return { textAlign: "right" };
      },
    },
    {
      field: "Payment Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
      cellStyle: params => {
        return { textAlign: "right" };
      },
    },
    {
      field: "Payment Status",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
      cellStyle: params => {
        if (params.value === 'DUE') {
          return { color: 'red', backgroundColor: 'white', textAlign: "center" , fontWeight:'800'};
        }
        else {
          return { color: 'green', backgroundColor: 'white', textAlign: "center", fontWeight:'800' };
        }
        return null;
      }
    },
    {
      field: "Balance",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
      cellStyle: params => {
        return { textAlign: "center" };
      },
    },

  ];
  

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      resizable: true,
      editable: true,
    }),
    []
  );

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  const findStoreById = (storeId) => {
    let storeObj = null;
    let storeName = null;
    if (stores) {
      storeObj = stores.filter((eachStore) => eachStore._id === storeId);
      if (storeObj && storeObj.length > 0) {
        storeName = storeObj[0].storeName ? storeObj[0].storeName : "";
      }
    }
    return storeName;
  };

  useEffect(() => {
    if (pos && pos.length > 0) {
      console.log("useEffect getting called to list All  Payments , Order Receipts & Walkin Receipts ");
      dispatch(listPaymentsByStore(pos));
      dispatch(listOrdersReceiptsByStore(pos));
      dispatch(listWalkinSalesReceiptsByStore(pos));
    }
  }, [pos]);

  useEffect(() => {
      console.log("useEffect getting called to list All  Payments , Order Receipts & Walkin Receipts ");
      if (receiptType && receiptType === 'Online') {
        populateStoreOnlineReceiptsRowData()
        console.log(' receiptType : ONLINE  So populating Receipts of Online Orders')
      }
      else if (receiptType && receiptType === 'Walkin') {
        populateStoreWalkinReceiptsRowData()
        console.log(' receiptType : ONLINE  So populating Receipts of Walkin Sales')
      }
  }, [receiptType]);

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  if ( paymentsRowData ) {
    renderPaymentsContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "70vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={paymentsRowData}
          columnDefs={paymentsColumnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        ></AgGridReact>
      </div>
    );
  }
  if ( onlineReceiptsRowData && walkinSaleReceiptsRowData ) {
      renderReceiptsContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "70vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={receiptType && receiptType.length > 0 && receiptType === 'Online' ? onlineReceiptsRowData:receiptType && receiptType.length > 0 && receiptType === 'Walkin' ? walkinSaleReceiptsRowData:[]}
          columnDefs={receiptsColumnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        ></AgGridReact>
      </div>
    );
  }

  return (
    <React.Fragment>
      {payments_by_store_error && <Message variant="danger">{payments_by_store_error}</Message>}
      {payments_by_store_loading && <CustomBackdropSpinner />}
      {walkin_sales_invoice_pos_loading && <CustomBackdropSpinner />}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
          <Card>
            <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                Payments / Receipts
                {/* <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ tetxtAlign: "right" , float:'right' }}
                /> */}
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={1} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
        <div style={{ marginBottom: "2.5rem" }}>
          <Stack direction="row" spacing={10} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
            {initialChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                variant="outlined"
                onClick={() => handleChipClick(chip.id)}
                style={{
                  backgroundColor: selectedChipId === chip.id ? '#f0c14b' : '#D3D3D3',
                  color: selectedChipId === chip.id ? 'white' : 'black',
                  width: "7.5rem"
                }}
              />
            ))}
          </Stack>
        </div>
      </GridContainer>
      <GridContainer spacing={1} style={{ margin:"1rem" }}>
      {selectedChipId && selectedChipId === 2 && renderReceiptsFilter }
      </GridContainer>
      {selectedChipId && selectedChipId === 1 && <GridContainer style={{ marginTop:"1rem" , marginBottom:"1rem" }}>
        <GridItem xs={12} sm={12} md={12} >
          {console.log('Payments RowData Count : ', paymentsRowData ? paymentsRowData.length : 0)}
          {renderPaymentsContentUsingAGGrid ? renderPaymentsContentUsingAGGrid : null}
        </GridItem>
      </GridContainer>}
      {selectedChipId && selectedChipId === 2 && <GridContainer style={{ textAlign: "center", justifyContent: "center", alignItems: "center", height: "50%", width: '100%', overflowY: 'auto' }}>
        <GridItem xs={12} sm={12} md={12}>
          {renderReceiptsContentUsingAGGrid ? renderReceiptsContentUsingAGGrid : null}
        </GridItem>
      </GridContainer>}
    </React.Fragment>
  );
};

export default PaymentsReceiptsListScreen;
