import React, { useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { listCustomers, deleteCustomer, updateCustomer, listCustomersByParentUser, listCustomersByPosName } from "../../actions/customerAction";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button } from "@mui/material";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import Message from "../Message";
import CustomBackdropSpinner from "../CustomBackdropSpinner";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const CustomerListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  let renderContentUsingAGGrid = "";
  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,

    },
    {
      field: "Name",
      headerName: "Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
    },
    {
      field: "Contact No",
      headerName: "Contact No",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true,
    },
    {
      field: "Email",
      headerName: "Email",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "Address",
      headerName: "Address",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
    },
    {
      field: "Id",
      headerName: "Edit",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Edit" arrow>
            <EditRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("params", params.data)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></EditRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
    {
      field: "Id",
      headerName: "Delete",
      resizable: true,
      suppressSizeToFit: true,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Delete" arrow>
            <DeleteRoundedIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => console.log("params", params.data)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></DeleteRoundedIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
      width: 125,
      minWidth: 125,
      maxWidth: 125,
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];
  const userLogin = useSelector(
    (state) => state.userLogin
  );
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;
  const pos = storeName ? storeName : warehouseName ? warehouseName : ''

  const customerListByParentUser = useSelector((state) => state.customerListByParentUser);
  const { customer_list_parent_user_loading, customer_list_parent_user_error, customer_list_parent_user } = customerListByParentUser;

  const customerDeleted = useSelector((state) => state.customerDeleted);
  const { customer_delete_success, customer_delete_error } = customerDeleted;

  useEffect(() => {
    console.log(" Loading Customers List By Mapped User  ");
    if (name && name.length > 0)
      dispatch(listCustomersByParentUser(name));
  }, [dispatch, name]);

  const deleteHandler = (id) => {
    dispatch(deleteUser(id));
  };

  const handleAddCustomer = (e) => {
    e.preventDefault();
    navigate("/admin/customer/signup");
  };


  /**
   * Start AG Grid
   */
  if (customer_list_parent_user && customer_list_parent_user.length > 0) {
    rowData = customer_list_parent_user.map((customer, index) => {
      console.log(customer);
      return {
        "#": index + 1,
        Name: customer.name,
        Email: customer.email,
        ContactNo: customer.contactNo,
        Address: customer.address,
        Edit: customer._id,
        Delete: customer._id,
      };
    })
    renderContentUsingAGGrid = (
      <>
        <div className="ag-theme-quartz" style={{ width: "100%", height: '65vh' }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowStyle={{ textAlign: "left", alignItems: "center" }}
            suppressExcelExport={false}
            popupParent={popupParent}
            pagination="true"
          ></AgGridReact>
        </div>
      </>
    );
  }
  /**
   * End AG Grid
   */
  return (
    <React.Fragment>
      {customer_list_parent_user_loading && <CustomBackdropSpinner />}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Typography variant="h5" style={{
                  fontWeight: 500,
                  textAlign: "Center"
                }}>
                  <Tooltip title="Back" arrow>
                    <ArrowBackIcon
                      fontSize="medium"
                      onClick={() => window.history.back()}
                      style={{ float: "left", marginRight: "3em", textAlign: "center" }}
                    />
                  </Tooltip>
                  Customers
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={handleAddCustomer}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "right", marginRight: "10px" }}
                    />
                    Customer
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
          <Tooltip title="Export > Excel" arrow>
            <IosShareRoundedIcon fontSize="medium" style={{ float: "right", margin: "1rem" }} onClick={onExportClick} />
          </Tooltip>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {customer_list_parent_user_error && <Message variant="danger">{customer_list_parent_user_error}</Message>}
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default CustomerListPage;
