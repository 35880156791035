import * as React from "react";
import { useEffect } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { Button, Grid, Tooltip } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import Section from "../organisms/Section/Section";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardBody from "../Card/CardBody";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsById, updateOrder } from "../../actions/orderAction";
import { ORDER_UPDATE_RESET } from "../../constants/orderConstants";
import GridContainer from "../Grid/GridContainer.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "35vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    height: "60vh",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
const steps = [
  {
    label: "Order Placed",
    description: `Order Placed by the Customer`,
  },
  {
    label: "Order Confirmed",
    description: "Order Confirmed by Store Manager",
  },
  {
    label: "Order Packed",
    description: `Order is packed and ready for delivery`,
  },
  {
    label: "Out for Delivery",
    description: `Order Items Out for Delivery`,
  },
  {
    label: "Delivered",
    description: `Order Items Delivered`,
  },
];

export default function OrderTracker() {
  const [activeStep, setActiveStep] = React.useState(0);
  const { id } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (id) dispatch(getOrderDetailsById(id));
  }, [dispatch, navigate, id]);

  const orderDetails = useSelector((state) => state.orderDetails);
  const orderUpdated = useSelector((state) => state.orderUpdated);
  const { update_success, loading } = orderUpdated;

  const { order } = orderDetails;
  console.log("Id from Order Tracker  ");
  console.log(id);
  if (update_success) {
    console.log("Updated Syccess");
    dispatch({ type: ORDER_UPDATE_RESET });
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    console.log("Completed ", steps[activeStep].label);
    const statusSelected = steps[activeStep].label;
    dispatch(updateOrder(order, id, statusSelected));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <GridContainer>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
            <Typography variant="h5" style={{ fontWeight: '500', textAlign:"center" }}>
                Update Order Status
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Section className={classes.section}>
            <div className={classes.formContainer}>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader>
                  <Typography variant="h5" style={{ fontWeight: 500 }}>
                      Order #{id}
                    </Typography>
                  </CardHeader>
                  <CardBody>
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                            optional={
                              index === 4 ? (
                                <Typography variant="caption">
                                  Last step
                                </Typography>
                              ) : null
                            }
                          >
                            {step.label}
                          </StepLabel>
                          <StepContent>
                            <Typography>{step.description}</Typography>
                            <div>
                              <Button
                                size="small"
                                variant="contained"
                                margin="1rem"
                                color="secondary"
                                type="submit"
                                fullWidth
                                justify="flex-start"
                                onClick={handleNext}
                                sx={{ mt: 1, mr: 1 }}
                                style={{
                                  textTransform: "capitalize",
                                  width: "20vh",
                                  borderRadius: "5px",
                                }}
                              >
                                {index === steps.length - 1
                                  ? "Finish"
                                  : "Update Status"}
                              </Button>
                              <Button
                                size="small"
                                variant="contained"
                                margin="1rem"
                                color="secondary"
                                type="submit"
                                fullWidth
                                justify="flex-start"
                                disabled={index === 0}
                                onClick={handleBack}
                                sx={{ mt: 1, mr: 1 }}
                                style={{
                                  textTransform: "capitalize",
                                  width: "20vh",
                                  borderRadius: "5px",
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  color="white"
                                  align="center"
                                  style={{ color: "black" }}
                                >
                                  Back
                                </Typography>
                              </Button>
                            </div>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                    {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>Delivered Succesfully</Typography>
                        <Button
                          size="small"
                          variant="contained"
                          margin="1rem"
                          color="secondary"
                          type="submit"
                          fullWidth
                          justify="flex-start"
                          onClick={(e) => console.log("Clicked Reset")}
                          style={{
                            textTransform: "capitalize",
                            width: "20vh",
                            borderRadius: "5px",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            align="center"
                            style={{ color: "black" }}
                          >
                            Reset
                          </Typography>
                        </Button>
                      </Paper>
                    )}
                  </CardBody>
                </Card>
              </GridItem>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "1rem",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  size="small"
                  variant="contained"
                  margin="1rem"
                  color="secondary"
                  type="submit"
                  fullWidth
                  onClick={(e) => console.log("Clicked Save")}
                  style={{
                    textTransform: "capitalize",
                    width: "20vh",
                    borderRadius: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    align="center"
                    style={{ color: "black" }}
                  >
                    CONFIRM
                  </Typography>
                </Button>
              </Grid>
            </div>
          </Section>
        </Grid>
      </Grid>
    </GridContainer>
  );
}
