import {
  POWDER_MATERIAL_STOCK_DETAILS_BY_ID_FAIL,
  POWDER_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST,
  POWDER_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS,
  POWDER_MATERIAL_STOCK_DETAILS_BY_ID_RESET,

  POWDER_MATERIAL_STOCK_REGISTER_FAIL,
  POWDER_MATERIAL_STOCK_REGISTER_REQUEST,
  POWDER_MATERIAL_STOCK_REGISTER_SUCCESS,
  POWDER_MATERIAL_STOCK_REGISTER_RESET,
 
  POWDER_MATERIAL_STOCK_LIST_FAIL,
  POWDER_MATERIAL_STOCK_LIST_SUCCESS,
  POWDER_MATERIAL_STOCK_LIST_REQUEST,
  POWDER_MATERIAL_STOCK_LIST_RESET,

  POWDER_MATERIAL_STOCK_DELETE_REQUEST,
  POWDER_MATERIAL_STOCK_DELETE_SUCCESS,
  POWDER_MATERIAL_STOCK_DELETE_FAIL,
  POWDER_MATERIAL_STOCK_DELETE_RESET,

  POWDER_MATERIAL_STOCK_UPDATE_FAIL,
  POWDER_MATERIAL_STOCK_UPDATE_SUCCESS,
  POWDER_MATERIAL_STOCK_UPDATE_REQUEST,
  POWDER_MATERIAL_STOCK_UPDATE_RESET,
} from "../constants/powderMaterialStockConstants";

export const powderMaterialStocksListReducer = (state = { }, action) => {
  switch (action.type) {
    case POWDER_MATERIAL_STOCK_LIST_REQUEST:
      return { powder_material_stock_list_loading: true };
    case POWDER_MATERIAL_STOCK_LIST_SUCCESS:
      return {
        powder_material_stock_list_loading: false,
        powder_material_stock_list_data: action.payload,
      };
    case POWDER_MATERIAL_STOCK_LIST_FAIL:
      return { powder_material_stock_list_loading: false, powder_material_stock_list_error: action.payload };
    default:
      return state;
  }
};

export const powderMaterialStockByIdReducer = (
  state = {  },
  action
) => {
  switch (action.type) {
    case POWDER_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST:
      return { powder_material_stock_details_loading: true};
    case POWDER_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS:
      return {
        powder_material_stock_details_loading: false,
        powder_material_stock_details_data: action.payload,
      };
    case POWDER_MATERIAL_STOCK_DETAILS_BY_ID_FAIL:
      return { powder_material_stock_details_loading: false, powder_material_stock_details_error: action.payload };
    default:
      return state;
  }
};

export const powderMaterialStockDeleteByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case POWDER_MATERIAL_STOCK_DELETE_REQUEST:
      return { powder_material_stock_delete_loading: true };
    case POWDER_MATERIAL_STOCK_DELETE_SUCCESS:
      return { powder_material_stock_delete_loading: false, powder_material_stock_delete_success: true };
    case POWDER_MATERIAL_STOCK_DELETE_FAIL:
      return { powder_material_stock_delete_loading: false, powder_material_stock_delete_error: action.payload };
      case POWDER_MATERIAL_STOCK_DELETE_RESET:
        return {  };
    default:
      return state;
  }
};


export const powderMaterialStockCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case POWDER_MATERIAL_STOCK_REGISTER_REQUEST:
      return { powder_material_stock_create_loading: true };
    case POWDER_MATERIAL_STOCK_REGISTER_SUCCESS:
      return { powder_material_stock_create_loading: false, powder_material_stock_create_success: true, powder_material_stock_create_data: action.payload };
    case POWDER_MATERIAL_STOCK_REGISTER_FAIL:
      return { powder_material_stock_create_loading: false, powder_material_stock_create_loading_error: action.payload };
    case POWDER_MATERIAL_STOCK_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const powderMaterialStockUpdateByIdReducer = (state = { powderMaterialStock: {} }, action) => {
  switch (action.type) {
    case POWDER_MATERIAL_STOCK_UPDATE_REQUEST:
      return { powder_material_stock_create_loading: true };
    case POWDER_MATERIAL_STOCK_UPDATE_SUCCESS:
      return { powder_material_stock_create_loading: false, powder_material_stock_create_success: true, powder_material_stock_create_data: action.payload };
    case POWDER_MATERIAL_STOCK_UPDATE_FAIL:
      return { powder_material_stock_create_loading: false, powder_material_stock_create_error: action.payload };
    case POWDER_MATERIAL_STOCK_UPDATE_RESET:
      return { };
    default:
      return state;
  }
};


