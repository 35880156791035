import {
  RAW_MATERIAL_STOCK_DETAILS_BY_ID_FAIL,
  RAW_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST,
  RAW_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS,
  RAW_MATERIAL_STOCK_DETAILS_BY_ID_RESET,

  RAW_MATERIAL_STOCK_REGISTER_FAIL,
  RAW_MATERIAL_STOCK_REGISTER_REQUEST,
  RAW_MATERIAL_STOCK_REGISTER_SUCCESS,
  RAW_MATERIAL_STOCK_REGISTER_RESET,
 
  RAW_MATERIAL_STOCK_LIST_FAIL,
  RAW_MATERIAL_STOCK_LIST_SUCCESS,
  RAW_MATERIAL_STOCK_LIST_REQUEST,
  RAW_MATERIAL_STOCK_LIST_RESET,

  RAW_MATERIAL_STOCK_DELETE_REQUEST,
  RAW_MATERIAL_STOCK_DELETE_SUCCESS,
  RAW_MATERIAL_STOCK_DELETE_FAIL,
  RAW_MATERIAL_STOCK_DELETE_RESET,

  RAW_MATERIAL_STOCK_UPDATE_FAIL,
  RAW_MATERIAL_STOCK_UPDATE_SUCCESS,
  RAW_MATERIAL_STOCK_UPDATE_REQUEST,
  RAW_MATERIAL_STOCK_UPDATE_RESET,
} from "../constants/rawMaterialStockConstants";

export const rawMaterialStocksListReducer = (state = { rawMaterialStocks: [] }, action) => {
  switch (action.type) {
    case RAW_MATERIAL_STOCK_LIST_REQUEST:
      return { raw_material_stock_list_loading: true };
    case RAW_MATERIAL_STOCK_LIST_SUCCESS:
      return {
        raw_material_stock_list_loading: false,
        raw_material_stock_list_data: action.payload,
      };
    case RAW_MATERIAL_STOCK_LIST_FAIL:
      return { raw_material_stock_list_loading: false, raw_material_stock_list_error: action.payload };
    default:
      return state;
  }
};

export const rawMaterialStockByIdReducer = (
  state = {  },
  action
) => {
  switch (action.type) {
    case RAW_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST:
      return { raw_material_stock_details_loading: true};
    case RAW_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS:
      return {
        raw_material_stock_details_loading: false,
        raw_material_stock_details_data: action.payload,
      };
    case RAW_MATERIAL_STOCK_DETAILS_BY_ID_FAIL:
      return { raw_material_stock_details_loading: false, raw_material_stock_details_error: action.payload };
    default:
      return state;
  }
};

export const rawMaterialStockDeleteByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case RAW_MATERIAL_STOCK_DELETE_REQUEST:
      return { raw_material_stock_delete_loading: true };
    case RAW_MATERIAL_STOCK_DELETE_SUCCESS:
      return { raw_material_stock_delete_loading: false, raw_material_stock_delete_success: true };
    case RAW_MATERIAL_STOCK_DELETE_FAIL:
      return { raw_material_stock_delete_loading: false, raw_material_stock_delete_error: action.payload };
      case RAW_MATERIAL_STOCK_DELETE_RESET:
        return {  };
    default:
      return state;
  }
};


export const rawMaterialStockCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case RAW_MATERIAL_STOCK_REGISTER_REQUEST:
      return { raw_material_stock_create_loading: true };
    case RAW_MATERIAL_STOCK_REGISTER_SUCCESS:
      return { raw_material_stock_create_loading: false, raw_material_stock_create_success: true, raw_material_stock_create_data: action.payload };
    case RAW_MATERIAL_STOCK_REGISTER_FAIL:
      return { raw_material_stock_create_loading: false, raw_material_stock_create_loading_error: action.payload };
    case RAW_MATERIAL_STOCK_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const rawMaterialStockUpdateByIdReducer = (state = { rawMaterialStock: {} }, action) => {
  switch (action.type) {
    case RAW_MATERIAL_STOCK_UPDATE_REQUEST:
      return { raw_material_stock_create_loading: true };
    case RAW_MATERIAL_STOCK_UPDATE_SUCCESS:
      return { raw_material_stock_create_loading: false, raw_material_stock_create_success: true, raw_material_stock_create_data: action.payload };
    case RAW_MATERIAL_STOCK_UPDATE_FAIL:
      return { raw_material_stock_create_loading: false, raw_material_stock_create_error: action.payload };
    case RAW_MATERIAL_STOCK_UPDATE_RESET:
      return { };
    default:
      return state;
  }
};


