import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { listBonds } from "../../../actions/bondAction.js";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button } from "@mui/material";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import Message from "../../Message.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { formatToLocaleCurrency } from "../../../util/Utility.js";
import { listPowderMaterialStocks} from "../../../actions/powderMaterialStockAction.js";


const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const CtcPowderStockListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const gridRef = useRef();
  const [powderMaterialStockRowData, setPowderMaterialStockRowData] = useState(null);
  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 350,
      minWidth: 350,
      maxWidth: 350,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "PowderNetWeight",
      headerName: "Powder Net Weight [Kgs]",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "ManufacturedDate",
      headerName: "Manufactured Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const powderMaterialStocksList = useSelector((state) => state.powderMaterialStocksList)
  const { powder_material_stock_list_loading, powder_material_stock_list_success, powder_material_stock_list_data, powder_material_stock_list_error } = powderMaterialStocksList

  const createCtcPowderHandler = () => {
    console.log('Routing to New CTC Powder Stock Entry ')
    navigate(`/ctc/powder-stock/new`);
  }
  const populateRawMaterialStockRowData = () => {
    if (powder_material_stock_list_data && powder_material_stock_list_data.length > 0) {
      setPowderMaterialStockRowData(() => powder_material_stock_list_data.map((eachRec, index) => {
        console.log("eachRec  : ", eachRec);
        return {
          "#": index + 1,
          Id: eachRec._id,
          ProductName: eachRec.productName,
          PowderNetWeight : eachRec.powderNetWeight ? formatToLocaleCurrency(Number(eachRec.powderNetWeight)) : 0.0,
          TotalPrice: eachRec.totalPrice,
          ManufacturedDate: eachRec.manufacturedDate,
        };
      }))
    }
  }

  useEffect(() => {
    console.log('Loading factory Stock Lis Page')
    dispatch(listPowderMaterialStocks())
  }, [])

  useEffect(() => {
    if (powder_material_stock_list_data && powder_material_stock_list_data.length > 0)
      console.log('Populating  rawMaterial Stock RowData Row Data')
    populateRawMaterialStockRowData()
  }, [powder_material_stock_list_data])

  const renderContentUsingAGGrid = (
    <div
      className="ag-theme-quartz"
      style={{ width: "65%", height: "65vh" ,margin:"auto"}}
    >
      <AgGridReact
        rowData={powderMaterialStockRowData && powderMaterialStockRowData.length > 0 ? powderMaterialStockRowData : [] }
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowStyle={{ textAlign: "left", alignItems: "center" }}
        suppressExcelExport={false}
        pagination="true"
      ></AgGridReact>
    </div>
  );
  return (
    <React.Fragment>
      {powder_material_stock_list_error && <Message>{powder_material_stock_list_error}</Message>}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  Chilli Turmeric Corriander - Powder Stock         
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={createCtcPowderHandler}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "right", marginRight: "10px" }}
                    />
                    New
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default CtcPowderStockListPage;
