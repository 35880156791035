import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import {
  Tooltip,
  Typography,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
  Input,
  AlertTitle,
} from "@mui/material";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { convertInvoiceIdToDigits } from '../../util/Utility.js'
import { formatToLocaleCurrency } from "../../util/Utility.js";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import * as XLSX from 'xlsx';
import { syncVyaparWalkinItemSaleInvoices, listVyaparWalkinItemSaleByStore } from "../../actions/walkinVyaparItemSaleReportAction.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import Message from "../Message.js";
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { listAllStores } from "../../actions/storeAction.js";
import './aggridStyles.css';
const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "300",
      lineHeight: "1",
    },
  },
};

const ItemSalesVyaparReport = ({ match }) => {
  const useStyles = makeStyles(styles);
  const gridRef = useRef();

  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storesList = useSelector((state) => state.storesList);
  const { stores } = storesList;

  useEffect(() => {
    console.log("Use Effect Gets Called for fetching the Vyapar Sale Report from MIS...!");
    dispatch(listVyaparWalkinItemSaleByStore('VNK_STORE'))
    {/** SELECTED STORE NAME */ }
  }, []);

  const tempColDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "InvoiceNo",
      headerName: "Invoice No",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true,
      cellDataType: 'text',
      headerComponentParams: {
        style: { display: 'flex', justifyContent: 'center', textAlign: "center" }
      },
      headerClass: 'ag-header-cell-label'
    },
    {
      field: "PartyName",
      headerName: "Party Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 600,
      minWidth: 600,
      maxWidth: 600,
      filter: true,
      cellStyle: params => {
        return { textAlign: "left" };
      },
      headerComponentParams: {
        style: { display: 'flex', justifyContent: 'left', textAlign: "left" }
      }
    },
    {
      field: "Date",
      headerName: "Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: true,
      cellStyle: params => {
        return { textAlign: "center" };
      },
      headerComponentParams: {
        style: { display: 'flex', justifyContent: 'center', textAlign: "center" }
      }
    },
    {
      field: "Quantity",
      headerName: "Quantity",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "right" };
      },
      width: 175,
      minWidth: 175,
      maxWidth: 175,
      filter: true,
      headerComponentParams: {
        style: { display: 'flex', justifyContent: 'center', textAlign: "center" }
      }
    },
    {
      field: "Unit",
      headerName: "Unit",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "center" };
      },
      width: 175,
      minWidth: 175,
      maxWidth: 175,
      filter: true,
      headerComponentParams: {
        style: { display: 'flex', justifyContent: 'center', textAlign: "center" }
      }
    },
    {
      field: "PricePerUnit",
      headerName: "Price / Unit",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "right" };
      },
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true
    },


    {
      field: "TotalAmount",
      headerName: "Total Amount",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: params => {
        return { textAlign: "right" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
  ]
  const [loadingExcelData, setLoadingExcelData] = useState(false);
  const [itemNames, setItemNames] = useState(() => []);
  const [selectedStoreName, setSelectedStoreName] = useState(() => "");
  const [storeWiseData, setStoreWiseData] = useState(() => []);
  const [selectedCustomer, setSelectedCustomer] = useState(() => "");
  const [selectedItemName, setSelectedItemName] = useState(() => "");
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(() => tempColDefs)
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName, role, userName } = userInfo;
  const [posName, setPosName] = useState(() => "");
  const [sumOfPaid, setSumOfPaid] = useState(() => 0.00);
  const [sumOfUnpaid, setSumOfUnpaid] = useState(() => 0.00);
  const [sumOfTotalSale, setSumOfTotalSale] = useState(() => 0.00);

  let salesReportByProducts = [];
  let noDataFoundContent = ""
  const [open, setOpen] = React.useState(true);
  const walkinVyaparItemSaleByStore = useSelector((state) => state.walkinVyaparItemSaleByStore);
  const { walkin_vyapar_item_sale_by_store_loading, walkin_vyapar_item_sale_by_store_data, walkin_vyapar_item_sale_by_store_error } = walkinVyaparItemSaleByStore;

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  // Calculate the sum of all the totalAmount if the paymentStatus is Paid and paymentType is 'Cash'
  const sumPaid = (data) => {
    console.log('$$$$$ Exec sumPaid $$$$')
    let paidTotal;
    if (data && data.length > 0) {
      paidTotal = data
        .filter(item => item.paymentStatus === 'Paid')
        .reduce((sum, item) => sum + item.totalAmount, 0);
    }
    console.log('$$$$$ Sum of totalAmount for Paid ', paidTotal);
    if (paidTotal) {
      setSumOfPaid(() => paidTotal.toFixed(2))
    } else
      setSumOfPaid(() => 0.00)
  }

  // Calculate the sum of all the totalAmount if the paymentStatus is UnPaid 
  const sumUnpaid = (data) => {
    console.log('$$$$$ Exec sumUnpaid $$$$')
    let unPaidTotal;
    if (data && data.length > 0) {
      unPaidTotal = data
        .filter(item => item.paymentStatus === 'Unpaid')
        .reduce((sum, item) => sum + item.totalAmount, 0);
    }
    console.log('$$$$$ Sum of totalAmount for UnPaid ', unPaidTotal);
    if (unPaidTotal) {
      setSumOfUnpaid(() => unPaidTotal.toFixed(2))
    } else
      setSumOfUnpaid(() => 0.00)

  }

  const sumTotalSaleAmount = (data) => {
    console.log('$$$$$ Exec sumTotalSaleAmount $$$$')
    let totalSaleAmount;
    if (data && data.length > 0) {
      totalSaleAmount = data.reduce((sum, item) => sum + item.totalAmount, 0);
    }
    if (totalSaleAmount) {
      setSumOfTotalSale(() => totalSaleAmount.toFixed(2))
    } else {
      setSumOfTotalSale(() => 0.00)
    }
    console.log('$$$$$ Sum of Total Sale Amount  ', totalSaleAmount);
  }

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
      ensureDomOrder: true
    }),
    []
  );

  const syncNow = () => {
    if (rowData) {
      console.log('########## PERFORMING MIS SYNC WITH VYAPAR ##########')
      dispatch(syncVyaparWalkinItemSaleInvoices(rowData, selectedStoreName))
    } else {
      console.error('########## FAILED PERFORMING MIS SYNC WITH VYAPAR AS NO DATA EXISTS ##########')
    }
  }

  const uniqueItemNames = () => {
    if (walkin_vyapar_item_sale_by_store_data && walkin_vyapar_item_sale_by_store_data.length > 0) {
      const uniqueItemNames = [...new Set(walkin_vyapar_item_sale_by_store_data.map(item => item.itemName))]
      console.log('uniqueItemNames : ', uniqueItemNames ? uniqueItemNames.length : 0)
      if (uniqueItemNames)
        setItemNames(() => uniqueItemNames)
    }
  }

  const prepareDataForSelectedItemName = () => {
    console.log('Exec prepareDataForSelectedItemName ...')
    if (walkin_vyapar_item_sale_by_store_data && walkin_vyapar_item_sale_by_store_data.length > 0 && selectedItemName && selectedItemName.length > 0) {
      const salesByItemName = walkin_vyapar_item_sale_by_store_data.filter(each => each.itemName === selectedItemName)
      if (salesByItemName && salesByItemName.length > 0 && salesByItemName[0] && salesByItemName[0].transactions.length > 0) {
        console.log('salesByItemName. Transactions Count : ', salesByItemName[0] ? salesByItemName[0].transactions.length : '')
        setRowData(() => salesByItemName[0].transactions.map((saleByPos, index) => {
          const invIdInDecimal = convertInvoiceIdToDigits(saleByPos._id)
          return {
            "#": index + 1,
            // Id: invIdInDecimal,
            InvoiceNo: saleByPos.invoiceNo ? saleByPos.invoiceNo : "",
            Date: saleByPos.date
              ? saleByPos.date
              : "",
            PartyName: saleByPos.partyName && saleByPos.partyName
              ? saleByPos.partyName : "",
            Quantity: saleByPos.quantity 
              ? saleByPos.quantity : "",
            Unit: saleByPos.unit ? saleByPos.unit : "",
            PricePerUnit: saleByPos.unitPrice
              ? formatToLocaleCurrency(saleByPos.unitPrice)
              : "",
            TotalAmount: saleByPos.amount
              ? formatToLocaleCurrency(saleByPos.amount)
              : 0.0,
          };
        }));
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForItemSalesReport = () => {
    console.log('Exec prepareDataForItemSalesReport ...')
    if (walkin_vyapar_item_sale_by_store_data && walkin_vyapar_item_sale_by_store_data.length > 0) {
      uniqueItemNames(walkin_vyapar_item_sale_by_store_data)
      {/**
        setRowData(() => walkin_vyapar_item_sale_by_store_data.map((saleByPos, index) => {
        const invIdInDecimal = convertInvoiceIdToDigits(saleByPos._id)
        return {
          "#": index + 1,
          // Id: invIdInDecimal,
          InvoiceNo: saleByPos.invoiceNo ? saleByPos.invoiceNo : "",
          Date: saleByPos.date
            ? saleByPos.date
            : "",
          PartyName: saleByPos.partyName && saleByPos.partyName
            ? saleByPos.partyName : "",
          Quantity: saleByPos.quantity
            ? saleByPos.quantity
            : "",
          PricePerUnit: saleByPos.pricePerUnit
            ? saleByPos.pricePerUnit
            : "",
          TransactionType: saleByPos.transactionType
            ? saleByPos.transactionType
            : "",
          BalanceDue: saleByPos.balanceDue
            ? saleByPos.balanceDue
            : 0.0,
          // Status: saleByPos.status
          // ? saleByPos.status
          // : "",
          TotalAmount: saleByPos.totalAmount
            ? formatToLocaleCurrency(saleByPos.totalAmount)
            : 0.0,
        };
      }));*/}
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  useEffect(() => {
    console.log("Use Effect Gets Called Only Once and sets the POS NAME.");
    if (storeName || warehouseName)
      setPosName(storeName ? storeName : warehouseName ? warehouseName : "")
    dispatch(listAllStores());
  }, [dispatch]);

  useEffect(() => {
    console.log('Use Effect getting Called as walkin_vyapar_item_sale_by_store_data  have Changed')
    if (walkin_vyapar_item_sale_by_store_data && selectedItemName == "") {
      prepareDataForItemSalesReport()
    }
  }, [walkin_vyapar_item_sale_by_store_data]);

  useEffect(() => {
    console.log('Use Effect getting Called For Change in Seledcted Customer | Selected payment Type', selectedCustomer)
    if (selectedItemName && selectedItemName.length > 0) {
      prepareDataForSelectedItemName()
    }
  }, [selectedItemName]);

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography variant="h6" style={{ margin: "0.5rem" }} color="gray">
              <i>No data found</i>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  const handleFileUpload = (event) => {
    setLoadingExcelData(true);
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // Assuming we want to read the first sheet
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      console.log('data : ', data)

      if (data.length > 0) {
        const headers = data[0];
        const rows = data.slice(1);

        setColumnDefs(headers.map(header => ({ headerName: header, field: header })));
        setRowData(rows.map((row) => {
          const rowDataObj = {};
          headers.forEach((header, index) => {
            rowDataObj[header] = row[index];
          });
          return rowDataObj;
        }));
        setLoadingExcelData(() => false)
      }
    };


    reader.readAsArrayBuffer(file);
  };

  return (
    <React.Fragment>
      {walkin_vyapar_item_sale_by_store_error && <Box sx={{ width: '100%' }}>
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Typography
              variant="h6"
              style={{ margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center" }}
              color="black"
            >{walkin_vyapar_item_sale_by_store_error}
            </Typography>
          </Alert>
        </Collapse>
      </Box>
      }
      {walkin_vyapar_item_sale_by_store_loading && <CustomBackdropSpinner />}
      {loadingExcelData && <CustomBackdropSpinner />}
      {/** SELECT DURATION OF SALES SECTION START */}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <Typography
              variant="h6"
              style={{ margin: "0.5rem" }}
              color="gray"
            >
              Item wise Sales Vyapar Report
              {/* :  {walkin_vyapar_item_sale_by_store_data && walkin_vyapar_item_sale_by_store_data.length > 0 ? walkin_vyapar_item_sale_by_store_data[0].storeName : ""} */}
            </Typography>
            <CardBody>
              {/** CUSTOMER NAME START */}
              {walkin_vyapar_item_sale_by_store_data && <GridContainer spacing={2} >
                <GridItem xs={12} sm={6} md={6} >
                  <Autocomplete
                    disablePortal
                    onChange={(event, value) => {
                      if (value) {
                        console.log("Chosen Item Name :==> ", value);
                        setSelectedItemName(value);
                        return;
                      } else {
                        console.log("Clicked X Btn")
                        setRowData(() => walkin_vyapar_item_sale_by_store_data)
                        setSelectedItemName(() => "")
                        return;
                      }
                    }}
                    fullWidth
                    id="combo-box-products"
                    options={itemNames}
                    size="small"
                    getOptionLabel={(option) => {
                      if (option) {
                        return `${option ? option : ''}`
                      }
                    }
                    }
                    clearOnBlur={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Item Name"
                        size="small"
                        value={selectedItemName && selectedItemName ? selectedItemName : ""}
                      />
                    )}
                  />
                </GridItem>
                {/* </GridContainer>} */}
                {/** CUSTOMER NAME END */}
              </GridContainer>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {selectedItemName && <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <Typography
              variant="h6"
              style={{ margin: "0.5rem",fontSize:"1rem" }}
              color="gray"
            >
              {selectedItemName}
              {/* :  {walkin_vyapar_item_sale_by_store_data && walkin_vyapar_item_sale_by_store_data.length > 0 ? walkin_vyapar_item_sale_by_store_data[0].storeName : ""} */}
            </Typography>
            <CardBody>
              {/** CUSTOMER NAME START */}
              {walkin_vyapar_item_sale_by_store_data && <GridContainer spacing={2} >
              <GridItem xs={12} sm={6} md={6} style={{textAlign:"left"}}>
                  <span
                    style={{ fontSize: "1rem", fontWeight: "500", marginLeft: "1rem", color: "gray" }}
                  >
                    Sale Price :
                    <CurrencyRupeeIcon
                      size="small"
                      variant="contained"g
                      type="submit"
                      color="Gray"
                      style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                    >
                    </CurrencyRupeeIcon>
                    {console.log('Sale Price : ',rowData && rowData.length>0 && rowData[0] ? rowData[0].PricePerUnit  : "0.0")}
                    {rowData && rowData.length>0 && rowData[0] ? rowData[0].PricePerUnit  : "0.0"}
                    {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} style={{textAlign:"right"}}>
                  <span
                    style={{ fontSize: "1rem", fontWeight: "500", marginLeft: "1rem", color: "gray" }}
                  >
                    Stock Quantity :
                    {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} style={{textAlign:"left"}}>
                  <span
                    style={{ fontSize: "1rem", fontWeight: "500", marginLeft: "1rem", color: "gray" }}
                  >
                    Purchase Price :
                    <CurrencyRupeeIcon
                      size="small"
                      variant="contained"
                      type="submit"
                      color="Gray"
                      style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                    >
                    </CurrencyRupeeIcon>
                    {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                  </span>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} style={{textAlign:"right"}}>
                  <span
                    style={{ fontSize: "1rem", fontWeight: "500", marginLeft: "1rem", color: "gray" }}
                  >
                    Stock Value :
                    <CurrencyRupeeIcon
                      size="small"
                      variant="contained"
                      type="submit"
                      color="Gray"
                      style={{ width: "1rem", textAlign: "center", justifyContent: "center", alignItems: "center", paddingTop: "0.75rem" }}
                    >
                    </CurrencyRupeeIcon>
                    {/* :  {walkin_vyapar_sale_report_pos_data && walkin_vyapar_sale_report_pos_data.length > 0 ? walkin_vyapar_sale_report_pos_data[0].storeName : ""} */}
                  </span>
                </GridItem>

              </GridContainer>}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>}
      {/* </GridContainer> */}
      {/** SELECT DURATION OF SALES SECTION END */}
      {role && role === "ADMIN_ROLE" && <GridContainer>
        <GridItem xs={12} sm={6} style={{ alignItems: 'center', justifyContent: "center", textAlign: "center" }}>
          <label htmlFor="files" style={{
            background: "grey",
            padding: "5px 10px",
            backgroundColor: '#f0c14b',
            width: "20vh",
            height: "1.92rem",
            borderRadius: '5px',
            boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
          }}>
            Upload Item wise Report
          </label>
          <Input
            id="files"
            type="file"
            size="small"
            variant="contained"
            onChange={handleFileUpload}
            accept=".xlsx, .xls"
            style={{ textTransform: "capitalize", width: "20vh", height: "1.92rem", borderRadius: "5px", visibility: 'hidden', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
          </Input>
        </GridItem>
        <GridItem xs={12} sm={6} style={{ alignItems: 'center', justifyContent: "center", textAlign: "center" }}>

          <Button
            size="small"
            variant="contained"
            type="submit"
            color="secondary"
            justify="center"
            onClick={syncNow}
            disabled={rowData && rowData.length === 0}
            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
          >
            SYNC NOW
          </Button>
        </GridItem>
      </GridContainer>}
      {role && role === "CEO_ROLE" && rowData &&
        <GridItem xs={12} sm={6} md={6} style={{ textAlign: "center", marginBottom: "1rem" }}>
          <Tooltip title="Download Full Item wise Report" arrow>
            <Button
              size="small"
              variant="contained"
              type="submit"
              color="secondary"
              justify="center"
              onClick={onExportClick}
              style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            >
              <SaveAltIcon
                fontSize="medium"
              />
              Item wise Report
            </Button>
          </Tooltip>
        </GridItem>
      }
      <GridContainer>
        <GridItem xs={12} style={{ alignItems: 'center', justifyContent: "center", textAlign: "center" }}>
          <div
            className="ag-theme-quartz"
            style={{ width: "100%", height: "65vh" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              pagination={true}
              paginationPageSize={rowData && rowData.length > 100 ? 20 : 10}
              cacheBlockSize={rowData && rowData.length > 100 ? 20 : 10}
              suppressExcelExport={false}
              popupParent={popupParent}
            />
          </div>
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default ItemSalesVyaparReport;
