import {
  CTC_CONVERSION_DETAILS_BY_ID_FAIL,
  CTC_CONVERSION_DETAILS_BY_ID_REQUEST,
  CTC_CONVERSION_DETAILS_BY_ID_SUCCESS,
  CTC_CONVERSION_DETAILS_BY_ID_RESET,

  CTC_CONVERSION_REGISTER_FAIL,
  CTC_CONVERSION_REGISTER_REQUEST,
  CTC_CONVERSION_REGISTER_SUCCESS,
  CTC_CONVERSION_REGISTER_RESET,
 
  CTC_CONVERSION_LIST_FAIL,
  CTC_CONVERSION_LIST_SUCCESS,
  CTC_CONVERSION_LIST_REQUEST,
  CTC_CONVERSION_LIST_RESET,

  CTC_CONVERSION_DELETE_REQUEST,
  CTC_CONVERSION_DELETE_SUCCESS,
  CTC_CONVERSION_DELETE_FAIL,
  CTC_CONVERSION_DELETE_RESET,

  CTC_CONVERSION_UPDATE_FAIL,
  CTC_CONVERSION_UPDATE_SUCCESS,
  CTC_CONVERSION_UPDATE_REQUEST,
  CTC_CONVERSION_UPDATE_RESET,

} from "../constants/ctcConversionConstants.js";


export const ctcConversionRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_CONVERSION_REGISTER_REQUEST:
      return { ctc_conversion_register_loading: true };
    case CTC_CONVERSION_REGISTER_SUCCESS:
      return { ctc_conversion_register_loading: false,ctc_conversion_register_success: true, ctc_conversion_register_data: action.payload };
    case CTC_CONVERSION_REGISTER_FAIL:
      return { ctc_conversion_register_loading: false, ctc_conversion_register_error: action.payload };
    case CTC_CONVERSION_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const ctcConversionDetailsByIdReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_CONVERSION_DETAILS_BY_ID_REQUEST:
      return { ...state, ctc_conversion_details_by_id_loading: true };
    case CTC_CONVERSION_DETAILS_BY_ID_SUCCESS :
      return { ctc_conversion_details_by_id_loading: false, ctc_conversion_details_data : action.payload };
    case CTC_CONVERSION_DETAILS_BY_ID_FAIL:
      return { ctc_conversion_details_by_id_loading: false, ctc_conversion_details_by_id_error: action.payload };
    case CTC_CONVERSION_DETAILS_BY_ID_RESET:
      return {  };
    default:
      return state;
  }
};

export const ctcConversionListReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_CONVERSION_LIST_REQUEST:
      return { ctc_conversion_list_loading: true };
    case CTC_CONVERSION_LIST_SUCCESS:
      return { ctc_conversion_list_loading: false, ctc_conversion_list_success: false, ctc_conversion_list_data : action.payload };
    case CTC_CONVERSION_LIST_FAIL:
      return { ctc_conversion_list_loading: false, ctc_conversion_list_error : action.payload };
    case CTC_CONVERSION_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const ctcConversionDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CTC_CONVERSION_DELETE_REQUEST:
      return { ctc_conversion_delete_loading: true };
    case CTC_CONVERSION_DELETE_SUCCESS:
      return { ctc_conversion_delete_loading: false, ctc_conversion_delete_success: true };
    case CTC_CONVERSION_DELETE_FAIL:
      return { ctc_conversion_delete_loading: false, ctc_conversion_delete_error: action.payload };
    default:
      return state;
  }
};

export const ctcConversionUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case CTC_CONVERSION_UPDATE_REQUEST:
      return { ctc_conversion_update_loading: true };
    case CTC_CONVERSION_UPDATE_SUCCESS:
      return { ctc_conversion_update_loading: false, ctc_conversion_update_success : true };
    case CTC_CONVERSION_UPDATE_FAIL:
      return { ctc_conversion_update_loading: false, ctc_conversion_update_error: action.payload };
    case CTC_CONVERSION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
