import axios from "axios";
import {
  CUSTOMER_DETAILS_FAIL,
  CUSTOMER_DETAILS_REQUEST,
  CUSTOMER_DETAILS_SUCCESS,
  CUSTOMER_DETAILS_RESET,

  CUSTOMER_REGISTER_FAIL,
  CUSTOMER_REGISTER_REQUEST,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_REGISTER_RESET,
 
  CUSTOMER_LIST_FAIL,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_LIST_REQUEST,
  CUSTOMER_LIST_RESET,

  CUSTOMER_DELETE_REQUEST,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DELETE_FAIL,
  CUSTOMER_DELETE_RESET,

  CUSTOMER_UPDATE_FAIL,
  CUSTOMER_UPDATE_SUCCESS,
  CUSTOMER_UPDATE_REQUEST,
  CUSTOMER_UPDATE_RESET,

  CUSTOMER_LIST_BY_POS_NAME_REQUEST,
  CUSTOMER_LIST_BY_POS_NAME_SUCCESS,
  CUSTOMER_LIST_BY_POS_NAME_FAIL,
  CUSTOMER_LIST_BY_POS_NAME_RESET,

  CUSTOMER_LIST_BY_PARENT_USER_REQUEST,
  CUSTOMER_LIST_BY_PARENT_USER_RESET,
  CUSTOMER_LIST_BY_PARENT_USER_SUCCESS,
  CUSTOMER_LIST_BY_PARENT_USER_FAIL,
} from "../constants/customerConstants.js";

export const register =
  (name, contactNo, email, billingAddress, parentUser,gst,city, district,state, pincode, pos) =>
  async (dispatch) => {
    console.log('EXEC Customer Register  name, contactNo, email, address, parentUser, pos', billingAddress, parentUser,gst,city, district,state, pincode, pos)
    try {
      dispatch({
        type: CUSTOMER_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("Before Perfoming role logic : ");

      const { data } = await axios.post(
        "/api/customer",
        { name, contactNo, email, billingAddress,parentUser, gst,city, district,state, pincode, pos},
        config
      );

      dispatch({
        type: CUSTOMER_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CUSTOMER_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getCustomerDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/customer/${id}`);

    dispatch({
      type: CUSTOMER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CUSTOMER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const listCustomers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CUSTOMER_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/customer`);

    dispatch({
      type: CUSTOMER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CUSTOMER_LIST_FAIL,
      payload: message,
    });
  }
};

export const listCustomersByPosName = (pos) => async (dispatch, getState) => {
  console.log('Exec listCustomersByPosName', pos)
  try {
    dispatch({
      type: CUSTOMER_LIST_BY_POS_NAME_REQUEST,
    });

    const { data } = await axios.get(`/api/customers/pos/${pos}`);

    dispatch({
      type: CUSTOMER_LIST_BY_POS_NAME_SUCCESS,
      payload: data,
    });
    dispatch({ type: CUSTOMER_LIST_BY_POS_NAME_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CUSTOMER_LIST_BY_POS_NAME_FAIL,
      payload: message,
    });
  }
};

export const listCustomersByParentUser = (parentUser) => async (dispatch, getState) => {
  console.log('Exec listCustomersByParentUser', parentUser)
  try {
    dispatch({
      type: CUSTOMER_LIST_BY_PARENT_USER_REQUEST,
    });

    const { data } = await axios.get(`/api/customer/parent-user/${parentUser}`);

    dispatch({
      type: CUSTOMER_LIST_BY_PARENT_USER_SUCCESS,
      payload: data,
    });
    // dispatch({ type: CUSTOMER_LIST_BY_PARENT_USER_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CUSTOMER_LIST_BY_PARENT_USER_FAIL,
      payload: message,
    });
  }
};

export const deleteCustomer = (id) => async (dispatch, getState) => {
  console.log("deleteCustomer ", id);
  try {
    dispatch({
      type: CUSTOMER_DELETE_REQUEST,
    });

    await axios.delete(`/api/customer/${id}`);

    dispatch({ type: CUSTOMER_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CUSTOMER_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateCustomer = (customer) => async (dispatch, getState) => {

  console.log('updateCustomer : Customer Obj :==>>  ',customer);
  try {
    dispatch({
      type: CUSTOMER_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/customer/${customer._id}`, customer );

    dispatch({ type: CUSTOMER_UPDATE_SUCCESS , payload: data });

    // dispatch({ type: CUSTOMER_UPDATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CUSTOMER_UPDATE_FAIL,
      payload: message,
    });
  }
};
