import React, { useEffect, useState, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { listBonds } from "../../../actions/bondAction.js";
import { useNavigate } from "react-router-dom";
import { Typography, Tooltip, Button } from "@mui/material";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import Message from "../../Message.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { listCtcPurchaseOrders } from "../../../actions/ctcPurchaseOrderAction.js";
import { listRawMaterialStocks} from "../../../actions/rawMaterialStockAction.js";
import { formatToLocaleCurrency } from "../../../util/Utility.js";


const styles = {
  root: {
    minWidTableCell: "100%",
  },
  margin: {
    margin: 1,
  },
  tr: {
    borderBottom: "2px solid gray",
  },
  container: {
    maxHeight: 440,
    // maxWidTableCell: "75%",
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

const RawMaterialStockListPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const gridRef = useRef();
  const [rawMaterialStockRowData, setRawMaterialStockRowData] = useState(null);
  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "CtcSupplierName",
      headerName: "Supplier Name",
      resizable: true,
       suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "CtcSupplierGstNo",
      headerName: "Supplier Gst No",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "CtcSupplierContactNo",
      headerName: "Supplier Contact No",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "ProductName",
      headerName: "Product Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "Variety",
      headerName: "Variety",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "BagsReceived",
      headerName: "Bags Received",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
    {
      field: "EachBagWeightInKgs",
      headerName: "Each Bag Weight In Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "PricePerKg",
      headerName: "Price Per Kg",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "TaxPerKg",
      headerName: "Tax Per Kg",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "TotalPrice",
      headerName: "Total Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "TotalWeightInKgs",
      headerName: "Total Weight Kgs",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"center"
      }
    },
    {
      field: "StockMoveInDate",
      headerName: "Stock Move In Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 400,
      minWidth: 400,
      maxWidth: 400,
      filter: true,
      cellStyle:{
        textAlign:"right"
      }
    },
  ];
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const rawMaterialStocksList = useSelector((state) => state.rawMaterialStocksList)
  const { raw_material_stock_list_loading, raw_material_stock_list_success, raw_material_stock_list_data, raw_material_stock_list_error } = rawMaterialStocksList

  const createRawMaterialHandler = () => {
    console.log('Routing to New CTC Raw Material Stock Entry ')
    navigate(`/ctc/raw-material-stock/new`);
  }
  const populateRawMaterialStockRowData = () => {
    if (raw_material_stock_list_data && raw_material_stock_list_data.length > 0) {
      setRawMaterialStockRowData(() => raw_material_stock_list_data.map((eachRec, index) => {
        console.log("eachRec  : ", eachRec);
        return {
          "#": index + 1,
          Id: eachRec._id,
          CtcSupplierName:eachRec.ctcSupplierName,
          CtcSupplierGstNo:eachRec.ctcSupplierGstNo,
          CtcSupplierContactNo:eachRec.ctcSupplierContactNo,
          ProductName: eachRec.productName,
          Variety: eachRec.variety,
          BagsReceived: eachRec.bagsReceived ? formatToLocaleCurrency(Number(eachRec.bagsReceived)) : 0.0, 
          EachBagWeightInKgs: eachRec.eachBagWeightInKgs ? formatToLocaleCurrency(Number(eachRec.eachBagWeightInKgs)) : 0.0, 
          PricePerKg: eachRec.pricePerKg? formatToLocaleCurrency(Number(eachRec.pricePerKg)) : 0.0,  
          TaxPerKg: eachRec.taxPerKg? formatToLocaleCurrency(Number(eachRec.taxPerKg)) : 0.0,   
          TotalPrice: eachRec.totalPrice? formatToLocaleCurrency(Number(eachRec.totalPrice)) : 0.0,   
          // TotalTaxPrice: eachRec.totalTaxPrice? formatToLocaleCurrency(Number(eachRec.totalTaxPrice)) : 0.0,    
          TotalWeightInKgs: eachRec.totalWeightInKgs? formatToLocaleCurrency(Number(eachRec.totalWeightInKgs)) : 0.0,
          StockMoveInDate: eachRec.stockMoveInDate,
        };
      }))
    }
  }

  useEffect(() => {
    console.log('Loading factory Stock Lis Page')
    dispatch(listRawMaterialStocks())
  }, [])

  useEffect(() => {
    if (raw_material_stock_list_data && raw_material_stock_list_data.length > 0)
      console.log('Populating  rawMaterial Stock RowData Row Data')
    populateRawMaterialStockRowData()
  }, [raw_material_stock_list_data])

  const renderContentUsingAGGrid = (
    <div
      className="ag-theme-quartz"
      style={{ width: "100%", height: "65vh" }}
    >
      <AgGridReact
        rowData={rawMaterialStockRowData && rawMaterialStockRowData.length > 0 ? rawMaterialStockRowData : [] }
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowStyle={{ textAlign: "left", alignItems: "center" }}
        suppressExcelExport={false}
        pagination="true"
      ></AgGridReact>
    </div>
  );
  return (
    <React.Fragment>
      {raw_material_stock_list_error && <Message>{raw_material_stock_list_error}</Message>}
      <GridContainer>
        <GridContainer spacing={2} alignItems="center" justify="center">
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                <Typography
                  variant="h6"
                  style={{ textAlign: "center" }}
                  color="black"
                >
                  CTC Factory Raw Material             
                  <Button
                    size="small"
                    variant="contained"
                    type="submit"
                    color="secondary"
                    justify="center"
                    onClick={createRawMaterialHandler}
                    style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                  >
                    <AddCircleIcon
                      fontSize="medium"
                      style={{ float: "right", marginRight: "10px" }}
                    />
                    New
                  </Button>
                </Typography>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
        >
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          {renderContentUsingAGGrid}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default RawMaterialStockListPage;
