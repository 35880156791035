export const FACTORY_REGISTER_REQUEST = "FACTORY_REGISTER_REQUEST";
export const FACTORY_REGISTER_SUCCESS = "FACTORY_REGISTER_SUCCESS";
export const FACTORY_REGISTER_FAIL = "FACTORY_REGISTER_FAIL";
export const FACTORY_REGISTER_RESET = "FACTORY_REGISTER_RESET";

export const FACTORY_DETAILS_REQUEST = "FACTORY_DETAILS_REQUEST";
export const FACTORY_DETAILS_SUCCESS = "FACTORY_DETAILS_SUCCESS";
export const FACTORY_DETAILS_FAIL = "FACTORY_DETAILS_FAIL";
export const FACTORY_DETAILS_RESET = "FACTORY_DETAILS_RESET";

export const FACTORY_LIST_REQUEST = "FACTORY_LIST_REQUEST";
export const FACTORY_LIST_SUCCESS = "FACTORY_LIST_SUCCESS";
export const FACTORY_LIST_FAIL = "FACTORY_LIST_FAIL";
export const FACTORY_LIST_RESET = "FACTORY_LIST_RESET";

export const FACTORY_DELETE_REQUEST = "FACTORY_DELETE_REQUEST";
export const FACTORY_DELETE_SUCCESS = "FACTORY_DELETE_SUCCESS";
export const FACTORY_DELETE_FAIL = "FACTORY_DELETE_FAIL";

export const FACTORY_UPDATE_REQUEST = "FACTORY_UPDATE_REQUEST";
export const FACTORY_UPDATE_SUCCESS = "FACTORY_UPDATE_SUCCESS";
export const FACTORY_UPDATE_FAIL = "FACTORY_UPDATE_FAIL";
export const FACTORY_UPDATE_RESET = "FACTORY_UPDATE_RESET";