import axios from "axios";
import {
  POWDER_MATERIAL_STOCK_DETAILS_BY_ID_FAIL,
  POWDER_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST,
  POWDER_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS,
  POWDER_MATERIAL_STOCK_DETAILS_BY_ID_RESET,

  POWDER_MATERIAL_STOCK_REGISTER_FAIL,
  POWDER_MATERIAL_STOCK_REGISTER_REQUEST,
  POWDER_MATERIAL_STOCK_REGISTER_SUCCESS,
  POWDER_MATERIAL_STOCK_REGISTER_RESET,
 
  POWDER_MATERIAL_STOCK_LIST_FAIL,
  POWDER_MATERIAL_STOCK_LIST_SUCCESS,
  POWDER_MATERIAL_STOCK_LIST_REQUEST,
  POWDER_MATERIAL_STOCK_LIST_RESET,

  POWDER_MATERIAL_STOCK_DELETE_REQUEST,
  POWDER_MATERIAL_STOCK_DELETE_SUCCESS,
  POWDER_MATERIAL_STOCK_DELETE_FAIL,
  POWDER_MATERIAL_STOCK_DELETE_RESET,

  POWDER_MATERIAL_STOCK_UPDATE_FAIL,
  POWDER_MATERIAL_STOCK_UPDATE_SUCCESS,
  POWDER_MATERIAL_STOCK_UPDATE_REQUEST,
  POWDER_MATERIAL_STOCK_UPDATE_RESET,

} from "../constants/powderMaterialStockConstants";


export const createPowderMaterialStock =
  ( 
    ctcPowderFormInputs, productsVarietyStockRowData
  ) =>
  async (dispatch) => {
    console.log(' ctcPowderFormInputs  : ', ctcPowderFormInputs)
    {/**
      additionalCosts, manufacturedDate, powderNetWeight, productName, totalWeightInKgs, wastageNetWeight
    */}  
    console.log(' productsVarietyStockRowData  : ', productsVarietyStockRowData)
    try {
      dispatch({
        type: POWDER_MATERIAL_STOCK_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { 
        productName, estimatedCost, totalWeightInKgs, powderNetWeight, wastageNetWeight, manufacturedDate
      } = ctcPowderFormInputs
      
      console.log("Before Perfoming API Call productName : ", productName);
      const { data } = await axios.post(
        "/api/powder-material-stock",
        { 
          productName, estimatedCost, totalWeightInKgs, powderNetWeight, wastageNetWeight, manufacturedDate
        },
        config
      );

      dispatch({
        type: POWDER_MATERIAL_STOCK_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: POWDER_MATERIAL_STOCK_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getPowderMaterialStockDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: POWDER_MATERIAL_STOCK_DETAILS_BY_ID_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/powder-material-stock/${id}`);

    dispatch({
      type: POWDER_MATERIAL_STOCK_DETAILS_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: POWDER_MATERIAL_STOCK_DETAILS_BY_ID_FAIL,
      payload: message,
    });
  }
};

export const listPowderMaterialStocks = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: POWDER_MATERIAL_STOCK_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/powder-material-stock`);

    dispatch({
      type: POWDER_MATERIAL_STOCK_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: POWDER_MATERIAL_STOCK_LIST_FAIL,
      payload: message,
    });
  }
};

export const deletePowderMaterialStock = (id) => async (dispatch, getState) => {
  console.log("deletePurchase Order ", id);
  try {
    dispatch({
      type: POWDER_MATERIAL_STOCK_DELETE_REQUEST,
    });

    await axios.delete(`/api/powder-material-stock/${id}`);

    dispatch({ type: POWDER_MATERIAL_STOCK_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: POWDER_MATERIAL_STOCK_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updatePowderMaterialStock = ( productName, variety, pricePerKg, totalWeightInKgs, powderNetWeight, wastageNetWeight, manufacturedDate) => async (dispatch, getState) => {
  console.log('update PowderMaterialStock : ', productName, variety, pricePerKg, totalWeightInKgs, powderNetWeight, wastageNetWeight, manufacturedDate)
  try {
    dispatch({
      type: POWDER_MATERIAL_STOCK_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/powder-material-stock/${id}`, {
      productName, variety, pricePerKg, totalWeightInKgs, powderNetWeight, wastageNetWeight, manufacturedDate
    }, {});

    dispatch({ type: POWDER_MATERIAL_STOCK_UPDATE_SUCCESS , payload: data });

    dispatch({ type: POWDER_MATERIAL_STOCK_UPDATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: POWDER_MATERIAL_STOCK_UPDATE_FAIL,
      payload: message,
    });
  }
};
