export const CUSTOMER_REGISTER_REQUEST = "CUSTOMER_REGISTER_REQUEST";
export const CUSTOMER_REGISTER_SUCCESS = "CUSTOMER_REGISTER_SUCCESS";
export const CUSTOMER_REGISTER_FAIL = "CUSTOMER_REGISTER_FAIL";
export const CUSTOMER_REGISTER_RESET = "CUSTOMER_REGISTER_RESET";

export const CUSTOMER_DETAILS_REQUEST = "CUSTOMER_DETAILS_REQUEST";
export const CUSTOMER_DETAILS_SUCCESS = "CUSTOMER_DETAILS_SUCCESS";
export const CUSTOMER_DETAILS_FAIL = "CUSTOMER_DETAILS_FAIL";
export const CUSTOMER_DETAILS_RESET = "CUSTOMER_DETAILS_RESET";

export const CUSTOMER_LIST_REQUEST = "CUSTOMER_LIST_REQUEST";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_FAIL = "CUSTOMER_LIST_FAIL";
export const CUSTOMER_LIST_RESET = "CUSTOMER_LIST_RESET";

export const CUSTOMER_DELETE_REQUEST = "CUSTOMER_DELETE_REQUEST";
export const CUSTOMER_DELETE_SUCCESS = "CUSTOMER_DELETE_SUCCESS";
export const CUSTOMER_DELETE_FAIL = "CUSTOMER_DELETE_FAIL";
export const CUSTOMER_DELETE_RESET = "CUSTOMER_DELETE_RESET";

export const CUSTOMER_UPDATE_REQUEST = "CUSTOMER_UPDATE_REQUEST";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAIL = "CUSTOMER_UPDATE_FAIL";
export const CUSTOMER_UPDATE_RESET = "CUSTOMER_UPDATE_RESET";

export const CUSTOMER_LIST_BY_POS_NAME_REQUEST = "CUSTOMER_LIST_BY_POS_NAME_REQUEST";
export const CUSTOMER_LIST_BY_POS_NAME_SUCCESS = "CUSTOMER_LIST_BY_POS_NAME_SUCCESS";
export const CUSTOMER_LIST_BY_POS_NAME_RESET = "CUSTOMER_LIST_BY_POS_NAME_RESET";
export const CUSTOMER_LIST_BY_POS_NAME_FAIL = "CUSTOMER_LIST_BY_POS_NAME_FAIL"

export const CUSTOMER_LIST_BY_PARENT_USER_REQUEST = "CUSTOMER_LIST_BY_PARENT_USER_REQUEST";
export const CUSTOMER_LIST_BY_PARENT_USER_SUCCESS = "CUSTOMER_LIST_BY_PARENT_USER_SUCCESS";
export const CUSTOMER_LIST_BY_PARENT_USER_RESET = "CUSTOMER_LIST_BY_PARENT_USER_RESET";
export const CUSTOMER_LIST_BY_PARENT_USER_FAIL = "CUSTOMER_LIST_BY_PARENT_USER_FAIL"
