import React from "react";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RawMaterialStockCreateForm from "./RawMaterialStockCreateForm";
import CardBody from "../../Card/CardBody";
import GridItem from "../../Grid/GridItem";
import GridContainer from "../../Grid/GridContainer";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: `calc(100vh - ${theme.mixins.toolbar["@media (min-width:600px)"].minHeight}px)`,
    maxWidth: 500,
    margin: `0 auto`,
  },
  section: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const RawMaterialStockCreate = ({ location, history }) => {
  return (
      <GridContainer spacing={2} >
        <GridItem  style={{ textAlign:"center", marginLeft:"auto", marginRight:"auto"}}>
          <Card style={{width: '100%', marginLeft:"auto", marginRight:"auto"}}>
            <CardHeader>
              <b><h2 align="center">Factory Raw Material Entry</h2></b>
            </CardHeader>
            <CardBody>
              <RawMaterialStockCreateForm />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
  );
};

export default RawMaterialStockCreate;
