import axios from "axios";
import {
  CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_FAIL,
  CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_REQUEST,
  CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_SUCCESS,
  CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_RESET,

  CTC_VARIETY_REGISTER_REQUEST,
  CTC_VARIETY_REGISTER_FAIL,
  CTC_VARIETY_REGISTER_SUCCESS,
  CTC_VARIETY_REGISTER_RESET,
 
  CTC_VARIETY_LIST_FAIL,
  CTC_VARIETY_LIST_SUCCESS,
  CTC_VARIETY_LIST_REQUEST,
  CTC_VARIETY_LIST_RESET,

  CTC_VARIETY_DELETE_REQUEST,
  CTC_VARIETY_DELETE_SUCCESS,
  CTC_VARIETY_DELETE_FAIL,
  CTC_VARIETY_DELETE_RESET,

  CTC_VARIETY_UPDATE_FAIL,
  CTC_VARIETY_UPDATE_SUCCESS,
  CTC_VARIETY_UPDATE_REQUEST,
  CTC_VARIETY_UPDATE_RESET,

} from "../constants/varietyConstants.js";

export const register =
  ( productName , variety ) =>
  async (dispatch) => {
    console.log('EXEC Variety Register  productName , variety  ', 
      productName , variety )
    try {
      dispatch({
        type: CTC_VARIETY_REGISTER_REQUEST,
      });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("Before Perfoming role logic : ");

      const { data } = await axios.post(
        "/api/ctc-variety",
        { productName , variety },
        config
      );

      dispatch({
        type: CTC_VARIETY_REGISTER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CTC_VARIETY_REGISTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getVarietyDetailsByName = (name) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await axios.get(`/api/ctc-variety/name/${name}`);

    dispatch({
      type: CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_VARIETY_DETAILS_BY_PRODUCT_NAME_FAIL,
      payload: message,
    });
  }
};

export const listVarieties = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CTC_VARIETY_LIST_REQUEST,
    });

    const { data } = await axios.get(`/api/ctc-variety`);

    dispatch({
      type: CTC_VARIETY_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_VARIETY_LIST_FAIL,
      payload: message,
    });
  }
};

export const deleteVariety = (id) => async (dispatch, getState) => {
  console.log("deleteVariety ", id);
  try {
    dispatch({
      type: CTC_VARIETY_DELETE_REQUEST,
    });

    await axios.delete(`/api/ctc-variety/${id}`);

    dispatch({ type: CTC_VARIETY_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_VARIETY_DELETE_FAIL,
      payload: message,
    });
  }
};

export const updateVariety = ( productName , variety ) => async (dispatch, getState) => {
  console.log('updateVariety : ',productName , variety );
  try {
    dispatch({
      type: CTC_VARIETY_UPDATE_REQUEST,
    });

    const { data } = await axios.put(`/api/ctc-variety/details/${name}`, {
      productName , variety },
      {});

    dispatch({ type: CTC_VARIETY_UPDATE_SUCCESS , payload: data });

    dispatch({ type: CTC_VARIETY_UPDATE_RESET });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CTC_VARIETY_UPDATE_FAIL,
      payload: message,
    });
  }
};
