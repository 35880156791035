import React, { useState, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridContainer from "../../Grid/GridContainer.js";
import GridItem from "../../Grid/GridItem.js";
import Card from "../../Card/Card.js";
import CardBody from "../../Card/CardBody.js";
import CardHeader from "../../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {
  Divider,
  Autocomplete,
} from "@mui/material";
import { createStockByVehicleNoAndProductName, listStockByVehicleNo } from "../../../actions/vehicleStockAction.js";
import { updateStockOutByWarehouseProduct } from "../../../actions/warehouseStockAction.js";
import { updateStockOutByStoreProduct } from "../../../actions/storeStockAction.js";
import { listStockByWarehouseName } from "../../../actions/warehouseStockAction.js";
import { listStockByStoreName } from "../../../actions/storeStockAction.js";
import { listUsers } from "../../../actions/userAction.js";
import { vehicleDetailsByVehicleNo } from "../../../actions/vehicleAction.js"
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper
} from "@mui/material";
import { WAREHOUSE_INVOICE_CREATE_RESET } from "../../../constants/warehouseInvoiceConstants.js";
import { VEHICLE_STOCK_IN_UPDATE_IN_TO_VEHICLE_NO_RESET } from "../../../constants/vehicleStockConstants.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import greeTickImage from "../../../assets/images/green_tick.png";
import { listAllVehicles } from "../../../actions/vehicleAction.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { styled } from '@mui/material/styles';
import { set } from "mongoose";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const VehicleSalesStockListScreen = ({ match }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName } = userInfo;
  const [selectedTargetVehicle, setSelectedTargetVehicle] = useState(() => null);
  const [availableProductsInVehicle, setAvailableProductsInVehicle] = useState(() => []);
  let customVehicles = [];
  let renderVehilceStockItemsUsingCards = null

  const vehicleStockListByVehicleNo = useSelector(
    (state) => state.vehicleStockListByVehicleNo
  );

  const { vehicle_stock_by_vehicle_no_data, vehicle_stock_by_vehicle_no_loading, vehicle_stock_by_vehicle_no_error } = vehicleStockListByVehicleNo;

  const vehicleList = useSelector((state) => state.vehicleList);
  const { vehicles } = vehicleList;
  if (vehicles && vehicles.length > 0) customVehicles = vehicles;

  const vehicleDetailsByNo = useSelector((state) => state.vehicleDetailsByNo);
  const { vehicle_details_by_vehicle_no_loading, vehicle_details_by_vehicle_no_error, vehicle_details_by_vehicle_no } = vehicleDetailsByNo;

  const loadOnlyProductsWithValidStock = () => {
    console.log(' Exec loadOnlyProductsWithValidStock ..! ')
    if (vehicle_stock_by_vehicle_no_data && vehicle_stock_by_vehicle_no_data.length > 0) {
      const productsInStock = vehicle_stock_by_vehicle_no_data.filter((prd => prd.countInStock > 0))
      console.log('vehicle_stock_by_vehicle_no After Filter of Out Of Stock : ', productsInStock);
      if (productsInStock)
        setAvailableProductsInVehicle((prev) => productsInStock)
    }else{
      setAvailableProductsInVehicle((prev) => null)
    }
  }

  renderVehilceStockItemsUsingCards = (
    <>
      {availableProductsInVehicle && availableProductsInVehicle.map((eachProduct, idx) => {
        return (
          <GridItem xs={12} sm={12} md={12} key={eachProduct.idx}>
            <Card style={{ backgroundColor: "#F2F2F2", marginTop: "0.5rem", marginBottom: "0.5rem" }}>
              <CardBody>
                <GridContainer spacing={2} alignItems="center" justify="center">
                  <GridItem xs={9} sm={9} md={9}>
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '600', fontSize: "1rem", alignItems: "left", justifyContent: "left", textAlign: "left" }}
                      color="black"
                    >
                      {eachProduct && eachProduct.productName ? eachProduct.productName : ""}
                    </Typography>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right", justifyContent: "center", alignItems: "center" }}>
                    <Typography
                      style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: "right", justifyContent: "center", alignItems: "center" }}
                      color="black"
                    >
                      Current Stock # {eachProduct && eachProduct.countInStock ? eachProduct.countInStock : 0} kgs
                    </Typography>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )
      })
      }
    </>
  )

  const emptyVehicleStockContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography style={{ textAlign: "center", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: "1rem", fontWeight: '600' }} color="red">
              No Stock Found in Vehicle  # <span style={{ textAlign: "center", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: "1rem", fontWeight: '900' }}>{selectedTargetVehicle}</span>
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );
  const createHandler = () => {
    navigate("/admin/vehicle-sales-stock/new");
  };

  useEffect(() => {
    dispatch(listAllVehicles());
  }, [dispatch]);

  useEffect(() => {
    if (selectedTargetVehicle && selectedTargetVehicle.length > 0) {
      console.log(" FETCHING DETAILS OF SALES INCHARGE & DRIVER BASED ON THE CURRENT SELECTED VEHICLE # : ", selectedTargetVehicle);
      dispatch(vehicleDetailsByVehicleNo(selectedTargetVehicle));
      console.log('NOW DISPATCHING TO FETCH THE STOCK BY VEHICLE NO # ', selectedTargetVehicle)
      dispatch(listStockByVehicleNo(selectedTargetVehicle));
    }
  }, [dispatch, selectedTargetVehicle]);

  useEffect(() => {
    if (vehicle_stock_by_vehicle_no_data && vehicle_stock_by_vehicle_no_data.length > 0) {
      loadOnlyProductsWithValidStock()
    }
  }, [vehicle_stock_by_vehicle_no_data]);

  return (
    <div className={classes.root}>
       {vehicle_stock_by_vehicle_no_loading && <CustomBackdropSpinner />}
     
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Tooltip title="Back" arrow>
                <ArrowBackIcon
                  fontSize="medium"
                  onClick={() => window.history.back()}
                  style={{ float: "left", marginRight: "3em", alignItems: "center" }}
                />
              </Tooltip>
              <Typography
                variant="h6"
                style={{ textAlign: "center", textTransform: 'none' }}
                color="black"
              >
                List Stock from Vehicle
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  onClick={createHandler}
                  style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                >
                  <AddCircleIcon
                    fontSize="medium"
                    style={{ float: "left", marginRight: "10px" }}
                  />
                  Vehicle Invoice
                </Button>
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      {/** VEHICLE DETAILS PANEL */}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <div>
              <Typography
                variant="h6"
                style={{ margin: "0.5rem" }}
                color="black"
              >
                Select Vehicle
              </Typography>
            </div>
            <CardBody>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Autocomplete
                    disablePortal
                    clearOnBlur
                    onChange={(event, value) => {
                      if (value) {
                        console.log("Currently Selected Vehicle Details :", value);
                        setAvailableProductsInVehicle((prev)=>[])
                        setSelectedTargetVehicle((prev) => value && value.vehicleNo && value.vehicleNo.length > 0 ? value.vehicleNo : "")
                      } else {
                        setSelectedTargetVehicle((prev) => null)
                      }
                    }}
                    id="combo-box-products"
                    options={customVehicles}
                    size="small"
                    fullWidth
                    getOptionLabel={(option) => `${option && option.vehicleNo && option.vehicleNo.length > 0 ? option.vehicleNo : ""}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select To"
                        size="small"
                        value={selectedTargetVehicle ? selectedTargetVehicle : ""}
                      />
                    )}
                  />
                </Grid>
                {selectedTargetVehicle && vehicle_details_by_vehicle_no && vehicle_details_by_vehicle_no.assignedTo && vehicle_details_by_vehicle_no.assignedTo.length > 0 &&
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Assigned Vehicle Sales Incharge"
                      placeholder="Assigned Vehicle Sales Incharge"
                      size="small"
                      fullWidth
                      disabled
                      value={vehicle_details_by_vehicle_no && vehicle_details_by_vehicle_no.assignedTo && vehicle_details_by_vehicle_no.assignedTo.length > 0 ? vehicle_details_by_vehicle_no.assignedTo : ""}
                    />
                  </Grid>}


                {selectedTargetVehicle && vehicle_details_by_vehicle_no && vehicle_details_by_vehicle_no.driverName && vehicle_details_by_vehicle_no.driverName.length > 0 &&
                  <Grid item xs={12} sm={6} md={4}>
                    <TextField
                      label="Driver Name"
                      placeholder="Driver Name"
                      size="small"
                      fullWidth
                      disabled
                      value={vehicle_details_by_vehicle_no && vehicle_details_by_vehicle_no.driverName && vehicle_details_by_vehicle_no.driverName.length > 0 ? vehicle_details_by_vehicle_no.driverName : ""}
                    />
                  </Grid>}
              </Grid>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/** VEHICLE DETAILS END */}
      <GridContainer spacing={2} alignItems="center" justify="center" >
        {availableProductsInVehicle && availableProductsInVehicle.length > 0 ? renderVehilceStockItemsUsingCards : selectedTargetVehicle ? emptyVehicleStockContent: null }
      </GridContainer>
    </div>
  );
};

export default VehicleSalesStockListScreen;
