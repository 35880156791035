import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import GridItem from "../../Grid/GridItem.js";
import GridContainer from "../../Grid/GridContainer.js";
import Card from "../../Card/Card.js";
import CardHeader from "../../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Tooltip, Typography
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SignpostIcon from "@mui/icons-material/Signpost";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { listStockTransferInvoicesByPos } from "../../../actions/stockTransferInvoiceAction.js";
import CustomBackdropSpinner from "../../CustomBackdropSpinner.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import { STOCK_TRANSER_INVOICE_LIST_BY_POS_RESET } from "../../../constants/stockTransferConstants.js";
import { Button } from "react-bootstrap";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const StockTransferListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef();
  let renderContentUsingAGGrid
  let rowData
  const userLogin = useSelector(
    (state) => state.userLogin
  );
  const { userInfo } = userLogin;
  const { name, role, storeName, warehouseName } = userInfo;

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  let gridApi = "";
  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const exportData = () => {
    gridApi.exportDataAsCsv();
  };

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "VehicleNo",
      headerName: "Vehicle No",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "FromWhStoreName",
      headerName: "From",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
    },
    {
      field: "ToWhStoreName",
      headerName: "To",
      resizable: true,
      suppressSizeToFit: true, width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "BillDate",
      headerName: "Bill Date",
      resizable: true,
      suppressSizeToFit: true, width: 250,
      minWidth: 250,
      maxWidth: 250
    },
    {
      field: "Total",
      headerName: "Total",
      resizable: true,
      suppressSizeToFit: true, width: 200,
      minWidth: 200,
      maxWidth: 200
    },
    {
      field: "Id",
      headerName: "Details",
      resizable: true,
      suppressSizeToFit: true,
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              onClick={() => showInvoiceDetails(params.data.Id)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const stockTransferInvoiceListByPos = useSelector(
    (state) => state.stockTransferInvoiceListByPos
  );
  const { stock_transfer_invoices_loading, stock_transfer_invoices_error, stock_transfer_invoices } = stockTransferInvoiceListByPos;

  useEffect(() => {
    console.log("Use Effect Gets Called to fetch the Data for Logged in Store | Warehouse");
    if (warehouseName && warehouseName.length > 0) {
      console.log("Now Dispatching Req to  listStockTransferInvoicesByPos by : ", warehouseName);
      dispatch(listStockTransferInvoicesByPos(warehouseName));
    } else if (storeName && storeName.length > 0) {
      console.log("Now Dispatching Req to  listStockTransferInvoicesByPos by : ", storeName);
      dispatch(listStockTransferInvoicesByPos(storeName));
    }
  }, [dispatch, storeName, warehouseName]);


  if (stock_transfer_invoices && stock_transfer_invoices.length > 0) {
    console.log("Prepare UI using AG Grid with Data .");
    rowData = stock_transfer_invoices.map((eachInvoice, index) => {
      return {
        "#": index + 1,
        VehicleNo: eachInvoice.vehicleNo,
        FromWhStoreName: eachInvoice.fromWhStoreName,
        ToWhStoreName: eachInvoice.toWhStoreName,
        BillDate: eachInvoice.billDate,
        Total: eachInvoice.total,
        Id: eachInvoice._id,
      };
    })
  } else
    console.log("No Data to Not preparing any for UI ")

  renderContentUsingAGGrid = (
    <div
      className="ag-theme-quartz"
      style={{ width: "100%", height: "65vh" }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowStyle={{ textAlign: "left", alignItems: "center" }}
        suppressExcelExport={false}
        popupParent={popupParent}
        pagination="true"
      ></AgGridReact>
    </div>
  )

  const createHandler = (category) => {
    navigate("/admin/stock-transfer/new");
  };

  const showInvoiceDetails = (id) => {
    navigate(`/admin/stock-transfer/${id}`);
  };

  return (
    <React.Fragment>
      {stock_transfer_invoices_loading && <CustomBackdropSpinner />}
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Typography
                variant="h6"
                style={{ textAlign: "center" }}
                color="black"
              >
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                Internal Stock <SignpostIcon
                  style={{
                    color: "black",
                    marginLeft: "2.5rem",
                    marginRight: "2.5rem",
                  }}
                /> Transfers

                {role && role === 'ADMIN_ROLE' && (<Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  onClick={createHandler}
                  style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                >
                  <AddCircleIcon
                    fontSize="medium"
                    style={{ float: "left", marginRight: "10px" }}
                  />
                  Invoice
                </Button>)}
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Tooltip title="Export > Excel" arrow>
          <IosShareRoundedIcon
            fontSize="medium"
            style={{ float: "right", margin: "0.5rem" }}
            onClick={onExportClick}
          />
        </Tooltip>
      </GridItem>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {rowData && renderContentUsingAGGrid ? renderContentUsingAGGrid : "No Records Found"}
        </GridItem>
      </GridContainer>
    </React.Fragment>
  );
};

export default StockTransferListScreen;
