import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Card from "../Card/Card.js";
import CardBody from "../Card/CardBody.js";
import CardHeader from "../Card/CardHeader.js";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import {
  Tooltip,
  Typography,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
  Input,
  AlertTitle,
  duration,
} from "@mui/material";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { exportToExcel } from "../../util/exportToExcelUtil.js";
import {
  convertInvoiceIdToDigits,
  getFirstAndlastDayOfCurrentMonth,
  getFirstAndlastDayOfPreviousMonth,
} from "../../util/Utility.js";
import {
  formatToLocaleCurrency,
  convertToMMDDYYYY,
} from "../../util/Utility.js";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  financialYearOptions,
  quarterlyOptions,
  walkinSalesReportDurations,
} from "../../data/index.js";
import * as XLSX from "xlsx";
import {
  syncVyaparWalkinSaleInvoices,
  listVyaparWalkinSaleByStore,
} from "../../actions/walkinVyaparSaleReportAction.js";
import CustomBackdropSpinner from "../CustomBackdropSpinner.js";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import Message from "../Message.js";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField/DateField";
import { listAllStores } from "../../actions/storeAction.js";
import {
  listWalkinSaleInvoices,
  listWalkinSaleInvoicesByPosName,
} from "../../actions/walkinSaleInvoiceAction.js";
import { DatePicker } from "@mui/x-date-pickers";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "300",
      lineHeight: "1",
    },
  },
};
const useStyles = makeStyles(styles);
// const classes = useStyles();
const WalkinSalesReport = () => {
  // const useStyles = makeStyles(styles);
  const gridRef = useRef();
  const dispatch = useDispatch();
  const classes = useStyles();
  const tempColDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "BillNo",
      headerName: "Invoice No",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false,
    },
    {
      field: "Date",
      headerName: "Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false,
    },
    {
      field: "StoreName",
      headerName: "Store Name",
      resizable: true,
      suppressSizeToFit: true,
      filter: true,
      cellStyle: (params) => {
        return { textAlign: "left" };
      },
      width: 275,
      minWidth: 275,
      maxWidth: 275,
    },
    {
      field: "PartyName",
      headerName: "Party Name",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: (params) => {
        return { textAlign: "left" };
      },
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: false,
    },
    // {
    //   field: "PartyPhoneNo",
    //   headerName: "Party Phone No",
    //   resizable: true,
    //   suppressSizeToFit: true,
    //   width: 150,
    //   minWidth: 150,
    //   maxWidth: 150,
    //   filter: false
    // },
    {
      field: "TransactionType",
      headerName: "Transaction Type",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: (params) => {
        return { textAlign: "center" };
      },
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: false,
    },
    {
      field: "TotalAmount",
      headerName: "Total Amount",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "PaidAmount",
      headerName: "Paid Amount",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "PaymentType",
      headerName: "Payment Type",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: (params) => {
        return { textAlign: "center" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false,
    },
    {
      field: "BalanceDue",
      headerName: "Balance Due",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: (params) => {
        return { textAlign: "right" };
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "PaymentStatus",
      headerName: "Payment Status",
      resizable: true,
      suppressSizeToFit: true,
      cellStyle: (params) => {
        // console.log("PaymentStatus", params.data.PaymentStatus)
        const payStatus = params.data.PaymentStatus;
        // console.log(" dueDate is Number .")
        if (payStatus === "PAID") {
          return { color: "#008200", textAlign: "center", fontWeight: "900" };
        } else {
          return { color: "#FF0800", textAlign: "center", fontWeight: "900" };
        }
      },
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: false,
    },
  ];
  const walkinSalesInvoicesListByPos = useSelector(
    (state) => state.walkinSalesInvoicesListByPos
  );

  const {
    walkin_sales_invoice_pos_loading,
    walkin_sales_invoice_pos_data,
    walkin_sales_invoice_pos_error,
  } = walkinSalesInvoicesListByPos;

  const walkinSalesInvoiceList = useSelector(
    (state) => state.walkinSalesInvoiceList
  );

  const {
    walkin_sales_invoice_loading,
    walkin_sales_invoice_data,
    walkin_sales_invoice_error,
  } = walkinSalesInvoiceList;

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  // const [posName, setPosName] = useState(() => "");
  const [customers, setCustomers] = useState(() => []);
  const [paymentTypes, setPaymentTypes] = useState(() => ["CASH", "UPI"]);
  const [selectedCustomer, setSelectedCustomer] = useState(() => "");
  const [selectedPaymentType, setSelectedPaymentType] = useState(() => "");
  // const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState(() => tempColDefs);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  const userLogin = useSelector((state) =>
    state.userLogin ? state.userLogin : {}
  );
  const { userInfo } = userLogin;
  const { name, storeName, warehouseName, role, userName } = userInfo;

  const [sumOfPaid, setSumOfPaid] = useState(() => 0.0);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const [sumOfUnpaid, setSumOfUnpaid] = useState(() => 0.0);
  const [sumOfTotalSale, setSumOfTotalSale] = useState(() => 0.0);
  const [open, setOpen] = React.useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [reportDuration, setReportDuration] = useState(() => "");
  const [selectedYear, setSelectedYear] = useState("2024");
  const [yearlyData, setYearlyData] = useState(() => []);
  // const [firstAndLastDayOfMonth, setFirstAndLastDayOfMonth] = useState("");
  const [firstAndLastDayOfCurrentMonth, setFirstAndLastDayOfCurrentMonth] =
    useState("");
  const [firstAndLastDayOfPreviousMonth, setFirstAndLastDayOfPreviousMonth] =
    useState("");

  let currentMonthFirstLastDate = "";
  let previousMonthFirstLastDate = "";

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };

  useEffect(() => {
    console.log(
      "Use Effect Gets Called for fetching FirstAndlastDayOfMonth..STRICTLY ONLY ONCE.!"
    );
    currentMonthFirstLastDate = getFirstAndlastDayOfCurrentMonth();
    previousMonthFirstLastDate = getFirstAndlastDayOfPreviousMonth();
    console.log(
      "currentMonthFirstLastDate , previousMonthFirstLastDate",
      currentMonthFirstLastDate,
      previousMonthFirstLastDate
    );
    setFirstAndLastDayOfCurrentMonth(
      () =>
        currentMonthFirstLastDate.firstDay +
        " To " +
        currentMonthFirstLastDate.lastDay
    );
    setFirstAndLastDayOfPreviousMonth(
      () =>
        previousMonthFirstLastDate.firstDay +
        " To " +
        previousMonthFirstLastDate.lastDay
    );
  }, []);

  useEffect(() => {
    let posName = "";
    if ((storeName || warehouseName) && role !== "CEO_ROLE") {
      posName = storeName ? storeName : warehouseName ? warehouseName : "";
      console.log(
        "Use Effect Gets Called Only Once and sets the POS NAME ::==> ",
        posName
      );
      dispatch(listWalkinSaleInvoicesByPosName(posName));
    } else if (role === "CEO_ROLE") {
      dispatch(listWalkinSaleInvoices());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      walkin_sales_invoice_pos_data &&
      walkin_sales_invoice_pos_data.length > 0
    ) {
      console.log(
        "\uD83D\uDE00 Use Effect getting Called FIRST TIME WHEN NO SELECTION IS MADE AND  walkin_sales_invoice_pos_data HAS DATA \uD83D\uDE00"
      );
      prepareDataForNoFilters(walkin_sales_invoice_pos_data);
    }
    if (walkin_sales_invoice_data && walkin_sales_invoice_data.length > 0) {
      console.log(
        "\uD83D\uDE00 Use Effect getting Called FIRST TIME WHEN NO SELECTION IS MADE AND  walkin_sales_invoice_data HAS DATA \uD83D\uDE00"
      );
      prepareDataForNoFilters(walkin_sales_invoice_data);
    }
  }, [walkin_sales_invoice_pos_data, walkin_sales_invoice_data]);

  useEffect(() => {
    console.log(
      "\uD83D\uDE00 Use Effect getting Called WHEN Filter SELECTION IS MADE AND  walkin_sales_invoice_pos_data HAS DATA \uD83D\uDE00"
    );
    if (
      walkin_sales_invoice_pos_data &&
      walkin_sales_invoice_pos_data.length > 0 &&
      role !== "CEO_ROLE"
    ) {
      console.log(
        "\uD83D\uDE00 Use Effect getting Called FIRST TIME WHEN NO SELECTION IS MADE AND  walkin_sales_invoice_pos_data HAS DATA \uD83D\uDE00"
      );
      prepareDataWithFilters(walkin_sales_invoice_pos_data);
    } else if (
      walkin_sales_invoice_data &&
      walkin_sales_invoice_data.length > 0 &&
      role === "CEO_ROLE"
    ) {
      console.log(
        "\uD83D\uDE00 Use Effect getting Called FIRST TIME WHEN NO SELECTION IS MADE AND  walkin_sales_invoice_data HAS DATA \uD83D\uDE00"
      );
      prepareDataWithFilters(walkin_sales_invoice_data);
    }
  }, [selectedCustomer, selectedPaymentType, reportDuration]);

  useEffect(() => {
    const unpaidTotal = Number(sumOfTotalSale) - Number(sumOfPaid);
    setSumOfUnpaid(unpaidTotal.toFixed(2));
  }, [sumOfTotalSale, sumOfPaid]);

  const uniquePartyNames = () => {
    if (
      role !== "CEO_ROLE" &&
      walkin_sales_invoice_pos_data &&
      walkin_sales_invoice_pos_data.length > 0
    ) {
      console.log(walkin_sales_invoice_pos_data.map((item) => item));
      const uniqueCustomers = [
        ...new Set(
          walkin_sales_invoice_pos_data.map((item) =>
            item.customerDetails && item.customerDetails.name
              ? item.customerDetails.name
              : ""
          )
        ),
      ];
      // console.log("uniqueCustomers : ", uniqueCustomers ? uniqueCustomers : 0);
      if (uniqueCustomers) setCustomers(() => uniqueCustomers);
    } else if (
      role === "CEO_ROLE" &&
      walkin_sales_invoice_data &&
      walkin_sales_invoice_data.length > 0
    ) {
      console.log(walkin_sales_invoice_data.map((item) => item));
      const uniqueCustomers = [
        ...new Set(
          walkin_sales_invoice_data.map((item) =>
            item.customerDetails && item.customerDetails.name
              ? item.customerDetails.name
              : ""
          )
        ),
      ];
      // console.log("uniqueCustomers : ", uniqueCustomers ? uniqueCustomers : 0);
      if (uniqueCustomers) setCustomers(() => uniqueCustomers);
    }
  };

  const prepareDataForNoFilters = (data) => {
    // console.log("Exec prepareDataForNoFilters ...");
    if (data && data.length > 0) {
      sumPaid(data);
      sumUnpaid(data);
      sumTotalSaleAmount(data);
      uniquePartyNames(data);
      saveToRowData(data);
    } else {
      console.log("Exec ELSE AS NO DATA...");
    }
  };

  const prepareDataWithFilters = (dt) => {
    // console.log("Exec prepareDataForNoFilters ...");
    let data = dt || [];
    if (selectedCustomer) {
      data = data.filter(
        (item) => item.customerDetails?.name === selectedCustomer
      );
    }
    if (selectedPaymentType) {
      data = data.filter((item) => {
        const cashPaid = Number(item.paymentDetails?.cashAmountPaid) || 0;
        const upiPaid = Number(item.paymentDetails?.upiAmountPaid) || 0;
        return selectedPaymentType === "CASH" ? cashPaid > 0 : upiPaid > 0;
      });
    }

    if (reportDuration) {
      const firstDayCurrentMonth =
        firstAndLastDayOfCurrentMonth.split("To")[0] || "";
      const lastDayCurrentMonth =
        firstAndLastDayOfCurrentMonth.split("To")[1] || "";
      const firstDayPreviousMonth =
        firstAndLastDayOfPreviousMonth.split("To")[0] || "";
      const lastDayPreviousMonth =
        firstAndLastDayOfPreviousMonth.split("To")[1] || "";

      // console.log(firstDayCurrentMonth,lastDayCurrentMonth ,firstDayPreviousMonth, lastDayPreviousMonth)
      data = data.filter((item) => {
        // console.log('item : ',item, "reportDuration : ",reportDuration)
        let billDateFmt = item.billDate ? item.billDate.split(",")[0] : "";
        billDateFmt = new Date(convertToMMDDYYYY(billDateFmt));
        console.log("billDateFmt : ", billDateFmt);
        if (reportDuration === "currentMonth") {
          // console.log("FirstAndLastDayOfCurrentMonth : ", firstAndLastDayOfCurrentMonth)
          // console.log("billDateFmt : ", billDateFmt,"firstDayCurrentMonth : " ,firstDayCurrentMonth ," lastDayCurrentMonth : ",lastDayCurrentMonth)
          // console.log("billDateFmt >= firstDayCurrentMonth && billDate <= lastDayCurrentMonth : ", billDateFmt >= new Date(firstDayCurrentMonth) && billDateFmt <= new Date(lastDayCurrentMonth))
          return (
            billDateFmt >= new Date(firstDayCurrentMonth) &&
            billDateFmt <= new Date(lastDayCurrentMonth)
          );
        } else if (reportDuration === "previousMonth") {
          return (
            billDateFmt >= new Date(firstDayPreviousMonth) &&
            billDateFmt <= new Date(lastDayPreviousMonth)
          );
        } else if (reportDuration === "custom") {
          console.log(
            "customStartDate : ",
            customStartDate,
            "customEndDate : ",
            customEndDate
          );
          return (
            billDateFmt >= new Date(customStartDate) &&
            billDateFmt <= new Date(customEndDate)
          );
        }
      });
    }
    console.log(
      "Final Filterd data by Date Range : ",
      data,
      "reportDuration : ",
      reportDuration
    );
    setFilteredData((prev) => data);
    sumPaid(data);
    sumUnpaid(data);
    sumTotalSaleAmount(data);
    uniquePartyNames(data);
    saveToRowData(data);
  };

  const sumPaid = (data) => {
    console.log("selectedPaymentType:", selectedPaymentType);
  
    if (!Array.isArray(data)) {
      setSumOfPaid("0.00");
      return;
    }
  
    let cashAmountPaid = 0.0;
    let upiAmountPaid = 0.0;
  
    // Calculate total cash and UPI payments
    cashAmountPaid = data.reduce(
      (sum, item) => sum + Number(item.paymentDetails?.cashAmountPaid || 0),
      0
    );
  
    upiAmountPaid = data.reduce(
      (sum, item) => sum + Number(item.paymentDetails?.upiAmountPaid || 0),
      0
    );
  
    // Determine final paid amount based on selected payment type
    const paidAmount =
      selectedPaymentType === "CASH"
        ? cashAmountPaid
        : selectedPaymentType === "UPI"
        ? upiAmountPaid
        : cashAmountPaid + upiAmountPaid;
  
    setSumOfPaid(paidAmount.toFixed(2));
  };
  

  const sumTotalSaleAmount = (data) => {
    const totalSaleAmount = Array.isArray(data)
      ? data.reduce(
          (sum, item) => sum + Number(item.paymentDetails?.orderTotal || 0),
          0
        )
      : 0;
    setSumOfTotalSale(totalSaleAmount.toFixed(2));
  };

  const sumUnpaid = () => {
    console.log("sumOfTotalSale , sumOfPaid :: ", sumOfTotalSale , sumOfPaid)
    const unpaidTotal = Number(sumOfTotalSale) - Number(sumOfPaid);
    setSumOfUnpaid(unpaidTotal.toFixed(2));
  };

  const saveToRowData = (data) => {
    console.log(
      "############ saveToRowData  ############",
      Array.isArray(data) ? data.length : "Not an array"
    );

    // Ensure data is always an array
    const safeData = Array.isArray(data) ? data : [];
    setFilteredData(() =>
      safeData.map((saleByPos, index) => {
        const invIdInDecimal = convertInvoiceIdToDigits(saleByPos._id);
        const cashPaid = Number(saleByPos?.paymentDetails?.cashAmountPaid) || 0;
        const upiPaid = Number(saleByPos?.paymentDetails?.upiAmountPaid) || 0;
        
        const amountPaid = cashPaid + upiPaid
        const totalAmount = Number(saleByPos?.paymentDetails?.orderTotal) || 0.0;
        const balanceDue = totalAmount - amountPaid
        const paymntType = saleByPos?.paymentDetails?.cashAmountPaid ? "CASH" : saleByPos?.paymentDetails?.upiAmountPaid ? "UPI" : "";
        const paymntStatus = totalAmount - amountPaid > 0 ? "DUE" : "PAID";
        return {
          "#": index + 1,
          BillNo: saleByPos.billNo ? saleByPos.billNo : "",
          StoreName: saleByPos.posName ? saleByPos.posName : "",
          Date: saleByPos.billDate ? saleByPos.billDate.split(",")[0] : "",
          PartyName:
            saleByPos.customerDetails &&
            saleByPos.customerDetails &&
            saleByPos.customerDetails.name
              ? saleByPos.customerDetails.name
              : "",
          TransactionType: "SALE",
          PaymentType: paymntType,
          BalanceDue: balanceDue,
          PaymentStatus: paymntStatus,
          TotalAmount: totalAmount ? formatToLocaleCurrency(totalAmount) : 0.0,
          PaidAmount: amountPaid ? formatToLocaleCurrency(amountPaid) : 0.0,
        };
      })
    );
  };

  return (
    <>
      {/* Error Alert */}
      {walkin_sales_invoice_pos_error && (
        <Box sx={{ width: "100%" }}>
          <Collapse in={open}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => setOpen(false)}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <Typography
                variant="h6"
                sx={{ margin: "0.5rem", textAlign: "center", color: "black" }}
              >
                {walkin_sales_invoice_pos_error}
              </Typography>
            </Alert>
          </Collapse>
        </Box>
      )}

      {/* Loading Spinner */}
      {walkin_sales_invoice_pos_loading && <CustomBackdropSpinner />}

      <GridContainer spacing={2} alignItems="center" justifyContent="center">
        <GridItem xs={12}>
          <Card>
            <Typography variant="h6" sx={{ margin: "0.5rem", color: "gray" }}>
              Walkin Sales Report
            </Typography>
            <CardBody>
              {walkin_sales_invoice_pos_data && (
                <GridContainer spacing={2}>
                  <GridItem xs={12} sm={6}>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={customers}
                      size="small"
                      onChange={(_, value) => setSelectedCustomer(value || "")}
                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Customer Name"
                          size="small"
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Autocomplete
                      disablePortal
                      fullWidth
                      options={paymentTypes}
                      size="small"
                      onChange={(_, value) =>
                        setSelectedPaymentType(value || "")
                      }
                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Payment Type"
                          size="small"
                        />
                      )}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} style={{ marginTop: "1rem" }}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, v) => {
                        if (v) {
                          const selectRptDr = v && v.value ? v.value : null;
                          console.log("AutoCom : Value : ", v.value);
                          setReportDuration(() => selectRptDr);
                          if (selectRptDr === "currentMonth") {
                            console.log(
                              "Chosen Value :==> ",
                              selectRptDr,
                              "currentMonthFirstLastDate : ",
                              firstAndLastDayOfCurrentMonth
                            );
                            // setFirstAndLastDayOfCurrentMonth(() => currentMonthFirstLastDate);
                          } else if (selectRptDr === "previousMonth") {
                            console.log(
                              "Chosen Value :==> ",
                              selectRptDr,
                              "previousMonthFirstLastDate : ",
                              firstAndLastDayOfPreviousMonth
                            );
                            // setFirstAndLastDayOfPreviousMonth(() => previousMonthFirstLastDate);
                          }
                        } else {
                          console.log("Clicked X Btn of Report Duration ...! ");
                          setReportDuration(() => "");
                        }
                      }}
                      fullWidth
                      id="combo-box-products"
                      options={walkinSalesReportDurations}
                      size="small"
                      getOptionLabel={(option) => {
                        if (option) {
                          return `${option ? option.title : ""}`;
                        }
                      }}
                      clearOnBlur={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Duration"
                          size="small"
                          value={
                            reportDuration && reportDuration
                              ? reportDuration
                              : ""
                          }
                        />
                      )}
                    />
                  </GridItem>
                  {reportDuration && reportDuration === "custom" && (
                    <GridItem
                      xs={12}
                      sm={6}
                      md={6}
                      style={{ marginTop: "1rem" }}
                    >
                      <GridContainer spacing={2}>
                        <GridItem
                          xs={12}
                          sm={6}
                          md={6}
                          style={{
                            textAlign: "left",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "gray",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="Start Date"
                              value={customStartDate}
                              onChange={(newValue) =>
                                setCustomStartDate(newValue)
                              }
                              format="MM-DD-YYYY"
                              renderInput={(params) => (
                                <TextField {...params} size="small" fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={6}
                          md={6}
                          style={{
                            textAlign: "right",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "gray",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label="End Date"
                              value={customEndDate}
                              onChange={(newValue) =>
                                setCustomEndDate(newValue)
                              }
                              format="MM-DD-YYYY"
                              renderInput={(params) => (
                                <TextField {...params} size="small" fullWidth />
                              )}
                            />
                          </LocalizationProvider>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  )}
                </GridContainer>
              )}

              {filteredData?.length > 0 && (
                <GridContainer justifyContent="center" alignItems="center">
                  {renderCard("Paid", sumOfPaid, "#C7F6C7")}
                  <Typography sx={operatorStyle}>+</Typography>
                  {renderCard("Unpaid", sumOfUnpaid, "#ADD8E6")}
                  <Typography sx={operatorStyle}>=</Typography>
                  {renderCard("Total", sumOfTotalSale, "#FFBE9F")}
                </GridContainer>
              )}
            </CardBody>
          </Card>
        </GridItem>

        {/* CEO Export Button */}
        {role === "CEO_ROLE" && filteredData && (
          <GridItem xs={12} sm={6} md={6} sx={{ textAlign: "center", mb: 2 }}>
            <Tooltip title="Download Full Sale Report" arrow>
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={onExportClick}
                sx={{
                  textTransform: "capitalize",
                  width: "20vh",
                  borderRadius: "5px",
                }}
              >
                <SaveAltIcon fontSize="medium" /> Sale Report
              </Button>
            </Tooltip>
          </GridItem>
        )}

        {/* Data Table */}
        <GridItem xs={12} sx={{ textAlign: "center" }}>
          <div
            className="ag-theme-quartz"
            style={{ width: "100%", height: "65vh" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={filteredData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              pagination
              paginationPageSize={filteredData?.length > 100 ? 20 : 10}
              cacheBlockSize={filteredData?.length > 100 ? 20 : 10}
              suppressExcelExport={false}
              popupParent={popupParent}
            />
          </div>
        </GridItem>
      </GridContainer>
    </>
  );
};

// Helper function for rendering financial cards
const renderCard = (title, amount, bgColor) => (
  <GridItem>
    <Tooltip title={title.toUpperCase()} arrow>
      <Card
        style={{
          justifyContent: "center",
          marginLeft: "auto",
          alignItems: "center",
          width: "12rem",
          height: "7.5rem",
          backgroundColor:
            title.toUpperCase() === "PAID"
              ? "#C7F6C7"
              : title.toUpperCase() === "UNPAID"
              ? "#ADD8E6"
              : "#FFBE9F",
        }}
      >
        <Typography
          style={{
            marginTop: "1rem",
            justifyContent: "left",
            textAlign: "left",
            alignItems: "left",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: "600",
            color: "black",
            fontSize: "1.1rem",
          }}
        >
          {title.toUpperCase()}
        </Typography>

        <Typography
          style={{
            margin: "0.5rem",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            fontFamily: "Montserrat, Arial, sans-serif",
            fontWeight: "600",
            color: "black",
            fontSize: "1.25rem",
          }}
        >
          <CurrencyRupeeIcon
            size="small"
            variant="contained"
            type="submit"
            color="Gray"
            justify="center"
            style={{ marginRight: "0.5rem", width: "1rem" }}
          ></CurrencyRupeeIcon>{" "}
          {amount ? formatToLocaleCurrency(amount) : 0.0}
        </Typography>
      </Card>
    </Tooltip>
  </GridItem>
);
const operatorStyle = {
  fontWeight: 600,
  fontSize: "1.2rem",
  color: "black",
  mx: 1,
};

export default WalkinSalesReport;
