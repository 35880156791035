import {
  FACTORY_REGISTER_FAIL,
  FACTORY_REGISTER_REQUEST,
  FACTORY_REGISTER_SUCCESS,
  FACTORY_REGISTER_RESET,
 
  FACTORY_LIST_FAIL,
  FACTORY_LIST_SUCCESS,
  FACTORY_LIST_REQUEST,
  FACTORY_LIST_RESET,

  FACTORY_DELETE_REQUEST,
  FACTORY_DELETE_SUCCESS,
  FACTORY_DELETE_FAIL,
  FACTORY_DELETE_RESET,

  FACTORY_UPDATE_FAIL,
  FACTORY_UPDATE_SUCCESS,
  FACTORY_UPDATE_REQUEST,
  FACTORY_UPDATE_RESET,

  FACTORY_DETAILS_REQUEST,
  FACTORY_DETAILS_SUCCESS,
  FACTORY_DETAILS_FAIL,
  FACTORY_DETAILS_RESET,
} from "../constants/factoryConstants";


export const factoryRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case FACTORY_REGISTER_REQUEST:
      return { factory_register_loading: true };
    case FACTORY_REGISTER_SUCCESS:
      return { factory_register_loading: false,factory_register_success: true, factory_register_data: action.payload };
    case FACTORY_REGISTER_FAIL:
      return { factory_register_loading: false, factory_register_error: action.payload };
    case FACTORY_REGISTER_RESET:
      return {};
    default:
      return state;
  }
};

export const factoryDetailsByNameReducer = (state = { customer: {} }, action) => {
  switch (action.type) {
    case FACTORY_DETAILS_REQUEST:
      return { ...state, factory_details_loading: true };
    case FACTORY_DETAILS_SUCCESS :
      return { factory_details_loading: false, factory_details_data : action.payload };
    case FACTORY_DETAILS_FAIL:
      return { factory_details_loading: false, factory_details_error: action.payload };
    case FACTORY_DETAILS_RESET:
      return {  };
    default:
      return state;
  }
};

export const factoryListReducer = (state = { customers_list: [] }, action) => {
  switch (action.type) {
    case FACTORY_LIST_REQUEST:
      return { factory_list_loading: true };
    case FACTORY_LIST_SUCCESS:
      return { factory_list_loading: false, factory_list_success: false, factory_list_data : action.payload };
    case FACTORY_LIST_FAIL:
      return { factory_list_loading: false, factory_list_error : action.payload };
    case FACTORY_LIST_RESET:
      return {};
    default:
      return state;
  }
};

export const factoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case FACTORY_DELETE_REQUEST:
      return { factory_delete_loading: true };
    case FACTORY_DELETE_SUCCESS:
      return { factory_delete_loading: false, factory_delete_success: true };
    case FACTORY_DELETE_FAIL:
      return { factory_delete_loading: false, factory_delete_error: action.payload };
    default:
      return state;
  }
};

export const factoryUpdateReducer = (state = { }, action) => {
  switch (action.type) {
    case FACTORY_UPDATE_REQUEST:
      return { factory_update_loading: true };
    case FACTORY_UPDATE_SUCCESS:
      return { factory_update_loading: false, factory_update_success : true };
    case FACTORY_UPDATE_FAIL:
      return { factory_update_loading: false, factory_update_error: action.payload };
    case FACTORY_UPDATE_RESET:
      return {};
    default:
      return state;
  }
};
