import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../Card/Card";
import CardBody from "../../../Card/CardBody";
import CardHeader from "../../../Card/CardHeader";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GridItem from "../../../Grid/GridItem";
import GridContainer from "../../../Grid/GridContainer";
import {
  Tooltip,
  Typography,
  Button,
  colors,
  Icon,
  Grid,
  Paper,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import HomeIcon from "@mui/icons-material/Home";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import { listVehicleSalesInvoices, salesInvoiceByVehicleNo, vehicleSalesInvoicesByIncharge } from "../../../../actions/vehicleSalesInvoiceAction.js";
import CustomBackdropSpinner from "../../../CustomBackdropSpinner.js";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { exportVehicleSalesProductWiseToExcel } from "../../../../util/exportToExcelUtil.js";
import { addDecimals, convertInvoiceIdToDigits, formatToLocaleCurrency } from '../../../../util/Utility.js'
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { listCustomersByParentUser } from "../../../../actions/customerAction";
import rupeeSvgIcon from "../../../../assets/images/currency-inr.svg";
import { listStockByVehicleNo, updateReturnStockFromVehicleNoByProductName } from "../../../../actions/vehicleStockAction.js";
import { fetchVehicleByUserName } from "../../../../actions/vehicleAction.js";
import { vehicleSalesInvoiceByInchargeNameReducer } from "../../../../reducers/vehicleSalesInvoiceReducer.js";
import { updateReturnStockToStoreByProductNames } from "../../../../actions/storeStockAction.js";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import greeTickImage from "../../../../assets/images/green_tick.png";
import { VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET } from "../../../../constants/vehicleStockConstants";

const styles = {
  formControl: {
    margin: "1rem",
    minWidth: "20rem",
  },
  cardTitleWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,10)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleGreen: {
    color: "#26A541",
    marginTop: "0px",
    minHeight: "auto",
    fontFamily: "Roboto",
    marginBottom: "3px",
    textDecoration: "none",
    fontSize: "1rem",
    fontWeight: 500,
    textTransform: "capitalize",
    textAlign: "left",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "300",
      lineHeight: "1",
    },
  },
};

const VehicleSalesInvoiceListScreen = ({ match }) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(() => false)
  const gridRef = useRef();
  const [availableProductsInVehicle, setAvailableProductsInVehicle] = useState(() => []);
  const userLogin = useSelector((state) => state.userLogin ? state.userLogin : {});
  const { userInfo } = userLogin;
  const { name, role, userName } = userInfo;
  const vehicleSalesIncharge = useSelector((state) => state.vehicleSalesIncharge);
  const {
    fetch_vehicle_by_user_data,
    fetch_vehicle_by_user_loading,
    fetch_vehicle_by_user_error,
  } = vehicleSalesIncharge;

  const { vehicleNo } = fetch_vehicle_by_user_data ? fetch_vehicle_by_user_data : {};

  // const vehicleSalesInvoiceDetailsByVehicleNo = useSelector((state) => state.vehicleSalesInvoiceDetailsByVehicleNo);
  const vehicleSalesInvoiceByInchargeName = useSelector((state) => state.vehicleSalesInvoiceByInchargeName);
  // const {
  //   vehicle_sale_invoice_by_vehicle_no_loading,
  //   vehicle_sale_invoice_by_vehicle_no_error,
  //   vehicle_sale_invoice_by_vehicle_no_data,
  // } = vehicleSalesInvoiceDetailsByVehicleNo;

  const { vehicle_sale_invoice_by_incharge_loading,
    vehicle_sale_invoice_by_incharge_success,
    vehicle_sale_invoice_by_incharge_data } = vehicleSalesInvoiceByInchargeName

  const customerListByParentUser = useSelector((state) => state.customerListByParentUser);
  const { customer_list_parent_user, customer_list_parent_user_error, customer_list_parent_user_success } = customerListByParentUser;
  const vehicleStockListByVehicleNo = useSelector(
    (state) => state.vehicleStockListByVehicleNo
  );
  const vehicleReturnStockOutUpdateByVehicleNo = useSelector(
    (state) => state.vehicleReturnStockOutUpdateByVehicleNo
  );

  const { vehicle_stock_by_vehicle_no_data, vehicle_stock_by_vehicle_no_loading, vehicle_stock_by_vehicle_no_error } = vehicleStockListByVehicleNo;

  const { vehicle_return_stock_update_loading, vehicle_return_stock_update_success, vehicle_return_stock_update_error } = vehicleReturnStockOutUpdateByVehicleNo;

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const initialChips = [
    { id: 1, label: 'Customers' },
    { id: 2, label: 'Transactions' },
    { id: 3, label: 'Items' }
  ];

  // State to keep track of the selected chip ID
  const [selectedChipId, setSelectedChipId] = useState(() => 1);

  const onExportClick = () => {
    gridRef.current.api.exportDataAsCsv();
    console.log("Clicked onExportClick");
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [salesContentUsingAGGrid, setSalesContentUsingAGGrid] = useState(() => null);
  const [salesContentProductWiseUsingAGGrid, setSalesContentProductWiseUsingAGGrid] = useState(() => null);
  let renderCustomersUsingCards = ""
  let renderContentUsingAGGrid = "";
  let renderTransactionsUsingCards = "";
  let renderVehilceStockItemsUsingCards = "";
  let renderReturnStock = "";
  let gridApi = "";
  let noDataFoundContent = null;
  let emptyVehicleStockContent = null;


  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    if (vehicle_return_stock_update_success) {
      dispatch({ type: VEHICLE_RETURN_STOCK_OUT_UPDATE_FROM_VEHICLE_NO_RESET });
      navigate("/");
    }
  };

  const loadOnlyProductsWithValidStock = () => {
    console.log(' Exec loadOnlyProductsWithValidStock ..! ')
    if (vehicle_stock_by_vehicle_no_data && vehicle_stock_by_vehicle_no_data.length > 0) {
      const productsInStock = vehicle_stock_by_vehicle_no_data.filter((prd => prd.countInStock > 0))
      console.log('vehicle_stock_by_vehicle_no After Filter of Out Of Stock : ', productsInStock);
      if (productsInStock && productsInStock.length > 0)
        setAvailableProductsInVehicle((prev) => productsInStock)
    }
  }

  useEffect(() => {
    if (userName) {
      console.log('About to Fetch Vehicle by User Name : ', userName)
      dispatch(fetchVehicleByUserName(userName))
    }
  }, [dispatch, userName]);

  useEffect(() => {
    if (vehicleNo && vehicleNo.length > 0)
      console.log('vehicleNo : ', vehicleNo)
    dispatch(salesInvoiceByVehicleNo(vehicleNo));
  }, [dispatch, vehicleNo]);

  useEffect(() => {
    if (userName && userName.length > 0)
      console.log('User Name  : ', userName)
    dispatch(vehicleSalesInvoicesByIncharge(userName));
  }, [dispatch, vehicleNo]);


  useEffect(() => {
    console.log('Use Effect getting Called as Vehicle Sales Report By Products has Changed')
    if (selectedChipId === 2) {
      prepareDataForSalesReport()
      prepareDataForProductWiseSaleReport()
    }
  }, [vehicle_sale_invoice_by_incharge_data]);

  useEffect(() => {
    if (name && name.length > 0 && role && role === "VEHICLE_SALES_INCHARGE_ROLE") {
      console.log('Exec listCustomersByParentUser for name, userName', name, userName)
      dispatch(listCustomersByParentUser(name));
    }
  }, [dispatch, role, name]);

  useEffect(() => {
    if (selectedChipId === 3 && vehicleNo && vehicleNo.length > 0 || vehicle_return_stock_update_success) {
      console.log('NOW DISPATCHING TO FETCH THE STOCK BY VEHICLE NO # ', vehicleNo, ' , selectedChipId : ', selectedChipId)
      dispatch(listStockByVehicleNo(vehicleNo));
    }
  }, [dispatch, vehicleNo, selectedChipId, vehicle_return_stock_update_success]);


  useEffect(() => {
    if (vehicle_stock_by_vehicle_no_data && vehicle_stock_by_vehicle_no_data.length > 0) {
      loadOnlyProductsWithValidStock()
    }
  }, [vehicle_stock_by_vehicle_no_data]);

  useEffect(() => {
    console.log("useEffect Getting Called on Return Stock From Vehicle to Store is Success ", vehicle_return_stock_update_success);
    if (vehicle_return_stock_update_success) {
      console.log("Open the Dialog");
      setOpen(() => true);
    }
  }, [vehicle_return_stock_update_success]);

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography style={{ textAlign: "center", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: "0.85rem", fontWeight: '600' }} color="red">
              No Stock Found in Vehicle {vehicleNo}
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  noDataFoundContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography style={{ textAlign: "center", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: "0.85rem", fontWeight: '600' }} color="red">
              No Data Found
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );
  emptyVehicleStockContent = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader>
            <Typography style={{ textAlign: "center", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', fontSize: "0.85rem", fontWeight: '600' }} color="red">
              No Stock Found in Vehicle {vehicleNo}
            </Typography>
          </CardHeader>
        </Card>
      </GridItem>
    </GridContainer>
  );

  const columnDefs = [
    {
      field: "#",
      resizable: true,
      suppressSizeToFit: true,
      width: 75,
      minWidth: 75,
      maxWidth: 75,
    },
    {
      field: "Id",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: true,
    },
    {
      field: "VehicleNo",
      headerName: "Vehicle No",
      resizable: true,
      suppressSizeToFit: true,
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      filter: true,
    },
    {
      field: "BillDate",
      headerName: "Bill Date",
      resizable: true,
      suppressSizeToFit: true,
      width: 250,
      minWidth: 250,
      maxWidth: 250,
      filter: true,
    },
    {
      field: "CustomerName",
      headerName: "Customer Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      filter: true,
    },
    {
      field: "CustomerContact",
      headerName: "Customer Contact",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      filter: true,
    },
    {
      field: "DriverName",
      headerName: "Driver Name",
      resizable: true,
      suppressSizeToFit: true,
      width: 200,
      minWidth: 200,
      maxWidth: 200,
      filter: true,
    },
    {
      field: "DriverContact",
      headerName: "Driver Contact",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "ItemsPrice",
      headerName: "Items Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "TaxPrice",
      headerName: "Tax Price",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "DiscountApplied",
      headerName: "Discount Applied",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "AmountPaid",
      headerName: "Amount Paid",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "BalanceAmount",
      headerName: "Balance Amount",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
    },
    {
      field: "Details",
      headerName: "Details",
      resizable: true,
      suppressSizeToFit: true,
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      cellRenderer: (params) => (
        <span>
          <Tooltip title="Details" arrow>
            <ReadMoreIcon
              size="small"
              variant="contained"
              type="submit"
              color="Gray"
              justify="center"
              // onClick={() => console.log("params", params.data.Details)}
              onClick={() => showInvoiceDetails(params.data.Details)}
              style={{ marginRight: "1rem", width: "2rem" }}
            ></ReadMoreIcon>
          </Tooltip>
        </span>
      ),
      sortable: false,
      filter: false,
      floatingFilter: false,
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      filter: false,
      floatingFilter: false,
      flex: 1,
    }),
    []
  );
  let rowData = [];

  const handleCustomers = () => {
    console.info('You clicked the Customers.');
  };

  // Handler for chip click
  const handleChipClick = (chipId) => {
    console.log('chipId : ', chipId, typeof chipId)

    setSelectedChipId(chipId);
  };

  const handleItems = () => {
    console.info('You clicked the Items.');
  };
  const handleTransactions = () => {
    console.info('You clicked the Transactions.');
  };

  const createHandler = (category) => {
    navigate("/admin/vehicle-sales-invoice/new");
  };

  const showInvoiceDetails = (id) => {
    navigate(`/admin/vehicle-sales-invoice/${id}`);
  };

  const showPaymentDetails = (id) => {
    navigate(`/admin/vehicle-sales-invoice/payment/${id}`);
  };

  const transformGroupInvoicesByName = (processedData) => {
    console.log('######### Exec transformGroupInvoicesByName : ', processedData ? processedData.length : 0, '#########')
    let transformedData = []
    if (processedData && processedData.length) {
      processedData.map((each) => {
        console.log(' $$$$$$$ each : each', each)
        if (each.invoiceProducts && each.invoiceProducts) {
          const { _id, billDate, itemsPrice, vehicleNo, driverName, driverContact, paymentDetails, taxPrice } = each
          const invProducts = each.invoiceProducts;
          const custDetails = each.customerDetails
          invProducts.map((eachInvProd, indx) => {
            console.log('eachInvProd ::==>> ', eachInvProd)
            console.log(' ************ billDate : ', new Date(billDate).toLocaleDateString(), ' ************')
            const invIdInDecimal = convertInvoiceIdToDigits(_id)
            transformedData.push({
              'InvoiceId': invIdInDecimal,
              'Bill Date': billDate ? billDate : '',
              'Id': _id,
              'Vehicle No': vehicleNo ? vehicleNo : '',
              'Customer Name': custDetails && custDetails.shopName ? custDetails.shopName : '',
              'Contact No': custDetails && custDetails.shopOwnerContact ? custDetails.shopOwnerContact : '',
              'Driver Name': driverName ? driverName : "",
              'Driver Contact': driverContact ? driverContact : "",
              'Tax Price': taxPrice ? taxPrice : 0.0,
              'Discount Applied': paymentDetails && paymentDetails.discountApplied
                ? paymentDetails.discountApplied
                : 0.0,
              'Product': eachInvProd.name ? eachInvProd.name : '',
              'Quantity': eachInvProd.quantity,
              'Items Price': eachInvProd && eachInvProd.unitPrice && eachInvProd.quantity ? (eachInvProd.unitPrice * eachInvProd.quantity) : '0.0'
            })
          })
        }
      }
      )
      console.log('######## transformedData ######## ', transformedData ? transformedData.length : 0)
      console.log('######## transformedData ######## ', transformedData ? transformedData : "")
    }
    return transformedData
  }

  const prepareDataForProductWiseSaleReport = () => {
    console.log('Exec prepareDataForProductWiseSaleReport ...')
    if (vehicle_sale_invoice_by_incharge_data && vehicle_sale_invoice_by_incharge_data.length > 0) {
      //vehicle_sale_invoice_by_incharge_data
      if (vehicle_sale_invoice_by_incharge_data) {
        const tempsalesReportByProducts = transformGroupInvoicesByName(vehicle_sale_invoice_by_incharge_data)
        if (tempsalesReportByProducts) {
          console.log('tempsalesReportByProducts Records processed : ', tempsalesReportByProducts ? tempsalesReportByProducts.length : 0)
          setSalesContentProductWiseUsingAGGrid(() => tempsalesReportByProducts)
        }
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  const prepareDataForSalesReport = () => {
    console.log('Exec prepareDataForSalesReport ...')
    if (vehicle_sale_invoice_by_incharge_data && vehicle_sale_invoice_by_incharge_data.length > 0) {
      rowData = vehicle_sale_invoice_by_incharge_data.map((salesByVehicle, index) => {
        const invIdInDecimal = convertInvoiceIdToDigits(salesByVehicle._id)
        return {
          "#": index + 1,
          Id: invIdInDecimal,
          VehicleNo: salesByVehicle.vehicleNo ? salesByVehicle.vehicleNo : "",
          BillDate: salesByVehicle.billDate
            ? salesByVehicle.billDate
            : "",
          CustomerName: salesByVehicle.customerDetails && salesByVehicle.customerDetails.shopName ? salesByVehicle.customerDetails.shopName : "",
          CustomerContact: salesByVehicle.customerDetails && salesByVehicle.customerDetails.shopOwnerContact ? salesByVehicle.customerDetails.shopOwnerContact : "",
          DriverName: salesByVehicle.driverName ? salesByVehicle.driverName : "",
          ItemsPrice: salesByVehicle.itemsPrice
            ? salesByVehicle.itemsPrice
            : 0.0,
          TaxPrice: salesByVehicle.taxPrice
            ? salesByVehicle.taxPrice
            : 0.0,
          AmountPaid: salesByVehicle.paymentDetails && salesByVehicle.paymentDetails.amountPaid
            ? salesByVehicle.paymentDetails.amountPaid
            : 0.0,
          BalanceAmount: salesByVehicle.paymentDetails && salesByVehicle.paymentDetails.balanceAmount
            ? salesByVehicle.paymentDetails.balanceAmount
            : 0.0,
          DiscountApplied: salesByVehicle.paymentDetails && salesByVehicle.paymentDetails.discountApplied
            ? salesByVehicle.paymentDetails.discountApplied
            : 0.0,
          Details: salesByVehicle._id,
        };
      });

      if (rowData) {
        renderContentUsingAGGrid = (
          <div
            className="ag-theme-quartz"
            style={{ width: "100%", height: "65vh" }}
          >
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowStyle={{ textAlign: "left", alignItems: "center" }}
              suppressExcelExport={false}
              popupParent={popupParent}
              pagination="true"
            ></AgGridReact>
          </div>
        );
        setSalesContentUsingAGGrid(() => renderContentUsingAGGrid)
      }
    } else {
      console.log('Exec ELSE AS NO DATA...')
    }
  }

  if (rowData) {
    renderContentUsingAGGrid = (
      <div
        className="ag-theme-quartz"
        style={{ width: "100%", height: "65vh" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowStyle={{ textAlign: "left", alignItems: "center" }}
          suppressExcelExport={false}
          popupParent={popupParent}
          pagination="true"
        // domLayout='autoHeight'
        ></AgGridReact>
      </div>
    );
  }

  renderCustomersUsingCards = (
    <>
      {customer_list_parent_user && customer_list_parent_user.map(eachCustomer => (
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ backgroundColor: "#F2F2F2", marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <CardBody>
              <GridContainer spacing={2} alignItems="center" justify="center">
                <GridItem xs={9} sm={9} md={9}>
                  <Typography
                    style={{ margin: "0.25rem", fontWeight: '600', fontSize: "1rem", alignItems: "left", justifyContent: "left", textAlign: "left" }}
                    color="black"
                  >
                    {eachCustomer.name}
                  </Typography>
                </GridItem>
                <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right", justifyContent: "center", alignItems: "center" }}>
                  <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                    <img
                      alt="curency inr"
                      src={rupeeSvgIcon}
                      style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                      className={classes.imageIcon}
                    />
                  </Icon>
                  {/* {eachCustomer.balance} */}
                  0.0
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      ))}
    </>
  );

  renderTransactionsUsingCards = (
    <>
      {vehicle_sale_invoice_by_incharge_data && vehicle_sale_invoice_by_incharge_data.length > 0 && vehicle_sale_invoice_by_incharge_data.sort((a, b) => {
        return new Date(b.billDate) - new Date(a.billDate);
      }).map(eachVehicleInvoice => {
        // { console.log('eachVehicleInvoice : ', eachVehicleInvoice) }
        const customerDetails = eachVehicleInvoice.customerDetails ? eachVehicleInvoice.customerDetails : null
        const paymentDetails = eachVehicleInvoice.paymentDetails ? eachVehicleInvoice.paymentDetails : null
        const billDate = eachVehicleInvoice.billDate ? eachVehicleInvoice.billDate : null
        const itemsPrice = eachVehicleInvoice.itemsPrice ? eachVehicleInvoice.itemsPrice : null
        const balance = eachVehicleInvoice.itemsPrice ? eachVehicleInvoice.itemsPrice : null
        return (
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ backgroundColor: "#F2F2F2", marginTop: "0.5rem", marginBottom: "0.5rem" }}>
              <CardBody>
                <GridContainer spacing={2} alignItems="center" justify="center">
                  {/* CUSTOMER NAME, BILLDATE START */}
                  <GridItem xs={9} sm={9} md={9}>
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '600', fontSize: "1rem", alignItems: "left", justifyContent: "left", textAlign: "left" }}
                      color="black"
                    >
                      {customerDetails && customerDetails.shopName ? customerDetails.shopName : ""}
                    </Typography>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right", justifyContent: "center", alignItems: "center" }}>
                    {billDate}
                  </GridItem>
                  {/* CUSTOMER NAME, BILLDATE END */}
                  {/* PAID / UNPAID , INV # START */}
                  <GridItem xs={6} sm={6} md={6}>
                    <Typography
                      style={{
                        margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem",
                        color: paymentDetails.balanceAmount && paymentDetails.balanceAmount > 0 ? "#FF0800" : "#008200"
                      }}
                    ><span style={{ backgroundColor: paymentDetails.balanceAmount && paymentDetails.balanceAmount > 0 ? "#FBCEB1" : "#D0F0C0" }}>{paymentDetails.balanceAmount && paymentDetails.balanceAmount > 0 ? 'SALE : UN PAID' : 'SALE : PAID'}</span></Typography>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Typography
                      style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: "right", justifyContent: "center", alignItems: "center" }}
                      color="black"
                    ># {eachVehicleInvoice && eachVehicleInvoice._id ? eachVehicleInvoice._id : ""}</Typography>
                  </GridItem>
                  {/* PAID / UNPAID , INV # END */}
                  <GridItem xs={4} sm={4} md={4}>
                    <Typography
                      style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem" }}
                      color="black"
                    >
                      Total : <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                          className={classes.imageIcon}
                        />
                      </Icon>

                      {paymentDetails && paymentDetails.orderTotal ? formatToLocaleCurrency(Number(paymentDetails.orderTotal)) : 0.0}
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4}>
                    <Typography
                      style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem" }}
                      color="black"
                    >
                      Balance / Due :
                      <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      {paymentDetails && paymentDetails.balanceAmount ? formatToLocaleCurrency(Number(paymentDetails.balanceAmount)) : 0.0}
                    </Typography>
                  </GridItem>
                  <GridItem xs={4} sm={4} md={4} style={{ textAlign: "right" }}>
                    <Button
                      size="small"
                      variant="contained"
                      type="submit"
                      color="secondary"
                      onClick={() => showPaymentDetails(eachVehicleInvoice._id)}
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        textAlign: "right"
                      }}
                      disabled={paymentDetails && paymentDetails.balanceAmount && paymentDetails.balanceAmount > 0 ? false : true}
                    >
                      Pay Now
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )
      })
      }
    </>
  )

  const renderVehicleStockSourceUsingCards = (
    <>
      <GridItem xs={12} sm={12} md={12}>
        <Card style={{ backgroundColor: "#f8f4f4", marginTop: "0.5rem", marginBottom: "0.5rem" }}>
          <CardBody>
            <GridContainer spacing={2} alignItems="center" justify="center">
              <GridItem xs={9} sm={9} md={9}>
                <Typography
                  style={{ margin: "0.25rem", fontWeight: '600', fontSize: "1rem", alignItems: "CENTER", justifyContent: "CENTER", textAlign: "CENTER" }}
                  color="black"
                >
                  PICKED GOODS FROM : {availableProductsInVehicle && availableProductsInVehicle.length > 0 && availableProductsInVehicle[0].fromName ? availableProductsInVehicle[0].fromName : ""}
                </Typography>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </>
  )

  renderVehilceStockItemsUsingCards = (
    <>
      {availableProductsInVehicle && availableProductsInVehicle.map(eachProduct => {
        return (
          <GridItem xs={12} sm={12} md={12}>
            <Card style={{ backgroundColor: "#F2F2F2", marginTop: "0.5rem", marginBottom: "0.5rem" }}>
              <CardBody>
                <GridContainer spacing={2} alignItems="center" justify="center">
                  {/* PRODUCT NAME START*/}
                  <GridItem xs={9} sm={9} md={9}>
                    <Typography
                      style={{ margin: "0.25rem", fontWeight: '600', fontSize: "1rem", alignItems: "left", justifyContent: "left", textAlign: "left" }}
                      color="black"
                    >
                      {eachProduct && eachProduct.productName ? eachProduct.productName : ""}
                    </Typography>
                  </GridItem>
                  <GridItem xs={3} sm={3} md={3} style={{ textAlign: "right", justifyContent: "center", alignItems: "center" }}>
                    <Typography
                      style={{ margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: "right", justifyContent: "center", alignItems: "center" }}
                      color="black"
                    >
                      Current Stock # {eachProduct && eachProduct.countInStock ? eachProduct.countInStock : 0} kgs
                    </Typography>
                  </GridItem>
                  {/* PRODUCT NAME END */}
                  {/* PAID / UNPAID , INV # START */}
                  {/*<GridItem xs={6} sm={6} md={6}>
                    <Typography
                      style={{
                        margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem",
                        color: "gray"
                      }}
                    >MRP
                      <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                      <span style={{ backgroundColor: eachProduct.name && eachProduct.name > 0 ? "#FBCEB1" : "#D0F0C0" }}>{eachProduct && eachProduct.name && eachProduct.name.length > 0 ? eachProduct.name : ''} </span></Typography>
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    <Typography
                      style={{
                        margin: "0.2rem", fontWeight: '600', fontSize: "0.75rem", textAlign: "right", justifyContent: "center", alignItems: "center",
                        color: "#008200"
                      }}
                    >Selling Price
                      <Icon classes={{ root: classes.iconRoot }} style={{ verticalAlign: "center", color: "gray" }}>
                        <img
                          alt="curency inr"
                          src={rupeeSvgIcon}
                          style={{ height: "1rem", verticalAlign: "center", color: "gray", paddingTop: "0.10rem" }}
                          className={classes.imageIcon}
                        />
                      </Icon>
                    </Typography>
                  </GridItem>*/}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )
      })
      }
    </>
  )

  renderReturnStock = (
    <GridContainer spacing={2} alignItems="center" justify="center">
      <GridItem xs={12} sm={12} md={12} style={{ textAlign: "center" }}>
        <Tooltip title="Return Stock" arrow>
          <Button
            size="small"
            variant="contained"
            type="submit"
            color="secondary"
            style={{ textTransform: "capitalize", width: "20vh", borderRadius: "5px" }}
            onClick={() => {
              console.log('CLICKED RETURN STOCK', availableProductsInVehicle ? availableProductsInVehicle.length : 0, ' vehicleNo :==>> ', vehicleNo)
              if (vehicleNo) {
                dispatch(updateReturnStockFromVehicleNoByProductName(availableProductsInVehicle, vehicleNo))  // to vehicleStock Out Deleting Records from Vehicle
                dispatch(updateReturnStockToStoreByProductNames(availableProductsInVehicle))  // to storeStock Return
              }
            }
            }
          >
            <Typography
              style={{
                margin: "0.5rem",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                fontFamily: 'Montserrat, Arial, sans-serif',
                fontWeight: "600",
                fontSize: "0.85rem"
              }}
            >Return Stock</Typography>
          </Button>
        </Tooltip>
      </GridItem>
    </GridContainer>
  )

  return (
    <React.Fragment>
      {vehicle_sale_invoice_by_incharge_loading && <CustomBackdropSpinner />}
      {vehicle_return_stock_update_loading && <CustomBackdropSpinner />}
      {/* vehicle_return_stock_update_success */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <GridContainer spacing={2} alignItems="center" justify="center">
            <Grid item xs={12} >
              <Paper style={{ margin: "1rem", width: "15rem", height: "15rem" }}>
                <div style={{ margin: "1rem", width: "10rem", height: "10rem", textAlign: "center", justifyContent: "center", alignItems: "center" }} >
                  <img
                    alt="Order Placed"
                    src={greeTickImage}
                    style={{ margin: "1rem", width: "9.5rem", height: "9.5rem" }}
                  />
                </div>
                <Typography
                  variant="h5"
                  color="black"
                  style={{
                    margin: "0.5rem", textAlign: "center", justifyContent: "center", alignItems: "center",
                    fontWeight: "600",
                    fontSize: "1rem",
                    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
                  }}
                >
                  Thanks , Returned Stock Successfully
                </Typography>
              </Paper>
            </Grid>
          </GridContainer>
        </DialogContent>
      </BootstrapDialog>
      <GridContainer spacing={2} alignItems="center" justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader>
              <Typography variant="h5" style={{
                fontWeight: 600,
                textAlign: "Center"
              }}>
                <Tooltip title="Back" arrow>
                  <ArrowBackIcon
                    fontSize="medium"
                    onClick={() => window.history.back()}
                    style={{ float: "left", marginRight: "3em" }}
                  />
                </Tooltip>
                Vehicle Sales  # <span variant="h5" style={{
                fontWeight: 900,
                textAlign: "Center"
              }}>
                {vehicleNo ? vehicleNo : ""} </span>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  color="secondary"
                  justify="center"
                  onClick={createHandler}
                  style={{ textTransform: "capitalize", borderRadius: "5px", float: 'right', textAlign: 'right' }}
                >
                  <AddCircleIcon
                    fontSize="medium"
                    style={{ float: "left", marginRight: "10px" }}
                  />
                  Vehicle Invoice
                </Button>
              </Typography>
            </CardHeader>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer spacing={1} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
        <div style={{ marginBottom: "2.5rem" }}>
          <Stack direction="row" spacing={10} style={{ textAlign: "center", justifyContent: "center", alignItems: "center" }}>
            {initialChips.map((chip) => (
              <Chip
                key={chip.id}
                label={chip.label}
                variant="outlined"
                onClick={() => handleChipClick(chip.id)}
                style={{
                  backgroundColor: selectedChipId === chip.id ? '#f0c14b' : '#D3D3D3',
                  color: selectedChipId === chip.id ? 'white' : 'black',
                  width: "7.5rem"
                }}
              />
            ))}
          </Stack>
        </div>
      </GridContainer>
      {selectedChipId && selectedChipId === 1 && <GridContainer style={{ height: "50%", width: '100%', overflowY: 'auto' }}>
        <GridItem xs={12} sm={12} md={12} >
          {renderCustomersUsingCards ? renderCustomersUsingCards : noDataFoundContent}
        </GridItem>
      </GridContainer>}
      {selectedChipId && selectedChipId === 2 && <GridContainer style={{ textAlign: "center", justifyContent: "center", alignItems: "center", height: "50%", width: '100%', overflowY: 'auto' }}>
        <GridItem xs={12} sm={12} md={12}>
          {renderTransactionsUsingCards ? renderTransactionsUsingCards : noDataFoundContent}
        </GridItem>
      </GridContainer>}
      {selectedChipId && selectedChipId === 3 && <GridContainer style={{ height: "50%", width: '100%', overflowY: 'auto' }}>
        <GridItem xs={12} sm={12} md={12} >
          {availableProductsInVehicle && availableProductsInVehicle.length > 0 ? renderVehicleStockSourceUsingCards : null}
          {availableProductsInVehicle && availableProductsInVehicle.length > 0 ? renderVehilceStockItemsUsingCards : emptyVehicleStockContent}
          {availableProductsInVehicle && availableProductsInVehicle.length > 0 ? renderReturnStock : null}
        </GridItem>
      </GridContainer>}
    </React.Fragment>
  );
};

export default VehicleSalesInvoiceListScreen;
